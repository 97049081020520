import { FiltroIcon, SearchIcon } from "../../../../Components/Icons";
import PagamentoStatus from "../../../../Enums/PagamentoStatus";
import { PagamentoStatusToString } from "../../../../Utils";

interface Props {
  handleFiltro: () => void;
  filtroRadioButtonsTipoBusca: { label: string; value: string }[];
  filtros: {
    filtroString: string;
    tipoBusca: string;
    filtroStatus: PagamentoStatus | null;
  };
  setFiltros: React.Dispatch<
    React.SetStateAction<{
      filtroString: string;
      tipoBusca: string;
      filtroStatus: PagamentoStatus | null;
    }>
  >;
}

export default function FiltroPedidos({
  handleFiltro,
  filtroRadioButtonsTipoBusca,
  filtros,
  setFiltros,
}: Props) {
  return (
    <ul className="navbar-nav flex-nowrap">
      <li className="nav-item dropdown no-arrow">
        <div className="nav-item dropdown no-arrow">
          <a
            className="dropdown-toggle nav-link cursor-pointer"
            aria-expanded="false"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
          >
            <button
              type="button"
              className="btn-outline py-2 px-3 me-md-3 mb-2 mb-md-0 d-flex justify-content-center align-items-center"
            >
              <p className="text-500-black-16 pe-2 m-0">Filtrar</p>
              <FiltroIcon />
            </button>
          </a>
          <div className="dropdown-menu shadow dropdown-menu-end p-3 dropdown-filtro">
            <p className="text-400-black-24 mb-2">Filtrar</p>
            <p className="text-400-black-14 mb-3">
              Faça uma busca por nome, documento ou e-mail do cliente e/ou filtre
              pelos status de pagamento abaixo
            </p>

            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleFiltro();
              }}
            >
              {/* Filtro por string */}
              <div className="input-group mb-3 rounded-pill">
                <button
                  className="btn btn-light py-0 pe-0"
                  style={{ borderRadius: "50px 0 0 50px" }}
                  type="submit"
                >
                  <SearchIcon />
                </button>
                <input
                  className="bg-light form-control border-0 small"
                  style={{ borderRadius: "0 50px 50px 0" }}
                  type="text"
                  placeholder="Faça uma busca"
                  value={filtros.filtroString}
                  onChange={({ target: { value } }) =>
                    setFiltros({
                      ...filtros,
                      filtroString: value,
                    })
                  }
                />
              </div>

              {/* Filtro por tipo de busca: Nome / E-mail / Documento */}
              <div
                className="p-3 pb-2 mb-3"
                style={{ borderRadius: 16, background: "#F9F8F8" }}
              >
                {filtroRadioButtonsTipoBusca.map((btn, index) => (
                  <div key={index} className="form-check mb-2">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="tipoBusca"
                      id={btn.value}
                      value={btn.value}
                      checked={filtros.tipoBusca === btn.value}
                      onChange={() =>
                        setFiltros({
                          ...filtros,
                          tipoBusca: btn.value,
                        })
                      }
                    />
                    <label className="form-check-label" htmlFor={btn.value}>
                      {btn.label}
                    </label>
                  </div>
                ))}
              </div>

              {/* Filtro por status */}
              <div className="px-3 mb-4">
                {Object.values(PagamentoStatus).map((status) => (
                  <div key={status} className="form-check mb-2">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="status"
                      id={status}
                      value={status}
                      checked={filtros.filtroStatus === status}
                      onChange={() =>
                        setFiltros({
                          ...filtros,
                          filtroStatus: status,
                        })
                      }
                    />
                    <label className="form-check-label" htmlFor={status}>
                      {PagamentoStatusToString(status)}
                    </label>
                  </div>
                ))}
              </div>

              <button
                type="submit"
                className="bc-btn bc-btn-primary px-3 py-2 w-100"
              >
                Filtrar
              </button>
            </form>
          </div>
        </div>
      </li>
    </ul>
  );
}
