import ReactModal from "react-modal";
import { CloseIcon } from "../Icons";
import { ModalProps } from "./EmptyModal";

ReactModal.setAppElement("#root");

interface PromptModalProps extends ModalProps {
  icon?: JSX.Element;
  title?: string;
  subtitle?: string;
  confirmText?: string;
  cancelText?: string;
  confirmAction: () => void | ((args: any) => void) | Promise<void>;
  cancelAction: () => void;
  backgroundModal?: string;
  colorTextModal?: string;
  removeCancelButton?: boolean;
  loading: boolean;
}

export default function PromptModal({
  children,
  isOpen,
  close,
  width,
  icon,
  title,
  subtitle,
  confirmText,
  cancelText,
  confirmAction,
  cancelAction,
  backgroundModal,
  colorTextModal,
  removeCancelButton,
  loading,
}: PromptModalProps) {
  const customStyles = {
    content: {
      maxWidth: width,
      background: backgroundModal,
    },
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={close}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      style={customStyles}
      className="modal-content"
      overlayClassName="modal-overlay"
      contentLabel="Example Modal"
    >
      <div className="mb-4 d-flex justify-content-end align-items-center">
        <div onClick={close} className="cursor-pointer">
          <CloseIcon />
        </div>
      </div>
      <div className="row d-flex justify-content-center align-itens-center text-center">
        {icon}

        <p className={`title-h1 text-black mb-2 ${colorTextModal}`}>{title}</p>
        <p
          className={`text-400-black-16 text-break mb-5 w-75 ${colorTextModal}`}
          style={{ whiteSpace: "pre-line" }}
        >
          {subtitle}
        </p>

        <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-sm-evenly align-items-center mb-3">
          {!removeCancelButton && (
            <button
              className="bc-btn bc-btn-light px-3 py-2 h-100 mb-3 mb-sm-0"
              style={{ width: 175 }}
              onClick={cancelAction}
            >
              {cancelText}
            </button>
          )}

          <button
            className="bc-btn bc-btn-primary px-3 py-2 h-100"
            style={{ width: 175 }}
            onClick={confirmAction}
            disabled={loading}
          >
            {confirmText}
          </button>
        </div>
      </div>
    </ReactModal>
  );
}
