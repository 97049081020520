import CupomDescontoModel from "../../../../Interfaces/Models/CupomDescontoModel";
import BotoesListagemCupom from "./BotoesListagemCupom";

interface Props {
  cupons: CupomDescontoModel[];
  eventoId: string;
  updateStatusCupons: (cupom: CupomDescontoModel) => void;
  abrirModalExcluirCupom: (cupom: CupomDescontoModel) => void;
}

export default function CuponsDesconto({
  cupons,
  eventoId,
  updateStatusCupons,
  abrirModalExcluirCupom,
}: Props) {
  return (
    <div className="row">
      <div className="d-none d-lg-block col-lg-12 mb-3">
        <div className="row">
          <div className="col-lg-3">
            <p className="text-500-black-16 m-0">Cupom</p>
          </div>
          <div className="col-lg-1">
            <p className="text-500-black-16 m-0">Desconto</p>
          </div>

          <div className="col-lg-3">
            <p className="text-500-black-16 m-0"></p>
          </div>
        </div>
      </div>

      {cupons.map((cupom) => (
        <div className="col-lg-12" key={cupom.id}>
          <div className="card-cinza-claro p-3 mb-3">
            <div className="row">
              <div className="col-lg-3 align-self-center">
                <p className="d-block d-lg-none text-500-black-16 m-0">Cupom</p>
                <p className="text-500-darkest-14 m-0 text-break">
                  {cupom.codigo}
                </p>
              </div>
              <div className="col-lg-3 align-self-center">
                <p className="d-block d-lg-none text-500-black-16 m-0">Cupom</p>
                <p className="text-500-darkest-14 m-0">{cupom.desconto}%</p>
              </div>
              <div className="col-lg-6 align-self-center">
                <BotoesListagemCupom
                  cupom={cupom}
                  eventoId={eventoId}
                  abrirModalExcluirCupom={abrirModalExcluirCupom}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
