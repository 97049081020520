export default function EditModalIcon() {
  return (
    <svg
      width="192"
      height="192"
      viewBox="0 0 192 192"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="96.3182"
        cy="96.3182"
        rx="61.8182"
        ry="61.8182"
        fill="#303F9F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.7274 110.979V118.809C73.7274 119.531 74.294 120.097 75.0152 120.097H82.8455C83.1804 120.097 83.5152 119.968 83.7471 119.711L111.874 91.6094L102.215 81.9503L74.1137 110.052C73.8561 110.309 73.7274 110.618 73.7274 110.979ZM119.344 84.1397C119.826 83.6584 120.097 83.0051 120.097 82.3238C120.097 81.6425 119.826 80.9892 119.344 80.5079L113.317 74.4806C112.835 73.9984 112.182 73.7274 111.501 73.7274C110.82 73.7274 110.166 73.9984 109.685 74.4806L104.971 79.1943L114.63 88.8533L119.344 84.1397Z"
        fill="white"
      />
    </svg>
  );
}
