export default function CodigoPromocionalIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.11257 2C2.49812 2 2 2.49812 2 3.11257V12.384C2 12.6793 2.1174 12.9625 2.32632 13.1712L10.2897 21.1253C10.5652 21.4011 10.8924 21.6199 11.2525 21.7692C11.6127 21.9185 11.9988 21.9954 12.3887 21.9954C12.7786 21.9954 13.1646 21.9185 13.5248 21.7692C13.885 21.6199 14.2122 21.4011 14.4877 21.1252L21.1349 14.478L20.3482 13.6913L21.1373 14.4757C21.6898 13.9198 22 13.1678 22 12.384C22 11.6002 21.6898 10.8483 21.1373 10.2924L13.1707 2.32587C12.9621 2.11722 12.6791 2 12.384 2H3.11257ZM19.56 12.9061L19.5592 12.9069L12.9134 19.5527C12.8445 19.6216 12.7627 19.6763 12.6727 19.7137C12.5827 19.751 12.4861 19.7702 12.3887 19.7702C12.2912 19.7702 12.1947 19.751 12.1046 19.7137C12.0146 19.6763 11.9328 19.6216 11.8639 19.5527L4.22515 11.9228V4.22515H11.9232L19.5592 11.8611L19.56 11.8619C19.6976 12.0008 19.7748 12.1885 19.7748 12.384C19.7748 12.5796 19.6976 12.7672 19.56 12.9061ZM7.74831 6.54831C7.08557 6.54831 6.54831 7.08557 6.54831 7.74831C6.54831 8.41106 7.08557 8.94831 7.74831 8.94831H7.75831C8.42106 8.94831 8.95831 8.41106 8.95831 7.74831C8.95831 7.08557 8.42106 6.54831 7.75831 6.54831H7.74831Z"
        fill={
          window.location.pathname.includes("/codigo-promocional")
            ? "#fff"
            : "#9d9d9d"
        }
      />
    </svg>
  );
}
