enum TransacaoSituacao {
  Criada = "Criada",
  Solicitada = "Solicitada",
  Aprovada = "Aprovada",
  Concluida = "Concluida",
  Recusada = "Recusada",
  Confirmada = "Confirmada",
  Agendada = "Agendada",
}

export default TransacaoSituacao;
