import { useEffect, useState } from "react";
import BreadCrumb, { Page } from "../../Components/Breadcrumb";
import MainLayout from "../../Components/Layouts/Main";
import WhiteContainer from "../../Components/WhiteContainer";
import FiltroCategorias from "./Components/FiltroCategorias";
import Input from "../../Components/Inputs/Input";
import { useForm } from "react-hook-form";
import ValidationError from "../../Components/ValidationError";
import SkeletonListagem from "../../Components/SkeletonListagem";
import { CategoriaModel } from "../../Interfaces/Models/CategoriaModel";
import AxiosClient from "../../Services/AxiosClient";
import { ListServiceResult } from "../../Interfaces";
import toast from "react-hot-toast";
import AxiosErrorHandler from "../../Services/AxiosErrorHandler";
import DarkGrayCard from "../../Components/DarkGrayCard";
import PromptModal from "../../Components/Modals/PromptModal";
import { DangerModalIcon } from "../../Components/Icons";
import { useParams } from "react-router-dom";
import BotoesListagemCategorias from "./Components/BotoesListagemCategorias";
import CategoriaSituacao from "../../Enums/CategoriaSituacao";

const breadCrumbHistory: Page[] = [
  {
    link: "/",
    name: "Dashboard",
  },
  {
    link: "/categorias",
    name: "Categorias",
  },
];

interface CategoriasPostArgs {
  nome: string;
  descricao: string | null;
  situacao: CategoriaSituacao | null;
}

export default function Categorias() {
  const { categoriaId } = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [categorias, setCategorias] = useState<CategoriaModel[]>([]);
  const [pagina, setPagina] = useState<number>(1);
  const [possuirMaisCategorias, setPossuirMaisCategorias] =
    useState<boolean>(false);
  const [filtroBusca, setFiltroBusca] = useState<string>("");
  const [filtrosAplicados, setFiltrosAplicados] = useState<string[]>([]);
  const [modalExcluirCategoria, setModalExcluirCategoria] = useState<
    string | null
  >(null);
  const [categoriaExcluir, setCategoriaExcluir] = useState<CategoriaModel>(
    {} as CategoriaModel,
  );
  const [acessoNegadoCategorias, setAcessoNegadoCategorias] =
    useState<boolean>(false);

  const handleFiltro = () => {
    setFiltrosAplicados([]);

    if (filtroBusca) {
      setFiltrosAplicados((prev) => [...prev, filtroBusca]);
    }

    setPagina(1);
    setCategorias([]);
  };

  const removerFiltro = (filtro: string): void => {
    if (filtro === filtroBusca) {
      setFiltroBusca("");
    }

    setFiltrosAplicados((prev) => prev.filter((f) => f !== filtro));
    setCategorias([]);
  };

  const getRequestPathWIthQueryParams = (): string => {
    let url: string = `/categorias`;
    url += `?pagina=${pagina}`;
    url += "$paginaTamanho=100";

    if (filtroBusca) {
      url += `&searchString=${filtroBusca}`;
    }

    return url;
  };

  const getCategorias = async (): Promise<void> => {
    setLoading(true);

    AxiosClient.get<ListServiceResult<CategoriaModel>>(
      getRequestPathWIthQueryParams(),
    )
      .then(({ data }) => {
        setCategorias((prev) => {
          if (pagina === 1) {
            return data?.data ?? [];
          } else {
            return [...prev, ...(data?.data ?? [])];
          }
        });
        //setPossuirMaisCategorias(data.pages! > data.page!);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          setAcessoNegadoCategorias(true);
          return;
        }

        toast.error(AxiosErrorHandler(error));
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    function handleScroll() {
      const { scrollTop, scrollHeight, clientHeight } =
        document.documentElement;

      if (
        !loading &&
        possuirMaisCategorias &&
        scrollTop + clientHeight >= scrollHeight - 500
      ) {
        setLoading(true);
        setPagina(pagina + 1);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loading, pagina, possuirMaisCategorias]);

  useEffect(() => {
    getCategorias();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagina, filtrosAplicados]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CategoriasPostArgs>({
    defaultValues: {
      nome: "",
      descricao: "",
      situacao: CategoriaSituacao.Ativo,
    },
  });

  const cadastrarCategoria = async (data: CategoriasPostArgs) => {
    setLoading(true);

    let args: CategoriasPostArgs = {
      nome: data.nome,
      descricao: data.descricao,
      situacao: data.situacao,
    };

    toast.promise(
      AxiosClient.post("/categorias", args).finally(() => setLoading(false)),
      {
        loading: "Cadastrando categoria...",
        success: () => {
          return "Categoria cadastrada com sucesso!";
        },
        error: (error) => AxiosErrorHandler(error),
      },
    );
  };

  const abrirModalExcluirCategoria = (categoria: CategoriaModel): void => {
    setCategoriaExcluir(categoria);
    setModalExcluirCategoria(categoria.id);
  };

  const excluirCategoria = async (): Promise<void> => {
    setModalExcluirCategoria(null);
    setLoading(true);

    toast.promise(
      AxiosClient.delete(`/categorias/${categoriaExcluir.id}`)
        .then(() => {
          const categoriasCopy = [...categorias];

          const index = categoriasCopy.findIndex(
            (c) => c.id === categoriaExcluir.id,
          );
          categoriasCopy.splice(index, 1);

          setCategorias(categoriasCopy);
        })
        .finally(() => setLoading(false)),
      {
        loading: "Excluindo categoria...",
        success: "Categoria excluída com sucesso!",
        error: (error) => AxiosErrorHandler(error),
      },
    );
  };

  return (
    <MainLayout>
      <div className="mb-3">
        <BreadCrumb history={breadCrumbHistory} />
      </div>

      <WhiteContainer containerPadding={4} containerWidth={12}>
        <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-sm-between align-items-center">
          <p className="title-h1 text-black text-center mb-2">Categorias</p>
          <FiltroCategorias
            filtroBusca={filtroBusca}
            setFiltroBusca={setFiltroBusca}
            handleFiltro={handleFiltro}
          />
        </div>
      </WhiteContainer>

      <form onSubmit={handleSubmit(cadastrarCategoria)}>
        <WhiteContainer containerPadding={4} rowMarginBottom={4}>
          <div className="row g-3">
            <div className="col-xl-4">
              <Input
                name="nome"
                control={control}
                errors={errors}
                validation={{
                  required: "Informe o título da categoria",
                }}
                placeholder="Informe o título da categoria"
              />
              {errors.nome && ValidationError(errors.nome)}
            </div>

            <div className="col-xl-4">
              <Input
                name="descricao"
                control={control}
                errors={errors}
                validation={{}}
                placeholder="Informe a descrição da categoria"
              />
              {errors.descricao && ValidationError(errors.descricao)}
            </div>

            <div className="col-xl-4 d-flex justify-content-xl-end align-items-xl-end">
              <button className="bc-btn bc-btn-primary py-2 px-3 d-flex justify-content-center align-items-center">
                <p className="text-500-white-16 m-0">Adicionar</p>
              </button>
            </div>
          </div>
        </WhiteContainer>
      </form>

      <WhiteContainer
        rowMarginBottom={4}
        containerPadding={4}
        containerWidth={12}
      >
        {filtrosAplicados.length > 0 && (
          <div className="d-flex flex-wrap align-items-center justify-content-center mb-4">
            {filtrosAplicados.map((filtro, index) => (
              <div className="me-3 mb-2" key={index}>
                <div
                  className="d-flex flex-wrap justify-content-center align-items-center px-4"
                  style={{ borderRadius: 50, background: "#F9F8F8" }}
                >
                  <p className="text-400-black-16 m-0 me-2 my-2">Filtro:</p>
                  <p className="text-400-black-16 me-2 my-2 text-break">
                    {filtro}
                  </p>
                  <button
                    className="btn-close my-2"
                    onClick={() => removerFiltro(filtro)}
                  ></button>
                </div>
              </div>
            ))}
          </div>
        )}

        {loading ? (
          <SkeletonListagem />
        ) : acessoNegadoCategorias ? (
          <div className="mb-3">
            <DarkGrayCard message="Você não tem permissão para listar categorias." />
          </div>
        ) : categorias.length === 0 ? (
          <DarkGrayCard message="Você ainda não tem categorias por aqui!" />
        ) : (
          <div className="row">
            <div className="d-none d-lg-block col-lg-12 mb-3">
              <div className="row">
                <div className="col-lg-3">
                  <p className="text-500-black-16 m-0">Título</p>
                </div>
                <div className="col-lg-3">
                  <p className="text-500-black-16 m-0">Descrição</p>
                </div>
                <div className="col-lg-3">
                  <p className="text-500-black-16 m-0">Situação</p>
                </div>
                <div className="col-lg-3">
                  <p className="text-500-black-16 m-0">Ações</p>
                </div>
              </div>
            </div>

            {categorias.map((categoria) => (
              <div className="col-lg-12" key={categoria.id}>
                <div className="card-cinza-claro p-3 mb-3">
                  <div className="row">
                    <div className="align-self-center col-lg-3">
                      <p className="d-block d-lg-none text-500-black-16 m-0 mt-3">
                        Título
                      </p>
                      <p className="text-500-darkest-14 text-break m-0">
                        {categoria.nome}
                      </p>
                    </div>

                    <div className="align-self-center col-lg-3">
                      <p className="d-block d-lg-none text-500-black-16 m-0 mt-3">
                        Descrição
                      </p>
                      <p className="text-500-darkest-14 text-break m-0">
                        {categoria.descricao}
                      </p>
                    </div>

                    <div className="align-self-center col-lg-3">
                      <p className="d-block d-lg-none text-500-black-16 m-0 mt-3">
                        Situação
                      </p>
                      <p className="text-500-darkest-14 text-break m-0">
                        {categoria.situacao}
                      </p>
                    </div>

                    <div className="align-self-center col-lg-3">
                      <p className="d-block d-lg-none text-500-black-16 m-0 mt-3">
                        Ações
                      </p>
                      <BotoesListagemCategorias
                        categoriaId={categoriaId!}
                        categoria={categoria}
                        updateSituacaoCategoria={() => getCategorias()}
                        abrirModalExcluirCategoria={abrirModalExcluirCategoria}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </WhiteContainer>

      <PromptModal
        isOpen={modalExcluirCategoria !== null}
        close={() => setModalExcluirCategoria(null)}
        icon={<DangerModalIcon />}
        title="Excluir categoria"
        subtitle={`Tem certeza que deseja excluir a categoria "${categoriaExcluir.nome}" ? Após o cancelamento sua categoria ficará inativa`}
        cancelText="Cancelar"
        cancelAction={() => setModalExcluirCategoria(null)}
        confirmText="Excluir"
        confirmAction={excluirCategoria}
        loading={loading}
      />
    </MainLayout>
  );
}
