import axios from "axios";

const ViaCepClient = () => {
  const defaultOptions = {
    baseURL: "https://viacep.com.br/",
  };

  let instance = axios.create(defaultOptions);

  return instance;
};

export default ViaCepClient();
