import { useParams, useSearchParams } from "react-router-dom";
import BreadCrumb, { Page } from "../../../Components/Breadcrumb";
import MainLayout from "../../../Components/Layouts/Main";
import WhiteContainer from "../../../Components/WhiteContainer";
import { useEffect, useState } from "react";
import { ListagemPedidosModel } from "../../../Interfaces/Models";
import FiltroPedidos from "./Components/FiltroPedidos";
import DarkGrayCard from "../../../Components/DarkGrayCard";
import AxiosClient from "../../../Services/AxiosClient";
import { ListServiceResult } from "../../../Interfaces";
import PagamentoStatus from "../../../Enums/PagamentoStatus";
import AxiosErrorHandler from "../../../Services/AxiosErrorHandler";
import toast from "react-hot-toast";
import {
  FormatarCentavosParaReais,
  PagamentoStatusToString,
} from "../../../Utils";
import ListagemPedidos from "./Components/ListagemPedidos";
import PromptModal from "../../../Components/Modals/PromptModal";
import { DangerModalIcon } from "../../../Components/Icons";
import moment from "moment";
import SkeletonListagem from "../../../Components/SkeletonListagem";
import TipoPagamento from "../../../Enums/TipoPagamento";
import useObterNomeEventoHeader from "../../../Hooks/useObterNomeEventoHeader";
import { TablePagination } from "../../../Components/table-pagination";

const filtroRadioButtonsTipoBusca = [
  { label: "Nome", value: "nome" },
  { label: "E-mail", value: "email" },
  { label: "Documento", value: "documento" },
];

export default function Pedidos() {
  const { eventoId } = useParams();

  const { obterNomeEvento } = useObterNomeEventoHeader();

  const [numeroPaginas, setNumeroPaginas] = useState<number>(0);
  const [searchParams] = useSearchParams();

  const pagina = Number(searchParams.get("page")) || 1;
  const paginaTamanho = Number(searchParams.get("size")) || 10;

  const breadCrumbHistory: Page[] = [
    {
      link: "/",
      name: "dashboard",
    },
    {
      link: "/eventos",
      name: "Eventos",
    },
    {
      link: `/eventos/${eventoId}/pedidos`,
      name: "Pedidos",
    },
  ];

  const [loading, setLoading] = useState<boolean>(false);
  const [exportingCsv, setExportingCsv] = useState<boolean>(false);
  const [acessoNegadoPedidos, setAcessoNegadoPedidos] =
    useState<boolean>(false);

  const [pedidos, setPedidos] = useState<ListagemPedidosModel[]>([]);
  const [filtros, setFiltros] = useState<{
    filtroString: string;
    tipoBusca: string;
    filtroStatus: PagamentoStatus | null;
  }>({
    filtroString: "",
    tipoBusca: filtroRadioButtonsTipoBusca[0].value,
    filtroStatus: null,
  });
  const [filtrosAplicados, setFiltrosAplicados] = useState<string[]>([]);

  const handleFiltro = () => {
    setFiltrosAplicados([]);

    if (filtros.filtroString) {
      setFiltrosAplicados((prev) => [...prev, filtros.filtroString]);
    }

    if (filtros.filtroStatus) {
      setFiltrosAplicados((prev) => [...prev, filtros.filtroStatus!]);
    }

    setPedidos([]);
  };

  const removerFiltro = (filtro: string): void => {
    if (filtro === filtros.filtroString) {
      setFiltros({
        ...filtros,
        filtroString: "",
        tipoBusca: filtroRadioButtonsTipoBusca[0].value,
      });
    }

    if (filtro === filtros.filtroStatus) {
      setFiltros({
        ...filtros,
        filtroStatus: null,
      });
    }

    setFiltrosAplicados((prev) => prev.filter((f) => f !== filtro));

    setPedidos([]);
  };

  const obterNomeDoFiltroAplicado = (filtro: string): string => {
    if (Object.values(PagamentoStatus).includes(filtro as PagamentoStatus)) {
      return PagamentoStatusToString(filtro as PagamentoStatus);
    }

    return filtro;
  };

  const getRequestPathWIthQueryParams = (): string => {
    let url: string = `/eventos/${eventoId}/pedidos`;
    url += `?pagina=${pagina}`;
    url += `&paginaTamanho=${paginaTamanho}`;

    if (filtros.filtroString) {
      url += `&${filtros.tipoBusca}=${filtros.filtroString}`;
    }

    if (filtros.filtroStatus) {
      url += `&status=${filtros.filtroStatus}`;
    }

    return url;
  };

  const getPedidos = async (): Promise<void> => {
    setLoading(true);

    AxiosClient.get<ListServiceResult<ListagemPedidosModel>>(
      getRequestPathWIthQueryParams()
    )
      .then(({ data }) => {
        setNumeroPaginas(data?.pages || 0);
        setPedidos(data?.data ?? []);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          setAcessoNegadoPedidos(true);
          return;
        }
        toast.error(AxiosErrorHandler(error));
      })
      .finally(() => setLoading(false));
  };

  const exportarCsv = async (): Promise<void> => {
    setExportingCsv(true);

    toast.promise(
      AxiosClient.get(`/eventos/${eventoId}/pedidos/csv`, {
        responseType: "blob",
      })
        .then(({ data }) => {
          const blob = new Blob([data], {
            type: "text/csv",
          });

          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `pedidos-${moment().format("DD-MM-YYYY")}.csv`;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .finally(() => setExportingCsv(false)),
      {
        loading: "Exportando CSV...",
        success: "CSV exportado com sucesso!",
        error: (error) => AxiosErrorHandler(error),
      }
    );
  };

  const [auxPedidoModel, setAuxPedidoModel] = useState<ListagemPedidosModel>(
    {} as ListagemPedidosModel
  );
  const [modalCancelarPedido, setModalCancelarPedido] = useState<string | null>(
    null
  );

  const abrirModalCancelarPedido = (pedido: ListagemPedidosModel) => {
    setAuxPedidoModel(pedido);
    setModalCancelarPedido(pedido.id);
  };

  const cancelarPedido = async (): Promise<void> => {
    setLoading(true);
    setModalCancelarPedido(null);

    toast.promise(
      AxiosClient.delete(`/eventos/${eventoId}/pedidos/${auxPedidoModel.id}`)
        .then(() => {
          setPedidos((prev) =>
            prev.map((p) => {
              if (p.id === auxPedidoModel.id) {
                p.statusPedido = PagamentoStatus.Devolvido;
              }

              return p;
            })
          );
        })
        .finally(() => {
          setLoading(false);
        }),
      {
        loading: "Cancelando pedido...",
        success: "Pedido estornado com sucesso!",
        error: (error) => AxiosErrorHandler(error),
      }
    );
  };

  useEffect(() => {
    getPedidos();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagina, paginaTamanho, filtrosAplicados]);

  useEffect(() => {
    obterNomeEvento();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout>
      <div className="mb-3">
        <BreadCrumb history={breadCrumbHistory} />
      </div>

      <WhiteContainer containerPadding={4} containerWidth={12}>
        <div className="d-flex flex-column flex-xl-row justify-content-xl-between align-items-center">
          <p className="title-h1 text-black text-center mb-2">Pedidos</p>

          <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
            <FiltroPedidos
              handleFiltro={handleFiltro}
              filtroRadioButtonsTipoBusca={filtroRadioButtonsTipoBusca}
              filtros={filtros}
              setFiltros={setFiltros}
            />

            <button
              type="button"
              onClick={exportarCsv}
              className={`bc-btn bc-btn-primary px-3 py-2 w-00 h-00 ${
                (!loading && pedidos.length === 0) || loading ? "disabled" : ""
              }`}
              disabled={
                (!loading && pedidos.length === 0) || loading || exportingCsv
              }
            >
              Exportar CSV
            </button>
          </div>
        </div>
      </WhiteContainer>

      <WhiteContainer
        rowMarginBottom={4}
        containerPadding={4}
        containerWidth={12}
      >
        {filtrosAplicados.length > 0 && (
          <div className="d-flex flex-wrap align-items-center justify-content-center mb-4">
            {filtrosAplicados.map((filtro, index) => (
              <div className="me-3 mb-2" key={index}>
                <div
                  className="d-flex flex-wrap justify-content-center align-items-center px-4"
                  style={{ borderRadius: 50, background: "#F9F8F8" }}
                >
                  <p className="text-400-black-16 m-0 me-2 my-2">Filtro:</p>
                  <p className="text-400-black-16 m-0 me-2 my-2 text-break">
                    {obterNomeDoFiltroAplicado(filtro)}
                  </p>
                  <button
                    className="btn-close my-2"
                    onClick={() => removerFiltro(filtro)}
                  ></button>
                </div>
              </div>
            ))}
          </div>
        )}

        {loading ? (
          <SkeletonListagem />
        ) : acessoNegadoPedidos ? (
          <div className="mb-3">
            <DarkGrayCard message="Acesso negado para listagem de pedidos." />
          </div>
        ) : pedidos.length === 0 ? (
          <DarkGrayCard message="Nenhum pedido encontrado." />
        ) : (
          <ListagemPedidos
            eventoId={eventoId!}
            pedidos={pedidos}
            abrirModalCancelarPedido={abrirModalCancelarPedido}
          />
        )}

        {/*
          --------------------------------------------------------------------------
                                Modal: Cancelamento de pedido                      
          --------------------------------------------------------------------------
        */}
        {modalCancelarPedido && (
          <PromptModal
            isOpen={modalCancelarPedido !== null}
            close={() => setModalCancelarPedido(null)}
            icon={<DangerModalIcon />}
            title="Confirmar cancelamento?"
            subtitle={
              auxPedidoModel.formaPagamento !== TipoPagamento.Cortesia
                ? `Confirme o estorno do valor de ”${FormatarCentavosParaReais(
                    auxPedidoModel.totalCentavos
                  )}” para o e-mail "${auxPedidoModel.compradorEmail}"?`
                : `Confirme o cancelamento do pedido de cortesia para o e-mail "${auxPedidoModel.compradorEmail}"?`
            }
            cancelText="Cancelar"
            cancelAction={() => setModalCancelarPedido(null)}
            confirmText="Confirmar"
            confirmAction={cancelarPedido}
            loading={loading}
          />
        )}
      </WhiteContainer>

      <TablePagination numeroPaginas={numeroPaginas} />
    </MainLayout>
  );
}
