import { useParams } from "react-router";
import MainLayout from "../../../Components/Layouts/Main";
import BreadCrumb, { Page } from "../../../Components/Breadcrumb";
import { useEffect, useState } from "react";
import useObterNomeEventoHeader from "../../../Hooks/useObterNomeEventoHeader";
import { useSearchParams } from "react-router-dom";
import { EstatisticasPrincipal } from "./Components";

export default function Estatisticas() {
  const { eventoId } = useParams();

  const { obterNomeEvento } = useObterNomeEventoHeader();

  const breadCrumbHistory: Page[] = [
    {
      link: "/",
      name: "dashboard",
    },
    {
      link: "/eventos",
      name: "Eventos",
    },
    {
      link: `/eventos/${eventoId}/estatisticas`,
      name: "Estatísticas",
    },
    // {
    //   link: `/eventos/${eventoId}/estatisticas?tab=financeiro`,
    //   name: "Financeiro",
    // },
  ];

  // const [searchParams, setSearchParams] = useSearchParams();

  // const [tabsPage, setTabsPage] = useState<{ name: string; active: boolean }[]>(
  //   [
  //     { name: "Principal", active: true },
  //     { name: "Financeiro", active: false },
  //   ],
  // );

  // const toggleTabPage = (tab: string) => {
  //   if (tab === "Financeiro") {
  //     setSearchParams({ tab: "financeiro" });
  //   } else {
  //     setSearchParams({});
  //   }
  // };

  // useEffect(() => {
  //   const tab = searchParams.get("tab");
  //   setTabsPage((prev) =>
  //     prev.map((t) => ({
  //       ...t,
  //       active:
  //         tab === "financeiro"
  //           ? t.name === "Financeiro"
  //           : t.name === "Principal",
  //     })),
  //   );
  // }, [searchParams]);

  useEffect(() => {
    obterNomeEvento();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout>
      <div className="mb-3">
        <BreadCrumb history={breadCrumbHistory} />
        {/*
                    ===== Tabs Financeiro =====
        <BreadCrumb
          history={
            searchParams.has("tab") // se tiver o tab=financeiro
              ? breadCrumbHistory // exibe todos os itens do breadcrumb
              : breadCrumbHistory.filter((page) => {
                  // senão, exibe todos menos o financeiro
                  return page.name !== "Financeiro";
                })
          }
        />
        */}
      </div>

      {/*
      <div className="d-flex flex-column flex-xl-row justify-content-center justify-content-xl-between align-items-center">
        <div className="d-flex flex-wrap align-items-center justify-content-center mb-4">
          {tabsPage.map((tab, i) => (
            <button
              key={i}
              className={`btn-tab bg-transparent me-3 mb-2 ${
                tab.active && "btn-tab-active"
              }`}
              onClick={() => toggleTabPage(tab.name)}
            >
              <p className="text-400-dark-16 m-0">{tab.name}</p>
            </button>
          ))}
        </div>
      </div>
      */}

      <EstatisticasPrincipal />

      {/*
      {tabsPage.find((tab) => tab.name === "Principal" && tab.active) && (
        <EstatisticasPrincipal />
      )}

      {tabsPage.find((tab) => tab.name === "Financeiro" && tab.active) && (
        <EstatisticasFinanceiro />
      )}
      */}
    </MainLayout>
  );
}
