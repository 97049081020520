import { ChangeEvent, useEffect, useRef, useState } from "react";
import BreadCrumb, { Page } from "../../../Components/Breadcrumb";
import MainLayout from "../../../Components/Layouts/Main";
import WhiteContainer from "../../../Components/WhiteContainer";
import {
  DangerModalIcon,
  EditModalIcon,
  ImgPreviewIcon,
  UploadIcon,
} from "../../../Components/Icons";
import { EstadosBrasileiros, SomenteNumeros } from "../../../Utils";
import { useNavigate, useParams } from "react-router-dom";
import AxiosClient from "../../../Services/AxiosClient";
import toast from "react-hot-toast";
import AxiosErrorHandler from "../../../Services/AxiosErrorHandler";
import GeoJSONPoint from "../../../Interfaces/GeoJSONPoint";
import EventoSituacao from "../../../Enums/EventoSituacao";
import EnderecoModel from "../../../Interfaces/Models/EnderecoModel";
import { useForm } from "react-hook-form";
import Input from "../../../Components/Inputs/Input";
import ValidationError from "../../../Components/ValidationError";
import ViaCepModel from "../../../Interfaces/Models/ViaCepModel";
import useViaCep from "../../../Hooks/useViaCep";
import Loading from "../../../Components/Loading";
import PromptModal from "../../../Components/Modals/PromptModal";
import { EventoModel } from "../../../Interfaces/Models";
import { ListServiceResult, ServiceResult } from "../../../Interfaces";
import { useEventoStore } from "../../../Contexts/EventoStore";
import { CategoriaModel } from "../../../Interfaces/Models/CategoriaModel";
import ReactInputMask from "react-input-mask";
import clsx from "clsx";

interface EventosPutBody {
  titulo: string;
  descricao: string;
  exibirDescricao: boolean;
  enderecoPrivado: boolean;
  endereco: EnderecoModel | null;
  idadeMinima: number | null;
  imagemBannerEventoBase64: string;
  imagemEventoBase64: string;
  imagemQuadradaBase64: string | null;
  bannerQuadradoBase64: string | null;
  linkSrcLocalizacaoMap: string | null;
  situacao: EventoSituacao;
  taxaAdministracao: number;
  taxaAdministracaoPOS: number;
  localizacao: GeoJSONPoint | null;
  exibirLocalizacao: boolean;
  parcelamentoMaximo: number;
  categoriaId: string | null;
}

export default function EditarEvento() {
  const { eventoId } = useParams();

  const { setTituloEvento } = useEventoStore();

  const breadCrumbHistory: Page[] = [
    {
      link: "/",
      name: "dashboard",
    },
    {
      link: "/eventos",
      name: "eventos",
    },
    {
      link: `/eventos/${eventoId}/editar`,
      name: "Salvar",
    },
  ];

  const navigate = useNavigate();

  const [formArgs, setFormArgs] = useState<EventosPutBody>(
    {} as EventosPutBody
  );

  const {
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors },
    setValue,
  } = useForm<EventosPutBody>({
    values: formArgs,
    /*defaultValues: {
      titulo: "",
      descricao: "",
      exibirDescricao: true,
      situacao: EventoSituacao.Ativo,
      idadeMinima: 18,
      taxaAdministracao: 0,
      taxaAdministracaoPOS: 0,
      linkSrcLocalizacaoMap: "",
      imagemBannerEventoBase64: "",
      imagemEventoBase64: "",
      exibirLocalizacao: true,
      cep: "",
      logradouro: "",
      numero: "",
      complemento: "",
      bairro: "",
      pais: "brasil",
      unidadeFederacao: "SP",
      cidade: "",
      enderecoPrivado: false,
    },*/
  });

  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [cancelEventModalOpen, setCancelEventModalOpen] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [portaria, setPortaria] = useState<{
    portariaAuthLogin: string;
    portariaAuthPassword: string;
  }>({ portariaAuthLogin: "", portariaAuthPassword: "" });

  const [bannerEvento, setBannerEvento] = useState<string | undefined>("");
  const [imagemEvento, setImagemEvento] = useState<string | undefined>("");
  const [imagemQuadrada, setImagemQuadrada] = useState<string | undefined>("");
  const [bannerQuadrado, setBannerQuadrado] = useState<string | undefined>("");

  const [enderecoPrivado, setEnderecoPrivado] = useState<boolean>(false);
  const [exibirDescricao, setExibirDescricao] = useState<boolean>(false);
  const [exibirLocalizacao, setExibirLocalizacao] = useState<boolean>(false);

  const [categorias, setCategorias] = useState<CategoriaModel[]>([]);

  const bannerEventoRef = useRef<HTMLInputElement | null>(null);
  // const imagemEventoRef = useRef<HTMLInputElement | null>(null);
  const imagemQuadradaRef = useRef<HTMLInputElement | null>(null);
  // const bannerQuadradoRef = useRef<HTMLInputElement | null>(null);

  const [acessoNegadoCategorias, setAcessoNegadoCategorias] =
    useState<boolean>(false);

  const obterTotalParcelas = (): number[] => {
    const n = 12;
    let parcelas: number[] = [];

    for (let i = 1; i <= n; i++) {
      parcelas.push(i);
    }

    return parcelas;
  };

  const AlterarImagem = (
    e: ChangeEvent<HTMLInputElement>,
    img: "banner" | "evento" | "imagemQuadrada" | "bannerQuadrado"
  ): void => {
    const files = e.target.files;

    if (files && files.length > 0) {
      const file = files[0];

      const formData = new FormData();
      formData.append("files", file, file.name);

      // set base 64 img
      const reader = new FileReader();
      reader.onload = (e) => {
        switch (img) {
          case "banner":
            setBannerEvento(e.target?.result as string);
            setBannerQuadrado(e.target?.result as string);
            break;

          case "imagemQuadrada":
            setImagemQuadrada(e.target?.result as string);
            setImagemEvento(e.target?.result as string);
            break;
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUploadClick = (inputFile: "banner" | "imagemQuadrada"): void => {
    if (loading) return;

    switch (inputFile) {
      case "banner":
        bannerEventoRef.current?.click();
        break;
      case "imagemQuadrada":
        imagemQuadradaRef.current?.click();
        break;
    }
  };

  const confirmarEdicaoEvento = (data: EventosPutBody): void => {
    if (!enderecoPrivado && viaCepErro) {
      toast.error("Informe um CEP válido para continuar.");
      return;
    }

    if (!bannerEvento) {
      toast.error("Insira o banner do evento.");
      return;
    }

    if (!imagemQuadrada) {
      toast.error("Insira a imagem quadrada do evento.");
      return;
    }

    setConfirmModalOpen(true);
    setFormArgs(data);
  };

  const editarEvento = async (): Promise<void> => {
    setLoading(true);
    setConfirmModalOpen(false);

    let args: EventosPutBody = formArgs;

    args.enderecoPrivado = enderecoPrivado;
    args.exibirDescricao = exibirDescricao;
    args.exibirLocalizacao = exibirLocalizacao;
    args.idadeMinima = formArgs.idadeMinima
      ? Number(formArgs.idadeMinima)
      : null;
    args.endereco = enderecoPrivado
      ? null
      : {
          bairro: formArgs.endereco?.bairro || "",
          cep: SomenteNumeros(formArgs.endereco?.cep || ""),
          cidade: formArgs.endereco?.cidade || "",
          complemento: formArgs.endereco?.complemento || null,
          logradouro: formArgs.endereco?.logradouro || "",
          numero: formArgs.endereco?.numero || null,
          pais: "brasil",
          unidadeFederacao: formArgs.endereco?.unidadeFederacao || "",
        };

    if (bannerEvento?.includes("data:")) {
      args.imagemBannerEventoBase64 = bannerEvento.split(",")[1];
    }

    if (imagemEvento?.includes("data:")) {
      args.imagemEventoBase64 = imagemEvento.split(",")[1];
    }

    args.imagemQuadradaBase64 = imagemQuadrada?.includes("data:")
      ? imagemQuadrada.split(",")[1]
      : null;
    args.bannerQuadradoBase64 = bannerQuadrado?.includes("data:")
      ? bannerQuadrado.split(",")[1]
      : null;

    args.taxaAdministracao = Number(
      formArgs.taxaAdministracao?.toString()?.replace(",", ".")
    );
    args.taxaAdministracaoPOS = Number(
      formArgs.taxaAdministracaoPOS?.toString()?.replace(",", ".")
    );

    AxiosClient.put(`/eventos/${eventoId}`, args)
      .then(() => {
        if (args.situacao === "Removido") {
          navigate("/eventos");
          toast.success("Evento removido com sucesso!");
        } else {
          toast.success("Evento atualizado com sucesso!");
          navigate(0);
        }
      })
      .catch((err) => {
        toast.error(AxiosErrorHandler(err));
        setLoading(false);
      });
  };

  const confirmarCancelarEvento = (): void => {
    setCancelEventModalOpen(true);
  };

  const cancelarEvento = async (): Promise<void> => {
    setLoading(true);
    setCancelEventModalOpen(false);

    // TODO: CANCELAR EVENTO
    const interval = setInterval(() => {
      setLoading(false);
      toast.success("Seu evento foi cancelado com sucesso!");
      navigate("/eventos");
      clearInterval(interval);
    }, 300);
  };

  const getEvento = async (): Promise<void> => {
    setLoading(true);

    AxiosClient.get<ServiceResult<EventoModel>>(`/eventos/${eventoId}`)
      .then(({ data: { data } }) => {
        const rngNumber = Math.floor(Math.random() * 1000) + 1;

        const evento = data as EventoModel;
        setTituloEvento(evento.titulo);

        setValue("endereco.cep", evento.endereco?.cep || "");

        setFormArgs({
          titulo: evento.titulo,
          categoriaId: data?.categoria?.id || null,
          descricao: evento.descricao,
          exibirDescricao: exibirDescricao,
          situacao: evento.situacao,
          idadeMinima: evento.idadeMinima,
          linkSrcLocalizacaoMap: evento.linkSrcLocalizacaoMap,
          exibirLocalizacao: exibirLocalizacao,
          endereco: {
            bairro: data?.endereco?.bairro || "",
            cep: data?.endereco?.cep || "",
            cidade: data?.endereco?.cidade || "",
            complemento: data?.endereco?.complemento || "",
            logradouro: data?.endereco?.logradouro || "",
            numero: data?.endereco?.numero || "",
            pais: "brasil",
            unidadeFederacao: data?.endereco?.unidadeFederacao || "",
          },
          enderecoPrivado: enderecoPrivado,
          taxaAdministracao: evento.taxaAdministracao,
          taxaAdministracaoPOS: evento.taxaAdministracaoPOS,
          parcelamentoMaximo: evento.parcelamentoMaximo,
        } as EventosPutBody);

        setPortaria({
          portariaAuthLogin: data?.portariaAuthLogin ?? "",
          portariaAuthPassword: data?.portariaAuthPassword ?? "",
        });

        setExibirDescricao(!!data?.exibirDescricao);
        setExibirLocalizacao(!!data?.exibirLocalizacao);
        setEnderecoPrivado(!!data?.enderecoPrivado);

        setBannerEvento(data?.imagemBannerEvento + "?updateCache=" + rngNumber);
        setImagemEvento(data?.imagemEvento + "?updateCache=" + rngNumber);
        setImagemQuadrada(data?.imagemQuadrada + "?updateCache=" + rngNumber);
        setBannerQuadrado(data?.bannerQuadrado + "?updateCache=" + rngNumber);

        if (data?.endereco) {
          setViaCepErro(false);
          setBuscouCep(true);
        }
      })
      .catch((error) => {
        navigate("/eventos");
        toast.error(AxiosErrorHandler(error));
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getEvento();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //#region CEP

  const { FetchCep, fetchingCep } = useViaCep();

  const cep: string = watch("endereco.cep");

  const [buscouCep, setBuscouCep] = useState<boolean>(false);
  const [viaCepErro, setViaCepErro] = useState<boolean>(true);

  const buscouCepInvalido = !fetchingCep && viaCepErro && buscouCep;

  const BuscarCep = async (): Promise<void> => {
    if (SomenteNumeros(cep)?.length === 8) {
      const viaCepResponse: ViaCepModel = await FetchCep(cep);

      setBuscouCep(true);

      if (viaCepResponse.erro) {
        toast.error("CEP inválido! Não foi possível obter o endereço.");

        setFormArgs({
          ...formArgs,
          endereco: {
            logradouro: "",
            numero: "",
            complemento: "",
            bairro: "",
            cidade: "",
            unidadeFederacao: "SP",
            pais: "Brasil",
          } as EnderecoModel,
        });

        //setEstado("SP");
      } else {
        setViaCepErro(false);

        setFormArgs({
          ...formArgs,
          endereco: {
            cep: viaCepResponse.cep,
            logradouro: viaCepResponse.logradouro,
            numero: "",
            complemento: viaCepResponse.complemento,
            bairro: viaCepResponse.bairro,
            cidade: viaCepResponse.localidade,
            unidadeFederacao: viaCepResponse.uf?.toUpperCase(),
            pais: "Brasil",
          },
        });

        //setEstado(viaCepResponse.uf?.toUpperCase());
      }
    }
  };

  const getCategorias = async (): Promise<void> => {
    setLoading(true);

    AxiosClient.get<ListServiceResult<CategoriaModel>>("/categorias")
      .then(({ data }) => {
        setCategorias(data?.data ?? []);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          setAcessoNegadoCategorias(true);
          return;
        }
        toast.error(AxiosErrorHandler(error));
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getCategorias();
  }, []);

  //#endregion

  return (
    <MainLayout>
      <div className="mb-3">
        <BreadCrumb history={breadCrumbHistory} />
      </div>

      <WhiteContainer>
        <p className="title-h1 text-black mb-2">Editar evento</p>
      </WhiteContainer>

      {loading ? (
        <>
          <WhiteContainer>
            <Loading container="30vh" />
          </WhiteContainer>
          <WhiteContainer>
            <Loading container="50vh" />
          </WhiteContainer>
        </>
      ) : (
        <form onSubmit={handleSubmit(confirmarEdicaoEvento)}>
          <WhiteContainer>
            <p className="title-h1 text-black mb-3">Informações do evento</p>

            <div className="mb-4">
              <p className="text-500-dark-18 mb-2">Título do evento</p>
              <input
                className="form-control backstage-input"
                style={{ height: 40 }}
                {...register("titulo", {
                  required: "Informe o título do evento",
                })}
                placeholder="Título do evento"
                autoFocus
              />
              {errors.titulo && ValidationError(errors.titulo)}
            </div>

            {!acessoNegadoCategorias && (
              <div className="mb-4">
                <p className="text-500-dark-18 mb-2">Categoria do evento</p>
                <select
                  className="form-select bc-input input-outlined p-2"
                  {...register("categoriaId")}
                >
                  <option value="" disabled>
                    Selecione
                  </option>
                  <option value="dd19d2c5-8893-49dc-b82d-345e32146ffc" disabled>
                    Outros
                  </option>
                  {categorias.map((categoria) => (
                    <option key={categoria.id} value={categoria.id}>
                      {categoria.nome}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <div className="mb-3">
              <div className="d-flex flex-wrap-reverse justify-content-between align-items-center">
                <p className="text-500-dark-18 mb-2">Descrição do evento</p>

                <div className="form-check form-switch d-flex align-items-center ps-5 mb-2">
                  <input
                    className="form-check-input mb-1"
                    style={{ width: "3.3em", height: "1.7em" }}
                    type="checkbox"
                    role="switch"
                    id="exibirDescricao"
                    checked={exibirDescricao}
                    onChange={(e) => setExibirDescricao(e.target.checked)}
                  />
                  <label
                    className="form-check-label ps-2"
                    htmlFor="exibirDescricao"
                  >
                    Habilitar descrição
                  </label>
                </div>
              </div>

              <textarea
                placeholder="Descrição do evento"
                className={`form-control backstage-input p-2 ${
                  !exibirDescricao && "disabled"
                }`}
                style={{ height: 120, resize: "none" }}
                disabled={!exibirDescricao}
                {...register("descricao")}
              />
            </div>
          </WhiteContainer>

          <WhiteContainer>
            <p className="title-h1 text-black mb-3">Informações adicionais</p>

            {/* Situação + Idade mínima */}
            <div className="row g-0 mb-4">
              {/* 
                --------------------------------------------------------------------------
                                            Situação do evento                            
                --------------------------------------------------------------------------
              */}
              <div className="col-lg-6 mb-3 mb-xl-0">
                <p className="text-500-dark-18 mb-3">Situação do evento</p>

                {Object.values(EventoSituacao).map((s) => (
                  <div className="mb-2" key={s}>
                    <input
                      type="radio"
                      name="situacao"
                      id={`situacao_${s}`}
                      value={s}
                      checked={formArgs.situacao === s}
                      onChange={() => setFormArgs({ ...formArgs, situacao: s })}
                    />
                    <label htmlFor={`situacao_${s}`} className="ps-2">
                      {s}
                    </label>
                  </div>
                ))}
              </div>

              {/* 
                --------------------------------------------------------------------------
                                          Idade mínima permitida                          
                --------------------------------------------------------------------------
              */}
              <div className="col-lg-6">
                <p className="text-500-dark-18 mb-2">Idade mínima permitida</p>
                <Input
                  name="idadeMinima"
                  control={control}
                  errors={errors}
                  validation={{
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "Informe apenas números",
                    },
                  }}
                  placeholder="Idade mínima"
                />
                {errors.idadeMinima && ValidationError(errors.idadeMinima)}
              </div>
            </div>

            <div className="row g-0 mb-4 justify-content-center">
              {/* Imagem quadrada */}
              <div className="col-lg-6 mb-4">
                <p className="text-500-dark-18 mb-2">
                  Imagem do evento (quadrada)
                </p>
                <p className="text-400-black-16 mb-2 me-lg-4">
                  Proporção quadrada (180x180px). Tamanho máximo 2MB.
                </p>

                <div className="my-4 me-lg-4">
                  {/* Input file hidden */}
                  <input
                    ref={imagemQuadradaRef}
                    style={{ display: "none" }}
                    type="file"
                    accept="image/*"
                    multiple={false}
                    onChange={(e) => AlterarImagem(e, "imagemQuadrada")}
                  />
                  <div
                    className="cursor-pointer text-center"
                    onClick={() => handleUploadClick("imagemQuadrada")}
                  >
                    {imagemQuadrada ? (
                      <img
                        src={imagemQuadrada}
                        alt="imagem quadrada do evento"
                        width="100%"
                        height="auto"
                      />
                    ) : (
                      <ImgPreviewIcon />
                    )}
                  </div>
                </div>

                <button
                  type="button"
                  className="
                                bc-btn 
                                bc-btn-primary 
                                py-2 px-3 
                                mx-auto
                                d-flex 
                                justify-content-center 
                                align-items-center
                                "
                  onClick={() => handleUploadClick("imagemQuadrada")}
                >
                  <p className="text-500-white-16 pe-2 m-0">
                    {imagemQuadrada ? "Trocar" : "Inserir"} imagem quadrada
                  </p>
                  <UploadIcon />
                </button>
              </div>
            </div>

            {/* Banner */}
            <div className="row g-0 mb-4 justify-content-center">
              <div className="col-lg-6 mb-4">
                <p className="text-500-dark-18 mb-2">Banner do evento</p>
                <p className="text-400-black-16 mb-2 me-lg-4">
                  Recomendamos imagens em 650x350 px. Tamanho máximo 2MB.
                </p>

                <div className="my-4 me-lg-4">
                  {/* Input file hidden */}
                  <input
                    ref={bannerEventoRef}
                    style={{ display: "none" }}
                    type="file"
                    accept="image/*"
                    multiple={false}
                    onChange={(e) => AlterarImagem(e, "banner")}
                  />
                  <div
                    className="cursor-pointer text-center"
                    onClick={() => handleUploadClick("banner")}
                  >
                    {bannerEvento ? (
                      <img
                        src={bannerEvento}
                        alt="banner"
                        width="100%"
                        height="auto"
                      />
                    ) : (
                      <ImgPreviewIcon />
                    )}
                  </div>
                </div>

                <button
                  type="button"
                  className="
                                bc-btn 
                                bc-btn-primary 
                                py-2 px-3 
                                mx-auto
                                d-flex 
                                justify-content-center 
                                align-items-center
                                "
                  onClick={() => handleUploadClick("banner")}
                >
                  <p className="text-500-white-16 pe-2 m-0">
                    {bannerEvento ? "Trocar" : "Inserir"} banner
                  </p>
                  <UploadIcon />
                </button>
              </div>
            </div>

            <div className="row g-3 mb-4">
              {/* Número de parcelas */}
              <div className="col-lg-4">
                <p className="text-500-dark-18 mb-2">Número de parcelas</p>
                <select
                  className="form-select bc-input input-outlined p-2"
                  {...register("parcelamentoMaximo")}
                  /*value={parcelamentoMaximo.toString()}
                  onChange={({ target: { value } }) =>
                    setParcelamentoMaximo(+value)
                  }*/
                >
                  {obterTotalParcelas().map((parcela) => (
                    <option key={parcela} value={parcela}>
                      {parcela}x
                    </option>
                  ))}
                </select>
              </div>

              {/* Taxa de administração (POS) */}
              <div className="col-lg-4">
                <p className="text-500-dark-18 mb-2">
                  Taxa de administração (POS)
                </p>
                <Input
                  name="taxaAdministracaoPOS"
                  control={control}
                  errors={errors}
                  validation={{
                    pattern: {
                      // 0.00 or 0,00
                      value: /^[0-9]+([.,][0-9]{1,2})?$/,
                      message: "Informe um valor válido",
                    },
                  }}
                  placeholder="Taxa de administração (POS)"
                />
                {errors.taxaAdministracaoPOS &&
                  ValidationError(errors.taxaAdministracaoPOS)}
              </div>

              {/* Taxa de administração */}
              <div className="col-lg-4">
                <p className="text-500-dark-18 mb-2">Taxa de administração</p>
                <Input
                  name="taxaAdministracao"
                  control={control}
                  errors={errors}
                  validation={{
                    pattern: {
                      // 0.00 or 0,00
                      value: /^[0-9]+([.,][0-9]{1,2})?$/,
                      message: "Informe um valor válido",
                    },
                  }}
                  placeholder="Taxa de administração"
                />
                {errors.taxaAdministracao &&
                  ValidationError(errors.taxaAdministracao)}
              </div>
            </div>

            <div className="row g-3 mb-4">
              <div className="col-lg-6 card-cinza-claro p-3">
                <p className="text-500-dark-18 mb-2">Portaria</p>
                <p className="text-400-black-16 m-0">
                  Login: {portaria.portariaAuthLogin}
                </p>
                <p className="text-400-black-16 mb-2">
                  Senha: {portaria.portariaAuthPassword}
                </p>
              </div>
            </div>
          </WhiteContainer>

          <WhiteContainer rowMarginBottom={4}>
            <p className="title-h1 text-black mb-3">Endereço do evento</p>

            {/*
              --------------------------------------------------------------------------
                                                  CEP                                   
              --------------------------------------------------------------------------
            */}
            <div className="row g-3 mb-3">
              <div>
                <div className="form-check form-switch d-flex align-items-center">
                  <input
                    className="form-check-input mb-1"
                    style={{ width: "3.3em", height: "1.7em" }}
                    type="checkbox"
                    role="switch"
                    id="enderecoPrivado"
                    checked={enderecoPrivado}
                    onChange={(e) => {
                      setEnderecoPrivado(e.target.checked);
                    }}
                  />
                  <label
                    className="form-check-label ps-2"
                    htmlFor="exibirPrivado"
                  >
                    Local Privado
                  </label>
                </div>
              </div>
              <div className={clsx("col-lg-4", enderecoPrivado && "d-none")}>
                <p className="text-500-dark-18 mb-2">CEP</p>
                <ReactInputMask
                  className={clsx(
                    "form-control backstage-input",
                    enderecoPrivado && "disabled"
                  )}
                  {...register("endereco.cep")}
                  placeholder="CEP"
                  mask="99999-999"
                  onBlur={BuscarCep}
                  disabled={enderecoPrivado}
                  style={{ height: 40 }}
                />
              </div>
            </div>
            <div className={clsx("row g-3 mb-3", enderecoPrivado && "d-none")}>
              {/*
    --------------------------------------------------------------------------
                                Link do google maps                           
    --------------------------------------------------------------------------
  */}
              <div className="row g-3 mb-3">
                <a
                  href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Não sei meu CEP
                </a>
                <div className="col-lg-4">
                  <p className="text-500-dark-18 mb-2">Link do google maps</p>
                  <input
                    {...register("linkSrcLocalizacaoMap")}
                    placeholder="Link do google maps"
                    className={clsx(
                      "form-control backstage-input",
                      (!exibirLocalizacao || enderecoPrivado) && "disabled"
                    )}
                    disabled={!exibirLocalizacao || enderecoPrivado}
                    style={{ height: 40 }}
                  />
                  {errors.linkSrcLocalizacaoMap &&
                    ValidationError(errors.linkSrcLocalizacaoMap)}
                </div>

                <div className="col-lg-8 d-flex align-items-end">
                  <div className="form-check form-switch d-flex align-items-center ps-5">
                    <input
                      className="form-check-input mb-1"
                      style={{ width: "3.3em", height: "1.7em" }}
                      type="checkbox"
                      role="switch"
                      id="exibirLocalizacao"
                      checked={exibirLocalizacao}
                      onChange={(e) => {
                        setExibirLocalizacao(e.target.checked);
                      }}
                      disabled={enderecoPrivado}
                    />
                    <label
                      className="form-check-label ps-2"
                      htmlFor="exibirLocalizacao"
                    >
                      Habilitar Link
                    </label>
                  </div>
                </div>
              </div>

              {fetchingCep && (
                <div className="row g-0 mb-3">
                  <div className="col-lg-12">
                    <p className="text-500-dark-18 text-center mt-5 mb-2">
                      Buscando CEP...
                    </p>
                    <Loading container="30vh" />
                  </div>
                </div>
              )}

              {buscouCepInvalido && (
                <div className="row g-0 mb-3">
                  <div className="col-lg-12">
                    <p className="text-500-dark-18 text-center mt-5 mb-2">
                      Não foi possível obter o endereço.
                    </p>
                  </div>
                </div>
              )}

              <>
                {/*
        --------------------------------------------------------------------------
                                    Logradouro                                
        --------------------------------------------------------------------------
      */}
                <div className="row g-0 mb-3">
                  <div className="col-lg-12">
                    <p className="text-500-dark-18 mb-2">
                      Endereço (Logradouro)
                    </p>
                    <input
                      className={clsx(
                        "form-control backstage-input",
                        enderecoPrivado && "disabled"
                      )}
                      {...register("endereco.logradouro")}
                      placeholder="Rua"
                      style={{ height: 40 }}
                    />
                  </div>
                </div>

                {/* 
        --------------------------------------------------------------------------
                              Número / Complemento / Bairro                      
        --------------------------------------------------------------------------
      */}
                <div className="row g-3 mb-3">
                  <div className="col-lg-3">
                    <p className="text-500-dark-18 mb-2">Número</p>
                    <input
                      className={clsx(
                        "form-control backstage-input",
                        enderecoPrivado && "disabled"
                      )}
                      {...register("endereco.numero")}
                      placeholder="Número"
                      style={{ height: 40 }}
                    />
                  </div>

                  <div className="col-lg-3">
                    <p className="text-500-dark-18 mb-2">Complemento</p>
                    <input
                      className={clsx(
                        "form-control backstage-input",
                        enderecoPrivado && "disabled"
                      )}
                      {...register("endereco.complemento")}
                      placeholder="Complemento"
                      style={{ height: 40 }}
                    />
                  </div>

                  <div className="col-lg-3">
                    <p className="text-500-dark-18 mb-2">Bairro</p>
                    <input
                      className={clsx(
                        "form-control backstage-input",
                        enderecoPrivado && "disabled"
                      )}
                      {...register("endereco.bairro")}
                      placeholder="Bairro"
                      style={{ height: 40 }}
                    />
                  </div>
                </div>

                {/*
        --------------------------------------------------------------------------
                          País / UF / Cidade                            
        --------------------------------------------------------------------------
      */}
                <div className="row g-3 mb-3">
                  <div className="col-lg-3">
                    <p className="text-500-dark-18 mb-2">País</p>
                    <select
                      name="pais"
                      className={clsx(
                        "form-select backstage-input",
                        enderecoPrivado && "disabled"
                      )}
                      style={{ height: 40 }}
                      disabled={enderecoPrivado}
                    >
                      <option value="brasil">Brasil</option>
                    </select>
                  </div>

                  <div className="col-lg-3">
                    <p className="text-500-dark-18 mb-2">Estado</p>
                    <select
                      className={clsx(
                        "form-select backstage-input",
                        enderecoPrivado && "disabled"
                      )}
                      style={{ height: 40 }}
                      {...register("endereco.unidadeFederacao")}
                      disabled={enderecoPrivado}
                    >
                      <option value="" disabled>
                        Selecione um estado
                      </option>
                      {EstadosBrasileiros.map((estado) => (
                        <option key={estado.sigla} value={estado.sigla}>
                          {estado.nome}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-lg-3">
                    <p className="text-500-dark-18 mb-2">Cidade</p>
                    <input
                      className={clsx(
                        "form-control backstage-input",
                        enderecoPrivado && "disabled"
                      )}
                      {...register("endereco.cidade")}
                      placeholder="Cidade"
                      style={{ height: 40 }}
                    />
                  </div>
                </div>
              </>
            </div>
          </WhiteContainer>

          {/*
            --------------------------------------------------------------------------
                            Botões: Cancelar Evento / Cancelar / Editar               
            --------------------------------------------------------------------------
          */}
          <div className="row mb-4">
            <div className="col-xxl-8">
              <div className="d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between align-items-center">
                <div>
                  <button
                    type="button"
                    className="bc-btn bc-btn-light px-3 py-2 mb-4 mb-lg-0 me-xl-3 d-flex justify-content-center align-items-center"
                    style={{ width: 200, height: 40 }}
                    onClick={confirmarCancelarEvento}
                    disabled={loading}
                  >
                    Cancelar evento
                  </button>
                </div>

                <div className="d-flex flex-column flex-lg-row align-items-center justify-content-lg-evenly">
                  <button
                    type="button"
                    className="bc-btn bc-btn-light px-3 py-2 mb-3 mb-lg-0 me-lg-3 d-flex justify-content-center align-items-center"
                    style={{ width: 200, height: 40 }}
                    onClick={() => navigate(-1)}
                    disabled={loading}
                  >
                    Cancelar
                  </button>

                  <button
                    type="submit"
                    className={`bc-btn bc-btn-primary px-3 py-2 d-flex justify-content-center align-items-center ${
                      (loading || fetchingCep || buscouCepInvalido) &&
                      "disabled"
                    }`}
                    style={{ width: 200, height: 40 }}
                    disabled={loading || fetchingCep || buscouCepInvalido}
                  >
                    Salvar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}

      {/*
        --------------------------------------------------------------------------
                                    Confirmar edição                             
        --------------------------------------------------------------------------
      */}
      <PromptModal
        icon={<EditModalIcon />}
        title="Confirmar alteração"
        subtitle={`Tem certeza que deseja alterar as informações do evento?`}
        cancelText="Voltar para edição"
        cancelAction={() => setConfirmModalOpen(false)}
        confirmText="Confirmar alteração"
        confirmAction={editarEvento}
        close={() => setConfirmModalOpen(false)}
        isOpen={confirmModalOpen}
        loading={loading}
      />

      {/*
        --------------------------------------------------------------------------
                                      Cancelar evento                             
        --------------------------------------------------------------------------
      */}
      <PromptModal
        icon={<DangerModalIcon />}
        title="Cancelar o evento"
        subtitle="Tem certeza que deseja cancelar o evento? Após o cancelamento seu evento estará inativo."
        cancelText="Voltar para edição"
        cancelAction={() => setCancelEventModalOpen(false)}
        confirmText="Confirmar cancelamento"
        confirmAction={cancelarEvento}
        close={() => setCancelEventModalOpen(false)}
        isOpen={cancelEventModalOpen}
        loading={loading}
      />
    </MainLayout>
  );
}
