import FormEsqueciSenha from "./Components/FormEsqueciSenha";

import { useValidateToken } from "../../Hooks/useValidateToken";
import CleanLayout from "../../Components/Layouts/Clean";
import LinkVoltarParaLogin from "../../Components/LinkVoltarParaLogin";

export default function EsqueciSenha() {
  useValidateToken();

  return (
    <CleanLayout>
      <LinkVoltarParaLogin />

      <p className="title-h1 text-black pt-5">Esqueceu sua senha?</p>

      <p className="text-400-black-18 mb-5">
        Informe seu e-mail abaixo para redefinir sua senha:
      </p>

      <FormEsqueciSenha />
    </CleanLayout>
  );
}
