import TipoPagamento from "../Enums/TipoPagamento";

export default function TipoPagamentoToString(tipo: TipoPagamento) {
  switch (tipo) {
    case TipoPagamento.CartaoCredito:
      return "Cartão de Crédito";
    case TipoPagamento.Pix:
      return "PIX";
    case TipoPagamento.ApplePay:
      return "Apple Pay";
    case TipoPagamento.Cortesia:
      return "Cortesia";
    case TipoPagamento.CartaoDebito:
      return "Cartão de Débito";
    case TipoPagamento.Dinheiro:
      return "Dinheiro";

    default:
      return "Tipo de pagamento não identificado";
  }
}
