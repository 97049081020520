import { useEffect, useState } from "react";
import Loading from "../../../../../Components/Loading";
import { IngressoCompradoModel } from "../../../../../Interfaces/Models";
import { Link, useNavigate, useParams } from "react-router-dom";
import AxiosClient from "../../../../../Services/AxiosClient";
import { ServiceResult } from "../../../../../Interfaces";
import toast from "react-hot-toast";
import QRCode from "react-qr-code";
import AxiosErrorHandler from "../../../../../Services/AxiosErrorHandler";
import moment from "moment";
import LogoSvg from "../../../../../Components/Logo/LogoSvg";
import { FaArrowLeft } from "react-icons/fa";

export default function BilheteriaClientesIngressos() {
  const navigate = useNavigate();

  const { eventoId, pedidoId } = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [detalhesIngresso, setDetalhesIngresso] =
    useState<IngressoCompradoModel[]>();
  const [detalhesCarregados, setDetalhesCarregados] = useState<boolean>(false);

  const obterDetalhesIngresso = async () => {
    setLoading(true);

    AxiosClient.get<ServiceResult<IngressoCompradoModel[]>>(
      `/eventos/${eventoId}/bilheteria/clientes/${pedidoId}`,
    )
      .then(({ data: { data } }) => {
        setDetalhesIngresso(data as IngressoCompradoModel[]);
        setDetalhesCarregados(true);
      })
      .catch((err) => {
        navigate(`/eventos/${eventoId}/bilheteria/clientes?menu=open`);
        toast.error(AxiosErrorHandler(err));
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    obterDetalhesIngresso();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (detalhesCarregados) {
      window.print();
    }
  }, [detalhesCarregados]);

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="m-3 min-vh-100">
      <style>
        {`
          @media print {
            @page {
              margin: 0;
            }
            body {
              margin: 1.6cm; 
            }
          }
        `}
      </style>

      <div className="d-print-none d-flex justify-content-between align-items-center">
        <Link to={`/eventos/${eventoId}/bilheteria/clientes?menu=open`}>
          <FaArrowLeft className="me-2" />
          Voltar
        </Link>

        <Link
          to="/"
          reloadDocument
          className="navbar-brand d-flex justify-content-center align-items-center sidebar-brand m-0 p-0"
        >
          <div className="sidebar-brand-icon">
            <div style={{ width: "100%", maxWidth: 120, maxHeight: 48 }}>
              <LogoSvg />
            </div>
          </div>
        </Link>

        <div className="d-none d-lg-flex"></div>
      </div>

      <button
        className="btn btn-primary mb-3 d-print-none"
        onClick={handlePrint}
      >
        Imprimir
      </button>

      {loading && <Loading container="30vh" />}

      {!loading &&
        detalhesIngresso &&
        detalhesIngresso.map((detalheIngresso, i) => (
          <div
            key={detalheIngresso.id}
            className="border rounded mb-4 p-3 p-lg-5 d-flex flex-wrap justify-content-center align-items-center detalhes-ingresso"
            style={{
              width: "fit-content",
              pageBreakAfter: i % 2 === 0 ? "auto" : "always",
            }}
          >
            <div
              style={{
                height: "300px",
                textAlign: "center",
              }}
            >
              <QRCode
                className="pt-4 pt-lg-0"
                style={{
                  height: "100%",
                  maxWidth: "220px",
                  width: "100%",
                }}
                viewBox={`0 0 256 256`}
                value={detalheIngresso.qrCode}
              />
            </div>
            <div>
              <div className="d-flex flex-column justify-content-center align-items-center align-items-lg-start p-4">
                <p className="text-500-black-16 m-0">Nome</p>
                <p className="text-400-dark-16 text-break text-center mb-1">
                  {detalheIngresso.ingressoEmNomeDe}
                </p>
                <p className="text-500-black-16 m-0">Evento</p>
                <p className="text-400-dark-16 text-break text-center mb-1">
                  {detalheIngresso.eventoTitulo}
                </p>
                <p className="text-500-black-16 m-0">Descrição</p>
                <p className="text-400-dark-16 text-break text-center mb-1">
                  {detalheIngresso.descricao}
                </p>
                <p className="text-500-black-16 m-0">Data início</p>
                <p className="text-400-dark-16 text-break text-center mb-1">
                  {moment(detalheIngresso.dataInicio).format(
                    "DD/MM/YYYY HH:mm",
                  )}
                </p>
                <p className="text-500-black-16 m-0">Situação</p>
                <p className="text-400-dark-16 text-break text-center mb-1">
                  {detalheIngresso.situacao}
                </p>
                <p className="text-500-black-16 m-0">Sessões</p>
                <p className="text-400-dark-16 text-break text-center mb-1">
                  {detalheIngresso.sessoes.map((sessao) => (
                    <>
                      {moment(sessao).format("DD/MM/YYYY HH:mm")}
                      <br />
                    </>
                  ))}
                </p>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}
