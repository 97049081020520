import {
  Link,
  useParams,
  useResolvedPath,
  useSearchParams,
} from "react-router-dom";
import LogoSvgSemiWhite from "./Logo/LogoSvgSemiWhite";
import LogoutIcon from "./Icons/LogoutIcon";
import CustomNavLink from "./CustomNavLink";
import {
  ArrowLeftIcon,
  EstatisticasIcon,
  IngressoIcon,
  CodigoPromocionalIcon,
  PedidosIcon,
  ParticipantesIcon,
  ConfiguracoesIcon,
  BilheteriaIcon,
  VendasIcon,
  ClientesIcon,
} from "./Icons";
import getAppPortariaDownloadUrl from "../Services/getAppPortariaDownloadUrl";
import clsx from "clsx";
import { FaLink } from "react-icons/fa";
import { PiDoorLight } from "react-icons/pi";
import { BiSolidDiscount } from "react-icons/bi";
import { FaUserLock } from "react-icons/fa6";
import { AiFillDollarCircle } from "react-icons/ai";
import { GrMoney } from "react-icons/gr";

interface Props {
  responsiveCss: "d-flex" | "d-none";
  logout: () => void;
}

interface ItemsProps {
  url: string;
  name: string;
  icon: JSX.Element;
}

export default function NavMenuEvento({ responsiveCss, logout }: Props) {
  const { eventoId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const menuBilheteria = searchParams.get("menuBilheteria");
  const menuPortaria = searchParams.get("menuPortaria");

  const toggleBilheteriaMenu = () => {
    setSearchParams((params) => {
      const newState = new URLSearchParams(params);
      if (!menuBilheteria || window.location.pathname.includes("bilheteria")) {
        newState.set("menuBilheteria", "open");
      } else {
        newState.delete("menuBilheteria");
      }
      return newState;
    });
  };

  const togglePortariaMenu = () => {
    setSearchParams((params) => {
      const newState = new URLSearchParams(params);
      if (!menuPortaria || window.location.pathname.includes("portaria")) {
        newState.set("menuPortaria", "open");
      } else {
        newState.delete("menuPortaria");
      }
      return newState;
    });
  };

  const path = useResolvedPath(`/eventos/${eventoId}`).pathname;

  const items: ItemsProps[] = [
    {
      url: `${path}/estatisticas`,
      name: "Estatísticas",
      icon: <EstatisticasIcon />,
    },
    {
      url: `${path}/financeiro`,
      name: "Financeiro",
      icon: <AiFillDollarCircle className="fs-4" />,
    },
    {
      url: `${path}/saque`,
      name: "Solicitar saque",
      icon: <GrMoney className="fs-4" />,
    },
    { url: `${path}/ingressos`, name: "Ingressos", icon: <IngressoIcon /> },
    {
      url: `${path}/codigo-promocional`,
      name: "Código promocional",
      icon: <CodigoPromocionalIcon />,
    },
    {
      url: `${path}/cupom-desconto`,
      name: "Cupom de desconto",
      icon: <BiSolidDiscount className="fs-4" />,
    },
    {
      url: `${path}/pedidos`,
      name: "Pedidos",
      icon: <PedidosIcon />,
    },
    {
      url: `${path}/participantes`,
      name: "Participantes",
      icon: <ParticipantesIcon />,
    },
    {
      url: `${path}/permissoes`,
      name: "Permissões",
      icon: <FaUserLock className="fs-4" />,
    },
    {
      url: `${path}/editar`,
      name: "Editar evento",
      icon: <ConfiguracoesIcon />,
    },
  ];

  const bilheteriaSubItems: ItemsProps[] = [
    {
      url: `${path}/bilheteria/vendas?menuBilheteria=open`,
      name: "Vendas",
      icon: <VendasIcon />,
    },
    {
      url: `${path}/bilheteria/clientes?menuBilheteria=open`,
      name: "Clientes",
      icon: <ClientesIcon />,
    },
  ];

  const portariaSubItems: ItemsProps[] = [
    {
      url: `${path}/portaria/estatisticas?menuPortaria=open`,
      name: "Estatísticas",
      icon: <EstatisticasIcon />,
    },
    {
      url: `${path}/portaria/vinculados?menuPortaria=open`,
      name: "Vincular eventos",
      icon: <FaLink />,
    },
    {
      url: `${path}/portaria/participantes?menuPortaria=open`,
      name: "Ingressos",
      icon: <IngressoIcon />,
    },
  ];

  return (
    <nav
      className={`${responsiveCss} navbar align-items-start sidebar accordion bg-navmenu-whitelabel p-0`}
    >
      <div className="container-fluid d-flex flex-column min-vh-100 h-100 px-3">
        <div className="d-flex flex-column justify-content-center align-items-center w-100">
          <Link
            to="/"
            className="navbar-brand d-flex justify-content-center align-items-center sidebar-brand m-0 p-0"
          >
            <div className="sidebar-brand-icon">
              <div style={{ width: "100%", maxWidth: 120, maxHeight: 48 }}>
                <LogoSvgSemiWhite />
              </div>
            </div>
          </Link>
          <hr className="sidebar-divider my-0" />
          <ul className="navbar-nav w-100" id="accordionSidebar">
            {/* Menu para tela inicial */}
            <Link
              to="/eventos"
              className="nav-link d-flex flex-column flex-md-row justify-content-center justify-content-md-start align-items-center text-center bc-text-navbar px-2 px-md-0"
            >
              <ArrowLeftIcon />
              <p
                className="m-0 mt-2 m-md-0 m-0 ms-md-3 fw-semibold"
                style={{ lineHeight: "normal" }}
              >
                Voltar para eventos
              </p>
            </Link>
            {items.map((p, index) => (
              <CustomNavLink
                key={index}
                href={
                  p.url +
                  (menuBilheteria ? `?menuBilheteria=${menuBilheteria}` : "")
                }
                title={p.name}
                icon={p.icon}
              />
            ))}

            {/* Menu de bilheteria */}
            <button
              onClick={toggleBilheteriaMenu}
              className={clsx(
                "bg-transparent bc-text-navbar nav-link d-flex flex-column flex-md-row justify-content-center justify-content-md-start align-items-center text-center px-2 px-md-0 mt-0 mt-md-2",
                window.location.pathname.includes("/bilheteria") &&
                  "text-white nav-link-active",
              )}
            >
              <BilheteriaIcon />
              <p className="m-0 mt-2 m-md-0 m-0 ms-md-3">Bilheteria</p>
              <svg
                className="d-none d-md-flex ms-2"
                width="10"
                height="8"
                viewBox="0 0 10 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M5 8L9.33013 0.5H0.669873L5 8Z" fill="#9D9D9D" />
              </svg>
            </button>
            {menuBilheteria === "open" &&
              bilheteriaSubItems.map((item, index) => (
                <CustomNavLink
                  key={index}
                  href={item.url}
                  title={item.name}
                  icon={item.icon}
                  className="nav-link-submenu m-0 ms-md-4"
                />
              ))}

            {/* Menu de portaria */}
            <button
              onClick={togglePortariaMenu}
              className={clsx(
                "bg-transparent bc-text-navbar nav-link d-flex flex-column flex-md-row justify-content-center justify-content-md-start align-items-center text-center px-2 px-md-0 mt-0 mt-md-2",
                window.location.pathname.includes("/portaria") &&
                  "text-white nav-link-active",
              )}
            >
              <PiDoorLight className="fs-4" />
              <p className="m-0 mt-2 m-md-0 m-0 ms-md-3">Portaria</p>
              <svg
                className="d-none d-md-flex ms-2"
                width="10"
                height="8"
                viewBox="0 0 10 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M5 8L9.33013 0.5H0.669873L5 8Z" fill="#9D9D9D" />
              </svg>
            </button>
            {menuPortaria === "open" &&
              portariaSubItems.map((item, index) => (
                <CustomNavLink
                  key={index}
                  href={item.url}
                  title={item.name}
                  icon={item.icon}
                  className="nav-link-submenu m-0 ms-md-4"
                />
              ))}
          </ul>
        </div>
        <div className="mt-5 mx-2 d-flex flex-column align-items-center">
          <button
            onClick={getAppPortariaDownloadUrl}
            className="bc-btn bc-btn-white mb-3 px-3 py-2 d-flex flex-wrap justify-content-center align-items-center"
            style={{ minHeight: 56, maxWidth: 216 }}
            title="Baixar app Portaria"
          >
            <div className="d-none d-md-flex">Baixar app Portaria</div>
            <i className="fa-solid fa-download p-2"></i>
          </button>

          <button
            className="bc-btn bc-btn-white w-100 mb-4 px-3 px-md-4 py-2 d-flex flex-wrap justify-content-center align-items-center"
            onClick={logout}
            style={{ maxWidth: 216, minHeight: 56 }}
          >
            <div className="d-none d-md-flex">Sair da conta &nbsp;</div>
            <LogoutIcon />
          </button>
          <p className="text-500-white-14 text-center mb-4">V.2. Tickets App</p>
        </div>
      </div>
    </nav>
  );
}
