import { ReactNode } from "react";
import LogoSvgWhite from "../Logo/LogoSvgWhite";

type CleanLayoutProps = {
  children: ReactNode;
};

export default function CleanLayout({ children }: CleanLayoutProps) {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <div
        className="container-fluid p-0"
        style={{ overflowX: "hidden", height: "100vh" }}
      >
        <div className="row g-0 h-100">
          <div className="col-md-6 col-lg-4 bc-login-bg">
            <div className="p-5 h-100 d-flex flex-column justify-content-between">
              <LogoSvgWhite />
              <div>
                <p className="bc-login-title m-0">Sua gestão de tickets</p>

                <p className="text-400-white-18 m-0">
                  Deixe sua gestão de tickets com toda a performance e qualidade
                  Tickets App
                </p>
              </div>

              {/* ↓ Div para espaçamento ↓ */}
              <div></div>
              {/* ↓ Div para espaçamento ↓ */}
              <div></div>

              {/* <CarouselMessages /> */}

              {/* ↓ Div para espaçamento ↓ */}
              <div></div>
            </div>
          </div>
          <div className="col-md-6 col-lg-8 bg-white">
            <div className="row flex-column justify-content-between align-items-center h-100 pt-md-5">
              <div className="col-lg-6 p-5 p-lg-0 pt-lg-5 mt-lg-5">
                {children}
              </div>
              <div className="col-lg-12 p-5 p-lg-0 pb-lg-5">
                <p className="text-500-darkest-16 text-center m-0">
                  &copy; {currentYear}. Tickets App, todos os direitos
                  reservados
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
