import clsx from "clsx";
import { ComponentProps } from "react";

interface Props extends ComponentProps<"button"> {
  text: string;
  height?: string | number;
  width?: string | number;
  icon?: JSX.Element;
  variant?: "primary" | "light" | "white";
}

const Button: React.FC<Props> = ({
  type = "button",
  className = "",
  disabled,
  height,
  icon,
  onClick,
  text,
  width,
  variant = "primary",
}) => {
  return (
    <button
      type={type}
      className={clsx(
        `${className} shadow-sm bc-btn bc-btn-${variant}`,
        icon && "d-flex justify-content-center align-items-center",
        disabled && "disabled"
      )}
      style={{
        maxWidth: width || 200,
        width: "100%",
        minHeight: height || 40,
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
      {icon && <div className="ms-2 mb-1">{icon}</div>}
    </button>
  );
};

export default Button;
