import WhiteContainer from "../../../Components/WhiteContainer";
import {
  SaquePostArgs,
  useSolicitarSaqueStore,
} from "../../../stores/solicitacao-saque.store";
import Button from "../../../Components/Button";
import Input from "../../../Components/Inputs/Input";
import { BsExclamationCircleFill } from "react-icons/bs";
import { useForm } from "react-hook-form";
import { FormatarCentavosParaReais } from "../../../Utils";
import moment from "moment";
import AxiosClient from "../../../Services/AxiosClient";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import AxiosErrorHandler from "../../../Services/AxiosErrorHandler";
import { useState } from "react";
import ConverterStringContaBancariaTipo from "../../../Utils/ConverterStringContaBancariaTipo";
import { ServiceResult } from "../../../Interfaces";

export default function ConfirmarDados() {
  const [loading, setLoading] = useState<boolean>(false);

  const { eventoId } = useParams();
  const { model, setStep, setSaqueId } = useSolicitarSaqueStore();

  const {
    control,
    formState: { errors },
  } = useForm<SaquePostArgs>({
    values: model,
  });

  function sendCode() {
    setLoading(true);

    AxiosClient.post<ServiceResult<string>>(
      `/eventos/${eventoId}/financeiro/saques`,
      model
    )
      .then(({ data: result }) => {
        setSaqueId(result.data ?? "");
        setStep("confirmar-codigo");
      })
      .catch((err) => {
        toast.error(AxiosErrorHandler(err));
      })
      .finally(() => setLoading(false));
  }

  function handleVoltarCorrigir() {
    if (model.favorecidoId) {
      setStep("valor");
    } else {
      setStep("novo-saque");
    }
  }

  return (
    <>
      <WhiteContainer>
        <div className="pt-4 p-lg-4 mb-4">
          <p className="text-500-black-20">
            Confirme os dados da transferência
          </p>
          <p className="text-400-black-14" style={{ lineHeight: "13px" }}>
            Você pode conferir os dados da transferência abaixo e voltar e
            editar caso seja necessário.
          </p>

          <hr className="m-0" />

          <div className="row mt-3">
            <div className="col-md-7">
              <label className="text-500-gray-18 mt-1 mb-1">
                Forma de transferência
              </label>
              <Input
                control={control}
                errors={errors}
                validation={{}}
                name="formaTransferencia"
                disabled
              />

              <label className="text-500-gray-18 mt-1 mb-1">
                Nome e sobrenome do titular
              </label>
              <Input
                control={control}
                errors={errors}
                validation={{}}
                name="nomeTitular"
                disabled
              />

              <label className="text-500-gray-18 mt-1 mb-1">
                Banco ou instituição financeira
              </label>
              <Input
                control={control}
                errors={errors}
                name="banco"
                validation={{}}
                type="text"
                disabled
              />

              <label className="text-500-gray-18 mt-1 mb-1">
                Numero da conta
              </label>
              <Input
                control={control}
                errors={errors}
                name="numeroConta"
                validation={{}}
                type="number"
                disabled
              />

              {/* Exibe a data selecionada */}
              <label className="text-500-gray-18 mt-1 mb-1">
                Data de agendamento
              </label>
              <input
                className="form-control backstage-input"
                value={moment(model.dataAgendamento).format("DD/MM/YYYY")}
                disabled
                style={{
                  height: 40,
                }}
              />
            </div>

            <div className="col-md-5">
              <label className="text-500-gray-18 mt-1 mb-1">Valor</label>
              <input
                className="form-control backstage-input"
                value={FormatarCentavosParaReais(model.valorCentavos)}
                disabled
                style={{ height: 40 }}
              />

              <label className="text-500-gray-18 mt-1 mb-1">CPF / CNPJ</label>
              <Input
                control={control}
                errors={errors}
                name="cpfCnpj"
                validation={{}}
                type="number"
                disabled
              />

              <label className="text-500-gray-18 mt-1 mb-1">Agência</label>
              <Input
                control={control}
                errors={errors}
                name="agencia"
                validation={{}}
                disabled
              />

              <label className="text-500-gray-18 mt-1 mb-1">
                Tipo de conta
              </label>
              <input
                className="form-control backstage-input"
                value={ConverterStringContaBancariaTipo(model.tipoConta!)}
                style={{ height: 40 }}
                disabled
              />

              <label className="text-500-gray-18 mt-1 mb-1">Nota</label>
              <Input
                control={control}
                errors={errors}
                name="descricao"
                validation={{}}
                type="text"
                disabled
              />
            </div>
          </div>

          <button
            className="btn btn-primary mt-4 text-500-gray-18"
            style={{
              width: "152px",
              height: "30px",
              gap: "8px",
              borderRadius: "32px",
              lineHeight: "16px",
              background: "#E5EDFF ",
              color: "#303F9F",
              fontSize: "16px",
              textAlign: "center",
              border: "none",
              padding: "16px, 32px, 16px, 32px",
            }}
            onClick={handleVoltarCorrigir}
          >
            Voltar e corrigir
          </button>
        </div>
      </WhiteContainer>

      <div className="row">
        <div className="mb-3 d-flex justify-content-lg-start align-items-center flex-column flex-sm-row">
          <BsExclamationCircleFill />
          <p className="ms-2 text-500-black-14 m-0 ms-lg-3 text-center text-sm-left">
            Revise os dados para garantir que a transferência seja realizada com
            sucesso
          </p>
        </div>

        <div className="col-xxl-8 mb-2">
          <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between justify-content-xl-end align-items-center mb-3 mb-md-0">
            <Button
              type="button"
              variant="light"
              width={216}
              height={40}
              text="Voltar"
              className=" mb-3 mb-md-0 me-xl-3"
              onClick={() => setStep("data")}
            />

            <Button
              type="button"
              onClick={sendCode}
              text={loading ? "Enviando..." : "Continuar"}
              width={216}
              height={40}
              disabled={loading}
            />
          </div>
        </div>
      </div>
    </>
  );
}
