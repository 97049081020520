import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Input from "../../../../Components/Inputs/Input";
import WhiteContainer from "../../../../Components/WhiteContainer";
import Button from "../../../../Components/Button";
import { AddIcon } from "../../../../Components/Icons";
import ValidationError from "../../../../Components/ValidationError";
import toast from "react-hot-toast";
import AxiosClient from "../../../../Services/AxiosClient";
import AxiosErrorHandler from "../../../../Services/AxiosErrorHandler";
import { useNavigate } from "react-router-dom";
import { ListServiceResult } from "../../../../Interfaces";
import PerfilBSModel from "../../../../Interfaces/Models/PerfilBSModel";
import { useParams } from "react-router-dom";

interface MembrosProducaoArgs {
  membroEmail: string;
  perfis: string[];
}

export const CadastrarMembroProducao = () => {
  const navigate = useNavigate();
  const { eventoId } = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingPerfis, setLoadingPerfis] = useState<boolean>(true);
  const [perfis, setPerfis] = useState<PerfilBSModel[]>([]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<MembrosProducaoArgs>({
    values: {
      membroEmail: "",
      perfis: [],
    },
  });

  const onSubmit = async (data: MembrosProducaoArgs): Promise<void> => {
    setLoading(true);

    toast.promise(
      AxiosClient.post(`/eventos/${eventoId}/membros-producao`, data).finally(
        () => setLoading(false)
      ),
      {
        loading: "Cadastrando permissões...",
        success: () => {
          navigate(0);
          return "Permissões adicionada com sucesso!";
        },
        error: (error) => AxiosErrorHandler(error),
      }
    );
  };

  const getPerfis = async (): Promise<void> => {
    setLoadingPerfis(true);

    AxiosClient.get<ListServiceResult<PerfilBSModel>>(
      `/eventos/${eventoId}/membros-producao/perfis`
    )
      .then(({ data: { data } }) => {
        setPerfis(data as PerfilBSModel[]);
      })
      .catch((error: any) => toast.error(AxiosErrorHandler(error)))
      .finally(() => setLoadingPerfis(false));
  };

  useEffect(() => {
    getPerfis();
  }, []);

  return (
    <WhiteContainer containerWidth={12} containerPadding={4}>
      <div className="col-xl-12">
        <p className="title-h1 text-black mb-4">Novo usuário</p>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row g-3">
          <div className="col-lg-8">
            <p className="text-500-dark-18">E-mail do usuário</p>

            <Input
              type="email"
              name="membroEmail"
              control={control}
              errors={errors}
              validation={{
                required: "Informe o email do usuário",
                validate: {
                  email: (value: string) =>
                    (value.includes("@") && value.includes(".")) ||
                    `O e-mail '${value}' é inválido. Insira um e-mail corretamente.`,
                },
              }}
              placeholder="E-mail"
            />
            {errors.membroEmail && ValidationError(errors.membroEmail)}
          </div>

          <div className="col-lg-12">
            <p className="text-500-dark-18">Perfis disponíveis</p>
            {loadingPerfis ? (
              <p>Carregando perfis...</p>
            ) : (
              <Controller
                control={control}
                name="perfis"
                render={({ field }) => (
                  <div className="form-group">
                    {perfis
                      .sort((a, b) => a.nome.localeCompare(b.nome))
                      .map(({ nome, id }) => (
                        <div key={id} className="form-check">
                          <input
                            type="checkbox"
                            id={id}
                            className="form-check-input"
                            value={nome}
                            checked={field.value?.includes(nome) || false}
                            onChange={(e) => {
                              if (e.target.checked) {
                                field.onChange([...(field.value || []), nome]);
                              } else {
                                field.onChange(
                                  (field.value || []).filter(
                                    (perfil) => perfil !== nome
                                  )
                                );
                              }
                            }}
                          />
                          <label className="form-check-label" htmlFor={id}>
                            {nome}
                          </label>
                        </div>
                      ))}
                  </div>
                )}
              />
            )}
          </div>

          <div className="col-lg-12 d-flex justify-content-end">
            <Button
              type="submit"
              disabled={loading || !!errors.membroEmail}
              text="Cadastrar"
              icon={<AddIcon />}
            />
          </div>
        </div>
      </form>
    </WhiteContainer>
  );
};
