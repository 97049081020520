export default function ClockMoneyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#505055"
        d="M11.077 2c-1.78 0-3.52.508-5 1.46a8.746 8.746 0 00-3.315 3.89 8.369 8.369 0 00-.512 5.008 8.572 8.572 0 002.463 4.437 9.112 9.112 0 004.608 2.372 9.322 9.322 0 005.2-.493 8.935 8.935 0 004.04-3.192 8.44 8.44 0 001.516-4.815c-.003-2.298-.952-4.501-2.639-6.126C15.751 2.916 13.463 2.002 11.077 2zm0 16a7.825 7.825 0 01-4.231-1.236 7.4 7.4 0 01-2.805-3.291 7.081 7.081 0 01-.433-4.237 7.253 7.253 0 012.084-3.755 7.71 7.71 0 013.9-2.007 7.888 7.888 0 014.4.418 7.561 7.561 0 013.417 2.7 7.142 7.142 0 011.283 4.075 7.207 7.207 0 01-2.233 5.183A7.775 7.775 0 0111.077 18zm5.538-7.333a.655.655 0 01-.202.471.706.706 0 01-.49.195h-4.846a.706.706 0 01-.49-.195.654.654 0 01-.202-.471V6c0-.177.072-.346.202-.471a.706.706 0 01.49-.196c.184 0 .36.07.49.196.13.125.202.294.202.471v4h4.154c.184 0 .36.07.49.195s.202.295.202.472z"
      ></path>
      <path
        fill="#fff"
        d="M0 0H11.077V10.667H0z"
        transform="translate(12.923 13.334)"
      ></path>
      <path
        fill="#505055"
        d="M18.462 23.111c1.835 0 3.692-.61 3.692-1.778V16c0-1.167-1.857-1.778-3.692-1.778-1.835 0-3.693.61-3.693 1.778v5.333c0 1.167 1.857 1.778 3.693 1.778zm0-.889c-1.718 0-2.77-.575-2.77-.889v-.563c.712.372 1.744.563 2.77.563s2.058-.19 2.769-.563v.563c0 .314-1.052.89-2.77.89zm0-7.11c1.717 0 2.769.575 2.769.888 0 .313-1.052.889-2.77.889-1.717 0-2.769-.576-2.769-.89 0-.312 1.052-.888 2.77-.888zm-2.77 2.102c.712.373 1.744.564 2.77.564s2.058-.191 2.769-.564v.564c0 .313-1.052.889-2.77.889-1.717 0-2.769-.576-2.769-.89v-.563zm0 1.778c.712.372 1.744.563 2.77.563s2.058-.19 2.769-.563v.563c0 .314-1.052.89-2.77.89-1.717 0-2.769-.576-2.769-.89v-.563z"
      ></path>
    </svg>
  );
}
