import { useNavigate, useParams } from "react-router-dom";
import MainLayout from "../../../../Components/Layouts/Main";
import BreadCrumb, { Page } from "../../../../Components/Breadcrumb";
import WhiteContainer from "../../../../Components/WhiteContainer";
import Loading from "../../../../Components/Loading";
import { useEffect, useState } from "react";
import FormButtons from "../../../../Components/Form/FormButtons";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import moment from "moment";
import DistribuicaoTaxa from "../../../../Enums/DistribuicaoTaxa";
import Input from "../../../../Components/Inputs/Input";
import ValidationError from "../../../../Components/ValidationError";
import AxiosClient from "../../../../Services/AxiosClient";
import AxiosErrorHandler from "../../../../Services/AxiosErrorHandler";
import { ClockIcon } from "../../../../Components/Icons";
import ReactInputMask from "react-input-mask";
import Button from "../../../../Components/Button";
import clsx from "clsx";
import {
  FormatarCentavosParaReais,
  FormatarReaisParaCentavos,
} from "../../../../Utils";
import ViradaAutomaticaIngressos from "../Components/ViradaAutomaticaIngressos";
import VincularCodigoAcesso from "../Components/VincularCodigoAcesso";
import {
  CodigoAcessoModel,
  EventosTaxasModel,
  GrupoModel,
  IngressoModel,
} from "../../../../Interfaces/Models";
import { ListServiceResult, ServiceResult } from "../../../../Interfaces";
import { IngressoBSViradaAutomaticaReferenciaModel } from "../../../../Interfaces/Models/IngressoModel";
import useObterNomeEventoHeader from "../../../../Hooks/useObterNomeEventoHeader";
import DarkGrayCard from "../../../../Components/DarkGrayCard";

interface IngressosPostBody {
  codigoAcessoId: string[] | null;
  ingressosIds: string[] | null;
  nome: string;
  valorCentavos: number;
  distribuicaoTaxa: DistribuicaoTaxa;
  quantidadeIngressosParaVenda: number;
  dataInicioVendas: string;
  dataFimVendas: string;
  pedidoMinimo: number | null;
  pedidoMaximo: number | null;
  limitarPedido: boolean;
  ativo: boolean;
}

interface ViewModel {
  nome: string;
  valor: string;
  distribuicaoTaxa: DistribuicaoTaxa;
  quantidadeIngressosParaVenda: number;
  pedidoMinimo: number;
  pedidoMaximo: number;
}

export default function CadastrarIngresso() {
  const { eventoId, grupoId } = useParams();
  const navigate = useNavigate();

  const { obterNomeEvento } = useObterNomeEventoHeader();

  const breadCrumbHistory: Page[] = [
    {
      link: "/",
      name: "dashboard",
    },
    {
      link: "/eventos",
      name: "Eventos",
    },
    {
      link: `/eventos/${eventoId}/ingressos`,
      name: "Ingressos",
    },
    {
      link: `/eventos/${eventoId}/ingressos/grupo/${grupoId}/cadastrar-ingresso`,
      name: "Cadastrar ingresso",
    },
  ];

  const [loading, setLoading] = useState<boolean>(false);

  const [taxas, setTaxas] = useState<EventosTaxasModel>(
    {} as EventosTaxasModel
  );

  //#region [Grupos]

  const [grupos, setGrupos] = useState<GrupoModel[]>([]);

  const [grupoSelecionado, setGrupoSelecionado] = useState<string>(grupoId!);

  //#endregion

  //#region [Virada automatica de ingressos]

  const [
    ingressosSelecionadosViradaAutomatica,
    setIngressosSelecionadosViradaAutomatica,
  ] = useState<IngressoBSViradaAutomaticaReferenciaModel[]>([]);

  const [ingressosViradaAutomatica, setIngressosViradaAutomatica] = useState<
    IngressoModel[]
  >([]);

  //#endregion

  //#region [Codigo de acesso]

  const [codigosAcesso, setCodigosAcesso] = useState<CodigoAcessoModel[]>([]);

  const [codigosAcessoSelecionados, setCodigosAcessoSelecionados] = useState<
    CodigoAcessoModel[]
  >([]);

  //#endregion

  //#region [Data vendas]

  const [dataInicio, setDataInicio] = useState<string>(
    new Date().toISOString().split("T")[0]
  );

  const [horaInicio, setHoraInicio] = useState<string>("00:00");

  const [dataTermino, setDataTermino] = useState<string>(
    // add 7 days to current date
    new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
      .toISOString()
      .split("T")[0]
  );

  const [horaTermino, setHoraTermino] = useState<string>("00:00");

  //#endregion

  const [limitarPedido, setLimitarPedido] = useState<boolean>(false);

  const [distribuicaoTaxa, setDistribuicaoTaxa] = useState<DistribuicaoTaxa>(
    DistribuicaoTaxa.Produtor
  );

  const [acessoNegadoGrupos, setAcessoNegadoGrupos] = useState<boolean>(false);
  const [acessoNegadoCodigos, setAcessoNegadoCodigos] =
    useState<boolean>(false);
  const [acessoNegadoTaxas, setAcessoNegadoTaxas] = useState<boolean>(false);

  useEffect(() => {
    obterGrupos();
    obterCodigos();
    obterTaxas();
    obterNomeEvento();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ViewModel>({
    defaultValues: {
      nome: "",
      valor: "",
      distribuicaoTaxa: DistribuicaoTaxa.Produtor,
      quantidadeIngressosParaVenda: 0,
      pedidoMinimo: 0,
      pedidoMaximo: 0,
    },
  });

  const calcularTaxaIngresso = (): string => {
    const valorStr: string = watch("valor");

    if (!valorStr) {
      return "R$ 0,00";
    }

    const valorCentavos: number = FormatarReaisParaCentavos(valorStr);
    let valorTaxado: number = 0;

    switch (distribuicaoTaxa) {
      case DistribuicaoTaxa.Produtor:
        valorTaxado = valorCentavos;
        break;

      case DistribuicaoTaxa.ProdutorCliente:
        valorTaxado =
          valorCentavos +
          Math.round(
            (valorCentavos *
              (taxas.taxaAdmnistracao / 100 + taxas.taxaProcessamento / 100)) /
              2
          );
        break;

      case DistribuicaoTaxa.Cliente:
        valorTaxado =
          valorCentavos +
          Math.round(
            valorCentavos *
              (taxas.taxaAdmnistracao / 100 + taxas.taxaProcessamento / 100)
          );
        break;

      default:
        break;
    }

    return FormatarCentavosParaReais(valorTaxado);
  };

  const obterTaxas = async (): Promise<void> => {
    setLoading(true);

    AxiosClient.get<ServiceResult<EventosTaxasModel>>(
      `/eventos/${eventoId}/taxas-ingressos`
    )
      .then(({ data: { data } }) => {
        setTaxas(data as EventosTaxasModel);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          setAcessoNegadoTaxas(true);
          return;
        }
        toast.error(AxiosErrorHandler(error));
      })
      .finally(() => setLoading(false));
  };

  const obterCodigos = async (): Promise<void> => {
    setLoading(true);

    AxiosClient.get<ServiceResult<CodigoAcessoModel[]>>(
      `/codigos?evento=${eventoId}&paginaTamanho=100`
    )
      .then(({ data: { data } }) => {
        setCodigosAcesso(data as CodigoAcessoModel[]);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          setAcessoNegadoCodigos(true);
          return;
        }
        toast.error(AxiosErrorHandler(error));
      })
      .finally(() => setLoading(false));
  };

  const obterGrupos = async (): Promise<void> => {
    setLoading(true);

    AxiosClient.get<ListServiceResult<GrupoModel>>(
      `/eventos/${eventoId}/grupos?paginaTamanho=100`
    )
      .then(({ data: { data } }) => {
        setGrupos(data as GrupoModel[]);

        const ingressos: IngressoModel[] =
          data?.flatMap((g) => g.ingressos) ?? [];

        setIngressosViradaAutomatica([
          ...ingressosViradaAutomatica,
          ...ingressos,
        ]);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          setAcessoNegadoGrupos(true);
          return;
        }
        toast.error(AxiosErrorHandler(error));
      })
      .finally(() => setLoading(false));
  };

  const formularioValido = (data: ViewModel): boolean => {
    let errorMessages: string[] = [];

    if (FormatarReaisParaCentavos(data.valor) < 100) {
      errorMessages.push("O valor do ingresso deve ser maior que R$ 1,00!");
    }

    if (!dataInicio || !horaInicio) {
      errorMessages.push("Informe a data e hora de início das vendas!");
    }

    if (!dataTermino || !horaTermino) {
      errorMessages.push("Informe a data e hora de término das vendas!");
    }

    if (
      Number(horaInicio.split(":")[0]) > 23 ||
      Number(horaInicio.split(":")[1]) > 59
    ) {
      errorMessages.push("Informe uma hora válida para o início das vendas!");
    }

    if (
      Number(horaTermino.split(":")[0]) > 23 ||
      Number(horaTermino.split(":")[1]) > 59
    ) {
      errorMessages.push("Informe uma hora válida para o término das vendas!");
    }

    if (limitarPedido) {
      const pedidoMinimo = watch("pedidoMinimo");
      const pedidoMaximo = watch("pedidoMaximo");

      if (!pedidoMinimo) {
        errorMessages.push("Informe o pedido mínimo!");
      }

      if (!pedidoMaximo) {
        errorMessages.push("Informe o pedido máximo!");
      }

      if (Number(pedidoMinimo) > Number(pedidoMaximo)) {
        errorMessages.push(
          "O pedido mínimo deve ser menor ou igual ao pedido máximo!"
        );
      }
    }

    if (errorMessages.length > 0) {
      toast.error(errorMessages.map((msg: string) => "➡️ " + msg).join("\n\n"));
      return false;
    }

    return true;
  };

  const cadastrarIngresso = async (data: ViewModel) => {
    if (!formularioValido(data)) return;

    setLoading(true);

    const dataHoraInicio = moment(
      `${dataInicio} ${horaInicio}`,
      "YYYY/MM/DD HH:mm"
    ).toISOString();

    const dataHoraTermino = moment(
      `${dataTermino} ${horaTermino}`,
      "YYYY/MM/DD HH:mm"
    ).toISOString();

    let args: IngressosPostBody = {
      nome: data.nome,
      valorCentavos: FormatarReaisParaCentavos(data.valor),
      quantidadeIngressosParaVenda: Number(data.quantidadeIngressosParaVenda),
      dataInicioVendas: dataHoraInicio,
      dataFimVendas: dataHoraTermino,
      distribuicaoTaxa: distribuicaoTaxa,
      ativo: true,
      limitarPedido: limitarPedido,

      pedidoMinimo: null,
      pedidoMaximo: null,
      codigoAcessoId: null,
      ingressosIds: null,
    };

    if (limitarPedido) {
      args.pedidoMinimo = Number(data.pedidoMinimo);
      args.pedidoMaximo = Number(data.pedidoMaximo);
    }

    if (codigosAcessoSelecionados.length > 0) {
      args.codigoAcessoId = codigosAcessoSelecionados.map((c) => c.id);
    }

    args.ingressosIds =
      ingressosSelecionadosViradaAutomatica?.map((i) => i.id) ??
      ([] as string[]);

    toast.promise(
      AxiosClient.post(
        `/eventos/${eventoId}/grupos/${grupoSelecionado}/ingressos`,
        args
      ).finally(() => setLoading(false)),
      {
        loading: "Cadastrando ingresso...",
        success: () => {
          navigate(`/eventos/${eventoId}/ingressos`);
          return "Ingresso cadastrado com sucesso!";
        },
        error: (error) => AxiosErrorHandler(error),
      }
    );
  };

  return (
    <MainLayout>
      <div className="mb-3">
        <BreadCrumb history={breadCrumbHistory} />
      </div>

      <WhiteContainer containerPadding={4}>
        <div className="d-flex flex-column flex-xl-row justify-content-xl-between align-items-center">
          <p className="title-h1 text-black text-center mb-2">
            Adicionar ingresso
          </p>
        </div>
      </WhiteContainer>

      {loading && (
        <WhiteContainer>
          <Loading container="50vh" />
        </WhiteContainer>
      )}

      {!loading && (
        <form onSubmit={handleSubmit(cadastrarIngresso)}>
          <WhiteContainer>
            <p className="title-h1 text-black mb-3">Informações do ingresso</p>

            <div className="row g-4">
              <div className="col-xl-6">
                <p className="text-500-dark-18 mb-2">Nome do ingresso*</p>
                <Input
                  autoFocus
                  name="nome"
                  control={control}
                  errors={errors}
                  validation={{
                    required: "Informe o nome do ingresso",
                  }}
                  placeholder="Nome"
                />
                {errors.nome && ValidationError(errors.nome)}
              </div>

              {!acessoNegadoGrupos && (
                <div className="col-xl-6">
                  <p className="text-500-dark-18 mb-2">Selecione o grupo*</p>
                  <select
                    className="form-select backstage-input"
                    style={{ height: 40 }}
                    disabled={loading}
                    value={grupoSelecionado}
                    onChange={({ target: { value } }) =>
                      setGrupoSelecionado(value)
                    }
                  >
                    <option value="" disabled>
                      Selecione o grupo
                    </option>
                    {grupos.map((g) => (
                      <option key={g.id} value={g.id}>
                        {g.nome}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {/* Período de vendas */}
              <div className="col-xl-8">
                <p className="text-500-black-18 mb-1">Período de vendas</p>
                <p className="text-400-darkest-16 mb-3">
                  Configure as datas e horários para início e término automático
                  de vendas.
                </p>

                {/*
                  --------------------------------------------------------------------------
                                             Data e hora de início                          
                  --------------------------------------------------------------------------
                */}
                <div className="row g-3 mb-3">
                  <div className="col-xl-6">
                    <p className="text-500-dark-18 mb-2">Data início*</p>
                    <div className="input-group">
                      <input
                        type="date"
                        placeholder="Data do início das vendas"
                        className="form-control backstage-input"
                        style={{ height: 40 }}
                        disabled={loading}
                        value={dataInicio}
                        onChange={({ target: { value } }) =>
                          setDataInicio(value)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <p className="text-500-dark-18 mb-2">Hora início*</p>
                    <div className="input-group">
                      <ReactInputMask
                        type="text"
                        mask="99:99"
                        maskChar="_"
                        placeholder="Hora do início das vendas"
                        className="form-control backstage-input"
                        style={{ height: 40 }}
                        disabled={loading}
                        value={horaInicio}
                        onChange={({ target: { value } }) =>
                          setHoraInicio(value)
                        }
                      />
                      <div className="input-group-text border-0 bg-cinza">
                        <ClockIcon />
                      </div>
                    </div>
                  </div>
                </div>

                {/*
                  --------------------------------------------------------------------------
                                            Data e hora de término                          
                  --------------------------------------------------------------------------
                */}
                <div className="row g-3">
                  <div className="col-xl-6">
                    <p className="text-500-dark-18 mb-2">Data término*</p>
                    <div className="input-group">
                      <input
                        type="date"
                        placeholder="Data do término das vendas"
                        className="form-control backstage-input"
                        style={{ height: 40 }}
                        disabled={loading}
                        value={dataTermino}
                        onChange={({ target: { value } }) =>
                          setDataTermino(value)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <p className="text-500-dark-18 mb-2">Hora término*</p>
                    <div className="input-group">
                      <ReactInputMask
                        type="text"
                        mask="99:99"
                        maskChar="_"
                        placeholder="Hora do término das vendas"
                        className="form-control backstage-input"
                        style={{ height: 40 }}
                        disabled={loading}
                        value={horaTermino}
                        onChange={({ target: { value } }) =>
                          setHoraTermino(value)
                        }
                      />
                      <div className="input-group-text border-0 bg-cinza">
                        <ClockIcon />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Virada automática de ingressos */}
              {acessoNegadoGrupos ? (
                <div className="mb-3">
                  <DarkGrayCard message="Você não tem acesso a virada automática de ingressos." />
                </div>
              ) : (
                <ViradaAutomaticaIngressos
                  ingressos={ingressosViradaAutomatica}
                  ingressosSelecionados={ingressosSelecionadosViradaAutomatica}
                  setIngressosSelecionados={
                    setIngressosSelecionadosViradaAutomatica
                  }
                />
              )}

              {/* Valor e quantidade */}
              <div className="col-xl-8">
                <p className="text-500-black-18 mb-2">Valor e quantidade</p>
                <p className="text-400-darkest-16 mb-2">
                  Define o valor do ingresso, o seu estoque e quem ficará
                  responsável pelas taxas.
                </p>
                <div className="row g-3 mb-3">
                  <div className="col-xl-6">
                    <p className="text-500-dark-18 mb-2">Valor*</p>

                    <div className="input-group">
                      <span
                        className={clsx(
                          "input-group-text bg-cinza text-500-black-16",
                          errors.valor && "border border-1 border-danger",
                          !errors.valor && "border-0"
                        )}
                      >
                        R$
                      </span>
                      <Input
                        type="text"
                        name="valor"
                        control={control}
                        errors={errors}
                        validation={{
                          required: "Informe o valor do ingresso",
                          pattern: {
                            // virgula e ponto são permitidos somente se houver 2 casas decimais
                            value: /^(\d+([,.]\d{0,2})?|[,.]\d{1,2})$/,
                            message: "Informe um valor válido",
                          },
                        }}
                        placeholder="Informe o valor em reais"
                      />
                    </div>
                    {errors.valor && ValidationError(errors.valor)}
                  </div>
                  <div className="col-xl-6">
                    <p className="text-500-dark-18 mb-2">Quantidade*</p>

                    <Input
                      type="number"
                      name="quantidadeIngressosParaVenda"
                      control={control}
                      errors={errors}
                      validation={{
                        required: "Informe a quantidade de ingressos",
                        min: {
                          value: 1,
                          message: "Informe um valor maior que 0",
                        },
                      }}
                      placeholder="Quantidade de ingressos"
                    />
                    {errors.quantidadeIngressosParaVenda &&
                      ValidationError(errors.quantidadeIngressosParaVenda)}
                  </div>

                  {/*
                    --------------------------------------------------------------------------
                                              Distribuição da taxa                           
                    --------------------------------------------------------------------------
                  */}

                  {acessoNegadoTaxas ? (
                    <div className="mt-3">
                      <DarkGrayCard message="Você não tem acesso às taxas." />
                    </div>
                  ) : (
                    <>
                      <div className="col-xl-12">
                        <p className="text-500-dark-18 mb-2">
                          Distribuição da taxa
                        </p>

                        <select
                          className="form-select backstage-input"
                          style={{ height: 40 }}
                          value={distribuicaoTaxa}
                          onChange={({ target: { value } }) =>
                            setDistribuicaoTaxa(value as DistribuicaoTaxa)
                          }
                        >
                          <option value={DistribuicaoTaxa.Produtor}>
                            Produtor pagará a taxa
                          </option>
                          <option value={DistribuicaoTaxa.Cliente}>
                            Cliente pagará a taxa
                          </option>
                          <option value={DistribuicaoTaxa.ProdutorCliente}>
                            Produtor e cliente pagará a taxa
                          </option>
                        </select>
                      </div>
                      <div className="col-xl-12">
                        <div className="row g-0 card-cinza-claro">
                          <div className="col-xl-6 align-self-center">
                            <div className="d-flex flex-column justify-content-center align-items-start bg-white shadow-sm p-3 m-3">
                              <p className="text-500-black-16 mb-1">
                                Preço de compra
                              </p>
                              <p className="text-400-darkest-14 m-0">
                                {calcularTaxaIngresso()}
                              </p>
                            </div>
                          </div>
                          <div className="col-xl-6 align-self-center p-3">
                            <p className="text-500-black-16 mb-1">
                              Taxa de Administração
                            </p>
                            <p className="text-400-darkest-14 mb-2">
                              {taxas.taxaAdmnistracao?.toPrecision(3) ?? 0} %
                            </p>

                            <p className="text-500-black-16 mb-1">
                              Taxa de Processamento
                            </p>
                            <p className="text-400-darkest-14 m-0">
                              {taxas.taxaProcessamento?.toPrecision(3) ?? 0} %
                            </p>

                            <p className="text-500-black-16 mb-1">Ingresso</p>
                            <p className="text-400-darkest-14 m-0">0 %</p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>

              {/* Limitar ingressos */}
              <div className="col-xl-8">
                <div className="card-cinza-claro p-3">
                  <p className="text-500-black-18 mb-2">Limitar ingressos</p>
                  <p className="text-400-darkest-14 mb-2">
                    Limite de ingressos que o cliente pode adquirir por compra.
                  </p>

                  {limitarPedido && (
                    <WhiteContainer>
                      <div className="row g-3">
                        <div className="col-xl-6">
                          <p className="text-500-dark-18 mb-2">Pedido mínimo</p>

                          <Input
                            type="number"
                            className="bg-white"
                            name="pedidoMinimo"
                            control={control}
                            errors={errors}
                            validation={{
                              required: "Informe o pedido mínimo",
                              min: {
                                value: 1,
                                message: "Informe um valor maior que 0",
                              },
                            }}
                            placeholder="Pedido mínimo"
                          />
                          {errors.pedidoMinimo &&
                            ValidationError(errors.pedidoMinimo)}
                        </div>

                        <div className="col-xl-6">
                          <p className="text-500-dark-18 mb-2">Pedido máximo</p>
                          <Input
                            type="number"
                            className="bg-white"
                            name="pedidoMaximo"
                            control={control}
                            errors={errors}
                            validation={{
                              required: "Informe o pedido máximo",
                              min: {
                                value: 1,
                                message: "Informe um valor maior que 0",
                              },
                            }}
                            placeholder="Pedido máximo"
                          />
                          {errors.pedidoMaximo &&
                            ValidationError(errors.pedidoMaximo)}
                        </div>
                      </div>
                    </WhiteContainer>
                  )}

                  <Button
                    className="mt-3"
                    text="Limitar"
                    variant={limitarPedido ? "primary" : "light"}
                    onClick={() => setLimitarPedido(!limitarPedido)}
                  />
                </div>
              </div>

              {/* Vincular código */}
              {acessoNegadoCodigos ? (
                <div className="mb-3">
                  <DarkGrayCard message="Você não tem acesso a listagem de códigos de acesso." />
                </div>
              ) : (
                <VincularCodigoAcesso
                  codigos={codigosAcesso}
                  codigosSelecionados={codigosAcessoSelecionados}
                  setCodigosSelecionados={setCodigosAcessoSelecionados}
                />
              )}
            </div>
          </WhiteContainer>

          <FormButtons
            cancelAction={() => navigate(-1)}
            disabled={loading}
            submitText="Cadastrar ingresso"
          />
        </form>
      )}
    </MainLayout>
  );
}
