export default function IngressoIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 12C20 11.24 20.43 10.58 21.06 10.24C21.66 9.91 22 9.23 22 8.54V6C22 4.9 21.1 4 20 4H4C2.9 4 2.01 4.89 2.01 5.99V8.54C2.01 9.23 2.34 9.91 2.95 10.23C3.58 10.58 4 11.24 4 12C4 12.76 3.57 13.43 2.94 13.76C2.34 14.09 2 14.77 2 15.46V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V15.46C22 14.77 21.66 14.09 21.06 13.76C20.43 13.42 20 12.76 20 12ZM14.5 16.1L12 14.5L9.5 16.11C9.12 16.35 8.63 16 8.75 15.56L9.5 12.68L7.2 10.8C6.85 10.51 7.03 9.94 7.49 9.91L10.45 9.74L11.53 6.99C11.7 6.57 12.3 6.57 12.46 6.99L13.54 9.75L16.5 9.92C16.95 9.95 17.14 10.52 16.79 10.81L14.49 12.69L15.25 15.55C15.37 16 14.88 16.35 14.5 16.1Z"
        fill={
          window.location.pathname.includes("/ingressos") ? "#fff" : "#9d9d9d"
        }
      />
    </svg>
  );
}
