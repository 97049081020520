import MainLayout from "../../Components/Layouts/Main";
import BreadCrumb, { Page } from "../../Components/Breadcrumb";
import { AddIcon, FiltroIcon, SearchIcon } from "../../Components/Icons";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import CardEvento from "../../Components/CardEvento";
import { FormEvent, useEffect, useState } from "react";
import EventoCardModel from "../../Interfaces/Models/EventoCardModel";
import toast from "react-hot-toast";
import AxiosClient from "../../Services/AxiosClient";
import RolagemInfinita from "../../Interfaces/RolagemInfinita";
import ServiceResult from "../../Interfaces/ServiceResult";
import Loading from "../../Components/Loading";
import WhiteContainer from "../../Components/WhiteContainer";
import DarkGrayCard from "../../Components/DarkGrayCard";
import AxiosErrorHandler from "../../Services/AxiosErrorHandler";

const breadCrumbHistory: Page[] = [
  {
    link: "/",
    name: "dashboard",
  },
  {
    link: "/eventos",
    name: "eventos",
  },
];

export default function Eventos() {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const searchString = searchParams.get("searchString") || "";

  const [possuiMaisEventos, setPossuiMaisEventos] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [pagina, setPagina] = useState<number>(1);
  const [eventos, setEventos] = useState<EventoCardModel[]>([]);
  const [tabs, setTabs] = useState<{ name: string; active: boolean }[]>([
    { name: "Todos os eventos", active: true },
    { name: "Eventos do mês", active: false },
    { name: "Eventos da semana", active: false },
    { name: "Eventos do dia", active: false },
  ]);
  const [filtros, setFiltros] = useState<{
    filtroString: string;
    filtroPeriodo: string;
    filtroStatus: string;
  }>({
    filtroString: "",
    filtroPeriodo: "Mais recentes",
    filtroStatus: "",
  });
  const [filtrosAplicados, setFiltrosAplicados] = useState<string[]>([]);

  const [acessoNegadoEventos, setAcessoNegadoEventos] =
    useState<boolean>(false);

  const naoPossuiEventos =
    !loading &&
    eventos.length === 0 &&
    filtrosAplicados.length === 0 &&
    tabs[0].active;

  const toggleTab = (tab: string) => {
    setTabs((prev) =>
      prev.map((t) => ({
        ...t,
        active: t.name === tab,
      }))
    );

    setPagina(1);
    setEventos([]);
  };

  const handleFiltro = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setFiltrosAplicados([]);

    if (filtros.filtroString) {
      setFiltrosAplicados((prev) => [...prev, filtros.filtroString]);
    }

    if (filtros.filtroPeriodo) {
      setFiltrosAplicados((prev) => [...prev, filtros.filtroPeriodo]);
    }

    if (filtros.filtroStatus) {
      setFiltrosAplicados((prev) => [...prev, filtros.filtroStatus]);
    }

    setPagina(1);
    setEventos([]);
  };

  const removerFiltro = (filtro: string): void => {
    if (filtro === filtros.filtroString) {
      setFiltros({
        ...filtros,
        filtroString: "",
      });
    }

    if (filtro === filtros.filtroPeriodo) {
      setFiltros({
        ...filtros,
        filtroPeriodo: "",
      });
    }

    if (filtro === filtros.filtroStatus) {
      setFiltros({
        ...filtros,
        filtroStatus: "",
      });
    }

    setFiltrosAplicados((prev) => prev.filter((f) => f !== filtro));

    setPagina(1);
    setEventos([]);
  };

  const getRequestPathWIthQueryParams = (): string => {
    let url: string = "/eventos";
    url += `?pagina=${pagina}`;
    url += "&paginaTamanho=100";
    url += "&apenasComIngressosDisponiveis=false";
    url += "&apenasVisiveis=false";

    const today = new Date();

    for (const tab of tabs) {
      if (tab.active) {
        if (tab.name === "Todos os eventos") break;

        if (tab.name === "Eventos do mês") {
          // dataInicio = primeiro dia do mês atual
          const startOfMonth = new Date(
            today.getFullYear(),
            today.getMonth(),
            1
          )
            .toISOString()
            .split("T")[0];

          // dataFim = último dia do mês atual
          const endOfMonth = new Date(
            today.getFullYear(),
            today.getMonth() + 1,
            0
          )
            .toISOString()
            .split("T")[0];

          url += `&dataInicio=${startOfMonth}&dataFim=${endOfMonth}`;
        }

        if (tab.name === "Eventos da semana") {
          // Define o início da semana para domingo
          const startOfWeek = new Date(today);
          startOfWeek.setDate(today.getDate() - today.getDay());
          startOfWeek.setHours(0, 0, 0, 0);

          // Define o final da semana para sábado
          const endOfWeek = new Date(today);
          endOfWeek.setDate(today.getDate() + (6 - today.getDay()));
          endOfWeek.setHours(23, 59, 59, 999);

          url += `&dataInicio=${startOfWeek.toISOString()}&dataFim=${endOfWeek.toISOString()}`;
        }

        if (tab.name === "Eventos do dia") {
          // dataInicio = hoje
          const dataInicio = new Date(today);
          dataInicio.setHours(0, 0, 0, 0);

          // dataFim = final do dia de hoje
          const dataFim = new Date(today);
          dataFim.setHours(23, 59, 59, 999);

          url += `&dataInicio=${dataInicio.toISOString()}&dataFim=${dataFim.toISOString()}`;
        }
      }
    }

    // verifica se tem filtro por string / search string (header)
    if (searchString) {
      url += `&searchString=${searchString}`;
    } else if (filtros.filtroString) {
      url += `&searchString=${filtros.filtroString}`;
    }

    // verifica se tem filtro por período
    if (filtros.filtroPeriodo) {
      // verifica se é filtro por mais antigo ou mais recente
      if (filtros.filtroPeriodo === "Mais antigo") {
        url += `&sort_by=+dataInicio`;
      } else {
        url += `&sort_by=-dataInicio`;
      }
    }

    // verifica se tem filtro por status
    if (filtros.filtroStatus) {
      // verifica se é filtro por ativo, finalizado ou cancelado
      if (filtros.filtroStatus === "Ativo") {
        url += `&ativo=true`;
      }

      if (filtros.filtroStatus === "Finalizado") {
        url += `&finalizado=true`;
      }

      if (filtros.filtroStatus === "Cancelado") {
        url += `&cancelado=true`;
      }
    }

    return url;
  };

  const getEventos = async (): Promise<void> => {
    setLoading(true);

    AxiosClient.get<ServiceResult<RolagemInfinita<EventoCardModel>>>(
      getRequestPathWIthQueryParams()
    )
      .then(({ data: { data } }) => {
        setEventos((prevValue) => [...prevValue, ...data!.registros]);
        setPossuiMaisEventos(data!.possuiMais);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          setAcessoNegadoEventos(true);
          return;
        }
        toast.error(AxiosErrorHandler(error));
      })
      .finally(() => setLoading(false));
  };

  const carregarMaisEventos = () => {
    setPagina((prev) => prev + 1);
  };

  useEffect(() => {
    if (searchString) {
      setEventos([]);
    }

    getEventos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagina, filtrosAplicados, tabs, searchString]);

  return (
    <MainLayout>
      <div className="mb-3">
        <BreadCrumb history={breadCrumbHistory} />
      </div>

      <WhiteContainer
        rowMarginBottom={loading ? 3 : 4}
        containerPadding={4}
        containerWidth={12}
      >
        <p className="title-h1 text-black mb-2">Meus eventos</p>
      </WhiteContainer>

      <WhiteContainer containerWidth={12}>
        {/* Botões filtragem */}
        <div className="d-flex flex-column flex-xl-row justify-content-center justify-content-xl-between align-items-center">
          <div className="d-flex flex-wrap align-items-center justify-content-center mb-4">
            {tabs.map((tab, i) => (
              <button
                key={i}
                className={`btn-tab me-3 mb-2 ${
                  tab.active && "btn-tab-active"
                }`}
                onClick={() => toggleTab(tab.name)}
              >
                <p className="text-400-dark-16 m-0">{tab.name}</p>
              </button>
            ))}
          </div>
          <div className="d-flex flex-wrap justify-content-center align-items-center mb-4">
            <ul className="navbar-nav flex-nowrap ms-auto">
              <li className="nav-item dropdown no-arrow">
                <div className="nav-item dropdown no-arrow">
                  <a
                    className="dropdown-toggle nav-link cursor-pointer"
                    aria-expanded="false"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                  >
                    <button
                      type="button"
                      className="btn-outline py-2 px-3 me-2 mb-2 d-flex justify-content-center align-items-center"
                    >
                      <p className="text-500-black-16 pe-2 m-0">Filtrar</p>
                      <FiltroIcon />
                    </button>
                  </a>
                  <div className="dropdown-menu shadow dropdown-menu-end p-3 dropdown-filtro">
                    <p className="text-400-black-24 mb-2">Filtrar</p>
                    <p className="text-400-black-14 mb-3">
                      Faça uma busca por nome ou data do evento
                    </p>

                    <form onSubmit={(e) => handleFiltro(e)}>
                      {/* Filtro por string */}
                      <div className="input-group mb-3 rounded-pill">
                        <button
                          className="btn btn-light py-0 pe-0"
                          style={{
                            borderRadius: "50px 0 0 50px",
                          }}
                          type="submit"
                        >
                          <SearchIcon />
                        </button>
                        <input
                          className="bg-light form-control border-0 small"
                          style={{
                            borderRadius: "0 50px 50px 0",
                          }}
                          type="text"
                          placeholder="Faça uma busca"
                          value={filtros.filtroString}
                          onChange={({ target: { value } }) =>
                            setFiltros({
                              ...filtros,
                              filtroString: value,
                            })
                          }
                        />
                      </div>

                      {/* Filtro por período: Mais antigo / Mais recentes */}
                      <div
                        className="p-3 mb-3"
                        style={{ borderRadius: 16, background: "#F9F8F8" }}
                      >
                        <div className="form-check mb-2">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="periodo"
                            id="antigo"
                            value={filtros.filtroPeriodo}
                            checked={filtros.filtroPeriodo === "Mais antigo"}
                            onChange={() =>
                              setFiltros({
                                ...filtros,
                                filtroPeriodo: "Mais antigo",
                              })
                            }
                          />
                          <label className="form-check-label" htmlFor="antigo">
                            Mais antigo
                          </label>
                        </div>
                        <div className="form-check m-0">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="periodo"
                            id="recente"
                            value={filtros.filtroPeriodo}
                            checked={filtros.filtroPeriodo === "Mais recentes"}
                            onChange={() =>
                              setFiltros({
                                ...filtros,
                                filtroPeriodo: "Mais recentes",
                              })
                            }
                          />
                          <label className="form-check-label" htmlFor="recente">
                            Mais recentes
                          </label>
                        </div>
                      </div>

                      {/* Filtro por status: Ativo / Finalizado / Cancelado */}
                      <div
                        className="p-3 mb-3"
                        style={{
                          borderRadius: 16,
                          background: "#F9F8F8",
                        }}
                      >
                        <div className="form-check mb-2">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="status"
                            id="ativo"
                            value={filtros.filtroStatus}
                            checked={filtros.filtroStatus === "Ativo"}
                            onChange={() =>
                              setFiltros({
                                ...filtros,
                                filtroStatus: "Ativo",
                              })
                            }
                          />
                          <label className="form-check-label" htmlFor="ativo">
                            Ativo
                          </label>
                        </div>
                        <div className="form-check mb-2">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="status"
                            id="finalizado"
                            value={filtros.filtroStatus}
                            checked={filtros.filtroStatus === "Finalizado"}
                            onChange={() =>
                              setFiltros({
                                ...filtros,
                                filtroStatus: "Finalizado",
                              })
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="finalizado"
                          >
                            Finalizado
                          </label>
                        </div>
                        <div className="form-check m-0">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="status"
                            id="cancelado"
                            value={filtros.filtroStatus}
                            checked={filtros.filtroStatus === "Cancelado"}
                            onChange={() =>
                              setFiltros({
                                ...filtros,
                                filtroStatus: "Cancelado",
                              })
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="cancelado"
                          >
                            Cancelado
                          </label>
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="bc-btn bc-btn-primary px-3 py-2 w-100"
                      >
                        Filtrar
                      </button>
                    </form>
                  </div>
                </div>
              </li>
            </ul>

            <button
              onClick={() => navigate("/cadastrar-evento")}
              className="bc-btn bc-btn-primary py-2 px-3 mb-2 d-flex justify-content-center align-items-center"
            >
              <p className="text-500-white-16 pe-2 m-0">Criar Evento</p>
              <AddIcon />
            </button>
          </div>
        </div>

        {/* Filtros aplicados */}
        {filtrosAplicados.length > 0 && (
          <div className="d-flex flex-wrap align-items-center justify-content-center mb-4">
            {filtrosAplicados.map((f, i) => (
              <div className="me-3 mb-2" key={i}>
                <div
                  className="d-flex flex-wrap justify-content-center align-items-center px-4"
                  style={{
                    borderRadius: 50,
                    background: "#F9F8F8",
                  }}
                >
                  <p className="text-400-black-16 m-0 me-2 my-2">Filtro:</p>
                  <p className="text-400-black-16 m-0 me-2 my-2 text-break">
                    {f}
                  </p>
                  <button
                    className="btn-close my-2"
                    onClick={() => removerFiltro(f)}
                  ></button>
                </div>
              </div>
            ))}
          </div>
        )}

        {/* Eventos */}
        {loading ? (
          <p className="text-500-darkest-18">Carregando eventos...</p>
        ) : acessoNegadoEventos ? (
          <div className="mb-3">
            <DarkGrayCard message="Você não tem acesso aos eventos." />
          </div>
        ) : eventos.length > 0 ? (
          <>
            <div className="row g-3">
              {eventos.map((e) => (
                <Link
                  to={`/eventos/${e.slug}/estatisticas`}
                  key={e.id}
                  className="col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xxl-2 mt-4 mt-lg-5 d-flex justify-content-center text-decoration-none"
                >
                  <CardEvento
                    imagemThumbEvento={e.imagemThumbEvento}
                    dataInicio={e.dataInicio}
                    titulo={e.titulo}
                  />
                </Link>
              ))}

              <div className="col-lg-12 pt-5 d-flex justify-content-center">
                <button
                  onClick={carregarMaisEventos}
                  className={`bc-btn bc-btn-primary w-100 px-3 py-2 ${
                    !possuiMaisEventos && "disabled"
                  }`}
                  style={{
                    maxWidth: 200,
                  }}
                  disabled={!possuiMaisEventos}
                >
                  Carregar mais
                </button>
              </div>
            </div>
          </>
        ) : naoPossuiEventos ? (
          <div className="col-lg-8">
            <DarkGrayCard
              message="Você ainda não tem eventos por aqui!"
              action={() => navigate("/cadastrar-evento")}
              actionText="Criar Evento"
            />
          </div>
        ) : (
          <p className="text-500-black-16">
            Nenhum evento encontrado por aqui!
          </p>
        )}
      </WhiteContainer>
    </MainLayout>
  );
}
