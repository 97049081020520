import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import AxiosClient from "../../../../Services/AxiosClient";
import BreadCrumb, { Page } from "../../../../Components/Breadcrumb";
import MainLayout from "../../../../Components/Layouts/Main";
import WhiteContainer from "../../../../Components/WhiteContainer";
import FormButtons from "../../../../Components/Form/FormButtons";
import moment from "moment";
import CupomDescontoModel from "../../../../Interfaces/Models/CupomDescontoModel";
import { ServiceResult } from "../../../../Interfaces";
import AxiosErrorHandler from "../../../../Services/AxiosErrorHandler";
import Loading from "../../../../Components/Loading";
import InputCurrency from "../../../../Components/Inputs/InputCurrency";
import { SomenteNumeros } from "../../../../Utils";

interface CupomDescontoPutArgs {
  codigo: string;
  descricao: string | null;
  dataVigenciaInicio: string;
  dataVigenciaFim: string;
  possuiQuantidadeMinima: boolean;
  quantidadeMinima: number | null;
  possuiValorMinimo: boolean;
  valorMinimoCentavos: number | null;
  desconto: number;
}

interface CumpoDescontoViewModel {
  codigo: string;
  descricao: string | null;
  dataVigenciaInicio: string;
  dataVigenciaFim: string;
  possuiQuantidadeMinima: boolean;
  quantidadeMinima: number | null;
  possuiValorMinimo: boolean;
  valorMinimoCentavos: string;
  desconto: number;
}

export default function EditarCupom() {
  const navigate = useNavigate();
  const { eventoId, cupomId } = useParams();
  const [loading, setLoading] = useState<boolean>(false);

  const { register, handleSubmit, setValue, control } =
    useForm<CumpoDescontoViewModel>();
  const breadCrumbHistory: Page[] = [
    { link: "/", name: "dashboard" },
    { link: "/eventos", name: "Eventos" },
    {
      link: `/eventos/${eventoId}/cupom-desconto/`,
      name: "Cupom desconto",
    },
    {
      link: `/eventos/${eventoId}/cupom-desconto/editar-cupom/${cupomId}`,
      name: "Editar cupom",
    },
  ];

  const [possuiValorMinimo, setPossuiValorMinimo] = useState<boolean>(true);
  const [possuiQuantidadeMinima, setPossuiQuantidadeMinima] =
    useState<boolean>(true);

  const getCupom = async () => {
    setLoading(true);
    AxiosClient.get<ServiceResult<CupomDescontoModel>>(
      `/eventos/${eventoId}/cupom-desconto/${cupomId}`
    )
      .then((response) => {
        const { data } = response.data;
        setValue("codigo", data?.codigo ?? "");
        setValue("descricao", data?.descricao ?? null);
        setValue(
          "dataVigenciaInicio",
          moment(data?.dataVigenciaInicio).format("YYYY-MM-DD")
        );
        setValue(
          "dataVigenciaFim",
          moment(data?.dataVigenciaFim).format("YYYY-MM-DD")
        );
        setValue("quantidadeMinima", data?.quantidadeMinima ?? 0);

        setValue(
          "valorMinimoCentavos",
          data?.valorMinimoCentavos?.toString() ?? ""
        );
        setValue("desconto", data?.desconto ?? 0);

        setPossuiQuantidadeMinima(data?.possuiQuantidadeMinima ?? false);
        setPossuiValorMinimo(data?.possuiValorMinimo ?? false);
      })

      .catch((error) => {
        navigate(`/eventos/${eventoId}/cupom-desconto`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getCupom();
  }, []);

  const atualizarCupons = async (
    data: CumpoDescontoViewModel
  ): Promise<void> => {
    const args: CupomDescontoPutArgs = {
      codigo: data.codigo,
      descricao: data.descricao,
      dataVigenciaInicio: data.dataVigenciaInicio,
      dataVigenciaFim: data.dataVigenciaFim,
      possuiValorMinimo: possuiValorMinimo,
      possuiQuantidadeMinima: possuiQuantidadeMinima,
      valorMinimoCentavos: data.valorMinimoCentavos
        ? Number(SomenteNumeros(data.valorMinimoCentavos))
        : null,
      desconto: data.desconto,
      quantidadeMinima: data.quantidadeMinima,
    };

    if (!data.codigo) {
      toast.error("O campo cupom é obrigatório.");
      return;
    }

    toast.promise(
      AxiosClient.put(
        `/eventos/${eventoId}/cupom-desconto/${cupomId}`,
        args
      ).then(() => navigate(`/eventos/${eventoId}/cupom-desconto`)),
      {
        loading: "Editando cupom...",
        success: "Cupom editado com sucesso!",
        error: (error) => AxiosErrorHandler(error),
      }
    );
  };

  return (
    <MainLayout>
      <div className="mb-3">
        <BreadCrumb history={breadCrumbHistory} />
      </div>

      <WhiteContainer containerPadding={4}>
        <div className="d-flex flex-column flex-xl-row justify-content-xl-between align-items-center">
          <p className="title-h1 text-black text-center mb-2">Editar cupom</p>
        </div>
      </WhiteContainer>
      {loading ? (
        <Loading container="50vh" />
      ) : (
        <form onSubmit={handleSubmit(atualizarCupons)}>
          <WhiteContainer containerPadding={4} rowMarginBottom={4}>
            <div className="row g-3">
              <p className="text-500-dark-18 mb-2">Cupom</p>
              <input
                type="text"
                className="form-control backstage-input p-2"
                id="codigo"
                {...register("codigo")}
              />
              <div className="col-xl-8">
                <p className="text-500-dark-18 mb-2">Descrição</p>
                <textarea
                  placeholder="Descrição"
                  className="form-control backstage-input p-2"
                  style={{ height: 120, resize: "none" }}
                  {...register("descricao")}
                />
              </div>
            </div>
            <div className="row g-3">
              <div className="col-xl-4">
                <div className="d-flex flex-wrap-reverse justify-content-between align-items-center">
                  <p className="text-500-dark-18 mb-2">Qtde mínima</p>

                  <div className="form-check form-switch d-flex align-items-center ps-5 mb-2">
                    <input
                      type="checkbox"
                      className="form-check-input mb-1 cursor-pointer"
                      id="possuiQuantidadeMinima"
                      checked={possuiQuantidadeMinima}
                      onChange={({ target: { checked } }) => {
                        setPossuiQuantidadeMinima(checked);
                      }}
                    />
                    <label
                      className="form-check-label ps-2"
                      htmlFor="possuiQuantidadeMinima"
                    >
                      <p className="text-500-dark-18 mb-2"> Habilitar</p>
                    </label>
                  </div>
                </div>
                <input
                  type="number"
                  className={`form-control backstage-input p-2 ${
                    !possuiQuantidadeMinima && "disabled"
                  }`}
                  disabled={!possuiQuantidadeMinima}
                  placeholder="Quantidade mínima"
                  {...register("quantidadeMinima", { valueAsNumber: true })}
                />
              </div>
              <div className="col-xl-4">
                <div className="d-flex flex-wrap-reverse justify-content-between align-items-center">
                  <p className="text-500-dark-18 mb-2">Valor mínimo</p>

                  <div className="form-check form-switch d-flex align-items-center ps-5 mb-2">
                    <input
                      type="checkbox"
                      className="form-check-input mb-1 cursor-pointer"
                      id="possuiValorMinimo"
                      checked={possuiValorMinimo}
                      onChange={({ target: { checked } }) => {
                        setPossuiValorMinimo(checked);
                      }}
                    />
                    <label
                      className="form-check-label ps-2"
                      htmlFor="possuiValorMinimo"
                    >
                      <p className="text-500-dark-18 mb-2"> Habilitar</p>
                    </label>
                  </div>
                </div>

                <InputCurrency
                  control={control}
                  name="valorMinimoCentavos"
                  validation={{}}
                  className={`form-control backstage-input p-2 ${
                    !possuiValorMinimo && "disabled"
                  }`}
                  placeholder="Valor mínimo"
                  disabled={!possuiValorMinimo}
                />
              </div>
            </div>
            <div className="row g-3">
              <div className="col-xl-4">
                <label
                  htmlFor="dataVigenciaInicio"
                  className="text-500-dark-18 mb-2"
                >
                  Início da vigência
                </label>
                <input
                  type="date"
                  className="form-control backstage-input p-2"
                  id="dataVigenciaInicio"
                  {...register("dataVigenciaInicio")}
                />
              </div>
              <div className="col-xl-4">
                <label
                  htmlFor="dataVigenciaFim"
                  className="text-500-dark-18 mb-2"
                >
                  Fim da vigência
                </label>
                <input
                  type="date"
                  className="form-control backstage-input p-2"
                  id="dataVigenciaFim"
                  {...register("dataVigenciaFim")}
                />
              </div>
            </div>
            <div className="row g-3">
              <div className="col-xl-4">
                <label htmlFor="desconto" className="text-500-dark-18 mb-2">
                  Desconto (%)
                </label>
                <input
                  type="number"
                  className="form-control backstage-input p-2"
                  id="desconto"
                  {...register("desconto", { valueAsNumber: true })}
                />
              </div>
            </div>
            <div className="row g-3"></div>
            {loading && <Loading container="50vh" />}
          </WhiteContainer>
          <FormButtons
            submitText="Salvar"
            cancelAction={() => navigate(`/eventos/${eventoId}/cupom-desconto`)}
          />
        </form>
      )}
    </MainLayout>
  );
}
