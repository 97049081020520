import { create } from "zustand";

type EventoStore = {
  tituloEvento: string;
  setTituloEvento: (titulo: string) => void;
  slug: string;
  setSlug: (slug: string) => void;
};

export const useEventoStore = create<EventoStore>()((set) => ({
  tituloEvento: "",
  setTituloEvento: (titulo) => set({ tituloEvento: titulo }),
  slug: "",
  setSlug: (slug) => set({ slug: slug }),
}));
