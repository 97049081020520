import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { useEventoStore } from "../Contexts/EventoStore";
import { ServiceResult } from "../Interfaces";
import { EventoModel } from "../Interfaces/Models";
import AxiosClient from "../Services/AxiosClient";
import AxiosErrorHandler from "../Services/AxiosErrorHandler";

export default function useObterNomeEventoHeader() {
  const navigate = useNavigate();
  const { eventoId } = useParams();
  const { tituloEvento, setTituloEvento, slug, setSlug } = useEventoStore();

  const obterNomeEvento = async (): Promise<void> => {
    // se possui tituloEvento setado
    // e se o slug é igual ao eventoId, o evento já foi carregado
    if (tituloEvento && slug === eventoId) {
      return;
    }

    setTituloEvento("Carregando...");

    AxiosClient.get<ServiceResult<EventoModel>>(`/eventos/${eventoId}`)
      .then(({ data: { data } }) => {
        const evento = data as EventoModel;
        setTituloEvento(evento.titulo);
        setSlug(evento.slug);
      })
      .catch((error) => {
        navigate("/eventos");
        if (error.response && error.response.status === 403) {
          toast.error("Acesso negado para esse evento.");
          return;
        }
        toast.error("Evento não encontrado.");
      });
  };

  return { obterNomeEvento };
}
