import { useForm } from "react-hook-form";
import { useState } from "react";
import AxiosClient from "../../../Services/AxiosClient";
import toast from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Input from "../../../Components/Inputs/Input";
import Loading from "../../../Components/Loading";
import Button from "../../../Components/Button";
import AxiosErrorHandler from "../../../Services/AxiosErrorHandler";
import { CheckSuccessIcon, InfoIcon } from "../../../Components/Icons";

const regex = {
  letrasNumeros: /^(?=.*[A-Za-z])(?=.*[0-9]).+$/,
  letraCaixaAlta: /[A-Z]/,
  caracteresEspeciais: /[ `!´¨@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/,
};

interface RedefinirSenhaArgs {
  senhaAtual?: string;
  novaSenha: string;
  confirmacaoNovaSenha: string;
}

export default function FormRedefinirSenha() {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<RedefinirSenhaArgs>({
    defaultValues: {
      senhaAtual: "",
      novaSenha: "",
      confirmacaoNovaSenha: "",
    },
  });

  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { token } = useParams<{ token: string }>();

  const RedefinirSenha = async (data: RedefinirSenhaArgs) => {
    setLoading(true);

    toast.promise(
      AxiosClient.post("/conta/esqueci-minha-senha/nova-senha", {
        token,
        ...data,
      })
        .then(() => navigate("/login"))
        .finally(() => setLoading(false)),
      {
        loading: "Aguarde...",
        success: "Senha redefinida com sucesso!",
        error: (error) => AxiosErrorHandler(error),
      },
    );
  };

  // #region [+ Validação da nova senha]

  const novaSenha = watch("novaSenha");
  const confirmacaoNovaSenha = watch("confirmacaoNovaSenha");

  const PossuiMinimoDe8Digitos = () => novaSenha.length >= 8;

  const PossuiLetrasENumeros = () => regex.letrasNumeros.test(novaSenha);

  const PossuiLetraCaixaAlta = () => regex.letraCaixaAlta.test(novaSenha);

  const PossuiCaracteresEspeciais = () =>
    regex.caracteresEspeciais.test(novaSenha);

  const SenhaAtendeTodosRequisitos = () => {
    if (!novaSenha || !confirmacaoNovaSenha) {
      return false;
    }

    if (
      !PossuiMinimoDe8Digitos() ||
      !PossuiLetrasENumeros() ||
      !PossuiLetraCaixaAlta() ||
      !PossuiCaracteresEspeciais()
    ) {
      return false;
    }

    return true;
  };

  // #endregion

  return (
    <form onSubmit={handleSubmit(RedefinirSenha)} className="mb-5">
      {pathname === "/meu-perfil/redefinir-senha" && (
        <div className="form-group mb-4">
          <div className="d-flex align-items-center">
            <Input
              control={control}
              name="senhaAtual"
              validation={{
                required: "Por favor, informe a senha atual.",
              }}
              type={showPassword ? "text" : "password"}
              placeholder="Senha atual"
              errors={errors}
            />
            <button
              type="button"
              className="btn"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <i className="fa-solid fa-eye-slash"></i>
              ) : (
                <i className="fa-solid fa-eye"></i>
              )}
            </button>
          </div>
          {errors.senhaAtual && (
            <p className="text-danger text-break m-0 mt-1">
              {errors.senhaAtual.message?.toString()}
            </p>
          )}
        </div>
      )}
      <div className="form-group mb-4">
        <div className="d-flex align-items-center">
          <Input
            control={control}
            name="novaSenha"
            validation={{
              required: "Por favor, informe a nova senha.",
            }}
            type={showPassword ? "text" : "password"}
            placeholder="Nova senha"
            errors={errors}
          />
          <button
            type="button"
            className="btn"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? (
              <i className="fa-solid fa-eye-slash"></i>
            ) : (
              <i className="fa-solid fa-eye"></i>
            )}
          </button>
        </div>
        {errors.novaSenha && (
          <p className="text-danger text-break m-0 mt-1">
            {errors.novaSenha.message?.toString()}
          </p>
        )}
      </div>

      <div className="form-group mb-5">
        <div className="d-flex align-items-center">
          <Input
            control={control}
            name="confirmacaoNovaSenha"
            validation={{
              required: "Por favor, confirme a nova senha.",
              validate: (value, formValues) =>
                value === formValues.novaSenha || "As senhas não coincidem",
            }}
            type={showPassword ? "text" : "password"}
            placeholder="Repetir nova senha"
            errors={errors}
          />
          <button
            type="button"
            className="btn"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? (
              <i className="fa-solid fa-eye-slash"></i>
            ) : (
              <i className="fa-solid fa-eye"></i>
            )}
          </button>
        </div>
        {errors.confirmacaoNovaSenha && (
          <p className="text-danger text-break m-0 mt-1">
            {errors.confirmacaoNovaSenha.message?.toString()}
          </p>
        )}
      </div>

      <div className="g-5">
        {loading ? (
          <div className="col-lg-9 d-flex justify-content-center align-items-center">
            <Loading />
          </div>
        ) : (
          <div className="col-lg-9">
            <Button
              width="100%"
              text="Criar nova senha"
              type="submit"
              className="bc-btn bc-btn-primary mb-5"
              disabled={!SenhaAtendeTodosRequisitos()}
            />
          </div>
        )}
      </div>

      <div className="g-5 p-3 card-requisitos-senha">
        <div className="d-flex align-items-center mb-3">
          <InfoIcon />
          <p className="text-400-darkest-16 ps-2 m-0">
            Sua senha precisa ter no mínimo:
          </p>
        </div>

        <div className="d-flex align-items-center mb-2">
          {PossuiMinimoDe8Digitos() ? (
            <CheckSuccessIcon enabled />
          ) : (
            <CheckSuccessIcon />
          )}
          <p className="text-400-darkest-14 ps-2 m-0">08 dígitos</p>
        </div>

        <div className="d-flex align-items-center mb-2">
          {PossuiLetrasENumeros() ? (
            <CheckSuccessIcon enabled />
          ) : (
            <CheckSuccessIcon />
          )}
          <p className="text-400-darkest-14 ps-2 m-0">Letras e números</p>
        </div>

        <div className="d-flex align-items-center mb-2">
          {PossuiLetraCaixaAlta() ? (
            <CheckSuccessIcon enabled />
          ) : (
            <CheckSuccessIcon />
          )}
          <p className="text-400-darkest-14 ps-2 m-0">
            Letra em caixa alta (maiúscula)
          </p>
        </div>

        <div className="d-flex align-items-center mb-2">
          {PossuiCaracteresEspeciais() ? (
            <CheckSuccessIcon enabled />
          ) : (
            <CheckSuccessIcon />
          )}
          <p className="text-400-darkest-14 ps-2 m-0">
            Caracteres especiais (@#$%&*)
          </p>
        </div>
      </div>
    </form>
  );
}
