import { UsuarioModel } from "../../../Interfaces/Models";
import BotoesListagemUsuarios from "./BotoesListagemUsuarios";

interface Props {
  usuarios: UsuarioModel[];
}

export default function ListagemUsuarios({ usuarios }: Props) {
  return (
    <div className="row">
      <div className="d-none d-lg-block col-lg-12 mb-3">
        <div className="row">
          <div className="col-lg-2">
            <p className="text-500-black-16 m-0">Nome</p>
          </div>
          <div className="col-lg-2">
            <p className="text-500-black-16 m-0">CPF</p>
          </div>
          <div className="col-lg-3">
            <p className="text-500-black-16 m-0">E-mail</p>
          </div>
          <div className="col-lg-1">
            <p className="text-500-black-16 m-0">Ativo</p>
          </div>
          <div className="col-lg-1">
            <p className="text-500-black-16 m-0">Confirmado</p>
          </div>
        </div>
      </div>

      {usuarios.map((usuario) => (
        <div className="col-lg-12" key={usuario.id}>
          <div className="card-cinza-claro p-3 mb-3">
            <div className="row">
              <div className="align-self-center col-lg-2">
                <p className="d-block d-lg-none text-500-black-16 m-0 mt-3">
                  Nome
                </p>
                <p className="text-500-darkest-14 text-break m-0">
                  {usuario.nomeCompleto}
                </p>
              </div>

              <div className="align-self-center col-lg-2">
                <p className="d-block d-lg-none text-500-black-16 m-0 mt-3">
                  CPF
                </p>
                <p className="text-500-darkest-14 text-break m-0">
                  {usuario.cpf}
                </p>
              </div>

              <div className="align-self-center col-lg-3">
                <p className="d-block d-lg-none text-500-black-16 m-0 mt-3">
                  Email
                </p>
                <p className="text-500-darkest-14 text-break m-0">
                  {usuario.email}
                </p>
              </div>

              <div className="align-self-center col-lg-1">
                <p className="d-block d-lg-none text-500-black-16 m-0 mt-3">
                  Ativo
                </p>
                <p className="text-500-darkest-14 text-break m-0">
                  {usuario.ativo ? "Sim" : "Não"}
                </p>
              </div>
              <div className="align-self-center col-lg-1">
                <p className="d-block d-lg-none text-500-black-16 m-0 mt-3">
                  Confirmado
                </p>
                <p className="text-500-darkest-14 text-break m-0">
                  {usuario.emailConfirmado ? "Sim" : "Não"}
                </p>
              </div>

              <div className="align-self-center col-lg-3">
                <BotoesListagemUsuarios usuario={usuario} />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
