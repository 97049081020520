export default function OlhoTableIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="16"
      fill="none"
      viewBox="0 0 24 16"
    >
      <path
        fill="#595961"
        d="M23.185 7.696c-.032-.074-.827-1.835-2.592-3.6C18.241 1.742 15.27.5 12 .5 8.73.5 5.76 1.743 3.407 4.095 1.642 5.861.844 7.625.815 7.696a.75.75 0 000 .61c.032.074.827 1.834 2.592 3.6C5.759 14.256 8.73 15.5 12 15.5c3.27 0 6.24-1.243 8.593-3.594 1.765-1.766 2.56-3.526 2.592-3.6a.75.75 0 000-.61zM12 14c-2.886 0-5.407-1.05-7.493-3.117A12.516 12.516 0 012.344 8a12.497 12.497 0 012.163-2.883C6.593 3.05 9.114 2 12 2s5.407 1.05 7.493 3.117A12.515 12.515 0 0121.661 8c-.676 1.262-3.62 6-9.661 6zm0-10.5a4.5 4.5 0 100 9 4.5 4.5 0 000-9zm0 7.5a3 3 0 110-5.999A3 3 0 0112 11z"
      ></path>
    </svg>
  );
}
