import { ChangeEvent, useRef, useState } from "react";
import BreadCrumb, { Page } from "../../../Components/Breadcrumb";
import { ImgPreviewIcon, UploadIcon } from "../../../Components/Icons";
import MainLayout from "../../../Components/Layouts/Main";
import { useNavigate } from "react-router-dom";
import AxiosClient from "../../../Services/AxiosClient";
import toast from "react-hot-toast";
import WhiteContainer from "../../../Components/WhiteContainer";
import FormButtons from "../../../Components/Form/FormButtons";
import Button from "../../../Components/Button";
import { ServiceResult } from "../../../Interfaces";
import { isAxiosError } from "axios";

const breadCrumbHistory: Page[] = [
  {
    link: "/",
    name: "dashboard",
  },
  {
    link: "/banners",
    name: "banners",
  },
  {
    link: "/banners/cadastrar",
    name: "cadastrar banner",
  },
];

interface BannerPostBody {
  nome: string;
  link: string;
}

export default function CadastrarBanner() {
  const navigate = useNavigate();

  const [args, setArgs] = useState<BannerPostBody>({} as BannerPostBody);
  const [uploadImg, setUploadImg] = useState<string | undefined>(undefined);
  const [fileName, setFileName] = useState<string | undefined>(undefined);
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setArgs({
      ...args,
      [e.target.name]: e.target.value,
    });
  };

  const handleUploadClick = (): void => {
    if (loading) return;

    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const AlterarImagemBanner = (e: ChangeEvent<HTMLInputElement>): void => {
    const files = e.target.files;

    if (files && files.length > 0) {
      const file = files[0];

      const formData = new FormData();
      formData.append("files", file, file.name);

      setFileName(file.name);

      // set base 64 img
      const reader = new FileReader();
      reader.onload = (e) => {
        setUploadImg(e.target?.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const cadastrarBanner = async (): Promise<void> => {
    setLoading(true);

    toast.promise(
      AxiosClient.post("/banners", {
        // send only the base64
        imagemBase64: uploadImg?.split(",")[1],
        ImagemExtensao: fileName?.split(".")[1],
        nome: args.nome,
        link: "",
      }).finally(() => setLoading(false)),
      {
        loading: "Cadastrando banner...",
        success: () => {
          navigate("/banners");
          return "Banner cadastrado com sucesso.";
        },
        error: (error: any) => {
          if (
            isAxiosError(error) &&
            error.response &&
            error.response.status >= 400 &&
            error.response.status < 500
          ) {
            if (error.response.status === 413) {
              return "O tamanho da imagem é muito grande. Tente novamente com uma imagem menor.";
            }

            const result: ServiceResult = error.response.data;

            if (!result.messages) {
              return "Ocorreu um erro inesperado. Tente novamente mais tarde.";
            }

            return result.messages
              .map((m: any) => "➡️ " + m.message)
              .join("\n\n");
          }

          return "Ocorreu um erro inesperado. Tente novamente mais tarde.";
        },
      },
    );
  };

  return (
    <MainLayout>
      <div className="mb-3">
        <BreadCrumb history={breadCrumbHistory} />
      </div>

      <WhiteContainer containerPadding={4}>
        <p className="title-h1 text-black mb-2">Cadastrar banner</p>
      </WhiteContainer>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          cadastrarBanner();
        }}
      >
        <WhiteContainer rowMarginBottom={4}>
          <div className="row">
            <div className="col-xl-8">
              <p className="title-h1 text-black mb-4">Inserir banner</p>

              <p className="text-500-dark-18">Nome do banner</p>
              <input
                type="text"
                className="form-control backstage-input mb-4"
                style={{ height: 40 }}
                name="nome"
                onChange={handleChange}
                placeholder="Inserir nome do banner"
              />
              <div className="card-cinza-claro p-3 mb-4">
                <div className="form-check form-check-inline d-flex align-items-center mb-4">
                  <input
                    className="form-check-input cursor-pointer"
                    type="checkbox"
                    id="exibicao_temporaria"
                    // value="option1"
                    style={{
                      width: 24,
                      height: 24,
                    }}
                  />
                  <label
                    className="form-check-label text-400-dark-16 mt-1 ps-2 cursor-pointer"
                    htmlFor="exibicao_temporaria"
                  >
                    Exibição temporária
                  </label>
                </div>
                <p className="text-400-darkest-16 m-0">
                  Se você ativar a exibição temporária o banner ficará
                  desativado.
                </p>
              </div>
            </div>
            <div className="col-xl-4">
              <p className="text-500-dark-18 mt-xl-5 mb-2">Banner do evento</p>
              <p className="text-400-black-16 mb-2">
                Recomendamos imagens em 650x350 px. Tamanho máximo 2MB.
              </p>

              <div className="my-4">
                {/* Input file hidden */}
                <input
                  ref={inputFileRef}
                  style={{ display: "none" }}
                  type="file"
                  accept="image/*"
                  multiple={false}
                  onChange={AlterarImagemBanner}
                />
                <div className="cursor-pointer" onClick={handleUploadClick}>
                  {uploadImg ? (
                    <img
                      src={uploadImg}
                      alt="upload banner img"
                      width="100%"
                      height={160}
                    />
                  ) : (
                    <ImgPreviewIcon />
                  )}
                </div>
              </div>

              <Button
                className="py-2 px-3 mx-auto"
                text={uploadImg ? "Trocar banner" : "Inserir banner"}
                onClick={handleUploadClick}
                icon={<UploadIcon />}
              />
            </div>
          </div>
        </WhiteContainer>

        <FormButtons
          cancelAction={() => navigate(-1)}
          disabled={loading}
          submitText="Cadastrar banner"
        />
      </form>
    </MainLayout>
  );
}
