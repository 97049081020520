export default function WarningModalIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="88"
      height="88"
      fill="none"
      viewBox="0 0 88 88"
    >
      <circle cx="44" cy="44" r="44" fill="#fff"></circle>
      <path
        fill="#F9A000"
        d="M28.003 60.015h30.12c3.08 0 5-3.34 3.46-6l-15.06-26.02c-1.54-2.66-5.38-2.66-6.92 0l-15.06 26.02c-1.54 2.66.38 6 3.46 6zm15.06-14c-1.1 0-2-.9-2-2v-4c0-1.1.9-2 2-2s2 .9 2 2v4c0 1.1-.9 2-2 2zm2 8h-4v-4h4v4z"
      ></path>
    </svg>
  );
}
