export default function ChevronRightIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.83686 6.33837C8.62119 6.55359 8.5 6.84576 8.5 7.15044C8.5 7.45512 8.62119 7.74728 8.83686 7.9625L13.3061 12.4317L8.83686 16.901C8.54673 17.1911 8.43343 17.6139 8.53962 18.0103C8.64581 18.4066 8.95537 18.7161 9.35168 18.8223C9.748 18.9285 10.1709 18.8152 10.461 18.5251L15.748 13.238C15.9637 13.0228 16.0849 12.7306 16.0849 12.426C16.0849 12.1213 15.9637 11.8291 15.748 11.6139L10.461 6.32686C10.0233 5.88915 9.28608 5.88915 8.83686 6.33837Z"
        fill="white"
      />
    </svg>
  );
}
