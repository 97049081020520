import { useEffect, useState } from "react";
import {
  CalendarMoneyIcon,
  ClockMoneyIcon,
  OlhoIcon,
  OlhoSlash,
  PigMoneyIcon,
} from "../../../../Components/Icons";
import { Link, useParams } from "react-router-dom";
import EventoFinanceiroSaldoModel from "../../../../Interfaces/Models/EventoFinanceiroSaldoModel";
import { FormatarCentavosParaReais } from "../../../../Utils";
import AxiosClient from "../../../../Services/AxiosClient";
import { ServiceResult } from "../../../../Interfaces";
import toast from "react-hot-toast";
import AxiosErrorHandler from "../../../../Services/AxiosErrorHandler";
import DarkGrayCard from "../../../../Components/DarkGrayCard";
import TooltipButton from "../../../../Components/Inputs/TooltipButton";
import { useSaldoStore } from "../../../../stores/saldo.store";

export const SolicitacaoSaque = () => {
  const { eventoId } = useParams();

  const {
    saldoRefreshing,
    isRefreshing: loadingSaldos,
    setRefreshing,
  } = useSaldoStore();

  const [isVisible, setIsVisible] = useState<boolean>(false);

  const [acessoNegadoSaldo, setAcessoNegadoSaldo] = useState<boolean>(false);

  const [saldos, setSaldos] = useState<EventoFinanceiroSaldoModel | null>(null);

  const obterSaldos = async (): Promise<void> => {
    setRefreshing(true);

    AxiosClient.get<ServiceResult<EventoFinanceiroSaldoModel>>(
      `/eventos/${eventoId}/financeiro/saldo`
    )
      .then(({ data: { data } }) => {
        setSaldos(data as EventoFinanceiroSaldoModel);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          setAcessoNegadoSaldo(true);
          return;
        }

        toast.error(AxiosErrorHandler(error));
      })
      .finally(() => setRefreshing(false));
  };

  useEffect(() => {
    obterSaldos();
  }, [saldoRefreshing]);

  return (
    <div className="col-xl-4">
      <div className="shadow-sm bg-white p-3 h-100">
        {acessoNegadoSaldo ? (
          <div className="mb-3">
            <DarkGrayCard message="Você não tem permissão para acessar o saldo." />
          </div>
        ) : (
          <>
            {/* Disponível para saque */}
            <span className="text-400-darkest-14" style={{ color: "#818187" }}>
              Disponível para saque
            </span>
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center justify-content-center flex-wrap">
                {loadingSaldos ? (
                  <span
                    className="placeholder bg-secondary"
                    style={{
                      width: "100px",
                      height: "20px",
                      background: "#858796",
                    }}
                  ></span>
                ) : (
                  <span className="text-400-black-24 fw-bold text-break">
                    {isVisible
                      ? FormatarCentavosParaReais(
                          saldos?.saldoDisponivelParaSaqueCentavos || 0
                        )
                      : "R$ ******"}
                  </span>
                )}
                <span
                  className="mx-3 cursor-pointer"
                  onClick={() => setIsVisible(!isVisible)}
                >
                  {isVisible ? <OlhoSlash /> : <OlhoIcon />}
                </span>
              </div>
              {/* <span className="mt-1">
                <ChevronRightGrayIcon />
              </span> */}
            </div>
            {loadingSaldos ? (
              <span
                className="placeholder"
                style={{ width: "90px", height: "10px", background: "#858796" }}
              ></span>
            ) : (
              <span
                className="text-400-darkest-14 text-break"
                style={{ color: "#818187" }}
              >
                {FormatarCentavosParaReais(
                  saldos?.saldoLiberarSaqueCentavos || 0
                )}{" "}
                à liberar
              </span>
            )}

            <hr className="mt-3" style={{ margin: "0 -16px" }} />

            {/* Saldos */}
            <div className="d-flex align-items-center justify-content-between mt-3 flex-wrap">
              <span
                className="text-400-darkest-14 fw-semibold"
                style={{ color: "#818187" }}
              >
                Saldos
              </span>
              <span
                className="text-400-darkest-14 fw-semibold"
                style={{ color: "#2B1479" }}
              >
                Entenda os valores
              </span>
            </div>

            <div className="d-flex align-items-center justify-content-between mt-4 flex-wrap">
              <div className="d-flex align-items-center justify-content-center">
                <PigMoneyIcon />
                <span className="mx-3 text-black fw-semibold text-400-darkest-14">
                  Disponível hoje
                </span>
                <TooltipButton tooltipText="Soma do líquido de todas as transações do evento menos os saques aprovados." />
              </div>
              {loadingSaldos ? (
                <span
                  className="placeholder mt-2 mt-lg-0"
                  style={{ width: "65px", background: "#858796" }}
                ></span>
              ) : (
                <span className="text-black fw-semibold text-400-darkest-14 mt-2 mt-lg-0">
                  {FormatarCentavosParaReais(
                    saldos?.saldoDisponivelHojeCentavos || 0
                  )}
                </span>
              )}
            </div>

            <hr className="mt-4" />
            <div className="d-flex align-items-center justify-content-between mt-4 flex-wrap">
              <div className="d-flex align-items-center justify-content-center">
                <ClockMoneyIcon />
                <span className="mx-3 text-black fw-semibold text-400-darkest-14">
                  Á receber
                </span>
                <TooltipButton tooltipText="Somatória do valor dos saques aprovados e agendados para os próximos 7 dias." />
              </div>

              {loadingSaldos ? (
                <span
                  className="placeholder mt-2 mt-lg-0"
                  style={{ width: "65px", background: "#858796" }}
                ></span>
              ) : (
                <span className="text-black fw-semibold text-400-darkest-14 mt-2 mt-lg-0">
                  {FormatarCentavosParaReais(
                    saldos?.saldoAReceberCentavos || 0
                  )}
                </span>
              )}
            </div>
            <hr className="mt-4" />

            <div className="d-flex align-items-center justify-content-between mt-4 flex-wrap">
              <div className="d-flex align-items-center justify-content-center">
                <CalendarMoneyIcon />
                <span className="mx-3 text-black fw-semibold text-400-darkest-14">
                  Agendado
                </span>
                <TooltipButton tooltipText="Saques solicitados com status pendente de aprovação e agendados a partir de 7 dias." />
              </div>

              {loadingSaldos ? (
                <span
                  className="placeholder mt-2 mt-lg-0"
                  style={{ width: "65px", background: "#858796" }}
                ></span>
              ) : (
                <span className="text-black fw-semibold text-400-darkest-14 mt-2 mt-lg-0">
                  {FormatarCentavosParaReais(
                    saldos?.saldoAgendadoCentavos || 0
                  )}
                </span>
              )}
            </div>

            {/* Button */}
            <div className="d-flex align-items-center justify-content-center mt-4 mb-2">
              <Link
                to={`/eventos/${eventoId}/saque`}
                className="rounded-pill border-0 py-1 cursor-pointer text-decoration-none"
                style={{ width: "157px", background: "#E5EBFF" }}
              >
                <span
                  className="text-400-darkest-14 fw-semibold d-flex align-items-center justify-content-center"
                  style={{ color: "#303F9F" }}
                >
                  Solicitar saque
                </span>
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
