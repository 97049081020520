import { WhiteLabel } from "../../Utils";

export default function CortesiaIcon() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5757 1.33334C2.16606 1.33334 1.83398 1.66542 1.83398 2.07506V8.25603C1.83398 8.4529 1.91225 8.64168 2.05153 8.78081L7.36044 14.0835C7.54412 14.2674 7.76224 14.4133 8.00234 14.5128C8.24246 14.6123 8.49984 14.6636 8.75976 14.6636C9.01969 14.6636 9.27707 14.6123 9.51718 14.5128C9.7573 14.4133 9.97544 14.2674 10.1591 14.0835L14.5906 9.65202L14.0661 9.12755L14.5922 9.65046C14.9605 9.27987 15.1673 8.77857 15.1673 8.25603C15.1673 7.73349 14.9605 7.23219 14.5922 6.8616L9.28114 1.55059C9.14205 1.41149 8.95339 1.33334 8.75667 1.33334H2.5757ZM13.5406 8.60409L13.5401 8.60464L9.10961 13.0351C9.06368 13.0811 9.00915 13.1176 8.94912 13.1425C8.88909 13.1673 8.82475 13.1801 8.75976 13.1801C8.69478 13.1801 8.63043 13.1673 8.57041 13.1425C8.51038 13.1176 8.45584 13.0811 8.40992 13.0351L3.31742 7.94855V2.81678H8.44944L13.5401 7.90742L13.5406 7.90797C13.6324 8.00057 13.6839 8.12565 13.6839 8.25603C13.6839 8.38641 13.6324 8.51149 13.5406 8.60409ZM5.66619 3.96555C5.00345 3.96555 4.46619 4.50281 4.46619 5.16555C4.46619 5.82829 5.00345 6.36555 5.66619 6.36555H5.67286C6.3356 6.36555 6.87286 5.82829 6.87286 5.16555C6.87286 4.50281 6.3356 3.96555 5.67286 3.96555H5.66619Z"
        fill={WhiteLabel().colorPrimary}
      />
    </svg>
  );
}
