import { useEffect, useState } from "react";
import WhiteContainer from "../../../../Components/WhiteContainer";
import { DownloadTableIcon, OlhoTableIcon } from "../../../../Components/Icons";
import EmptyModal from "../../../../Components/Modals/EmptyModal";
import { useParams, useSearchParams } from "react-router-dom";
import AxiosClient from "../../../../Services/AxiosClient";
import { ListServiceResult, ServiceResult } from "../../../../Interfaces";
import EventoFinanceiroSaqueModel from "../../../../Interfaces/Models/EventoFinanceiroSaqueModel";
import toast from "react-hot-toast";
import AxiosErrorHandler from "../../../../Services/AxiosErrorHandler";
import { FormatarCentavosParaReais } from "../../../../Utils";
import moment from "moment";
import TransacaoSituacao from "../../../../Enums/TransacaoSituacao";
import SkeletonListagem from "../../../../Components/SkeletonListagem";
import DarkGrayCard from "../../../../Components/DarkGrayCard";
import Loading from "../../../../Components/Loading";
import { TablePagination } from "../../../../Components/table-pagination";
import { AiOutlineFilePdf } from "react-icons/ai";

export const HistoricoPagamentos = () => {
  const { eventoId } = useParams();

  const [numeroPaginas, setNumeroPaginas] = useState<number>(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const pagina = Number(searchParams.get("page")) || 1;
  const paginaTamanho = Number(searchParams.get("size")) || 10;

  const [modalDadosOpen, setModalDadosOpen] = useState<boolean>(false);

  const [acessoNegadoSaques, setAcessoNegadoSaques] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSaque, setLoadingSaque] = useState<boolean>(false);

  const [loadingDownload, setLoadingDownload] = useState<boolean>(false);

  const [saques, setSaques] = useState<EventoFinanceiroSaqueModel[]>([]);
  const [saqueInfo, setSaqueInfo] = useState<EventoFinanceiroSaqueModel | null>(
    null
  );

  const getRequestPathWIthQueryParams = (): string => {
    let url = `/eventos/${eventoId}/financeiro/saques`;
    url += `?pagina=${pagina}`;
    url += `&paginaTamanho=${paginaTamanho}`;

    return url;
  };

  const obterSaques = async (): Promise<void> => {
    setLoading(true);

    AxiosClient.get<ListServiceResult<EventoFinanceiroSaqueModel>>(
      getRequestPathWIthQueryParams()
    )
      .then(({ data }) => {
        setNumeroPaginas(data?.pages || 0);
        setSaques(data.data ?? []);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          setAcessoNegadoSaques(true);
          return;
        }

        toast.error(AxiosErrorHandler(error));
      })
      .finally(() => setLoading(false));
  };

  const obterInfoSaque = (saqueId: string) => {
    setLoadingSaque(true);

    AxiosClient.get<ServiceResult<EventoFinanceiroSaqueModel>>(
      `/eventos/${eventoId}/financeiro/saques/${saqueId}`
    )
      .then(({ data: { data } }) =>
        setSaqueInfo(data as EventoFinanceiroSaqueModel)
      )
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          setModalDadosOpen(false);
          return;
        }

        toast.error(AxiosErrorHandler(error));
      })
      .finally(() => setLoadingSaque(false));
  };

  const downloadComprovante = async (saqueId: string): Promise<void> => {
    setLoadingDownload(true);

    toast.promise(
      AxiosClient.get<ServiceResult<string>>(
        `/eventos/${eventoId}/financeiro/saques/${saqueId}/comprovante/download`
      )
        .then(({ data }) => {
          if (data.success && data.data) {
            const link = document.createElement("a");
            link.href = data.data; // Use a URL do servidor diretamente
            link.download = `comprovante-${moment().format("DD-MM-YYYY")}`;
            link.target = "_blank";
            link.click();
          } else {
            toast.error("Comprovante não disponível.");
          }
        })
        .finally(() => setLoadingDownload(false)),
      {
        loading: "Exportando comprovante...",
        success: "Comprovante exportado com sucesso!",
        error: "Erro ao exportar comprovante.",
      }
    );
  };

  const handleOpenModalDados = (saqueId: string) => {
    setModalDadosOpen(true);
    obterInfoSaque(saqueId);
  };

  useEffect(() => {
    obterSaques();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagina, paginaTamanho]);

  return (
    <div>
      <WhiteContainer
        rowMarginBottom={4}
        containerPadding={3}
        containerWidth={12}
      >
        <p className="text-400-black-24 fw-bold mb-4">
          Histórico de pagamentos
        </p>
        {loading ? (
          <SkeletonListagem />
        ) : acessoNegadoSaques ? (
          <div className="mb-3">
            <DarkGrayCard message="Não tem permissão para acessar o historico de pagamentos." />
          </div>
        ) : saques.length === 0 ? (
          <div className="mb-3">
            <DarkGrayCard message="Você ainda não tem pagamentos por aqui!" />
          </div>
        ) : (
          <div className="row p-lg-2">
            <div className="d-none d-lg-block col-lg-12 mb-3">
              <div className="row">
                <div className="col">
                  <p className="text-500-black-16 m-0">Solicitante</p>
                </div>
                <div className="col">
                  <p className="text-500-black-16 m-0">Favorecido</p>
                </div>
                <div className="col">
                  <p className="text-500-black-16 m-0">Valor</p>
                </div>
                <div className="col">
                  <p className="text-500-black-16 m-0">Data solicitação</p>
                </div>
                <div className="col">
                  <p className="text-500-black-16 m-0">Data programada</p>
                </div>
                <div className="col">
                  <p className="text-500-black-16 m-0">Status</p>
                </div>
                <div className="col">
                  <p className="text-500-black-16 m-0">Ações</p>
                </div>
              </div>
            </div>

            {saques.map((saque) => (
              <div className="col-lg-12" key={saque.id}>
                <div className="card-cinza-claro mb-3 p-2">
                  <div className="row">
                    <div className="align-self-center col">
                      <p className="d-block d-lg-none text-500-black-16 m-0 mt-3">
                        Solicitante
                      </p>
                      <p className="text-500-darkest-14 text-break m-0">
                        {saque.solicitante}
                      </p>
                    </div>

                    <div className="align-self-center col">
                      <p className="d-block d-lg-none text-500-black-16 m-0 mt-3">
                        Favorecido
                      </p>
                      <p className="text-500-darkest-14 text-break m-0">
                        {saque.favorecido}
                      </p>
                    </div>

                    <div className="align-self-center col">
                      <p className="d-block d-lg-none text-500-black-16 m-0 mt-3">
                        Valor
                      </p>
                      <p className="text-500-darkest-14 text-break m-0">
                        {FormatarCentavosParaReais(saque.valor)}
                      </p>
                    </div>

                    <div className="align-self-center col">
                      <p className="d-block d-lg-none text-500-black-16 m-0 mt-3">
                        Data solicitação
                      </p>
                      <p className="text-500-darkest-14 text-break m-0">
                        {moment(saque.solicitadoEm).format("DD/MM/YYYY")}
                      </p>
                    </div>

                    <div className="align-self-center col">
                      <p className="d-block d-lg-none text-500-black-16 m-0 mt-3">
                        Data programada
                      </p>
                      <p className="text-500-darkest-14 text-break m-0">
                        {moment(saque.agendadoPara).format("DD/MM/YYYY")}
                      </p>
                    </div>

                    <div className="align-self-center col">
                      <p className="d-block d-lg-none text-500-black-16 m-0 mt-3">
                        Status
                      </p>
                      <div
                        className="rounded"
                        style={{
                          padding: "2px 8px 2px 8px",
                          background:
                            saque.situacao === TransacaoSituacao.Aprovada
                              ? "#52CC6C"
                              : saque.situacao === TransacaoSituacao.Solicitada
                              ? "#FFD600"
                              : saque.situacao === TransacaoSituacao.Recusada
                              ? "#F41515"
                              : saque.situacao === TransacaoSituacao.Confirmada
                              ? "#00B0FF"
                              : saque.situacao === TransacaoSituacao.Agendada
                              ? "#FFA000"
                              : saque.situacao === TransacaoSituacao.Concluida
                              ? "#4CAF50"
                              : "#B5B5B5",
                        }}
                      >
                        <p className="text-500-darkest-14 text-break m-0 text-center text-black">
                          {saque.situacao}
                        </p>
                      </div>
                    </div>

                    <div className="align-self-center col">
                      <p className="d-block d-lg-none text-500-black-16 m-0 mt-3">
                        Ações
                      </p>
                      <div className="d-flex align-items-center">
                        <span
                          className="mx-2 ms-0 cursor-pointer"
                          onClick={() => {
                            handleOpenModalDados(saque.id);
                          }}
                        >
                          <OlhoTableIcon />
                        </span>
                        {saque.comprovanteUrl && (
                          <>
                            <button
                              className="mx-2 cursor-pointer border-0 bg-transparent"
                              onClick={() => downloadComprovante(saque.id)}
                            >
                              <DownloadTableIcon />
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </WhiteContainer>

      <TablePagination numeroPaginas={numeroPaginas} />

      {/* Modal de anexos */}
      {/* <EmptyModal
        isOpen={modalAnexosOpen}
        close={() => setModalAnexosOpen(false)}
      >
        <p
          className="title-h2 text-black m-0 text-center"
          style={{ fontSize: "32px" }}
        >
          Incluir comprovante
        </p>

        {!file && (
          <div
            className={`mt-5 mx-auto w-100 d-flex align-items-center justify-content-center py-5 ${
              file ? "" : "cursor-pointer"
            }`}
            style={{
              maxWidth: "450px",
              border: "2px dashed #444444",
            }}
            onClick={handleUploadClick}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <input
              ref={inputFile}
              className="d-none"
              type="file"
              onChange={handleFileSelect}
            />
            <div className="d-flex flex-column align-items-center justify-content-center">
              <DownloadGrayIcon />
              <p className="text-500-dark-18 text-black mt-4 text-center">
                <span style={{ color: "#F82853" }}>Selecione</span> ou arraste e
                solte o comprovante aqui
              </p>
            </div>
          </div>
        )}

        {file && (
          <div className="d-flex align-items-center justify-content-between mt-5 border border-2 rounded-4 p-3 w-100">
            <div></div>
            <p className="text-500-darkest-18 m-0">{file.name}</p>
            <IoIosClose
              className="cursor-pointer d-flex align-items-center justify-content-center mt-1"
              size={30}
              onClick={handleRemoveFile}
            />
          </div>
        )}

        <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-sm-evenly align-items-center mb-3 mt-5">
          <button
            className="bc-btn bc-btn-light p-3 h-100 w-100 mb-3 mb-sm-0 rounded ms-2"
            style={{ width: 175 }}
            onClick={() => {
              setModalAnexosOpen(false);
              handleRemoveFile();
            }}
          >
            Agora não
          </button>

          <button
            className="bc-btn bc-btn-primary p-3 h-100 w-100 rounded mx-2"
            style={{ width: 175 }}
          >
            Salvar
          </button>
        </div>
      </EmptyModal> */}

      {/* Modal de dados */}
      <EmptyModal
        isOpen={modalDadosOpen}
        close={() => {
          setModalDadosOpen(false);
        }}
      >
        <p
          className="title-h2 text-black m-0 text-center"
          style={{ fontSize: "32px" }}
        >
          Dados do saque
        </p>

        {loadingSaque && (
          <div className="d-flex align-items-center justify-content-center mt-4">
            <Loading />
          </div>
        )}

        {!loadingSaque && (
          <>
            <div className="w-100">
              <div className="row g-3 mt-2">
                <div className="col-6">
                  <span className="text-500-dark-14 fw-normal mt-4">
                    Forma de transferência
                  </span>
                  <p
                    className="fw-semibold text-black"
                    style={{ fontSize: "20px" }}
                  >
                    Conta bancária (TED)
                  </p>
                </div>
                <div className="col-6">
                  <span className="text-500-dark-14 fw-normal mt-4">
                    Solicitante
                  </span>
                  <p
                    className="fw-semibold text-black"
                    style={{ fontSize: "20px" }}
                  >
                    {saqueInfo?.solicitante}
                  </p>
                </div>
              </div>

              <div className="row g-3">
                <div className="col-12">
                  <span className="text-500-dark-14 fw-normal mt-4">
                    Favorecido
                  </span>
                  <p
                    className="fw-semibold text-black"
                    style={{ fontSize: "20px" }}
                  >
                    {saqueInfo?.favorecido}
                  </p>
                </div>
              </div>

              <div className="row g-3">
                <div className="col-6">
                  <span className="text-500-dark-14 fw-normal mt-4">
                    Data solicitada
                  </span>
                  <p
                    className="fw-semibold text-black"
                    style={{ fontSize: "20px" }}
                  >
                    {moment(saqueInfo?.solicitadoEm).format("DD/MM/YYYY")}
                  </p>
                </div>
                <div className="col-6">
                  <span className="text-500-dark-14 fw-normal mt-4">
                    Data agendada
                  </span>
                  <p
                    className="fw-semibold text-black"
                    style={{ fontSize: "20px" }}
                  >
                    {moment(saqueInfo?.agendadoPara).format("DD/MM/YYYY")}
                  </p>
                </div>
              </div>

              <div className="row g-3 mb-3">
                <div className="col-6">
                  <span className="text-500-dark-14 fw-normal mt-4">
                    CPF / CNPJ
                  </span>
                  <p
                    className="fw-semibold text-black"
                    style={{ fontSize: "20px" }}
                  >
                    {saqueInfo?.cpfCnpj}
                  </p>
                </div>
                <div className="col-6">
                  <span className="text-500-dark-14 fw-normal mt-4">Valor</span>
                  <p
                    className="fw-semibold text-black text-break"
                    style={{ fontSize: "20px" }}
                  >
                    {FormatarCentavosParaReais(saqueInfo?.valor || 0)}
                  </p>
                </div>
              </div>

              {saqueInfo?.situacao === TransacaoSituacao.Recusada && (
                <div className="row g-3 mb-3">
                  <div className="col-12">
                    <span className="text-500-dark-14 fw-normal mt-4">
                      Motivo da recusa
                    </span>
                    <p
                      className="fw-semibold text-black text-break"
                      style={{ fontSize: "20px" }}
                    >
                      {saqueInfo?.motivoRecusa || "Motivo não especificado"}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </EmptyModal>
    </div>
  );
};
