export default function LogoutIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6081 7.35539C15.9986 6.96487 16.6318 6.96487 17.0223 7.35539L20.9598 11.2929C21.3503 11.6834 21.3503 12.3166 20.9598 12.7071L17.0223 16.6446C16.6318 17.0351 15.9986 17.0351 15.6081 16.6446C15.2176 16.2541 15.2176 15.6209 15.6081 15.2304L18.8385 12L15.6081 8.76961C15.2176 8.37908 15.2176 7.74592 15.6081 7.35539Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75 12C8.75 11.4477 9.19772 11 9.75 11H20.25C20.8023 11 21.25 11.4477 21.25 12C21.25 12.5523 20.8023 13 20.25 13H9.75C9.19772 13 8.75 12.5523 8.75 12Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.75 4.75H11.25C11.8023 4.75 12.25 4.30228 12.25 3.75C12.25 3.19772 11.8023 2.75 11.25 2.75H4.5C4.03587 2.75 3.59075 2.93438 3.26256 3.26256C2.93438 3.59075 2.75 4.03587 2.75 4.5V19.5C2.75 19.9641 2.93437 20.4092 3.26256 20.7374C3.59075 21.0656 4.03587 21.25 4.5 21.25H11.25C11.8023 21.25 12.25 20.8023 12.25 20.25C12.25 19.6977 11.8023 19.25 11.25 19.25H4.75V4.75Z"
        fill="white"
      />
    </svg>
  );
}
