enum TipoPagamento {
  CartaoCredito = "CartaoCredito",
  Pix = "Pix",
  ApplePay = "ApplePay",
  Cortesia = "Cortesia",
  CartaoDebito = "CartaoDebito",
  Dinheiro = "Dinheiro",
}

export default TipoPagamento;
