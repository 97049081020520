import { useEffect, useState } from "react";
import { FormatarCentavosParaReais, WhiteLabel } from "../../../../Utils";
import {
  EstatisticasEventoModel,
  FuncionalidadesModel,
} from "../../../../Interfaces/Models";
import { useNavigate, useParams } from "react-router-dom";
import AxiosClient from "../../../../Services/AxiosClient";
import { ServiceResult } from "../../../../Interfaces";
import toast from "react-hot-toast";
import AxiosErrorHandler from "../../../../Services/AxiosErrorHandler";
import moment from "moment";
import { CategoryScale, Chart as ChartJS } from "chart.js/auto";
import WhiteContainer from "../../../../Components/WhiteContainer";
import DarkGrayCard from "../../../../Components/DarkGrayCard";
import Loading from "../../../../Components/Loading";
import { EstatisticasPontosVenda } from "./PontosVenda";
import { EstatisticasIngressoVenda } from "./IngressosVenda";
import { FaFileExport, FaShareAlt } from "react-icons/fa";
import EmptyModal from "../../../../Components/Modals/EmptyModal";
import { IoCopy } from "react-icons/io5";
import Button from "../../../../Components/Button";

enum Granularidade {
  Dia = "Dia",
  Semana = "Semana",
  Todos = "Todos",
}

export const EstatisticasPrincipal = () => {
  const { eventoId } = useParams();
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState<boolean>(false);

  const [frontstageUrl, setFrontstageUrl] = useState<string | null>(
    WhiteLabel().frontstageUrl
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [exportingCsv, setExportingCsv] = useState<boolean>(false);
  const [estatisticas, setEstatisticas] =
    useState<EstatisticasEventoModel | null>(null);
  const [granularidade, setGranularidade] = useState<Granularidade>(
    Granularidade.Todos
  );

  const [grafico, setGrafico] = useState<any>();

  const [acessoNegadoEstatisticas, setAcessoNegadoEstatisticas] =
    useState<boolean>(false);

  const obterEstatisticas = async (): Promise<void> => {
    setLoading(true);

    AxiosClient.get<ServiceResult<EstatisticasEventoModel>>(
      `/eventos/${eventoId}/estatisticas`
    )
      .then(({ data: { data } }) => {
        const estatisticas = data as EstatisticasEventoModel;
        setEstatisticas(estatisticas);
        alterarGraficoGranularidade(estatisticas);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          setAcessoNegadoEstatisticas(true);
          return;
        }

        toast.error(AxiosErrorHandler(error));
      })
      .finally(() => setLoading(false));
  };

  const obterUrls = async (): Promise<void> => {
    AxiosClient.get<ServiceResult<FuncionalidadesModel>>(
      "/conta/funcionalidades"
    )
      .then(({ data: { data } }) => {
        setFrontstageUrl(data?.frontstageUrl ?? WhiteLabel().frontstageUrl);
        // setBackstageUrl(data?.backstageUrl ?? WhiteLabel().backstageUrl);
      })
      .catch((error) => {
        navigate("/eventos");
        toast.error(AxiosErrorHandler(error));
      });
  };

  const exportarCsv = async (): Promise<void> => {
    setExportingCsv(true);

    toast.promise(
      AxiosClient.get(`/eventos/${eventoId}/estatisticas/csv`, {
        responseType: "blob",
      })
        .then(({ data }) => {
          const blob = new Blob([data], {
            type: "text/csv",
          });

          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `estatisticas-${moment().format("DD-MM-YYYY")}.csv`;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .finally(() => setExportingCsv(false)),
      {
        loading: "Exportando CSV...",
        success: "CSV exportado com sucesso!",
        error: (error) => AxiosErrorHandler(error),
      }
    );
  };

  const alterarGraficoGranularidade = (
    estatisticas: EstatisticasEventoModel
  ) => {
    const { grafico } = estatisticas;
    let labels = Object.keys(grafico || {});
    const dateNow = new Date();

    switch (granularidade) {
      case Granularidade.Dia:
        labels = labels.filter((label) => {
          const data = new Date(label);
          return (
            data.getUTCDate() === dateNow.getDate() &&
            data.getUTCMonth() === dateNow.getMonth() &&
            data.getUTCFullYear() === dateNow.getFullYear()
          );
        });
        break;

      case Granularidade.Semana:
        labels = labels.filter((label) => {
          const data = new Date(label);
          return (
            data.getUTCDate() >= dateNow.getDate() - 7 &&
            data.getUTCDate() <= dateNow.getDate() &&
            data.getUTCMonth() === dateNow.getMonth() &&
            data.getUTCFullYear() === dateNow.getFullYear()
          );
        });
        break;

      default:
        break;
    }

    if (labels.length === 0) {
      setGrafico({
        labels: [],
        datasets: [
          {
            label: "Ingressos vendidos",
            data: [],
            borderColor: "#2b1479",
            backgroundColor: "#2b1479",
            CategoryScale,
          },
          {
            label: "Receita (R$)",
            data: [],
            borderColor: WhiteLabel().colorPrimary,
            backgroundColor: WhiteLabel().colorPrimary,
            CategoryScale,
          },
        ],
      });

      return;
    }

    setGrafico({
      labels: labels,
      datasets: [
        {
          label: "Ingressos vendidos",
          data: labels.map((data) => grafico[data].ingressos),
          borderColor: "#2b1479",
          backgroundColor: "#2b1479",
          CategoryScale,
        },
        {
          label: "Receita (R$)",
          data: labels.map((data) => grafico[data].receitaCentavos / 100),
          borderColor: WhiteLabel().colorPrimary,
          backgroundColor: WhiteLabel().colorPrimary,
          CategoryScale,
        },
      ],
    });
  };

  const copiarLink = (field: "evento" | "embedded") => {
    let textToCopy = "";

    switch (field) {
      case "evento":
        textToCopy = `${frontstageUrl}/${field}/${eventoId}`;
        break;

      case "embedded":
        textToCopy = `<iframe
            src="${frontstageUrl}/${field}/${eventoId}"
            title="Checkout Tickets App"
          ></iframe>
        `;
    }

    navigator?.clipboard?.writeText(textToCopy);

    toast.success(`Link do ${field} copiado para a área de transferência!`);
  };

  useEffect(() => {
    alterarGraficoGranularidade(
      estatisticas || ({} as EstatisticasEventoModel)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [granularidade]);

  useEffect(() => {
    obterEstatisticas();
    obterUrls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <WhiteContainer containerPadding={4} containerWidth={12}>
        <div className="d-flex flex-column flex-xl-row justify-content-xl-between align-items-center">
          <p className="title-h1 text-black text-center mb-2">Estatísticas</p>

          <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
            {/* <button
              type="button"
              onClick={copiarLink}
              className="bc-btn bc-btn-primary px-3 py-2 mb-2 mb-md-0 me-md-3"
            >
              Copiar link do evento
            </button> */}

            <button
              type="button"
              onClick={() => setOpenModal(true)}
              className={`p-3 w-00 h-00 border-0 bg-transparent me-2`}
            >
              <FaShareAlt className="fs-4" />
            </button>

            <button
              type="button"
              onClick={exportarCsv}
              className={`p-3 w-00 h-00 border-0 bg-transparent`}
              disabled={(!loading && !estatisticas) || loading || exportingCsv}
            >
              <FaFileExport className="fs-4" />
            </button>
          </div>
        </div>
      </WhiteContainer>

      {loading ? (
        <Loading container="50vh" />
      ) : acessoNegadoEstatisticas ? (
        <WhiteContainer
          rowMarginBottom={4}
          containerPadding={4}
          containerWidth={12}
        >
          <DarkGrayCard message="Você não tem permissão para acessar as estatísticas." />
        </WhiteContainer>
      ) : !loading && !estatisticas ? (
        <WhiteContainer
          rowMarginBottom={4}
          containerPadding={4}
          containerWidth={12}
        >
          <DarkGrayCard message="Você ainda não tem estatísticas por aqui!" />
        </WhiteContainer>
      ) : (
        estatisticas && (
          <>
            <EstatisticasIngressoVenda
              estatisticas={estatisticas}
              granularidade={granularidade}
              setGranularidade={setGranularidade}
              grafico={grafico}
              alterarGraficoGranularidade={alterarGraficoGranularidade}
            />

            <EstatisticasPontosVenda
              estatisticas={estatisticas}
              loading={loading}
            />
          </>
        )
      )}

      <EmptyModal
        isOpen={openModal}
        close={() => setOpenModal(false)}
        title="Compartilhar"
      >
        <div className="d-flex flex-column">
          <div className="d-flex flex-column align-items-center">
            <p className="text-500-darkest-16 mb-1">
              Copie esse link para compartilhar esse evento com seus amigos
            </p>

            <input
              type="text"
              value={`${frontstageUrl}/evento/${eventoId}`}
              disabled
              style={{ height: 40 }}
              className="form-control"
            />

            <Button
              text="Copiar link"
              icon={<IoCopy className="w-5 h-5 copy-icon text-white" />}
              onClick={() => copiarLink("evento")}
              className="mt-2"
            />
          </div>

          <div className="d-flex flex-column align-items-center mt-3">
            <p className="text-500-darkest-16 mb-1">
              Copie o HTML para incorporar o checkout do Tickets App no seu site
            </p>

            <input
              type="text"
              value={`<iframe src="${frontstageUrl}/embedded/${eventoId}" title="Checkout Tickets App"></iframe>`}
              disabled
              style={{ height: 40 }}
              className="form-control"
            />

            <Button
              text="Copiar HTML"
              icon={<IoCopy className="w-5 h-5 copy-icon text-white" />}
              onClick={() => copiarLink("embedded")}
              className="mt-2"
            />
          </div>
        </div>
      </EmptyModal>
    </>
  );
};
