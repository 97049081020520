import Button from "../../../../Components/Button";
import { ChevronDownIcon, ChevronUpIcon } from "../../../../Components/Icons";
import PagamentoStatus from "../../../../Enums/PagamentoStatus";
import TipoPagamento from "../../../../Enums/TipoPagamento";
import { ListagemPedidosModel } from "../../../../Interfaces/Models";

interface Props {
  pedido: ListagemPedidosModel;
  exibindoPedido: string | null;
  toggleExibicao: (pedidoId: string) => void;
  abrirModalCancelarPedido: (g: ListagemPedidosModel) => void;
}

export default function BotoesListagemPedidos({
  pedido,
  exibindoPedido,
  toggleExibicao,
  abrirModalCancelarPedido,
}: Props) {
  return (
    <div className="d-flex flex-wrap justify-content-center justify-content-md-evenly align-items-center">
      {pedido.statusPedido === PagamentoStatus.Pago && (
          <Button
            className="m-2 py-1 px-3 rounded-pill w-100 h-100"
            variant="primary"
            text="cancelar pedido"
            height={24}
            width={200}
            onClick={() => abrirModalCancelarPedido(pedido)}
          />
        )}

      <Button
        className="m-2 px-3 rounded-pill w-100 h-100"
        variant={pedido.id === exibindoPedido ? "primary" : "light"}
        text={pedido.id === exibindoPedido ? "fechar" : "ver mais"}
        height={24}
        width={200}
        icon={
          pedido.id === exibindoPedido ? <ChevronUpIcon /> : <ChevronDownIcon />
        }
        onClick={() => toggleExibicao(pedido.id)}
      />
    </div>
  );
}
