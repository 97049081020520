import { ReactNode, useState } from "react";
import Header from "../Header";
import NavMenuInicial from "../NavMenuInicial";
import { useMatch, useNavigate, useResolvedPath } from "react-router-dom";
import NavMenuEvento from "../NavMenuEventos";
import toast from "react-hot-toast";
import { useUserStore } from "../../Contexts/UserStore";

interface LayoutProps {
  children: ReactNode;
}

export default function MainLayout({ children }: LayoutProps) {
  const navigate = useNavigate();
  const { setNome } = useUserStore();

  const [navMenuCssClass, setNavMenuCssClass] = useState<"d-flex" | "d-none">(
    "d-flex",
  );

  const resolved = useResolvedPath("/eventos/:id");
  const match = useMatch({ path: resolved.pathname, end: false });

  const toggleNavMenu = () => {
    if (navMenuCssClass === "d-flex") setNavMenuCssClass("d-none");
    else setNavMenuCssClass("d-flex");
  };

  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    setNome("");
    navigate("/login");
    toast("Desconectado.");
  };

  return (
    <div id="page-top">
      <div id="wrapper">
        {match ? (
          <NavMenuEvento
            responsiveCss={navMenuCssClass}
            logout={handleLogout}
          />
        ) : (
          <NavMenuInicial
            responsiveCss={navMenuCssClass}
            logout={handleLogout}
          />
        )}
        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            <Header
              toggleNavMenu={toggleNavMenu}
              responsiveCss={navMenuCssClass}
            />

            <div className="container-fluid">{children}</div>
          </div>
        </div>
        <a
          className="border rounded d-inline scroll-to-top"
          href="#page-top"
          style={{ zIndex: 999 }}
        >
          <i className="fas fa-angle-up" />
        </a>
      </div>
    </div>
  );
}
