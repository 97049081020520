import { create } from "zustand";

type SaldoStore = {
  isRefreshing: boolean;
  setRefreshing: (refreshing: boolean) => void;
  saldoRefreshing: number;
  updateSaldo: () => void;
};

export const useSaldoStore = create<SaldoStore>((set) => ({
  isRefreshing: false,
  setRefreshing: (refreshing: boolean) => set({ isRefreshing: refreshing }),
  saldoRefreshing: 0,
  updateSaldo: () =>
    set((state) => ({ saldoRefreshing: state.saldoRefreshing + 1 })),
}));
