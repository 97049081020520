import clsx from "clsx";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import BreadCrumb, { Page } from "../../../../Components/Breadcrumb";
import Button from "../../../../Components/Button";
import {
  CloseIcon,
  MinusIcon,
  PlusIcon,
  SearchIcon,
} from "../../../../Components/Icons";
import Input from "../../../../Components/Inputs/Input";
import MainLayout from "../../../../Components/Layouts/Main";
import Loading from "../../../../Components/Loading";
import WhiteContainer from "../../../../Components/WhiteContainer";
import MetodoPagamento from "../../../../Enums/MetodoPagamento";
import TipoPagamento from "../../../../Enums/TipoPagamento";
import { ListServiceResult, ServiceResult } from "../../../../Interfaces";
import {
  GrupoModel,
  IngressoSelecionado,
  PedidoDetalheModel,
  UsuarioModel,
} from "../../../../Interfaces/Models";
import AxiosClient from "../../../../Services/AxiosClient";
import AxiosErrorHandler from "../../../../Services/AxiosErrorHandler";
import {
  ConverterStringMetodoPagamento,
  FormatarCentavosParaReais,
  FormatarDataIngressoEvento,
} from "../../../../Utils";
import { useEventoStore } from "../../../../Contexts/EventoStore";
import useObterNomeEventoHeader from "../../../../Hooks/useObterNomeEventoHeader";
import DarkGrayCard from "../../../../Components/DarkGrayCard";

let ultimaAlteracaoPedidoEm: number = 0; // não pode ser useState porque é para tracking interno de delay
const delayAtualizarPedido = 1000; // 1 segundo

interface BilheteriaVendaArgs {
  eventoId: string;
  ingressos: Record<string, number>; // IngressoId, Quantidade
  clienteId: string;
  metodoPagamento: TipoPagamento;
}

interface ClienteSelecionado {
  id: string;
  nome: string;
  cpf: string;
  telefone: string;
  email: string;
}

interface FormData {
  search: string;
}

export default function BilheteriaVendas() {
  const { eventoId } = useParams();
  const navigate = useNavigate();

  const { tituloEvento } = useEventoStore();
  const { obterNomeEvento } = useObterNomeEventoHeader();

  const breadCrumbHistory: Page[] = [
    {
      link: "/",
      name: "dashboard",
    },
    {
      link: "/eventos",
      name: "Eventos",
    },
    {
      link: `/eventos/${eventoId}/bilheteria/vendas`,
      name: "Bilheteria - Vendas",
    },
  ];

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormData>({
    values: {
      search: "",
    },
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [metodoPagamento, setMetodoPagamento] = useState<MetodoPagamento>(
    MetodoPagamento.Credito
  );
  const [ingressosSelecionados, setIngressosSelecionados] = useState<
    IngressoSelecionado[]
  >([] as IngressoSelecionado[]);

  const [grupos, setGrupos] = useState<GrupoModel[]>([]);

  const [clienteSelecionado, setClienteSelecionado] =
    useState<ClienteSelecionado | null>(null);
  const [resultadosBusca, setResultadosBusca] = useState<UsuarioModel[]>([]);

  const [valorIngressos, setValorIngressos] = useState<number>(0);
  const [valorTotalItemCentavos, setValorTotalItemCentavos] =
    useState<number>(0);
  const [taxaAdminPos, setTaxaAdminPos] = useState<number>(10.0);

  const [acessoNegadoGrupos, setAcessoNegadoGrupos] = useState<boolean>(false);
  const [acessoNegadoTaxas, setAcessoNegadoTaxas] = useState<boolean>(false);
  const [acessoNegadoUsuarios, setAcessoNegadoUsuarios] =
    useState<boolean>(false);
  const [acessoNegadoValor, setAcessoNegadoValor] = useState<boolean>(false);

  const getGrupos = async (): Promise<void> => {
    setLoading(true);

    AxiosClient.get<ListServiceResult<GrupoModel>>(
      `/eventos/${eventoId}/bilheteria/vendas`
    )
      .then(({ data }) => {
        setGrupos(data?.data ?? []);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          setAcessoNegadoGrupos(true);
          return;
        }
        toast.error(AxiosErrorHandler(error));
      })
      .finally(() => setLoading(false));
  };

  const getTaxas = async (): Promise<void> => {
    setLoading(true);

    AxiosClient.get<ServiceResult<{ taxaAdministracaoPOS: number }>>(
      `/eventos/${eventoId}/taxas-ingressos`
    )
      .then(({ data }) => {
        setTaxaAdminPos(data.data?.taxaAdministracaoPOS ?? 10.0);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          setAcessoNegadoTaxas(true);
          return;
        }
        toast.error(AxiosErrorHandler(error));
      })
      .finally(() => setLoading(false));
  };

  async function handleSearchClient(data: FormData): Promise<void> {
    setSearchLoading(true);

    AxiosClient.get<ServiceResult<UsuarioModel[]>>(
      `/eventos/usuarios?searchString=${data.search}`
    )
      .then(({ data }) => {
        setResultadosBusca(data.data ?? []);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          setAcessoNegadoUsuarios(true);
          return;
        }
        toast.error(AxiosErrorHandler(error));
      })
      .finally(() => setSearchLoading(false));
  }

  function addIngresso(
    ingressoId: string,
    nome: string,
    valorCentavos: number
  ) {
    ultimaAlteracaoPedidoEm = Date.now();
    const ingressoSelecionado = ingressosSelecionados.find(
      (i) => i.ingressoId === ingressoId
    );

    if (ingressoSelecionado) {
      ingressoSelecionado.quantidade++;
    } else {
      ingressosSelecionados.push({
        ingressoId,
        nome,
        quantidade: 1,
        valorCentavos,
      } as IngressoSelecionado);
    }

    setIngressosSelecionados([...ingressosSelecionados]);
    setValorIngressos(0);
    setValorTotalItemCentavos(0);
    setTimeout(() => getValorSimulado(), 1000);
  }

  function removeIngresso(ingressoId: string) {
    ultimaAlteracaoPedidoEm = Date.now();
    const ingressoSelecionado = ingressosSelecionados.find(
      (i) => i.ingressoId === ingressoId
    );

    if (ingressoSelecionado) {
      ingressoSelecionado.quantidade--;

      if (ingressoSelecionado.quantidade === 0) {
        const index = ingressosSelecionados.findIndex(
          (i) => i.ingressoId === ingressoId
        );

        ingressosSelecionados.splice(index, 1);
      }
    }

    setIngressosSelecionados([...ingressosSelecionados]);
    setValorIngressos(0);
    setValorTotalItemCentavos(0);
    setTimeout(() => getValorSimulado(), 1000);
  }

  function getValorSimulado() {
    if (ultimaAlteracaoPedidoEm <= Date.now() - delayAtualizarPedido) {
      ultimaAlteracaoPedidoEm = 0;
      if (ingressosSelecionados.length < 1) {
        setValorIngressos(0);
        setValorTotalItemCentavos(0);
        return;
      }

      AxiosClient.post<
        ServiceResult<{
          itens: {
            ingressoId: string;
            quantidade: number;
            valorIngressoBaseCentavos: number;
            taxaProcessamentoPercentual: number;
            taxaAdministracaoPercentual: number;
            valorIngressoMaisTaxasCentavos: number;
            valorTotalItemCentavos: number;
          }[];
          totalPedidoCentavos: number;
        }>
      >(`/eventos/${eventoId}/simulacao-pedido`, {
        ingressos: ingressosSelecionados.reduce((acc, ingresso) => {
          acc[ingresso.ingressoId] = ingresso.quantidade;
          return acc;
        }, {} as Record<string, number>),
        canalVenda: "POS",
      })
        .then(({ data }) => {
          setValorTotalItemCentavos(data.data?.totalPedidoCentavos ?? 0);
          setValorIngressos(
            data.data?.itens.reduce(
              (acc, i) => acc + i.valorIngressoBaseCentavos * i.quantidade,
              0
            ) ?? 0
          );
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            setAcessoNegadoValor(true);
            return;
          }
          toast.error(AxiosErrorHandler(error));
        });
    }
  }

  const enviarIngresso = async (): Promise<void> => {
    if (!clienteSelecionado) {
      toast.error("Selecione um cliente para continuar");
      return;
    }

    let args: BilheteriaVendaArgs = {
      eventoId: eventoId!,
      ingressos: ingressosSelecionados.reduce((acc, ingresso) => {
        acc[ingresso.ingressoId] = ingresso.quantidade;
        return acc;
      }, {} as Record<string, number>),
      clienteId: clienteSelecionado.id,
      metodoPagamento:
        converterMetodoPagamentoParaTipoPagamento(metodoPagamento),
    };

    setLoading(true);

    AxiosClient.post<ServiceResult<PedidoDetalheModel>>(
      `/eventos/${eventoId}/bilheteria/vendas`,
      args
    )
      .then(({ data }) => {
        toast.success("Ingresso(s) vendido(s) com sucesso!");
        resetPage();
      })
      .catch((err) => toast.error(AxiosErrorHandler(err)))
      .finally(() => setLoading(false));
  };

  const converterMetodoPagamentoParaTipoPagamento = (
    metodoPagamento: MetodoPagamento
  ): TipoPagamento => {
    switch (metodoPagamento) {
      case MetodoPagamento.PIX:
        return TipoPagamento.Pix;
      case MetodoPagamento.Credito:
        return TipoPagamento.CartaoCredito;
      case MetodoPagamento.Dinheiro:
        return TipoPagamento.Dinheiro;

      default:
        return TipoPagamento.CartaoCredito;
    }
  };

  const resetPage = () => {
    setClienteSelecionado(null);
    setIngressosSelecionados([]);
    setMetodoPagamento(MetodoPagamento.Credito);
    setValue("search", "");
    setResultadosBusca([]);
    setValorIngressos(0);
    setValorTotalItemCentavos(0);
  };

  useEffect(() => {
    getGrupos();
    getTaxas();
    obterNomeEvento();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout>
      <div className="mb-3">
        <BreadCrumb history={breadCrumbHistory} />
      </div>

      <WhiteContainer containerPadding={4} containerWidth={12}>
        <div className="d-flex flex-column flex-xl-row justify-content-xl-between align-items-center">
          <p className="title-h1 text-black text-center mb-2">Bilheteria</p>

          <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
            <button
              type="button"
              className={`bc-btn bc-btn-light px-3 py-2 w-00 h-00`}
              onClick={() =>
                navigate(`/eventos/${eventoId}/bilheteria/clientes?menu=open`)
              }
            >
              Ver lista de clientes
            </button>
          </div>
        </div>
      </WhiteContainer>

      <div className="row mb-4">
        <div className="col-xxl-8">
          <WhiteContainer
            containerPadding={4}
            containerWidth={12}
            rowMarginBottom={4}
          >
            <p className="title-h2 text-black mb-3">Busque pelo cliente</p>
            <p className="text-500-dark-14 mb-1 d-none d-lg-flex justify-content-end">
              Pressione enter ou clique na lupa para buscar
            </p>

            <form onSubmit={handleSubmit(handleSearchClient)}>
              <div className="d-flex justify-content-center align-items-center">
                <Input
                  control={control}
                  errors={errors}
                  name="search"
                  validation={{
                    required:
                      "Insira um nome, CPF ou e-mail para buscar o cliente",
                  }}
                  type="search"
                  placeholder="Busque por nome, CPF ou e-mail"
                  style={{
                    height: 40,
                    borderStartEndRadius: 0,
                    borderEndEndRadius: 0,
                  }}
                  disabled={!!clienteSelecionado || searchLoading}
                />
                {!clienteSelecionado && (
                  <button
                    className={clsx(
                      "p-2 backstage-input",
                      errors.search &&
                        "border-end border-top border-bottom border-danger"
                    )}
                    type="submit"
                    style={{
                      width: 40,
                      height: 40,
                      borderStartStartRadius: 0,
                      borderEndStartRadius: 0,
                    }}
                  >
                    <SearchIcon />
                  </button>
                )}

                {clienteSelecionado && (
                  <button
                    className="p-2 backstage-input d-flex justify-content-center align-items-center"
                    type="button"
                    onClick={() => {
                      setClienteSelecionado(null);
                      setValue("search", "");
                    }}
                    style={{
                      width: 40,
                      height: 40,
                      borderStartStartRadius: 0,
                      borderEndStartRadius: 0,
                    }}
                  >
                    <CloseIcon />
                  </button>
                )}
              </div>
              {errors.search && (
                <p className="text-danger">{errors.search.message}</p>
              )}
            </form>

            {searchLoading && (
              <div className="my-5">
                <Loading container="0" />
              </div>
            )}

            {clienteSelecionado && (
              <div className="row mt-4">
                <div className="col-12 col-lg-4">
                  <p className="text-black text-500-dark-14 mb-2">CPF</p>
                  <input
                    disabled
                    type="text"
                    className="p-2 form-control backstage-input"
                    value={
                      clienteSelecionado.cpf.slice(0, 3) +
                      "." +
                      clienteSelecionado.cpf.slice(3, 6) +
                      "." +
                      clienteSelecionado.cpf.slice(6, 9) +
                      "-" +
                      clienteSelecionado.cpf.slice(9, 11)
                    }
                  />
                </div>

                <div className="col-12 col-lg-4">
                  <p className="text-black text-500-dark-14 mb-2">Telefone</p>
                  <input
                    disabled
                    type="text"
                    className="p-2 form-control backstage-input"
                    value={
                      "(" +
                      clienteSelecionado.telefone.slice(0, 2) +
                      ") " +
                      clienteSelecionado.telefone.slice(2, 7) +
                      "-" +
                      clienteSelecionado.telefone.slice(7, 11)
                    }
                  />
                </div>

                <div className="col-12 col-lg-4">
                  <p className="text-black text-500-dark-14 mb-2">E-mail</p>
                  <input
                    disabled
                    type="text"
                    className="p-2 form-control backstage-input"
                    value={clienteSelecionado.email}
                  />
                </div>
              </div>
            )}

            {acessoNegadoUsuarios ? (
              <div className="mb-3">
                <DarkGrayCard message="Você não tem acesso a listagem de usuários." />
              </div>
            ) : (
              resultadosBusca.length > 0 &&
              !clienteSelecionado && (
                <div className="mt-4">
                  <p className="text-black text-500-dark-14 mb-2">
                    Resultados da busca
                  </p>

                  <div
                    className="d-flex flex-column"
                    style={{ maxHeight: 175, overflowY: "auto" }}
                  >
                    {resultadosBusca.map((u) => (
                      <div
                        key={u.id}
                        className="d-flex justify-content-between align-items-center border-bottom py-2"
                      >
                        <p className="text-500-dark-14 m-0">{u.nomeCompleto}</p>
                        <Button
                          type="button"
                          text="Selecionar"
                          variant="light"
                          className="me-3"
                          onClick={() => {
                            setClienteSelecionado({
                              id: u.id,
                              nome: u.nomeCompleto,
                              cpf: u.cpf,
                              email: u.email,
                              telefone: u.telefone,
                            });

                            setValue("search", u.nomeCompleto);

                            setResultadosBusca([]);
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )
            )}
          </WhiteContainer>

          {loading ? (
            <Loading container="30vh" />
          ) : acessoNegadoGrupos ? (
            <div className="mb-3">
              <DarkGrayCard message="Você não tem acesso à bilheteria de vendas." />
            </div>
          ) : (
            grupos.map((grupo) => (
              <div key={grupo.id} className="row mb-4 justify-content-center">
                {/* Grupo */}
                <div className="col-lg-12">
                  <div className="accordion" id="ticketsAccordion">
                    <div
                      className="accordion-item"
                      style={{
                        borderRadius: "7px",
                        border: "none",
                        boxShadow: "none",
                        background: "transparent",
                      }}
                    >
                      <h2 className="accordion-header grupo-ingressos">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={"#collapse" + grupo.id}
                          aria-expanded="true"
                          aria-controls={"collapse" + grupo.id}
                        >
                          <div className="d-flex flex-column">
                            <p className="text-500-black-16 mb-3">
                              {grupo.nome}
                            </p>
                            <p
                              className="text-500-darkest-14 text-break m-0"
                              style={{ whiteSpace: "break-spaces" }}
                            >
                              {/* mapear as sessões de cada ingresso do grupo */}
                              {grupo.sessoes
                                .map((d) => FormatarDataIngressoEvento(d.data))
                                .join("\t | \t")}
                            </p>
                          </div>
                        </button>
                      </h2>
                      <div
                        id={"collapse" + grupo.id}
                        className="row justify-content-center accordion-collapse collapse show"
                        style={{ transition: "0.3s" }}
                        // data-bs-parent="#ticketsAccordion" -> Omit the data-bs-parent attribute on each .accordion-collapse to make accordion items stay open when another item is opened.
                      >
                        {/* Ingressos */}
                        {grupo.ingressos.map((ingresso) => (
                          <div
                            key={ingresso.id}
                            className="col-11 col-lg-10 mt-3"
                          >
                            <div className="card-ingresso d-flex flex-column flex-sm-row">
                              {/* Data */}
                              <div className="data-card-ingresso col col-sm-2 pt-3 p-sm-4">
                                {grupo.sessoes.length > 0 ? (
                                  <>
                                    <p className="m-0 text-dia-ingresso">
                                      {new Date(
                                        grupo.sessoes[0].data
                                      ).toLocaleDateString("pt-BR", {
                                        day: "2-digit",
                                      })}
                                    </p>
                                    <p className="m-0 text-mes-ingresso">
                                      {new Date(
                                        grupo.sessoes[0].data
                                      ).toLocaleDateString("pt-BR", {
                                        month: "short",
                                      })}
                                    </p>
                                  </>
                                ) : (
                                  <p className="m-0 text-mes-ingresso">
                                    Grupo sem sessões
                                  </p>
                                )}
                              </div>
                              {/* Nome ingresso + Nome evento */}
                              <div className="col d-flex flex-column flex-sm-row justify-content-between align-items-center p-3 p-sm-4">
                                <div className="d-flex flex-column justify-content-center mb-4 mb-sm-0">
                                  <p className="text-500-black-16 text-break m-0">
                                    {ingresso.nome}
                                  </p>
                                  <p className="text-500-black-16 text-break m-0">
                                    {tituloEvento}
                                  </p>
                                </div>
                                <div className="d-flex flex-column flex-sm-row align-items-center">
                                  {/* Valor (dinâmico) + Qtde */}
                                  <p className="text-400-darkest-16 m-0 mb-3 mb-sm-0 mx-sm-3">
                                    {FormatarCentavosParaReais(
                                      ingresso.valorCentavos
                                    )}
                                  </p>
                                  <div className="d-flex align-items-center">
                                    {(ingressosSelecionados.find(
                                      (i) => i.ingressoId === ingresso.id
                                    )?.quantidade ?? 0) < 1 ? (
                                      <div className="cursor-pointer">
                                        <MinusIcon disabled />
                                      </div>
                                    ) : (
                                      <div
                                        className="cursor-pointer"
                                        onClick={() =>
                                          removeIngresso(ingresso.id)
                                        }
                                      >
                                        <MinusIcon />
                                      </div>
                                    )}
                                    <p
                                      className="text-500-black-16 m-0 text-center"
                                      style={{
                                        width: "50px",
                                        height: "25px",
                                      }}
                                    >
                                      {ingressosSelecionados.find(
                                        (i) => i.ingressoId === ingresso.id
                                      )?.quantidade || 0}
                                    </p>
                                    <div
                                      className="cursor-pointer"
                                      onClick={() =>
                                        addIngresso(
                                          ingresso.id,
                                          ingresso.nome,
                                          ingresso.valorCentavos
                                        )
                                      }
                                    >
                                      <PlusIcon />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>

        <div className="col-xxl-4">
          <WhiteContainer containerPadding={3} containerWidth={12}>
            <div className="d-flex flex-column justify-content-center">
              <div className="mb-2 d-flex flex-row justify-content-between align-items-center">
                {acessoNegadoValor ? (
                  <p className="text-500-black-14 m-0">
                    Você não tem acesso ao valor dos ingressos.
                  </p>
                ) : (
                  <>
                    <p className="text-500-black-14 m-0">
                      Valor do(s) ingresso(s)
                    </p>
                    <p className="text-500-black-14 m-0">
                      {ingressosSelecionados.find((i) => i.valorCentavos) &&
                      valorIngressos <= 0
                        ? "Calculando..."
                        : FormatarCentavosParaReais(valorIngressos)}
                    </p>
                  </>
                )}
              </div>
              <div className="mb-2 d-flex flex-row justify-content-between align-items-center">
                {acessoNegadoTaxas ? (
                  <p className="text-500-black-14 m-0">
                    Acesso negado, não é possível calcular as taxas.
                  </p>
                ) : (
                  <>
                    <p className="text-500-black-14 m-0">Taxa admin (POS)</p>
                    <p className="text-500-black-14 m-0">{taxaAdminPos}%</p>
                  </>
                )}
              </div>
              <div className="d-flex flex-row justify-content-between align-items-center">
                {acessoNegadoValor ? (
                  <p className="text-500-black-14 m-0">
                    Você não tem acesso ao valor total.
                  </p>
                ) : (
                  <>
                    <p className="text-500-black-14 m-0">Valor total</p>
                    <p className="text-500-black-14 m-0">
                      {ingressosSelecionados.find((i) => i.valorCentavos) &&
                      valorTotalItemCentavos <= 0
                        ? "Calculando..."
                        : FormatarCentavosParaReais(valorTotalItemCentavos)}
                    </p>
                  </>
                )}
              </div>
            </div>
          </WhiteContainer>

          <WhiteContainer containerPadding={3} containerWidth={12}>
            <p className="title-h2 text-black">Pagamento do ingresso</p>

            <select
              className="form-select bc-input input-outlined p-2 my-4"
              value={metodoPagamento}
              onChange={({ target: { value } }) =>
                setMetodoPagamento(value as MetodoPagamento)
              }
            >
              {Object.values(MetodoPagamento).map((mp) => (
                <option key={mp} value={mp}>
                  {ConverterStringMetodoPagamento(mp)}
                </option>
              ))}
            </select>

            <Button
              onClick={enviarIngresso}
              type="button"
              text="Enviar ingresso"
              variant="primary"
              width="100%"
              disabled={ingressosSelecionados.length < 1}
            />
          </WhiteContainer>
        </div>
      </div>
    </MainLayout>
  );
}
