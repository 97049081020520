import Button from "../Button";

interface Props {
  submitText: string;
  cancelAction?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  disabled?: boolean;
  containerWidth?: 4 | 6 | 8 | 12;
}

export default function FormButtons({
  submitText = "",
  cancelAction,
  disabled,
  containerWidth = 8,
}: Props) {
  return (
    <div className="row mb-4">
      <div className={`col-xxl-${containerWidth}`}>
        <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between justify-content-xl-end align-items-center">
          <Button
            variant="light"
            text="Cancelar"
            onClick={cancelAction}
            className="px-3 py-2 mb-3 mb-md-0 me-xl-3"
          />

          <Button
            type="submit"
            text={submitText}
            className="px-3 py-2"
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
}
