import { AddIcon, ErroIcon } from "./Icons";

interface Props {
  message: string;
  action?: () => void;
  actionText?: string;
}

export default function DarkGrayCard({
  message,
  action,
  actionText,
}: Props) {
  return (
    <div className="card-cinza-escuro p-3 mt-2 d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center">
      <div className="mb-4 mb-md-0 d-flex flex-column flex-sm-row align-items-center">
        <div className="me-2 my-2">
          <ErroIcon />
        </div>
        <p className="text-500-black-18 m-0">{message}</p>
      </div>
      {action && (
        <div>
          <button
            className="bc-btn bc-btn-primary py-2 px-3 d-flex justify-content-center align-items-center"
            onClick={action}
          >
            <p className="text-500-white-16 pe-2 m-0">{actionText}</p>
            <AddIcon />
          </button>
        </div>
      )}
    </div>
  );
}
