import { create } from "zustand";

type PermissoesStore = {
  status: "inicial" | "cadastrando" | "editando";
  usuarioId: string | null;
  setCadastrando: () => void;
  setEditando: (usuarioId: string) => void;
  setInicial: () => void;
};

export const usePermissoesStore = create<PermissoesStore>()((set) => ({
  status: "inicial",
  usuarioId: null,
  setCadastrando: () => set({ status: "cadastrando", usuarioId: null }),
  setEditando: (usuarioId) => set({ status: "editando", usuarioId }),
  setInicial: () => set({ status: "inicial", usuarioId: null }),
}));
