import { WhiteLabel } from "../../Utils";

export default function ChevronDownIcon() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4233 5.98342C12.2799 5.83965 12.0851 5.75885 11.882 5.75885C11.6788 5.75885 11.4841 5.83965 11.3406 5.98342L8.36111 8.96291L5.38162 5.98342C5.18821 5.79001 4.9063 5.71447 4.64209 5.78526C4.37788 5.85606 4.17151 6.06243 4.10071 6.32664C4.02992 6.59085 4.10546 6.87276 4.29887 7.06617L7.82357 10.5909C7.96705 10.7346 8.16183 10.8154 8.36495 10.8154C8.56806 10.8154 8.76284 10.7346 8.90632 10.5909L12.431 7.06617C12.7228 6.77437 12.7228 6.2829 12.4233 5.98342Z"
        fill={WhiteLabel().colorPrimary}
      />
    </svg>
  );
}
