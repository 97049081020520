import { WhiteLabel } from "../../Utils";

export default function EditIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 11.6416V13.6683C2 13.855 2.14667 14.0016 2.33333 14.0016H4.36C4.44667 14.0016 4.53333 13.9683 4.59333 13.9016L11.8733 6.6283L9.37333 4.1283L2.1 11.4016C2.03333 11.4683 2 11.5483 2 11.6416ZM13.8067 4.69496C13.9315 4.5704 14.0016 4.4013 14.0016 4.22496C14.0016 4.04862 13.9315 3.87953 13.8067 3.75496L12.2467 2.19496C12.1221 2.07014 11.953 2 11.7767 2C11.6003 2 11.4312 2.07014 11.3067 2.19496L10.0867 3.41496L12.5867 5.91496L13.8067 4.69496Z"
        fill={WhiteLabel().colorPrimary}
      />
    </svg>
  );
}
