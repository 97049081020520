import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import BreadCrumb, { Page } from "../../../Components/Breadcrumb";
import MainLayout from "../../../Components/Layouts/Main";
import WhiteContainer from "../../../Components/WhiteContainer";
import { useEffect, useState } from "react";
import AxiosErrorHandler from "../../../Services/AxiosErrorHandler";
import toast from "react-hot-toast";
import { ListServiceResult } from "../../../Interfaces";
import AxiosClient from "../../../Services/AxiosClient";
import DarkGrayCard from "../../../Components/DarkGrayCard";
import Loading from "../../../Components/Loading";
import PromptModal from "../../../Components/Modals/PromptModal";
import { DangerModalIcon } from "../../../Components/Icons";
import useObterNomeEventoHeader from "../../../Hooks/useObterNomeEventoHeader";
import { TablePagination } from "../../../Components/table-pagination";
import FiltroCupom from "./Components/FiltroCupom";
import CupomDescontoModel from "../../../Interfaces/Models/CupomDescontoModel";
import CuponsDesconto from "./Components/CuponsDesconto";
import SkeletonListagem from "../../../Components/SkeletonListagem";

export default function CupomDesconto() {
  const { eventoId } = useParams();
  const navigate = useNavigate();

  const { obterNomeEvento } = useObterNomeEventoHeader();

  const [numeroPaginas, setNumeroPaginas] = useState<number>(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const pesquisa = searchParams.get("q") || "";
  const status = searchParams.get("status") || "";
  const pagina = Number(searchParams.get("page")) || 1;
  const paginaTamanho = Number(searchParams.get("size")) || 10;

  const breadCrumbHistory: Page[] = [
    {
      link: "/",
      name: "dashboard",
    },
    {
      link: "/eventos",
      name: "Eventos",
    },
    {
      link: `/eventos/${eventoId}/cupom-desconto`,
      name: "Cupom de desconto",
    },
  ];

  const [loading, setLoading] = useState<boolean>(false);
  const [cupons, setCupons] = useState<CupomDescontoModel[]>([]);
  const [acessoNegadoCupons, setAcessoNegadoCupons] = useState<boolean>(false);

  const possuiCuponsOuFiltrosAplicados =
    !loading &&
    (cupons.length > 0 || (cupons.length === 0 && (pesquisa || status)));

  const removerFiltro = (filtro: string): void => {
    setSearchParams((state) => {
      state.delete(filtro);

      return state;
    });

    setCupons([]);
  };

  const getRequestPathWIthQueryParams = () => {
    let url: string = `/eventos/${eventoId}/cupom-desconto/`;
    url += `?searchString=${pesquisa}`;
    url += `&pagina=${pagina}`;
    url += `&paginaTamanho=${paginaTamanho}`;
    url += `&evento=${eventoId}`;

    return url;
  };

  const getCupons = async () => {
    setLoading(true);

    AxiosClient.get<ListServiceResult<CupomDescontoModel>>(
      getRequestPathWIthQueryParams()
    )
      .then(({ data: lsr }) => {
        setNumeroPaginas(lsr?.pages || 0);
        // sortby data criacao
        lsr.data!.sort((a, b) => {
          return (
            new Date(b.criadoEm).getTime() - new Date(a.criadoEm).getTime()
          );
        });

        setCupons(lsr.data ?? []);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          setAcessoNegadoCupons(true);
          return;
        }
        toast.error(AxiosErrorHandler(error));
      })
      .finally(() => setLoading(false));
  };

  const [auxCupomModel, setAuxCupomModel] = useState<CupomDescontoModel>(
    {} as CupomDescontoModel
  );
  const [modalExcluirCupom, setModalExcluirCupom] = useState<string | null>(
    null
  );

  const abrirModalExcluirCupom = (cupom: CupomDescontoModel) => {
    setModalExcluirCupom(cupom.id);
    setAuxCupomModel(cupom);
  };

  const excluirCupom = async (): Promise<void> => {
    setModalExcluirCupom(null);
    setLoading(true);
    toast.promise(
      AxiosClient.delete(
        `/eventos/${eventoId}/cupom-desconto/${auxCupomModel.id}`
      )
        .then(() => {})
        .finally(() => {
          getCupons(); // Chama novamente o método para obter a lista atualizada de cupons
          setAuxCupomModel({} as CupomDescontoModel);
          setLoading(false);
        }),
      {
        loading: "Excluindo cupom...",
        success: "Cupom excluído com sucesso!",
        error: (error) => AxiosErrorHandler(error),
      }
    );
  };

  useEffect(() => {
    obterNomeEvento();
  }, []);

  useEffect(() => {
    getCupons();
  }, [pesquisa, status, pagina, paginaTamanho]);

  return (
    <MainLayout>
      <div className="mb-3">
        <BreadCrumb history={breadCrumbHistory} />
      </div>

      <WhiteContainer
        rowMarginBottom={3}
        containerPadding={4}
        containerWidth={12}
      >
        <div className="d-flex flex-column flex-xl-row justify-content-xl-between align-items-center">
          <p className="title-h1 text-black text-center mb-2">
            Cupom de desconto
          </p>

          <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
            <FiltroCupom />

            <button
              type="button"
              onClick={() =>
                navigate(`/eventos/${eventoId}/cupom-desconto/cadastrar-cupom`)
              }
              className="bc-btn bc-btn-primary px-3 py-2 w-100 h-100"
            >
              Adicionar cupom
            </button>
          </div>
        </div>
      </WhiteContainer>

      <WhiteContainer
        rowMarginBottom={4}
        containerPadding={4}
        containerWidth={12}
      >
        {(pesquisa || status) && (
          <div className="d-flex flex-wrap align-items-center justify-content-center mb-4">
            {pesquisa && (
              <div className="me-3 mb-2">
                <div
                  className="d-flex flex-wrap justify-content-center align-items-center px-4"
                  style={{
                    borderRadius: 50,
                    background: "#F9F8F8",
                  }}
                >
                  <p className="text-400-black-16 m-0 me-2 my-2">Filtro:</p>
                  <p className="text-400-black-16 m-0 me-2 my-2 text-break">
                    {pesquisa}
                  </p>
                  <button
                    className="btn-close my-2"
                    onClick={() => removerFiltro("q")}
                  ></button>
                </div>
              </div>
            )}
            {status && (
              <div className="me-3 mb-2">
                <div
                  className="d-flex flex-wrap justify-content-center align-items-center px-4"
                  style={{
                    borderRadius: 50,
                    background: "#F9F8F8",
                  }}
                >
                  <p className="text-400-black-16 m-0 me-2 my-2">Status:</p>
                  <p className="text-400-black-16 m-0 me-2 my-2 text-break">
                    {status}
                  </p>
                  <button
                    className="btn-close my-2"
                    onClick={() => removerFiltro("status")}
                  ></button>
                </div>
              </div>
            )}
          </div>
        )}

        {loading ? (
          <SkeletonListagem />
        ) : acessoNegadoCupons ? (
          <div className="mb-3">
            <DarkGrayCard message="Você não tem permissão para listar cupons." />
          </div>
        ) : cupons.length === 0 ? (
          <DarkGrayCard message="Você ainda não tem cupons promocionais por aqui!" />
        ) : (
          possuiCuponsOuFiltrosAplicados && (
            <CuponsDesconto
              cupons={cupons}
              eventoId={eventoId!}
              updateStatusCupons={() => getCupons()}
              abrirModalExcluirCupom={abrirModalExcluirCupom}
            />
          )
        )}

        {/*
          --------------------------------------------------------------------------
                               Modal: Excluir cupom promocional                    
          --------------------------------------------------------------------------
        */}
        {modalExcluirCupom && (
          <PromptModal
            isOpen={modalExcluirCupom !== null}
            close={() => setModalExcluirCupom(null)}
            icon={<DangerModalIcon />}
            title="Excluir código"
            subtitle={`Tem certeza que deseja excluir o cupom "${auxCupomModel.codigo}” ?`}
            cancelText="Cancelar"
            cancelAction={() => setModalExcluirCupom(null)}
            confirmText="Excluir"
            confirmAction={excluirCupom}
            loading={loading}
          />
        )}
        <TablePagination numeroPaginas={numeroPaginas} />
      </WhiteContainer>
    </MainLayout>
  );
}
