import React, { useState } from "react";

interface TooltipButtonProps {
  tooltipText: string;
}

const TooltipButton: React.FC<TooltipButtonProps> = ({ tooltipText }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => setShowTooltip(true);
  const handleMouseLeave = () => setShowTooltip(false);

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <button
        type="button"
        style={{
          backgroundColor: "#f0f0f0",
          border: "none",
          borderRadius: "50%",
          width: "20px",
          height: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        ?
      </button>
      {showTooltip && (
        <div
          style={{
            visibility: "visible",
            opacity: 1,
            width: "200px",
            backgroundColor: "#333",
            color: "#fff",
            textAlign: "center",
            borderRadius: "6px",
            padding: "5px",
            position: "absolute",
            zIndex: 1,
            bottom: "125%",
            left: "50%",
            marginLeft: "-100px",
            transition: "opacity 0.3s",
          }}
        >
          {tooltipText}
          <div
            style={{
              content: '""',
              position: "absolute",
              top: "100%",
              left: "50%",
              marginLeft: "-5px",
              borderWidth: "5px",
              borderStyle: "solid",
              borderColor: "#333 transparent transparent transparent",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default TooltipButton;
