export default function PigMoneyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="#505055"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M13 9v.01M3.173 6.378a3 3 0 114.656-1.377"
      ></path>
      <path
        stroke="#505055"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M14 2v3.803A6.02 6.02 0 0116.658 9h1.341a1 1 0 011 1v2a1 1 0 01-1 1h-1.342c-.336.95-.907 1.8-1.658 2.473V17.5a1.5 1.5 0 11-3 0v-.583c-.33.055-.665.083-1 .083h-4c-.335 0-.67-.027-1-.083v.583a1.5 1.5 0 01-3 0v-2.027A6 6 0 016.999 5h2.5L14 2z"
      ></path>
    </svg>
  );
}
