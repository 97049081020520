import Button from "../../../Components/Button";
import PerfilBSModel from "../../../Interfaces/Models/PerfilBSModel";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { FaRegEye, FaRegTrashAlt } from "react-icons/fa";

interface Props {
  perfil: PerfilBSModel;
  abrirModalExcluirPerfil: (g: PerfilBSModel) => void;
}

export default function BotoesListagemPerfil({
  perfil,
  abrirModalExcluirPerfil,
}: Props) {
  const navigate = useNavigate();

  return (
    <div className="d-flex justify-content-end flex-wrap gap-2 flex-lg-nowrap">
      {perfil.nome === "Gestor" ||
      perfil.nome === "Produtor" ||
      perfil.nome === "CoProdutor" ? (
        <Button
          className="m-2 px-3 rounded-pill"
          variant="light"
          text="Visualizar"
          height={24}
          icon={<FaRegEye />}
          onClick={() =>
            navigate(`/gestao-de-acesso/perfil/${perfil.id}/visualizar`)
          }
        />
      ) : (
        <>
          <Button
            className="m-2 px-3 rounded-pill"
            variant="light"
            text="Editar"
            height={24}
            icon={<MdOutlineModeEditOutline />}
            onClick={() =>
              navigate(`/gestao-de-acesso/perfil/${perfil.id}/editar`)
            }
          />

          <Button
            className="m-2 px-3 rounded-pill"
            variant="light"
            text="Excluir"
            height={24}
            icon={<FaRegTrashAlt />}
            onClick={() => abrirModalExcluirPerfil(perfil)}
          />
        </>
      )}
    </div>
  );
}
