import { useEffect, useState } from "react";
import BreadCrumb, { Page } from "../../Components/Breadcrumb";
import MainLayout from "../../Components/Layouts/Main";
import WhiteContainer from "../../Components/WhiteContainer";
import { UsuarioModel } from "../../Interfaces/Models";
import ListagemUsuarios from "./Components/ListagemUsuarios";
import SkeletonListagem from "../../Components/SkeletonListagem";
import DarkGrayCard from "../../Components/DarkGrayCard";
import { ListServiceResult } from "../../Interfaces";
import AxiosClient from "../../Services/AxiosClient";
import AxiosErrorHandler from "../../Services/AxiosErrorHandler";
import toast from "react-hot-toast";
import FiltroUsuarios from "./Components/FiltroUsuarios";
import { useSearchParams } from "react-router-dom";
import { TablePagination } from "../../Components/table-pagination";

const breadCrumbHistory: Page[] = [
  {
    link: "/",
    name: "dashboard",
  },
  {
    link: "/usuarios",
    name: "Usuários",
  },
];

export default function Usuarios() {
  const [loading, setLoading] = useState<boolean>(false);
  const [usuarios, setUsuarios] = useState<UsuarioModel[]>([]);
  const [acessoNegadoUsuarios, setAcessoNegadoUsuarios] =
    useState<boolean>(false);

  const [numeroPaginas, setNumeroPaginas] = useState<number>(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const pagina = Number(searchParams.get("page")) || 1;
  const paginaTamanho = Number(searchParams.get("size")) || 10;

  const [filtroBusca, setFiltroBusca] = useState<string>("");
  const [filtrosAplicados, setFiltrosAplicados] = useState<string[]>([]);

  const handleFiltro = () => {
    setFiltrosAplicados([]);
    setSearchParams((state) => {
      state.delete("page");
      return state;
    });

    if (filtroBusca) {
      setFiltrosAplicados((prev) => [...prev, filtroBusca]);
    }

    setUsuarios([]);
  };

  const removerFiltro = (filtro: string): void => {
    if (filtro === filtroBusca) {
      setFiltroBusca("");
    }

    setFiltrosAplicados((prev) => prev.filter((f) => f !== filtro));

    setUsuarios([]);
  };

  const getRequestPathWIthQueryParams = (): string => {
    let url: string = `/usuarios`;
    url += `?pagina=${pagina}`;
    url += `&paginaTamanho=${paginaTamanho}`;

    if (filtroBusca) {
      url += `&searchString=${filtroBusca}`;
    }

    return url;
  };

  const getUsuarios = async (): Promise<void> => {
    setLoading(true);

    AxiosClient.get<ListServiceResult<UsuarioModel>>(
      getRequestPathWIthQueryParams(),
    )
      .then(({ data }) => {
        setNumeroPaginas(data?.pages || 0);
        setUsuarios(data?.data ?? []);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          setAcessoNegadoUsuarios(true);
          return;
        }

        toast.error(AxiosErrorHandler(error));
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getUsuarios();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagina, paginaTamanho, filtrosAplicados]);

  return (
    <MainLayout>
      <div className="mb-3">
        <BreadCrumb history={breadCrumbHistory} />
      </div>

      <WhiteContainer containerPadding={4} containerWidth={12}>
        <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-sm-between align-items-center">
          <p className="title-h1 text-black text-center mb-2">Usuários</p>
          <FiltroUsuarios
            filtroBusca={filtroBusca}
            setFiltroBusca={setFiltroBusca}
            handleFiltro={handleFiltro}
          />
        </div>
      </WhiteContainer>

      <WhiteContainer
        rowMarginBottom={4}
        containerPadding={4}
        containerWidth={12}
      >
        {filtrosAplicados.length > 0 && (
          <div className="d-flex flex-wrap align-items-center justify-content-center mb-4">
            {filtrosAplicados.map((filtro, index) => (
              <div className="me-3 mb-2" key={index}>
                <div
                  className="d-flex flex-wrap justify-content-center align-items-center px-4"
                  style={{ borderRadius: 50, background: "#F9F8F8" }}
                >
                  <p className="text-400-black-16 m-0 me-2 my-2">Filtro:</p>
                  <p className="text-400-black-16 m-0 me-2 my-2 text-break">
                    {filtro}
                  </p>
                  <button
                    className="btn-close my-2"
                    onClick={() => removerFiltro(filtro)}
                  ></button>
                </div>
              </div>
            ))}
          </div>
        )}

        {loading ? (
          <SkeletonListagem />
        ) : acessoNegadoUsuarios ? (
          <div className="mb-3">
            <DarkGrayCard message="Você não tem permissão para acessar a lista de usuários." />
          </div>
        ) : usuarios.length === 0 ? (
          <div className="mb-3">
            <DarkGrayCard message="Você ainda não tem usuários por aqui!" />
          </div>
        ) : (
          <ListagemUsuarios usuarios={usuarios} />
        )}
      </WhiteContainer>
      <TablePagination numeroPaginas={numeroPaginas} />
    </MainLayout>
  );
}
