import { create } from "zustand";

type UserStore = {
  imagemPerfil: string;
  setImagemPerfil: (img: string) => void;
  nome: string;
  setNome: (name: string) => void;
};

export const useUserStore = create<UserStore>()((set) => ({
  imagemPerfil: "/assets/images/anon_image.png",
  setImagemPerfil: (img) => set({ imagemPerfil: img }),
  nome: "",
  setNome: (name) => set({ nome: name }),
}));
