export default function BarChartMinIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.3333 4H6.66667C5.2 4 4 5.2 4 6.66667V25.3333C4 26.8 5.2 28 6.66667 28H25.3333C26.8 28 28 26.8 28 25.3333V6.66667C28 5.2 26.8 4 25.3333 4ZM10.6667 22.6667C9.93333 22.6667 9.33333 22.0667 9.33333 21.3333V14.6667C9.33333 13.9333 9.93333 13.3333 10.6667 13.3333C11.4 13.3333 12 13.9333 12 14.6667V21.3333C12 22.0667 11.4 22.6667 10.6667 22.6667ZM16 22.6667C15.2667 22.6667 14.6667 22.0667 14.6667 21.3333V10.6667C14.6667 9.93333 15.2667 9.33333 16 9.33333C16.7333 9.33333 17.3333 9.93333 17.3333 10.6667V21.3333C17.3333 22.0667 16.7333 22.6667 16 22.6667ZM21.3333 22.6667C20.6 22.6667 20 22.0667 20 21.3333V18.6667C20 17.9333 20.6 17.3333 21.3333 17.3333C22.0667 17.3333 22.6667 17.9333 22.6667 18.6667V21.3333C22.6667 22.0667 22.0667 22.6667 21.3333 22.6667Z"
        fill="#303F9F"
      />
    </svg>
  );
}
