export default function SearchIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5015 14.0015H14.7115L14.4315 13.7315C15.6968 12.2615 16.2396 10.3032 15.9115 8.39149C15.4415 5.61149 13.1215 3.39149 10.3215 3.05149C8.32662 2.79912 6.32716 3.48349 4.90532 4.90532C3.48349 6.32716 2.79912 8.32662 3.05149 10.3215C3.39149 13.1215 5.61149 15.4415 8.39149 15.9115C10.3032 16.2396 12.2615 15.6968 13.7315 14.4315L14.0015 14.7115V15.5015L18.2515 19.7515C18.6615 20.1615 19.3315 20.1615 19.7415 19.7515C20.1515 19.3415 20.1515 18.6715 19.7415 18.2615L15.5015 14.0015ZM9.50149 14.0015C7.01149 14.0015 5.00149 11.9915 5.00149 9.50149C5.00149 7.01149 7.01149 5.00149 9.50149 5.00149C11.9915 5.00149 14.0015 7.01149 14.0015 9.50149C14.0015 11.9915 11.9915 14.0015 9.50149 14.0015Z"
        fill="#D8D8D8"
      />
    </svg>
  );
}
