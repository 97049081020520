type CardEventoProps = {
  titulo: string;
  dataInicio: string;
  imagemThumbEvento: string | null;
};

function dataFormatada(data: string) {
  const dataAsDate = new Date(data);
  // epoch: 01/01/1970 (getTime() = 0)
  if (dataAsDate.getTime() <= 0) {
    return "Sem data e hora definidas";
  }
  return dataAsDate.toLocaleDateString("pt-br", {
    day: "2-digit",
    month: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
}

export default function CardEvento({
  imagemThumbEvento,
  titulo,
  dataInicio,
}: CardEventoProps) {
  return (
    <div className="card-evento">
      <img
        src={imagemThumbEvento || ""}
        alt={titulo}
        className="img-card-evento mb-2"
      />
      <p className="text-500-black-16 text-break mb-1">
        {titulo.length > 35 ? `${titulo.substring(0, 35)}...` : titulo}
      </p>
      <p className="text-500-darkest-16 m-0">
        {dataFormatada(dataInicio)}
        {/* {new Intl.DateTimeFormat("pt-br").format(new Date(dataInicio))} */}
      </p>
    </div>
  );
}
