import { useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ptBR } from "date-fns/locale";
import "./../../../Components/Styles/CSS/date-picker.css";
import WhiteContainer from "../../../Components/WhiteContainer";
import { useSolicitarSaqueStore } from "../../../stores/solicitacao-saque.store";
import Button from "../../../Components/Button";
import { useForm } from "react-hook-form";
import moment from "moment";
import { toast } from "react-hot-toast";
registerLocale("pt-BR", ptBR);

enum Options {
  standard = "standard",
  express = "express",
  custom = "custom",
}

type radioOption = {
  label: string;
  value: Options;
};

const radioOptions: radioOption[] = [
  {
    label: "Padrão (7 dias)",
    value: Options.standard,
  },
  {
    label: "Expresso (D+1 com uma taxa de 12%)",
    value: Options.express,
  },
  {
    label: "Personalizado",
    value: Options.custom,
  },
];

export default function QuandoReceber() {
  const { model, setModel, setStep } = useSolicitarSaqueStore();

  const [selectedDate, setSelectedDate] = useState<Date | null>(
    model.dataAgendamento
      ? moment(model.dataAgendamento).toDate()
      : moment().add(7, "days").toDate(),
  );
  const [selectedOption, setSelectedOption] =
    useState<Options>(getSelectedOption());

  function getSelectedOption() {
    if (!model.dataAgendamento) {
      return Options.standard;
    }

    const date = moment(model.dataAgendamento).toDate();

    // compare only date and not time
    if (
      date.toDateString() === moment().add(7, "days").toDate().toDateString()
    ) {
      return Options.standard;
    } else if (
      date.toDateString() === moment().add(1, "days").toDate().toDateString()
    ) {
      return Options.express;
    } else {
      return Options.custom;
    }
  }

  const { handleSubmit } = useForm();

  const handleRadioChange = (option: Options) => {
    setSelectedOption(option);

    switch (option) {
      case Options.standard:
        setSelectedDate(moment().add(7, "days").toDate());
        break;
      case Options.express:
        setSelectedDate(moment().add(1, "days").toDate());
        break;
      case Options.custom:
        setSelectedDate(null);
        break;
      default:
        break;
    }
  };

  function onSubmit() {
    if (!selectedDate) {
      toast.error("Selecione uma data para continuar");
      return;
    }

    setModel({
      ...model,
      dataAgendamento: selectedDate.toISOString(),
    });

    setStep("revisao");
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <WhiteContainer>
        <div className="pt-2 p-2" style={{ height: "400px" }}>
          <p className="text-500-black-20">Quando deseja receber?</p>
          <p className="text-400-black-14" style={{ lineHeight: "13px" }}>
            Selecione a data desejada de transferência
          </p>

          <hr className="m-0" />

          <div className="mt-5" style={{ color: "#9D9D9D" }}>
            <p>Selecione quando deseja receber</p>

            {radioOptions.map((option) => (
              <div
                key={option.value}
                className="form-check"
                style={{ marginBottom: "10px" }}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id={option.value}
                  style={{
                    appearance: "none",
                    width: "16px",
                    height: "16px",
                    borderRadius: "50%",
                    border: "2px solid #9D9D9D",
                    marginRight: "10px",
                    cursor: "pointer",
                    position: "relative",
                  }}
                  onChange={() => {
                    handleRadioChange(option.value);
                  }}
                  checked={selectedOption === option.value}
                />
                <label
                  className="form-check-label d-flex align-items-center"
                  htmlFor={option.value}
                  style={{
                    color:
                      selectedOption === option.value ? "black" : "#9D9D9D",
                  }}
                >
                  {option.label}
                </label>
              </div>
            ))}

            {selectedOption === "custom" && (
              <div className="mt-2" style={{ maxWidth: "200px" }}>
                <DatePicker
                  locale="pt-BR"
                  dateFormat="dd/MM/yyyy"
                  showIcon
                  isClearable
                  withPortal
                  selected={selectedDate}
                  onChange={(date: Date | null) => setSelectedDate(date)}
                  className="form-control cursor-pointer"
                  // disable past dates including today(D) and tomorrow(D+1)
                  minDate={moment().add(2, "days").toDate()}
                />
              </div>
            )}
          </div>
        </div>
      </WhiteContainer>

      <div className="row">
        <div className="col-xxl-8">
          <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between justify-content-xl-end align-items-center mb-3 mb-md-0">
            <Button
              type="button"
              variant="light"
              width={216}
              height={40}
              text="Voltar"
              className=" mb-3 mb-md-0 me-xl-3"
              onClick={() => setStep("valor")}
            />

            <Button
              disabled={!selectedDate}
              text="Continuar"
              width={216}
              height={40}
              type="submit"
            />
          </div>
        </div>
      </div>
    </form>
  );
}
