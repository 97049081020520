export default function GerarAcronimo(nomeCompleto?: string | null): string {
  if (!nomeCompleto) {
    return "??";
  }

  const nomes = nomeCompleto.split(" "); // ["João", "Paulo", "Franchini", "de", "Freitas"]

  const primeiroNome = nomes[0]; // "João"
  const ultimoNome = nomes[nomes.length - 1]; // "Freitas"

  const primeiraLetra_primeiroNome = primeiroNome[0]; // "J"
  const primeiraLetra_ultimoNome = ultimoNome[0]; // "F"

  const acronimo = primeiraLetra_primeiroNome + primeiraLetra_ultimoNome; // "JF"

  return acronimo.toUpperCase(); // "JF"
}
