import WhiteContainer from "../../../Components/WhiteContainer";
import Button from "../../../Components/Button";
import {
  SaquePostArgs,
  useSolicitarSaqueStore,
} from "../../../stores/solicitacao-saque.store";
import Input from "../../../Components/Inputs/Input";
import { useForm } from "react-hook-form";
import InputCurrency from "../../../Components/Inputs/InputCurrency";
import { SomenteNumeros } from "../../../Utils";
import GerarAcronimo from "../../../Utils/GerarAcronimo";

interface FormValues extends Pick<SaquePostArgs, "descricao"> {
  valorCentavos: string;
}

export default function ValorTransferir() {
  const { model, setModel, step, setStep } = useSolicitarSaqueStore();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    values: {
      valorCentavos: model.valorCentavos?.toString(),
      descricao: model.descricao,
    },
  });

  function onSubmit(data: FormValues) {
    const valor = Number(SomenteNumeros(data.valorCentavos));

    setModel({
      ...model,
      ...data,
      valorCentavos: valor,
    });

    setStep("data");
  }

  console.log(model);

  function handleVoltar() {
    if (model.favorecidoId) {
      setStep("inicial");
    } else {
      setStep("novo-saque");
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <WhiteContainer>
        <div className="pt-2 p-2" style={{ height: "438px" }}>
          <p className="text-500-black-20">Quanto você quer transferir?</p>
          <p className="text-400-black-14" style={{ lineHeight: "13px" }}>
            Você tem R$ 00,00 disponível para transferência
          </p>
          <hr className="m-0" />

          <div className="d-flex align-items-start mt-lg-5 mt-2">
            <div className="list-group-item d-flex flex-column flex-lg-row align-items-center mb-4 border-0">
              <div
                className="rounded-circle bg-light d-flex justify-content-center align-items-center"
                style={{ width: "64px", height: "64px" }}
              >
                <span className="text-400-gray-16" style={{ fontSize: "32px" }}>
                  {GerarAcronimo(model.nomeTitular!)}
                </span>
              </div>
              <div className="ms-lg-3">
                <p className="mb-0 text-400-gray-16">{model.nomeTitular}</p>
                <small className="text-muted">{model.cpfCnpj}</small>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-8">
              <InputCurrency
                control={control}
                errors={errors}
                name="valorCentavos"
                validation={{ required: "Campo obrigatório" }}
                placeholder="R$ 00,00"
              />
              {errors.valorCentavos && (
                <p className="text-danger text-break m-0 mt-1">
                  {errors.valorCentavos?.message?.toString()}
                </p>
              )}
            </div>

            <div className="col-lg-8">
              <Input
                control={control}
                errors={errors}
                name="descricao"
                validation={{}}
                type="text"
                placeholder="Nota (opcional)"
                className="mt-4"
              />
            </div>
          </div>
        </div>
      </WhiteContainer>

      <div className="row">
        <div className="col-xxl-8">
          <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between justify-content-xl-end align-items-center mb-3 mb-md-0">
            <Button
              type="button"
              variant="light"
              width={216}
              height={40}
              text="Voltar"
              className=" mb-3 mb-md-0 me-xl-3"
              onClick={handleVoltar}
            />

            <Button text="Continuar" width={216} height={40} type="submit" />
          </div>
        </div>
      </div>
    </form>
  );
}
