enum PagamentoStatus {
  AguardandoPagamento = "AguardandoPagamento",
  Pago = "Pago",
  Recusado = "Recusado",
  Processando = "Processando",
  Devolvido = "Devolvido",
  Cancelado = "Cancelado",
}

export default PagamentoStatus;
