import { Bar } from "react-chartjs-2";
import DarkGrayCard from "../../../../Components/DarkGrayCard";
import { FormatarCentavosParaReais } from "../../../../Utils";
import { EstatisticasEventoModel } from "../../../../Interfaces/Models";
import { useEffect } from "react";
import {
  BarChartIcon,
  BarChartMinIcon,
  CortesiaEstatisticasIcon,
  LineChartIcon,
  PendenteIcon,
} from "./../../../../Components/Icons";

enum Granularidade {
  Dia = "Dia",
  Semana = "Semana",
  Todos = "Todos",
}

interface Props {
  estatisticas: EstatisticasEventoModel;
  granularidade: Granularidade;
  setGranularidade: React.Dispatch<React.SetStateAction<Granularidade>>;
  grafico: any;
  alterarGraficoGranularidade: (estatisticas: EstatisticasEventoModel) => void;
}

export const EstatisticasIngressoVenda = ({
  estatisticas,
  granularidade,
  setGranularidade,
  grafico,
  alterarGraficoGranularidade,
}: Props) => {
  const obterTituloGrafico = () => {
    switch (granularidade) {
      case Granularidade.Dia:
        return "Vendas de hoje";
      case Granularidade.Semana:
        return "Vendas nos últimos 7 dias";
      default:
        return "Todas as vendas";
    }
  };

  useEffect(() => {
    alterarGraficoGranularidade(
      estatisticas || ({} as EstatisticasEventoModel)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [granularidade]);

  return (
    <>
      <div className="row g-3 mb-3">
        <div className="col-lg-4">
          <div className="shadow-sm bg-white p-3 h-100">
            <p className="text-500-black-18 m-0">Ingressos emitidos</p>
            <p className="text-number-card-home m-0 text-break text-black">
              {estatisticas.ingressosECortesiasEmitidos}
            </p>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="shadow-sm bg-white p-3 h-100">
            <p className="text-500-black-18 m-0">Receita líquida</p>
            <p className="text-number-card-home m-0 text-break text-black">
              {FormatarCentavosParaReais(estatisticas.receitaLiquidaCentavos)}
            </p>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="shadow-sm bg-white p-3 h-100">
            <p className="text-500-black-18 m-0">Ticket médio</p>
            <p className="text-number-card-home m-0 text-break text-black">
              {FormatarCentavosParaReais(estatisticas.ticketMedioCentavos)}
            </p>
          </div>
        </div>
      </div>

      <div className="row g-3 mb-4">
        <div className="col-xl-6">
          <div className="shadow-sm bg-white p-3 h-100">
            <div className="row g-3 h-100">
              <div className="col-lg-4">
                <div className="d-flex flex-column card-cinza-escuro p-3 w-100 h-100">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="text-500-black-18 m-0">Pedidos</p>
                    <BarChartMinIcon />
                  </div>
                  <div className="d-flex flex-column justify-content-between h-100">
                    <p className="text-number-card-home text-black m-0">
                      {estatisticas.totalVendas}
                    </p>
                    <LineChartIcon />
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="row g-3 h-100 m-0">
                  <div className="col-12 card-cinza-escuro p-3 m-0">
                    <div className="d-flex flex-wrap-reverse align-items-center justify-content-between h-100">
                      <div className="d-flex flex-column justify-content-center">
                        <p className="text-500-black-18 m-0">Pendentes</p>
                        <p className="text-number-card-home m-0 text-break text-black fs-2">
                          {estatisticas.totalPendentes.toString().length < 2
                            ? "0" + estatisticas.totalPendentes
                            : estatisticas.totalPendentes.toString()}
                        </p>
                      </div>
                      <PendenteIcon />
                    </div>
                  </div>

                  <div className="col-12 card-cinza-escuro p-3">
                    <div className="d-flex flex-wrap-reverse align-items-center justify-content-between h-100">
                      <div className="d-flex flex-column justify-content-center">
                        <p className="text-500-black-18 m-0">Cortesias</p>
                        <p className="text-number-card-home m-0 text-break text-black fs-2">
                          {estatisticas.totalCortesias.toString().length < 2
                            ? "0" + estatisticas.totalCortesias
                            : estatisticas.totalCortesias.toString()}
                        </p>
                      </div>
                      <CortesiaEstatisticasIcon />
                    </div>
                  </div>

                  <div className="col-12 card-cinza-escuro p-3">
                    <div className="d-flex flex-wrap align-items-center justify-content-between h-100">
                      <div className="d-flex flex-column justify-content-center">
                        <p className="text-500-black-18 m-0">Disponível</p>
                        <p className="text-number-card-home m-0 text-break text-black fs-2">
                          {estatisticas.totalDisponivel.toString().length < 2
                            ? "0" + estatisticas.totalDisponivel
                            : estatisticas.totalDisponivel}
                        </p>
                      </div>
                      <BarChartIcon />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-none d-sm-block col-xl-6">
          <div
            className="shadow-sm bg-white p-3 w-100 h-100"
            style={{ minHeight: "50vh" }}
          >
            <div className="d-flex flex-column flex-lg-row justify-content-between mb-3 me-lg-4">
              <div className="d-flex flex-column me-3">
                <p className="text-400-black-24 mb-1">Vendas de ingressos</p>
                <p className="text-500-dark-18 m-0">
                  Relatório dos ingressos do evento
                </p>
              </div>
              <div className="d-flex flex-column card-cinza-escuro p-3 pe-5">
                <div className="form-check form-switch d-flex align-items-center ps-5">
                  <input
                    className="form-check-input mb-1 cursor-pointer"
                    style={{ width: "3.3em", height: "1.7em" }}
                    type="checkbox"
                    role="switch"
                    id="semana"
                    name="granularidade"
                    checked={granularidade === Granularidade.Semana}
                    onChange={() => {
                      setGranularidade((prev) => {
                        if (prev === Granularidade.Semana) {
                          return Granularidade.Todos;
                        }
                        return Granularidade.Semana;
                      });
                      alterarGraficoGranularidade(estatisticas);
                    }}
                  />
                  <label
                    className="form-check-label ps-2 cursor-pointer"
                    htmlFor="semana"
                  >
                    Semana
                  </label>
                </div>
                <div className="form-check form-switch d-flex align-items-center ps-5">
                  <input
                    className="form-check-input mb-1 cursor-pointer"
                    style={{ width: "3.3em", height: "1.7em" }}
                    type="checkbox"
                    role="switch"
                    id="dia"
                    name="granularidade"
                    checked={granularidade === Granularidade.Dia}
                    onChange={() => {
                      setGranularidade((prev) => {
                        if (prev === Granularidade.Dia) {
                          return Granularidade.Todos;
                        }
                        return Granularidade.Dia;
                      });
                      alterarGraficoGranularidade(estatisticas);
                    }}
                  />
                  <label
                    className="form-check-label ps-2 cursor-pointer"
                    htmlFor="dia"
                  >
                    Dia
                  </label>
                </div>
              </div>
            </div>
            {grafico ? (
              <Bar
                data={grafico}
                options={{
                  responsive: true,
                  plugins: {
                    title: {
                      display: true,
                      text: obterTituloGrafico(),
                      font: {
                        size: 18,
                      },
                    },
                  },
                }}
              />
            ) : (
              <DarkGrayCard message="Dados insuficientes para gerar o gráfico." />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
