import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import WhiteContainer from "../../../../Components/WhiteContainer";
import Button from "../../../../Components/Button";
import toast from "react-hot-toast";
import AxiosClient from "../../../../Services/AxiosClient";
import AxiosErrorHandler from "../../../../Services/AxiosErrorHandler";
import { ServiceResult } from "../../../../Interfaces";
import { useNavigate, useParams } from "react-router-dom";
import { usePermissoesStore } from "../../../../Contexts/PermissoesStore";
import { FaPencil } from "react-icons/fa6";
import PerfilUsuarioEventoModel from "../../../../Interfaces/Models/PerfilUsuarioEventoModel";
import Loading from "../../../../Components/Loading";

interface FormValues {
  membroEmail: string;
  perfis: { [key: string]: boolean };
}

interface MembrosProducaoArgs {
  membroEmail: string;
  perfis: string[];
}

export const EditarMembroProducao = () => {
  const navigate = useNavigate();
  const { eventoId } = useParams();
  const { usuarioId } = usePermissoesStore();

  const [loading, setLoading] = useState<boolean>(false);
  const [usuario, setUsuario] = useState<PerfilUsuarioEventoModel | null>(null);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    values: {
      membroEmail: "",
      perfis: {},
    },
  });

  const onSubmit = async (data: FormValues): Promise<void> => {
    setLoading(true);

    // pega a diferença entre os perfis iniciais e os novos perfis selecionados
    const diff: string[] = Object.keys(data.perfis).filter(
      (perfil) =>
        data.perfis[perfil] !==
        usuario?.perfis?.find((p) => p.perfil === perfil)?.hasAccess,
    );

    let args: MembrosProducaoArgs = {
      membroEmail: data.membroEmail,
      perfis: diff,
    };

    AxiosClient.put(`/eventos/${eventoId}/membros-producao`, args)
      .then(() => {
        navigate(0);
      })
      .catch((error) => toast.error(AxiosErrorHandler(error)))
      .finally(() => setLoading(false));
  };

  const getUsuario = async (): Promise<void> => {
    setLoading(true);

    AxiosClient.get<ServiceResult<PerfilUsuarioEventoModel>>(
      `/eventos/${eventoId}/membros-producao/${usuarioId}`,
    )
      .then(({ data: result }) => {
        setUsuario(result.data as PerfilUsuarioEventoModel);
        setValue("membroEmail", result.data?.email ?? "");

        // cria um objeto com todos os perfis e se tem acesso ou não
        // será usado para controlar os checkboxes (preenchendo os valores iniciais)
        const perfis: {
          [key: string]: boolean;
        } =
          result.data?.perfis?.reduce(
            (acc, perfil) => {
              acc[perfil.perfil] = perfil.hasAccess ?? false;
              return acc;
            },
            {} as { [key: string]: boolean },
          ) ?? {};

        setValue("perfis", perfis);
      })
      .catch((error) => toast.error(AxiosErrorHandler(error)))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getUsuario();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usuarioId]);

  return (
    <WhiteContainer containerWidth={12} containerPadding={4}>
      <div className="col-xl-12">
        <p className="title-h1 text-black mb-4">Editar usuário</p>
      </div>

      {loading && <Loading container="30vh" />}

      {!loading && usuario && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row g-2">
            <div className="col-lg-8">
              <p className="text-500-dark-18 m-0">Nome do usuário</p>
              <p className="text-500-black-16">{usuario.nome}</p>
            </div>

            <div className="col-lg-8">
              <p className="text-500-dark-18 m-0">E-mail do usuário</p>
              <input
                type="text"
                value={usuario.email}
                placeholder="E-mail"
                className="form-control backstage-input"
                style={{ height: 40 }}
                disabled
              />
            </div>

            <div className="col-lg-12">
              <p className="text-500-dark-18">Perfis disponíveis</p>

              <div className="form-check">
                <Controller
                  control={control}
                  name="perfis"
                  render={({ field: { onChange, value } }) => (
                    <>
                      {Object.keys(value)
                        .sort()
                        .map((id) => (
                          <div key={id}>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id={`perfis.${id}`}
                              checked={value[id]}
                              onChange={({ target: { checked } }) =>
                                onChange({
                                  ...value,
                                  [id]: checked,
                                })
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`perfis.${id}`}
                            >
                              {
                                usuario.perfis?.find(
                                  (perfil) => perfil.perfil === id,
                                )?.perfil
                              }
                            </label>
                          </div>
                        ))}
                    </>
                  )}
                />
              </div>
            </div>

            <div className="col-lg-12 d-flex justify-content-end">
              <Button
                type="submit"
                disabled={loading || !!errors.membroEmail}
                text="Salvar"
                icon={<FaPencil />}
              />
            </div>
          </div>
        </form>
      )}
    </WhiteContainer>
  );
};
