import { useEffect, useState } from "react";
import BreadCrumb, { Page } from "../../Components/Breadcrumb";
import MainLayout from "../../Components/Layouts/Main";
import WhiteContainer from "../../Components/WhiteContainer";
import { useNavigate } from "react-router-dom";
import AxiosClient from "../../Services/AxiosClient";
import { ListServiceResult } from "../../Interfaces";
import PerfilBSModel from "../../Interfaces/Models/PerfilBSModel";
import AxiosErrorHandler from "../../Services/AxiosErrorHandler";
import toast from "react-hot-toast";
import BotoesListagemPerfil from "./Components/BotoesListagemPerfil";
import PromptModal from "../../Components/Modals/PromptModal";
import { DangerModalIcon } from "../../Components/Icons";
import DarkGrayCard from "../../Components/DarkGrayCard";

const breadCrumbHistory: Page[] = [
  {
    link: "/",
    name: "Dashboard",
  },
  {
    link: "/gestao-de-acesso",
    name: "Gestão de acesso",
  },
];

export default function GestaoAcesso() {
  const navigate = useNavigate();

  const [loadingPerfis, setLoadingPerfis] = useState<boolean>(true);
  const [perfis, setPerfis] = useState<PerfilBSModel[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [perfilAux, setPerfilAux] = useState<PerfilBSModel | null>(null);

  const [acessoNegadoPerfis, setAcessoNegadoPerfis] = useState<boolean>(false);

  const abrirModalExcluirPerfil = (perfil: PerfilBSModel): void => {
    setPerfilAux(perfil);
  };

  const getPerfis = async (): Promise<void> => {
    setLoadingPerfis(true);
    AxiosClient.get<ListServiceResult<PerfilBSModel>>(`/Perfis`)
      .then(({ data: { data } }) => {
        setPerfis(data as PerfilBSModel[]);
      })
      .catch((error: any) => {
        if (error.response && error.response.status === 403) {
          setAcessoNegadoPerfis(true);
          return;
        }
        toast.error(AxiosErrorHandler(error));
      })
      .finally(() => setLoadingPerfis(false));
  };

  const excluirPerfil = async (): Promise<void> => {
    setLoading(true);

    toast.promise(
      AxiosClient.delete(`/Perfis/${perfilAux!.id}`).finally(() => {
        setLoading(false);
      }),
      {
        loading: "Removendo...",
        success: () => {
          navigate(0);
          return "Perfil excluído com sucesso!";
        },
        error: (error) => AxiosErrorHandler(error),
      },
    );
  };

  useEffect(() => {
    getPerfis();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout>
      <div className="mb-3">
        <BreadCrumb history={breadCrumbHistory} />
      </div>
      <WhiteContainer containerPadding={4} containerWidth={12}>
        <div className="d-flex flex-column flex-xl-row justify-content-xl-between align-items-center">
          <p className="title-h1 text-black text-center mb-2">
            Gestão de acesso
          </p>
          <div className="row flex-wrap align-items-center g-3">
            <div className="col-md-6">
              <button
                type="button"
                onClick={() => navigate(`/gestao-de-acesso/perfil/cadastrar`)}
                className="bc-btn bc-btn-primary px-3 py-2 w-100 h-100"
                style={{ minWidth: 200 }}
              >
                Cadastrar perfil
              </button>
            </div>
          </div>
        </div>
      </WhiteContainer>

      <WhiteContainer containerWidth={12} containerPadding={4}>
        {loadingPerfis ? (
          <p>Carregando...</p>
        ) : acessoNegadoPerfis ? (
          <div className="mb-3">
            <DarkGrayCard message="Você não tem permissão para acessar a lista de perfis." />
          </div>
        ) : (
          <>
            <div className="row">
              {perfis.map((perfil) => (
                <div key={perfil.id} className="col-12 mb-3">
                  <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
                    <div className="text-break">
                      <p className="mb-0">
                        <strong>Nome:</strong> {perfil.nome}
                      </p>
                      <p className="mb-0">
                        <strong>Descrição:</strong> {perfil.descricao}
                      </p>
                    </div>
                    <BotoesListagemPerfil
                      perfil={perfil}
                      abrirModalExcluirPerfil={abrirModalExcluirPerfil}
                    />
                  </div>

                  <hr />
                </div>
              ))}
            </div>
          </>
        )}
      </WhiteContainer>

      <PromptModal
        isOpen={perfilAux !== null}
        close={() => setPerfilAux(null)}
        icon={<DangerModalIcon />}
        title="Remover perfil"
        subtitle={`Tem certeza que deseja remover o perfil "${
          perfilAux?.nome ?? ""
        }"?`}
        cancelText="Cancelar"
        cancelAction={() => setPerfilAux(null)}
        confirmText="Excluir"
        confirmAction={excluirPerfil}
        loading={loading}
      />
    </MainLayout>
  );
}
