import moment from "moment";
import DarkGrayCard from "../../../../Components/DarkGrayCard";
import IngressoModel from "../../../../Interfaces/Models/IngressoModel";
import BotoesListagemIngresso from "./BotoesListagemIngresso";
import { FormatarCentavosParaReais } from "../../../../Utils";
import { useState } from "react";
import { DangerModalIcon, EditModalIcon } from "../../../../Components/Icons";
import PromptModal from "../../../../Components/Modals/PromptModal";
import AxiosClient from "../../../../Services/AxiosClient";
import toast from "react-hot-toast";
import AxiosErrorHandler from "../../../../Services/AxiosErrorHandler";
import { useNavigate } from "react-router-dom";
import EmptyModal from "../../../../Components/Modals/EmptyModal";
import IngressoSituacao from "../../../../Enums/IngressoSituacao";

interface Props {
  eventoId: string;
  grupoId: string;
  ingressos: IngressoModel[];
  updateSituacaoIngresso: (ingresso: IngressoModel) => void;
}

export default function IngressosDoGrupo({
  eventoId,
  grupoId,
  ingressos,
  updateSituacaoIngresso,
}: Props) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const [modalCortesia, setModalCortesia] = useState<string | null>(null);
  const [ingressoCortesia, setIngressoCortesia] = useState<IngressoModel>(
    {} as IngressoModel
  );
  const [emailsCortesia, setEmailsCortesia] = useState<string>("");
  const [modalExcluirIngresso, setModalExcluirIngresso] = useState<
    string | null
  >(null);
  const [ingressoExcluir, setIngressoExcluir] = useState<IngressoModel>(
    {} as IngressoModel
  );

  const abrirModalExcluirIngresso = (ingresso: IngressoModel): void => {
    setIngressoExcluir(ingresso);
    setModalExcluirIngresso(ingresso.id);
  };

  const excluirIngresso = async (): Promise<void> => {
    setLoading(true);
    setModalExcluirIngresso(null);

    toast.promise(
      AxiosClient.delete(
        `/eventos/${eventoId}/grupos/${grupoId}/ingressos/${ingressoExcluir.id}`
      ).finally(() => {
        setLoading(false);
      }),
      {
        loading: "Excluindo ingresso...",
        success: (data) => {
          navigate(0);
          return "Ingresso excluído com sucesso!";
        },
        error: (error) => AxiosErrorHandler(error),
      }
    );
  };

  const abrirModalCortesia = (ingresso: IngressoModel): void => {
    setIngressoCortesia(ingresso);
    setModalCortesia(ingresso.id);
  };

  const resetarModalCortesia = (): void => {
    setIngressoCortesia({} as IngressoModel);
    setEmailsCortesia("");
    setModalCortesia(null);
  };

  const enviarIngressoCortesia = async (): Promise<void> => {
    if (!emailsCortesia) {
      toast.error("Informe os e-mails para enviar a cortesia");
      return;
    }

    setLoading(true); // Inicia o estado de carregamento

    setModalCortesia(null);

    let emails: string[] = emailsCortesia
      .split(/[,;]/) // Separa os e-mails por vírgula ou ponto e vírgula
      .map((e) => e.trim()) // Remove os espaços em branco
      .filter((e) => e); // Remove os vazios

    AxiosClient.post(
      `/eventos/${eventoId}/grupos/${grupoId}/ingressos/${ingressoCortesia.id}/cortesias`,
      {
        emails: emails,
      }
    )
      .then(({ data: { data } }) => {
        const emailsNaoEncontrados: string[] = data.naoEncontrados;

        if (emailsNaoEncontrados.length > 0) {
          toast(
            "Alguns e-mails não foram encontrados! Verifique-os e tente novamente.",
            {
              icon: "⚠️",
              duration: 10000,
              style: {
                borderRadius: "10px",
                color: "#000",
                fontWeight: "500",
              },
            }
          );
          setEmailsCortesia(emailsNaoEncontrados.join(", "));
          setModalCortesia(ingressoCortesia.id);
        } else {
          toast.success("Cortesia enviada com sucesso!");
          setEmailsCortesia("");
        }
      })
      .catch((error) => {
        setModalCortesia(ingressoCortesia.id);
        AxiosErrorHandler(error);
      })
      .finally(() => {
        setLoading(false); // Finaliza o estado de carregamento
      });
  };

  return (
    <div className="card-cinza-claro p-3 mb-4" style={{ marginTop: "-12px" }}>
      <div className="row">
        <div className="col-xl-10">
          <div className="bg-white shadow-sm p-3">
            {ingressos.length > 0 && (
              <>
                <div className="row g-2 mb-3">
                  <div className="col-lg-6">
                    <p className="text-500-black-18 m-0">Ingressos do grupo</p>
                  </div>
                  <div className="col-lg-6 d-flex justify-content-start justify-content-lg-end">
                    <p className="text-500-blue-16 m-0">
                      {ingressos.some(
                        (i) => i.quantidadeCodigosAssociados > 0
                      ) ? (
                        <>
                          {ingressos.reduce(
                            (acc, cur) => acc + cur.quantidadeCodigosAssociados,
                            0
                          )}{" "}
                          códigos associados
                        </>
                      ) : (
                        "Nenhum código associado"
                      )}
                    </p>
                  </div>
                </div>
                <div className="row">
                  {ingressos.map((ingresso) => (
                    <div className="col-lg-12" key={ingresso.id}>
                      <div className="card-cinza-claro py-3 mb-3">
                        <div className="row g-0">
                          <div className="col-6 col-xxl-3 text-center">
                            <p className="text-500-black-16 m-0">Início</p>
                            <p className="text-500-darkest-14 text-break mb-2">
                              {moment(ingresso.dataInicioVendas).format(
                                "DD/MM/YYYY | HH:mm"
                              )}
                            </p>
                          </div>
                          <div className="col-6 col-xxl-3 text-center">
                            <p className="text-500-black-16 m-0">Término</p>
                            <p className="text-500-darkest-14 text-break mb-2">
                              {moment(ingresso.dataFimVendas).format(
                                "DD/MM/YYYY | HH:mm"
                              )}
                            </p>
                          </div>

                          <div className="col-6 col-xxl-3 text-center">
                            <p className="text-500-black-16 m-0">Nome</p>
                            <p className="text-500-darkest-14 text-break mb-2">
                              {ingresso.nome}
                            </p>
                          </div>
                          <div className="col-6 col-xxl-3 text-center">
                            <p className="text-500-black-16 m-0">
                              Valor unitário
                            </p>
                            <p className="text-500-darkest-14 text-break mb-2">
                              {FormatarCentavosParaReais(
                                ingresso.valorCentavos
                              )}
                            </p>
                          </div>

                          <div className="col-6 col-xxl-3 text-center">
                            <p className="text-500-black-16 m-0">
                              Emissões Totais
                            </p>
                            <p className="text-500-darkest-14 text-break mb-2">
                              {ingresso.emissoesTotais}
                            </p>
                          </div>

                          <div className="col-6 col-xxl-3 text-center">
                            <p className="text-500-black-16 m-0">
                              Emissões Cortesias
                            </p>
                            <p className="text-500-darkest-14 text-break mb-2">
                              {ingresso.emissoesCortesias}
                            </p>
                          </div>
                          <div className="col-6 col-xxl-3 text-center">
                            <p className="text-500-black-16 m-0">
                              Emissões Vendas
                            </p>
                            <p className="text-500-darkest-14 text-break mb-2">
                              {ingresso.emissoesVendas}
                            </p>
                          </div>

                          <div className="col-6 col-xxl-3 text-center">
                            <p className="text-500-black-16 m-0">
                              Codigos associados
                            </p>
                            <p className="text-500-darkest-14 text-break mb-2">
                              {ingresso.quantidadeCodigosAssociados}
                            </p>
                          </div>

                          <div className="col-6 col-xxl-3 text-center">
                            <p className="text-500-black-16 m-0">Status</p>
                            <p
                              className="text-500-darkest-14 text-break mb-2"
                              style={{
                                color:
                                  ingresso.situacao === IngressoSituacao.Ativo
                                    ? "#5BC241"
                                    : "red",
                              }}
                            >
                              {ingresso.situacao === IngressoSituacao.Ativo
                                ? "Venda ativada"
                                : "Venda desativada"}
                            </p>
                          </div>
                          <div className="col-6 col-xxl-3 text-center">
                            <p className="text-500-black-16 m-0">
                              Total vendas
                            </p>
                            <p className="text-500-darkest-14 text-break mb-2">
                              {FormatarCentavosParaReais(
                                ingresso.totalIngressosVendidosCentavos
                              )}
                            </p>
                          </div>

                          <div className="col-12 text-center">
                            <BotoesListagemIngresso
                              eventoId={eventoId}
                              grupoId={grupoId}
                              ingresso={ingresso}
                              abrirModalExcluirIngresso={
                                abrirModalExcluirIngresso
                              }
                              abrirModalCortesia={abrirModalCortesia}
                              editarIngresso={() =>
                                navigate(
                                  `/eventos/${eventoId}/ingressos/grupo/${grupoId}/editar-ingresso/${ingresso.id}`
                                )
                              }
                              updateSituacaoIngresso={updateSituacaoIngresso}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}

            {ingressos.length === 0 && (
              <div className="row g-2">
                <div className="col-lg-12">
                  <p className="text-500-black-18 m-0">Ingressos do grupo</p>
                </div>
                <div className="col-lg-12">
                  <DarkGrayCard message="Você ainda não tem ingressos por aqui!" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/*
        --------------------------------------------------------------------------
                                  Modal: Enviar cortesia                          
        --------------------------------------------------------------------------
      */}
      <EmptyModal isOpen={modalCortesia !== null} close={resetarModalCortesia}>
        <div className="row d-flex justify-content-center align-itens-center text-center">
          <EditModalIcon />

          <p className="title-h1 text-black mb-2">Enviar cortesia</p>
          <p className="text-400-black-16 text-break mb-4 w-75">
            Informe os e-mails abaixo separados por
            <span className="text-dark fw-bold fs-4"> ,</span> ou
            <span className="text-dark fw-bold fs-4"> ;</span> que deseja enviar
            a cortesia
          </p>

          <textarea
            autoFocus
            className="form-control backstage-input p-2 mb-4"
            placeholder="exemplo@email.com, exemplo2@email.com, ..."
            style={{ width: "90%", height: 100, resize: "none" }}
            value={emailsCortesia}
            onChange={({ target: { value } }) => setEmailsCortesia(value)}
          ></textarea>

          <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-sm-evenly align-items-center mb-3">
            <button
              className="bc-btn bc-btn-light px-3 py-2 h-100 mb-3 mb-sm-0"
              style={{ width: 175 }}
              onClick={resetarModalCortesia}
            >
              Cancelar
            </button>

            <button
              className="bc-btn bc-btn-primary px-3 py-2 h-100"
              style={{ width: 175 }}
              onClick={enviarIngressoCortesia}
            >
              Enviar cortesia
            </button>
          </div>
        </div>
      </EmptyModal>

      {/*
        --------------------------------------------------------------------------
                                  Modal: Excluir Ingresso                         
        --------------------------------------------------------------------------
      */}
      <PromptModal
        isOpen={modalExcluirIngresso !== null}
        close={() => setModalExcluirIngresso(null)}
        icon={<DangerModalIcon />}
        title="Excluir ingresso"
        subtitle={`Tem certeza que deseja excluir o ingresso "${ingressoExcluir.nome}” ? Após o cancelamento seu ingresso
        ficará inativo`}
        cancelText="Cancelar"
        cancelAction={() => setModalExcluirIngresso(null)}
        confirmText="Excluir"
        confirmAction={excluirIngresso}
        loading={loading}
      />
    </div>
  );
}
