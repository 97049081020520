import { useEffect, useState } from "react";
import { PedidoDetalheModel } from "../../../../Interfaces/Models";
import { ServiceResult } from "../../../../Interfaces";
import AxiosClient from "../../../../Services/AxiosClient";
import AxiosErrorHandler from "../../../../Services/AxiosErrorHandler";
import toast from "react-hot-toast";
import Loading from "../../../../Components/Loading";
import {
  AplicarMascaraTelefone,
  FormatarCentavosParaReais,
  FormatarDataDDMMYYYY,
  FormatarDataHHMM,
  FormatarDataItemPedido,
  FormatarDistribuicaoTaxa,
  TipoPagamentoToString,
} from "../../../../Utils";
import TipoPagamento from "../../../../Enums/TipoPagamento";
import { IngressoIcon } from "../../../../Components/Icons";
import Button from "../../../../Components/Button";
import PagamentoStatus from "../../../../Enums/PagamentoStatus";
import { useNavigate } from "react-router-dom";

interface Props {
  eventoId: string;
  pedidoId: string;
}

export default function DetalhesPedido({ eventoId, pedidoId }: Props) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [pedido, setPedido] = useState<PedidoDetalheModel>(
    {} as PedidoDetalheModel,
  );

  const syncPedido = async (): Promise<void> => {
    setLoading(true);

    toast.promise(
      AxiosClient.patch<ServiceResult<PedidoDetalheModel>>(
        `/eventos/${eventoId}/pedidos/${pedidoId}/sync`,
      ).finally(() => setLoading(false)),
      {
        loading: "Sincronizando pedido...",
        success: ({ data: result }) => {
          if (result.data?.statusPagamento !== pedido.statusPagamento) {
            navigate(0);
          }

          return "Pedido atualizado!";
        },
        error: (error) => AxiosErrorHandler(error),
      },
    );
  };

  const getPedido = async (): Promise<void> => {
    setLoading(true);

    AxiosClient.get<ServiceResult<PedidoDetalheModel>>(
      `/eventos/${eventoId}/pedidos/${pedidoId}`,
    )
      .then(({ data: { data } }) => {
        setPedido(data as PedidoDetalheModel);
      })
      .catch((error) => {
        toast.error(AxiosErrorHandler(error));
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getPedido();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="card-cinza-claro p-3 mb-4" style={{ marginTop: "-12px" }}>
      <div className="row">
        <div className="col-xl-10">
          {loading && <Loading container="30vh" />}

          {!loading && (
            <div className="row g-4 h-100">
              <div className="col-lg-12">
                <div className="d-flex flex-wrap align-items-center">
                  <Button
                    type="button"
                    onClick={syncPedido}
                    text="Sincronizar"
                  />
                  <p className="text-400-black-16 m-2">
                    Você pode forçar a sincronização do pedido para que o status
                    seja atualizado em tempo real.
                  </p>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="bg-white shadow-sm p-3 d-flex flex-column justify-content-between">
                  <p className="text-500-black-18 m-0">
                    Quantidade de ingressos
                  </p>
                  <p className="text-number-card-home text-black m-0">
                    {pedido.quantidadeDeIngressos.toString().length < 2
                      ? "0" + pedido.quantidadeDeIngressos
                      : pedido.quantidadeDeIngressos}
                  </p>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="bg-white shadow-sm p-3 h-100 d-flex flex-column justify-content-between">
                  <p className="text-500-black-18 m-0">Valor total do pedido</p>
                  <p className="title-h1 text-black m-0">
                    {pedido.formaPagamento === TipoPagamento.Cortesia
                      ? "Cortesia"
                      : FormatarCentavosParaReais(pedido.valorTotalCentavos)}
                  </p>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="bg-white shadow-sm p-3 h-100">
                  <div className="row g-3">
                    {/* Usuário */}
                    <div className="col-lg-12">
                      <p className="text-500-black-18 m-0">Usuário</p>
                    </div>

                    <div className="col-lg-4">
                      <p className="text-500-dark-18 mb-2">Nome comprador</p>
                      <input
                        type="text"
                        disabled
                        value={pedido.usuarioNome}
                        className="form-control backstage-input"
                        style={{ height: 40 }}
                      />
                    </div>

                    <div className="col-lg-4">
                      <p className="text-500-dark-18 mb-2">Email</p>
                      <input
                        type="text"
                        disabled
                        value={pedido.usuarioEmail}
                        className="form-control backstage-input"
                        style={{ height: 40 }}
                      />
                    </div>

                    <div className="col-lg-4">
                      <p className="text-500-dark-18 mb-2">Telefone</p>
                      <input
                        type="text"
                        disabled
                        value={AplicarMascaraTelefone(pedido.usuarioTelefone)}
                        className="form-control backstage-input"
                        style={{ height: 40 }}
                      />
                    </div>

                    {/* Forma pagamento */}
                    <div className="col-lg-12 mt-4">
                      <p className="text-500-black-18 m-0">
                        Forma de pagamento
                      </p>
                    </div>

                    <div className="col-lg-4">
                      <p className="text-500-dark-18 mb-2">
                        Forma de pagamento
                      </p>
                      <input
                        type="text"
                        disabled
                        value={TipoPagamentoToString(pedido.formaPagamento)}
                        className="form-control backstage-input"
                        style={{ height: 40 }}
                      />
                    </div>

                    <div className="col-lg-4">
                      <p className="text-500-dark-18 mb-2">Data da compra</p>
                      <input
                        type="text"
                        disabled
                        value={
                          FormatarDataDDMMYYYY(pedido.criadoEm) +
                          " às " +
                          FormatarDataHHMM(pedido.criadoEm)
                        }
                        className="form-control backstage-input"
                        style={{ height: 40 }}
                      />
                    </div>

                    <div className="col-lg-4">
                      <p className="text-500-dark-18 mb-2">Valor total</p>
                      <input
                        type="text"
                        disabled
                        value={
                          pedido.formaPagamento === TipoPagamento.Cortesia
                            ? "Cortesia"
                            : FormatarCentavosParaReais(
                                pedido.valorTotalCentavos,
                              )
                        }
                        className="form-control backstage-input"
                        style={{ height: 40 }}
                      />
                    </div>

                    {/* Evento */}
                    <div className="col-lg-12 mt-4">
                      <p className="text-500-black-18 m-0">Evento</p>
                    </div>

                    <div className="col-lg-4">
                      <p className="text-500-dark-18 mb-2">Título do evento</p>
                      <input
                        type="text"
                        disabled
                        value={pedido.tituloEvento}
                        className="form-control backstage-input"
                        style={{ height: 40 }}
                      />
                    </div>

                    <div className="col-lg-4">
                      <p className="text-500-dark-18 mb-2">
                        Última atualização
                      </p>
                      <input
                        type="text"
                        disabled
                        value={
                          FormatarDataDDMMYYYY(pedido.ultimaAtualizacaoEm) +
                          " às " +
                          FormatarDataHHMM(pedido.ultimaAtualizacaoEm)
                        }
                        className="form-control backstage-input"
                        style={{ height: 40 }}
                      />
                    </div>

                    {/* Itens */}
                    <div className="col-lg-12 mt-4">
                      <p className="text-500-black-18 m-0">Itens</p>
                    </div>

                    {pedido.itens.length > 0 &&
                      pedido.itens.map((item) => (
                        <div className="col-lg-12" key={item.id}>
                          <div className="row g-3">
                            <div className="col-lg-12">
                              <div className="mb-4 mb-md-0 d-flex flex-column flex-sm-row align-items-center">
                                <div className="me-2 my-2">
                                  <IngressoIcon />
                                </div>
                                <p className="text-400-black-18 m-0 ps-2">
                                  {item.nomeGrupo + " - " + item.nomeIngresso}
                                </p>
                              </div>
                            </div>

                            {/* TODO: Alterar model para receber o nome do titular */}
                            <div className="col-lg-4">
                              <p className="text-500-dark-18 mb-2">
                                Nome do titular
                              </p>
                              <input
                                type="text"
                                disabled
                                value={item.titularNome ?? pedido.usuarioNome}
                                className="form-control backstage-input"
                                style={{ height: 40 }}
                              />
                            </div>

                            {item.codigoAcessoUtilizado && (
                              <div className="col-lg-4">
                                <p className="text-500-dark-18 mb-2">
                                  Código utilizado
                                </p>
                                <input
                                  type="text"
                                  disabled
                                  value={item.codigoAcessoUtilizado}
                                  className="form-control backstage-input"
                                  style={{ height: 40 }}
                                />
                              </div>
                            )}

                            <div className="col-lg-6 col-xl-3">
                              <p className="text-500-dark-18 mb-2">
                                Valor unitário
                              </p>
                              <input
                                type="text"
                                disabled
                                value={FormatarCentavosParaReais(
                                  item.valorUnitarioCentavosQuandoComprado,
                                )}
                                className="form-control backstage-input"
                                style={{ height: 40 }}
                              />
                            </div>

                            <div className="col-lg-6 col-xl-3">
                              <p className="text-500-dark-18 mb-2">
                                Valor da taxa
                              </p>
                              <input
                                type="text"
                                disabled
                                value={FormatarCentavosParaReais(
                                  item.taxaAdmnistracaoCentavos +
                                    item.taxaProcessamentoCentavos,
                                )}
                                className="form-control backstage-input"
                                style={{ height: 40 }}
                              />
                            </div>

                            <div className="col-lg-6 col-xl-3">
                              <p className="text-500-dark-18 mb-2">
                                Taxa de pagamento
                              </p>
                              <input
                                type="text"
                                disabled
                                value={FormatarDistribuicaoTaxa(
                                  item.ingressoDistribuicaoTaxa,
                                )}
                                className="form-control backstage-input"
                                style={{ height: 40 }}
                              />
                            </div>

                            <div className="col-lg-4 mb-3">
                              <p className="text-500-dark-18 mb-2">Data</p>
                              <input
                                type="text"
                                disabled
                                value={FormatarDataItemPedido(item.criadoEm)}
                                className="form-control backstage-input"
                                style={{ height: 40 }}
                              />
                            </div>
                          </div>
                          <hr />
                        </div>
                      ))}

                    {pedido.itens.length === 0 &&
                      pedido.statusPagamento !== PagamentoStatus.Recusado && (
                        <div className="col-lg-12 mt-2">
                          <p className="text-400-black-18 mb-2">
                            Nenhum item encontrado
                          </p>
                        </div>
                      )}

                    {pedido.itens.length === 0 &&
                      pedido.statusPagamento === PagamentoStatus.Recusado && (
                        <div className="col-lg-12 mt-2">
                          <p className="text-400-black-18 mb-2">
                            Nenhum item a ser exibido pois o pagamento foi
                            recusado
                          </p>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
