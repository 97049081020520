import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { TicketIcon } from "../../../../../Components/Icons";
import { BilheteriaClienteModel } from "../../../../../Interfaces/Models";
import AxiosClient from "../../../../../Services/AxiosClient";
import AxiosErrorHandler from "../../../../../Services/AxiosErrorHandler";
import {
  FormatarCentavosParaReais,
  PagamentoStatusToString,
  TipoPagamentoToString,
} from "../../../../../Utils";
import { FiPrinter } from "react-icons/fi";

interface Props {
  clientes: BilheteriaClienteModel[];
}

export default function ListagemClientes({ clientes }: Props) {
  const { eventoId } = useParams();

  const navigate = useNavigate();

  const [enviandoIngresso, setEnviandoIngresso] = useState<boolean>(false);

  const enviarIngresso = async (clientId: string): Promise<void> => {
    setEnviandoIngresso(true);

    AxiosClient.post(
      `/eventos/${eventoId}/bilheteria/clientes/${clientId}/enviar-ingresso`,
    )
      .then(() => toast.success("Ingresso enviado com sucesso!"))
      .catch((err) => toast.error(AxiosErrorHandler(err)))
      .finally(() => setEnviandoIngresso(false));
  };

  return (
    <div className="row">
      <div className="d-none d-lg-block col-lg-12 mb-3">
        <div className="row">
          <div className="col">
            <p className="text-500-black-16 m-0">Nome</p>
          </div>
          <div className="col">
            <p className="text-500-black-16 m-0">Ingresso</p>
          </div>
          <div className="col">
            <p className="text-500-black-16 m-0">Quantidade</p>
          </div>
          <div className="col">
            <p className="text-500-black-16 m-0">Pagamento</p>
          </div>
          <div className="col">
            <p className="text-500-black-16 m-0">Valor</p>
          </div>
          <div className="col">
            <p className="text-500-black-16 m-0">Status</p>
          </div>
          <div className="col">
            <p className="text-500-black-16 m-0">Ações</p>
          </div>
        </div>
      </div>

      {clientes.map((c) => (
        <div className="col-lg-12" key={c.id}>
          <div className="card-cinza-claro p-3 mb-3">
            <div className="row">
              <div className="align-self-center col">
                <p className="d-block d-lg-none text-500-black-16 m-0 mt-3">
                  Nome
                </p>
                <p className="text-500-darkest-14 text-break m-0">
                  {c.nomeCompleto}
                </p>
              </div>

              <div className="align-self-center col">
                <p className="d-block d-lg-none text-500-black-16 m-0 mt-3">
                  Ingresso
                </p>
                <p className="text-500-darkest-14 text-break m-0">
                  {c.ingressoNome}
                </p>
              </div>
              <div className="align-self-center col">
                <p className="d-block d-lg-none text-500-black-16 m-0 mt-3">
                  Quantidade
                </p>
                <p className="text-500-darkest-14 text-break m-0">
                  {c.quantidade}
                </p>
              </div>
              <div className="align-self-center col">
                <p className="d-block d-lg-none text-500-black-16 m-0 mt-3">
                  Pagamento
                </p>
                <p className="text-500-darkest-14 text-break m-0">
                  {TipoPagamentoToString(c.tipoPagamento)}
                </p>
              </div>
              <div className="align-self-center col">
                <p className="d-block d-lg-none text-500-black-16 m-0 mt-3">
                  Valor
                </p>
                <p className="text-500-darkest-14 text-break m-0">
                  {FormatarCentavosParaReais(c.totalCentavos)}
                </p>
              </div>
              <div className="align-self-center col">
                <p className="d-block d-lg-none text-500-black-16 m-0 mt-3">
                  Status
                </p>
                <p className="text-500-darkest-14 text-break m-0">
                  {PagamentoStatusToString(c.pagamentoStatus)}
                </p>
              </div>
              <div className="align-self-center col">
                <p className="d-block d-lg-none text-500-black-16 m-0 mt-3">
                  Ação
                </p>
                <p className="text-500-darkest-14 text-break m-0 d-flex align-items-center">
                  <button
                    onClick={() => enviarIngresso(c.id)}
                    title="Enviar ingresso"
                    style={{ height: 24, width: 24 }}
                    className="border-0 bg-transparent px-lg-3"
                    disabled={enviandoIngresso}
                  >
                    <TicketIcon />
                  </button>
                  <button
                    onClick={() => navigate(`/eventos/${eventoId}/bilheteria/clientes/${c.pedidoId}`)}
                    title="Imprimir ingresso"
                    style={{ height: 24, width: 24 }}
                    className="border-0 bg-transparent px-lg-3 ms-2"
                  >
                    <FiPrinter style={{ height: 20, width: 20, color: "#003967" }} />
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
