import toast from "react-hot-toast";
import Button from "../../../../Components/Button";
import { DeleteIcon, EditIcon, LinkIcon } from "../../../../Components/Icons";
import { CodigoAcessoModel } from "../../../../Interfaces/Models";
import CodigoAcessoStatus from "../../../../Enums/CodigoAcessoStatus";
import { useState } from "react";
import AxiosClient from "../../../../Services/AxiosClient";
import AxiosErrorHandler from "../../../../Services/AxiosErrorHandler";
import { WhiteLabel } from "../../../../Utils";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

interface Props {
  codigo: CodigoAcessoModel;
  eventoId: string;
  updateStatusCodigo: (codigo: CodigoAcessoModel) => void;
  abrirModalExcluirCodigo: (codigo: CodigoAcessoModel) => void;
}

export default function BotoesListagemCodigo({
  codigo,
  eventoId,
  updateStatusCodigo,
  abrirModalExcluirCodigo,
}: Props) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const toggleStatusCodigo = async () => {
    setLoading(true);

    toast.promise(
      AxiosClient.put(`/codigos/${codigo.id}/status`, {
        status:
          codigo.status === CodigoAcessoStatus.Ativo
            ? CodigoAcessoStatus.Inativo
            : CodigoAcessoStatus.Ativo,
      }).finally(() => setLoading(false)),
      {
        loading: "Alterando status...",
        success: () => {
          updateStatusCodigo(codigo);
          return "Status alterado com sucesso!";
        },
        error: (error) => AxiosErrorHandler(error),
      },
    );
  };

  const copiarLink = () => {
    navigator.clipboard.writeText(
      codigo.urlEvento || `${WhiteLabel().frontstageUrl}/evento/${eventoId}?codigo=${codigo.codigo}`
    );

    toast.success(`Link para o código "${codigo.codigo}" copiado!`);
  };

  return (
    <div className="d-flex flex-wrap justify-content-center justify-content-md-evenly align-items-center">
      <Button
        className="m-2 px-3 rounded-pill w-100 h-100 fs-6"
        variant="light"
        text="Copiar link"
        height={24}
        width={150}
        icon={<LinkIcon />}
        onClick={copiarLink}
      />

      <Button
        className="m-2 px-3 rounded-pill w-100 h-100"
        variant="light"
        text="Editar"
        height={24}
        width={150}
        icon={<EditIcon />}
        onClick={() =>
          navigate(
            `/eventos/${eventoId}/codigo-promocional/editar-codigo/${codigo.id}`,
          )
        }
      />

      <Button
        className="m-2 px-3 rounded-pill w-100 h-100"
        variant="light"
        text="Excluir"
        height={24}
        width={150}
        icon={<DeleteIcon />}
        onClick={() => abrirModalExcluirCodigo(codigo)}
      />

      <div className="w-100" style={{ maxWidth: 150 }}>
        <div className="form-check form-switch d-flex align-items-center ps-5">
          <input
            className="form-check-input mb-1 cursor-pointer"
            style={{ width: "3.3em", height: "1.7em" }}
            type="checkbox"
            role="switch"
            id={`cod_status_${codigo.id}`}
            checked={codigo.status === CodigoAcessoStatus.Ativo}
            onChange={toggleStatusCodigo}
            disabled={loading}
          />
          <label
            className={clsx(
              "form-check-label px-2 cursor-pointer",
              codigo.status === CodigoAcessoStatus.Ativo && "text-500-black-16",
              (codigo.status === CodigoAcessoStatus.Inativo || loading) &&
              "text-400-darkest-16",
            )}
            htmlFor={`cod_status_${codigo.id}`}
          >
            {loading && "Alterando..."}

            {!loading && codigo.status === CodigoAcessoStatus.Ativo && "Ativo"}

            {!loading &&
              codigo.status === CodigoAcessoStatus.Inativo &&
              "Desativado"}
          </label>
        </div>
      </div>
    </div>
  );
}
