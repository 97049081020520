interface Props {
  children: React.ReactNode;
  rowMarginBottom?: 3 | 4;
  containerPadding?: 3 | 4;
  containerWidth?: 4 | 6 | 8 | 12;
  containerBreakpoint?: "sm" | "md" | "lg" | "xl" | "xxl";
}

export default function WhiteContainer({
  rowMarginBottom = 3,
  containerPadding = 3,
  containerWidth = 8,
  containerBreakpoint = "xxl",
  children,
}: Props): JSX.Element {
  return (
    <div className={`row mb-${rowMarginBottom}`}>
      <div className={`col-${containerBreakpoint}-${containerWidth}`}>
        <div className={`bg-white shadow-sm p-${containerPadding}`}>
          {children}
        </div>
      </div>
    </div>
  );
}
