import BotoesListagemParticipantes from "./BotoesListagemParticipantes";
import { ParticipanteModel } from "../../../../Interfaces/Models";

interface Props {
  participantes: ParticipanteModel[];
  abrirModalEnviarMensagem: (participante: ParticipanteModel) => void;
}

export default function ListagemParticipantes({
  participantes,
  abrirModalEnviarMensagem,
}: Props) {
  return (
    <div className="row">
      <div className="d-none d-lg-block col-lg-12 mb-3">
        <div className="row">
          <div className="col-lg-3">
            <p className="text-500-black-16 m-0">Nome</p>
          </div>
          <div className="col-lg-3">
            <p className="text-500-black-16 m-0">E-mail</p>
          </div>
          <div className="col-lg-3">
            <p className="text-500-black-16 m-0">Ingresso</p>
          </div>
        </div>
      </div>

      {participantes.map((participante) => (
        <div className="col-lg-12" key={participante.id}>
          <div className="card-cinza-claro p-3 mb-3">
            <div className="row">
              <div className="align-self-center col-lg-3">
                <p className="d-block d-lg-none text-500-black-16 m-0 mt-3">
                  Nome
                </p>
                <p className="text-500-darkest-14 text-break m-0">
                  {participante.nomeCompleto}
                </p>
              </div>

              <div className="align-self-center col-lg-3">
                <p className="d-block d-lg-none text-500-black-16 m-0 mt-3">
                  Email
                </p>
                <p className="text-500-darkest-14 text-break m-0">
                  {participante.email}
                </p>
              </div>
              <div className="align-self-center col-lg-3">
                <p className="d-block d-lg-none text-500-black-16 m-0 mt-3">
                  Ingresso
                </p>
                <p className="text-500-darkest-14 text-break m-0">
                  {participante.ingressoNome}
                </p>
              </div>

              <div className="align-self-center col-lg-3">
                <BotoesListagemParticipantes
                  participante={participante}
                  abrirModalEnviarMensagem={abrirModalEnviarMensagem}
                />
              </div>
            </div>
          </div>
        </div>
      ))}

      {/* 
      <div className="d-flex justify-content-center mt-3">
        <button
          type="button"
          onClick={() => setPagina((prev) => prev + 1)}
          className={clsx(
            "bc-btn bc-btn-primary px-3 py-2 mx-auto",
            !possuiMaisParticipantes && "disabled"
          )}
          disabled={!possuiMaisParticipantes}
        >
          Carregar mais
        </button>
      </div>
      */}
    </div>
  );
}
