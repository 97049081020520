export default function HomeIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.58904 19.5058V14.2341H13.8064V19.5058C13.8064 20.0857 14.2809 20.5602 14.8608 20.5602H18.0238C18.6037 20.5602 19.0782 20.0857 19.0782 19.5058V12.1254H20.8706C21.3556 12.1254 21.5875 11.5244 21.2185 11.2081L12.4041 3.26886C12.0035 2.91038 11.392 2.91038 10.9913 3.26886L2.17697 11.2081C1.8185 11.5244 2.03991 12.1254 2.52491 12.1254H4.3173V19.5058C4.3173 20.0857 4.79176 20.5602 5.37165 20.5602H8.53469C9.11458 20.5602 9.58904 20.0857 9.58904 19.5058Z"
        stroke={window.location.pathname === "/" ? "#fff" : "#9d9d9d"}
        strokeWidth="2"
      />
    </svg>
  );
}
