import { useState } from "react";
import WhiteContainer from "../../../../Components/WhiteContainer";
import { EstatisticasEventoModel } from "../../../../Interfaces/Models";
import toast from "react-hot-toast";
import AxiosClient from "../../../../Services/AxiosClient";
import { useParams } from "react-router-dom";
import moment from "moment";
import AxiosErrorHandler from "../../../../Services/AxiosErrorHandler";
import { FormatarCentavosParaReais } from "../../../../Utils";

interface Props {
  estatisticas: EstatisticasEventoModel;
  loading: boolean;
}

export const EstatisticasPontosVenda = ({ estatisticas, loading }: Props) => {
  const { eventoId } = useParams();

  const [exportingCsv, setExportingCsv] = useState<boolean>(false);

  const exportarCsvPOS = async (): Promise<void> => {
    setExportingCsv(true);

    toast.promise(
      AxiosClient.get(`/eventos/${eventoId}/bilheteria/estatisticas/csv`, {
        responseType: "blob",
      })
        .then(({ data }) => {
          const blob = new Blob([data], {
            type: "text/csv",
          });

          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `estatisticas-pos-${moment().format(
            "DD-MM-YYYY"
          )}.csv`;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .finally(() => setExportingCsv(false)),
      {
        loading: "Exportando CSV...",
        success: "CSV exportado com sucesso!",
        error: (error) => AxiosErrorHandler(error),
      }
    );
  };

  return (
    <>
      <WhiteContainer containerPadding={4} containerWidth={12}>
        <div className="d-flex flex-column flex-xl-row justify-content-xl-between align-items-center">
          <p className="title-h1 text-black text-center mb-2">
            Estatísticas Point of Sales
          </p>

          <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
            <button
              type="button"
              onClick={exportarCsvPOS}
              className={`bc-btn bc-btn-primary px-3 py-2 w-00 h-00 ${
                !estatisticas || loading ? "disabled" : ""
              }`}
              disabled={(!loading && !estatisticas) || loading || exportingCsv}
            >
              Exportar dados
            </button>
          </div>
        </div>
      </WhiteContainer>

      <div className="row g-3 mb-3">
        <div className="col-lg-3">
          <div className="shadow-sm bg-white p-3 h-100">
            <p className="text-500-black-18 m-0">Vendas</p>
            <p className="text-number-card-home m-0 text-break text-black">
              {estatisticas.vendasPOS}
            </p>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="shadow-sm bg-white p-3 h-100">
            <p className="text-500-black-18 m-0">Ingressos emitidos</p>
            <p className="text-number-card-home m-0 text-break text-black">
              {estatisticas.ingressosCompradosPOSEmitidos}
            </p>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="shadow-sm bg-white p-3 h-100">
            <p className="text-500-black-18 m-0">Receita total</p>
            <p className="text-number-card-home m-0 text-break text-black">
              {FormatarCentavosParaReais(estatisticas.receitaPOSCentavos)}
            </p>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="shadow-sm bg-white p-3 h-100">
            <p className="text-500-black-18 m-0">Receita PIX</p>
            <p className="text-number-card-home m-0 text-break text-black">
              {FormatarCentavosParaReais(estatisticas.receitaPOSPixCentavos)}
            </p>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="shadow-sm bg-white p-3 h-100">
            <p className="text-500-black-18 m-0">Receita Débido</p>
            <p className="text-number-card-home m-0 text-break text-black">
              {FormatarCentavosParaReais(
                estatisticas.receitaPOSCartaoDebitoCentavos
              )}
            </p>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="shadow-sm bg-white p-3 h-100">
            <p className="text-500-black-18 m-0">Receita Crédito</p>
            <p className="text-number-card-home m-0 text-break text-black">
              {FormatarCentavosParaReais(
                estatisticas.receitaPOSCartaoCreditoCentavos
              )}
            </p>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="shadow-sm bg-white p-3 h-100">
            <p className="text-500-black-18 m-0">Receita Dinheiro</p>
            <p className="text-number-card-home m-0 text-break text-black">
              {FormatarCentavosParaReais(
                estatisticas.receitaPOSDinheiroCentavos
              )}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
