export default function ErroIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_951_1709)">
        <path
          d="M16.0001 2.66675C8.62675 2.66675 2.66675 8.62675 2.66675 16.0001C2.66675 23.3734 8.62675 29.3334 16.0001 29.3334C23.3734 29.3334 29.3334 23.3734 29.3334 16.0001C29.3334 8.62675 23.3734 2.66675 16.0001 2.66675ZM22.6667 20.7867L20.7867 22.6667L16.0001 17.8801L11.2134 22.6667L9.33342 20.7867L14.1201 16.0001L9.33342 11.2134L11.2134 9.33342L16.0001 14.1201L20.7867 9.33342L22.6667 11.2134L17.8801 16.0001L22.6667 20.7867Z"
          fill="#9D9D9D"
        />
      </g>
      <defs>
        <clipPath id="clip0_951_1709">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
