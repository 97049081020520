import { useNavigate, useParams } from "react-router-dom";
import MainLayout from "../../../../Components/Layouts/Main";
import useObterNomeEventoHeader from "../../../../Hooks/useObterNomeEventoHeader";
import { useEffect, useState } from "react";
import BreadCrumb, { Page } from "../../../../Components/Breadcrumb";
import WhiteContainer from "../../../../Components/WhiteContainer";
import Loading from "../../../../Components/Loading";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
} from "chart.js";
import { Doughnut, Line, Bar } from "react-chartjs-2";
import { PortariaEventosEstatisticaModel } from "../../../../Interfaces/Models/PortariaEventosEstatisticaModel";
import AxiosClient from "../../../../Services/AxiosClient";
import { ServiceResult } from "../../../../Interfaces";
import toast from "react-hot-toast";
import AxiosErrorHandler from "../../../../Services/AxiosErrorHandler";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarElement
);

export default function EstatisticasPortaria() {
  const { eventoId } = useParams();

  const { obterNomeEvento } = useObterNomeEventoHeader();

  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [exportingCsv, setExportingCsv] = useState<boolean>(false);
  const [estatisticas, setEstatisticas] =
    useState<PortariaEventosEstatisticaModel | null>(null);

  const obterEstatisticas = async (): Promise<void> => {
    setLoading(true);

    AxiosClient.get<ServiceResult<PortariaEventosEstatisticaModel>>(
      `/eventos/${eventoId}/portaria/estatisticas`
    )
      .then(({ data: { data } }) => {
        const estatisticas = data as PortariaEventosEstatisticaModel;
        setEstatisticas(estatisticas);
      })
      .catch((error) => {
        toast.error(AxiosErrorHandler(error));
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const interval = setInterval(obterEstatisticas, 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, [eventoId]);

  const handleRecarregarEstatisticas = () => {
    obterEstatisticas();
  };

  useEffect(() => {
    obterEstatisticas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* (INÍCIO) GRÁFICO ENTRADAS */

  const labels = Object.keys(
    estatisticas?.entradasPorFaixaDeTempo.minutos || {}
  );

  const data = {
    labels,
    datasets: [
      {
        label: "Entradas",
        data: labels.map(
          (data) => estatisticas?.entradasPorFaixaDeTempo.entradas[data]
        ),
        borderColor: "#2b1479",
        backgroundColor: "#2b1479",
      },
    ],
  };
  /* FIM GRÁFICO ENTRDAS */

  /* INÍCIO GRÁFICO GÊNEROS */
  const dataGeneros = {
    labels: ["Masculino", "Feminino", "Não Definido"],
    datasets: [
      {
        label: "# of Votes",
        data:
          estatisticas?.entradasPorGenero.masculino &&
          estatisticas.entradasPorGenero.feminino &&
          estatisticas.entradasPorGenero.naoDefinido
            ? [
                estatisticas?.entradasPorGenero.masculino,
                estatisticas?.entradasPorGenero.feminino,
                estatisticas?.entradasPorGenero.naoDefinido,
              ]
            : [0, 0, 0],
        backgroundColor: ["#007bff", "#dc3545", "#ffc107"],
        borderColor: ["#007bff", "#dc3545", "#ffc107"],
        borderWidth: 1,
      },
    ],
  };
  /* FIM GRÁFICO GÊNEROS */

  /* INÍCIO GRÁFICO IDADE */
  const labelsIdade = Object.keys(estatisticas?.entradasPorIdade || {}).map(
    Number
  );

  const dataIdade = {
    labels: labelsIdade,
    datasets: [
      {
        label: "Idades",
        data: labelsIdade.map((idade) => estatisticas?.entradasPorIdade[idade]),
        backgroundColor: "#2b1479",
      },
    ],
  };
  /* FIM GRÁFICO IDADE */

  const exportarCsv = async (): Promise<void> => {
    setExportingCsv(true);

    toast.promise(
      AxiosClient.get(`/eventos/${eventoId}/portaria/estatisticas/csv`, {
        responseType: "blob",
      })
        .then(({ data }) => {
          const blob = new Blob([data], {
            type: "text/csv",
          });

          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `estatisticas-portaria${moment().format(
            "DD-MM-YYYY"
          )}.csv`;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .finally(() => setExportingCsv(false)),
      {
        loading: "Exportando CSV...",
        success: "CSV exportado com sucesso!",
        error: (error) => AxiosErrorHandler(error),
      }
    );
  };

  useEffect(() => {
    obterNomeEvento();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const breadCrumbHistory: Page[] = [
    { link: "/", name: "Dashboard" },
    { link: "/eventos", name: "Eventos" },
    { link: `/eventos/${eventoId}/portaria`, name: "Portaria - Estatísticas" },
  ];

  return (
    <MainLayout>
      <div className="mb-3">
        <BreadCrumb history={breadCrumbHistory} />
      </div>

      <WhiteContainer containerPadding={4} containerWidth={12}>
        <div className="d-flex flex-column flex-xl-row justify-content-xl-between align-items-center">
          <p className="title-h1 text-black text-center mb-2">Portaria</p>

          <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
            <button
              className="bc-btn bc-btn-light px-3 py-2 mb-2 mb-md-0 me-md-3"
              onClick={handleRecarregarEstatisticas}
            >
              Atualizar
            </button>
            <button
              type="button"
              className="bc-btn bc-btn-light px-3 py-2 mb-2 mb-md-0 me-md-3"
            >
              Validar ingressos
            </button>
            <button
              type="button"
              className="bc-btn bc-btn-light px-3 py-2 mb-2 mb-md-0 me-md-3"
              onClick={() =>
                navigate(
                  `/eventos/${eventoId}/portaria/operadores?menuPortaria=open`
                )
              }
            >
              Adicionar operador de porta
            </button>

            <button
              type="button"
              onClick={exportarCsv}
              className={`bc-btn bc-btn-primary px-3 py-2 w-00 h-00 ${
                !estatisticas || loading ? "disabled" : ""
              }`}
              disabled={(!loading && !estatisticas) || loading || exportingCsv}
            >
              Exportar CSV
            </button>
          </div>
        </div>
      </WhiteContainer>

      {loading && <Loading container="50vh" />}

      {!loading && estatisticas && (
        <>
          <div className="row g-3 mb-3">
            <div className="col-lg-4">
              <div className="shadow-sm bg-white p-3 h-100">
                <p className="text-500-black-18 m-0">Ingressos Ativos</p>
                <p className="text-number-card-home m-0 text-break text-black">
                  {estatisticas.ingressosAtivos}
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="shadow-sm bg-white p-3 h-100">
                <p className="text-500-black-18 m-0">Ingressos Pendentes</p>
                <p className="text-number-card-home m-0 text-break text-black">
                  {estatisticas.ingressosPendentes}
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="shadow-sm bg-white p-3 h-100">
                <p className="text-500-black-18 m-0">Insgressos Totais</p>
                <p className="text-number-card-home m-0 text-break text-black">
                  {estatisticas.ingressosTotais
                    ? estatisticas.ingressosTotais
                    : "0"}
                </p>
              </div>
            </div>
          </div>

          <div className="row g-3 mb-4">
            <div className="col-xl-8">
              <div className="shadow-sm bg-white p-3 h-100">
                <p className="text-500-black-18 m-0">Entradas a cada 30 min</p>
                <Line data={data} />
              </div>
            </div>

            <div className="col-xl-4">
              <div className="shadow-sm bg-white p-3 h-100">
                <p className="text-500-black-18 m-0">Entradas de Gêneros</p>
                {estatisticas.entradasPorGenero.masculino &&
                estatisticas.entradasPorGenero.feminino &&
                estatisticas.entradasPorGenero.naoDefinido ? (
                  <Doughnut data={dataGeneros} className="mt-3" />
                ) : (
                  <p className="text-400-black-18">Sem dados</p>
                )}
              </div>
            </div>

            <div className="col-xl-12">
              <div className="shadow-sm bg-white p-3 h-100">
                <p className="text-500-black-18 m-0">Idades por evento</p>
                <Bar data={dataIdade} />
              </div>
            </div>
          </div>
        </>
      )}
    </MainLayout>
  );
}
