import { useState } from "react";
import ListServiceResult from "../Interfaces/ListServiceResult";
import SessaoModel from "../Interfaces/Models/SessaoModel";
import AxiosClient from "../Services/AxiosClient";
import AxiosErrorHandler from "../Services/AxiosErrorHandler";

export default function useObterSessoesGrupo() {
  const [loadingSessoes, setLoadingSessoes] = useState<boolean>(false);

  const [acesseNegadoSessoes, setAcesseNegadoSessoes] =
    useState<boolean>(false);

  const getSessoes = async (
    eventoId: string
  ): Promise<SessaoModel[] | null> => {
    setLoadingSessoes(true);

    return AxiosClient.get<ListServiceResult<SessaoModel>>(
      `/eventos/${eventoId}/sessoes?paginaTamanho=100`
    )
      .then(({ data: { data } }) => {
        return data as SessaoModel[];
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          setAcesseNegadoSessoes(true);
        }
        AxiosErrorHandler(error);
        return null;
      })
      .finally(() => setLoadingSessoes(false));
  };

  return { loadingSessoes, getSessoes, acesseNegadoSessoes };
}
