export default function AplicarMascaraTelefone(telefone: string) {
  // 11987654321 -> (11) 98765-4321
  return (
    "(" +
    telefone.slice(0, 2) +
    ") " +
    telefone.slice(2, 7) +
    "-" +
    telefone.slice(7, 11)
  );
}
