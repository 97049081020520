export default function ParticipantesIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 3.75C7.06074 3.75 5.08333 5.72741 5.08333 8.16667C5.08333 10.6059 7.06074 12.5833 9.5 12.5833C11.9393 12.5833 13.9167 10.6059 13.9167 8.16667C13.9167 5.72741 11.9393 3.75 9.5 3.75ZM15.602 3.89219C15.0224 3.74378 14.4323 4.09335 14.2839 4.67296C14.1354 5.25257 14.485 5.84274 15.0646 5.99115C15.5486 6.11507 15.9776 6.39654 16.2839 6.7912C16.5903 7.18585 16.7565 7.67124 16.7565 8.17084C16.7565 8.67043 16.5903 9.15582 16.2839 9.55047C15.9776 9.94513 15.5486 10.2266 15.0646 10.3505C14.485 10.4989 14.1354 11.0891 14.2839 11.6687C14.4323 12.2483 15.0224 12.5979 15.602 12.4495C16.5521 12.2062 17.3941 11.6537 17.9955 10.879C18.5968 10.1043 18.9232 9.15152 18.9232 8.17084C18.9232 7.19015 18.5968 6.23735 17.9955 5.46266C17.3941 4.68796 16.5521 4.13544 15.602 3.89219ZM7.25 8.16667C7.25 6.92403 8.25736 5.91667 9.5 5.91667C10.7426 5.91667 11.75 6.92403 11.75 8.16667C11.75 9.40931 10.7426 10.4167 9.5 10.4167C8.25736 10.4167 7.25 9.40931 7.25 8.16667ZM6.16667 13.75C4.99529 13.75 3.8719 14.2153 3.04361 15.0436C2.21533 15.8719 1.75 16.9953 1.75 18.1667V19.8333C1.75 20.4316 2.23502 20.9167 2.83333 20.9167C3.43164 20.9167 3.91667 20.4316 3.91667 19.8333V18.1667C3.91667 17.5699 4.15372 16.9976 4.57568 16.5757C4.99763 16.1537 5.56993 15.9167 6.16667 15.9167H12.8333C13.4301 15.9167 14.0024 16.1537 14.4243 16.5757C14.8463 16.9976 15.0833 17.5699 15.0833 18.1667V19.8333C15.0833 20.4316 15.5684 20.9167 16.1667 20.9167C16.765 20.9167 17.25 20.4316 17.25 19.8333V18.1667C17.25 16.9953 16.7847 15.8719 15.9564 15.0436C15.1281 14.2153 14.0047 13.75 12.8333 13.75H6.16667ZM18.9375 13.8927C18.3582 13.7432 17.7673 14.0915 17.6177 14.6708C17.4682 15.2501 17.8165 15.841 18.3958 15.9906C18.8785 16.1152 19.3062 16.3966 19.6116 16.7907C19.917 17.1846 20.0829 17.669 20.0833 18.1675L20.0833 19.8333C20.0833 20.4316 20.5684 20.9167 21.1667 20.9167C21.765 20.9167 22.25 20.4316 22.25 19.8333V18.1659C22.2493 17.1873 21.9236 16.2366 21.324 15.4632C20.7245 14.6898 19.885 14.1374 18.9375 13.8927Z"
        fill={
          window.location.pathname.includes("/participantes")
            ? "#fff"
            : "#9d9d9d"
        }
      />
    </svg>
  );
}
