export default function DangerModalIcon() {
  return (
    <svg
      width="192"
      height="192"
      viewBox="0 0 192 192"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="96.3182"
        cy="96.3182"
        rx="61.8182"
        ry="61.8182"
        fill="#FF0000"
      />
      <path
        d="M76.3064 117.535H115.097C119.064 117.535 121.537 113.233 119.553 109.807L100.158 76.2967C98.1746 72.8709 93.2291 72.8709 91.2458 76.2967L71.8504 109.807C69.867 113.233 72.3397 117.535 76.3064 117.535ZM95.7019 99.5043C94.2852 99.5043 93.1261 98.3452 93.1261 96.9285V91.777C93.1261 90.3603 94.2852 89.2012 95.7019 89.2012C97.1185 89.2012 98.2776 90.3603 98.2776 91.777V96.9285C98.2776 98.3452 97.1185 99.5043 95.7019 99.5043ZM98.2776 109.807H93.1261V104.656H98.2776V109.807Z"
        fill="white"
      />
    </svg>
  );
}
