import CleanLayout from "../../Components/Layouts/Clean";
import LinkVoltarParaLogin from "../../Components/LinkVoltarParaLogin";
import FormRedefinirSenha from "./Components/FormRedefinirSenha";

export default function RedefinirSenha() {
  return (
    <CleanLayout>
      <LinkVoltarParaLogin />

      <p className="title-h1 text-black">Crie sua nova senha</p>

      <p className="text-400-black-18 mb-5">
        Informe abaixo sua nova senha de acesso
      </p>

      <FormRedefinirSenha />
    </CleanLayout>
  );
}
