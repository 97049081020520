export default function ClockIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.99 1.99997C6.47 1.99997 2 6.47997 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.47997 17.52 1.99997 11.99 1.99997ZM12.0002 19.9999C7.58016 19.9999 4.00016 16.4199 4.00016 11.9999C4.00016 7.57991 7.58016 3.99991 12.0002 3.99991C16.4202 3.99991 20.0002 7.57991 20.0002 11.9999C20.0002 16.4199 16.4202 19.9999 12.0002 19.9999ZM11.78 6.99997H11.72C11.32 6.99997 11 7.31997 11 7.71997V12.44C11 12.79 11.18 13.12 11.49 13.3L15.64 15.79C15.98 15.99 16.42 15.89 16.62 15.55C16.7215 15.3864 16.7522 15.1886 16.705 15.0019C16.6579 14.8153 16.537 14.6558 16.37 14.56L12.5 12.26V7.71997C12.5 7.31997 12.18 6.99997 11.78 6.99997Z"
        fill="black"
      />
    </svg>
  );
}
