import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import BreadCrumb, { Page } from "../../../Components/Breadcrumb";
import MainLayout from "../../../Components/Layouts/Main";
import WhiteContainer from "../../../Components/WhiteContainer";
import AxiosClient from "../../../Services/AxiosClient";
import toast from "react-hot-toast";
import AxiosErrorHandler from "../../../Services/AxiosErrorHandler";
import { useNavigate, useParams } from "react-router-dom";
import FormButtons from "../../../Components/Form/FormButtons";
import { ServiceResult } from "../../../Interfaces";
import PerfilBSModel from "../../../Interfaces/Models/PerfilBSModel";
import RoleBSModel from "../../../Interfaces/Models/RoleBSModel";

interface PerfilPutArgs {
  nome: string;
  descricao: string;
  roles: { [key: string]: boolean };
}

export default function EditarPerfil() {
  const { perfilId } = useParams();

  const breadCrumbHistory: Page[] = [
    {
      link: "/",
      name: "dashboard",
    },
    {
      link: "/gestao-de-acesso",
      name: "Gestão de acesso",
    },
    {
      link: `/gestao-de-acesso/perfil/${perfilId}/editar`,
      name: "Editar Perfil",
    },
  ];

  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [roles, setRoles] = useState<RoleBSModel[]>([]);
  const [initialRolesState, setInitialRolesState] = useState<{
    [key: string]: boolean;
  }>({});

  const { handleSubmit, register, setValue } = useForm<PerfilPutArgs>({
    defaultValues: {
      nome: "",
      descricao: "",
      roles: {},
    },
  });

  const putPerfil = (data: PerfilPutArgs): void => {
    setLoading(true);

    const { nome, descricao, roles: rolesData } = data;

    // Filtrar IDs das roles que tiveram alteração nos checkboxes
    const rolesIdsToUpdate = roles
      .filter((role) => initialRolesState[role.id] !== rolesData[role.id])
      .map((role) => role.id);

    AxiosClient.put(`/Perfis/${perfilId}`, {
      nome,
      descricao,
      roles: rolesIdsToUpdate, // Enviar apenas os IDs das roles que foram modificadas após o GET
    })
      .then(() => {
        toast.success("Perfil atualizado com sucesso!");
        navigate("/gestao-de-acesso");
      })
      .catch((error: any) => toast.error(AxiosErrorHandler(error)))
      .finally(() => setLoading(false));
  };

  const getRolesPerfil = async (): Promise<void> => {
    setLoading(true);

    AxiosClient.get<ServiceResult<PerfilBSModel>>(`/Perfis/${perfilId}`)
      .then(({ data: result }) => {
        setRoles(result.data?.roles ?? []);

        setValue("nome", result.data?.nome ?? "");
        setValue("descricao", result.data?.descricao ?? "");

        if (result.data?.roles && result.data.roles.length > 0) {
          const initialRoles: { [key: string]: boolean } = {};

          result.data.roles.forEach((role) => {
            setValue(`roles.${role.id}`, role.hasAccess);
            initialRoles[role.id] = role.hasAccess;
          });

          setInitialRolesState(initialRoles);
        }
      })
      .catch((error: any) => toast.error(AxiosErrorHandler(error)))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getRolesPerfil();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout>
      <div className="mb-3">
        <BreadCrumb history={breadCrumbHistory} />
      </div>

      <WhiteContainer containerPadding={4} containerWidth={12}>
        <div className="d-flex flex-column flex-xl-row justify-content-xl-between align-items-center">
          <p className="title-h1 text-black text-center mb-2">Editar perfil</p>
        </div>
      </WhiteContainer>

      <WhiteContainer containerPadding={4} containerWidth={12}>
        {loading ? (
          <div className="text-center">Carregando...</div>
        ) : (
          <form onSubmit={handleSubmit(putPerfil)}>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="nome" className="form-label">
                    Nome:
                  </label>
                  <input
                    type="text"
                    className="form-control backstage-input p-2"
                    id="nome"
                    {...register("nome")}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="descricao" className="form-label">
                    Descrição:
                  </label>
                  <input
                    type="text"
                    className="form-control backstage-input p-2"
                    id="descricao"
                    {...register("descricao")}
                  />
                </div>
              </div>

              <div className="col-12 mb-3">
                <label htmlFor="roles" className="form-label">
                  Permissões:
                </label>

                <div className="row">
                  {roles
                    // distincty by categoria
                    .filter(
                      (role, index, self) =>
                        index ===
                        self.findIndex((t) => t.categoria === role.categoria)
                    )
                    // sort by categoria with more roles first
                    .sort(
                      (a, b) =>
                        roles.filter((r) => r.categoria === b.categoria)
                          .length -
                        roles.filter((r) => r.categoria === a.categoria).length
                    )
                    .map((role) => (
                      <div
                        className="col-md-6 col-lg-4 col-xxl-3"
                        key={role.id}
                      >
                        <p className="text-500-dark-18 fw-bold mb-1">
                          {role.categoria}:
                        </p>

                        <div className="mb-4 border border-2 rounded pt-2">
                          {roles
                            .sort((a, b) =>
                              a.descricao.localeCompare(b.descricao)
                            )
                            .filter((r) => r.categoria === role.categoria)
                            .map((r) => (
                              <div
                                className="form-check form-switch d-flex align-items-center ps-5 mb-2"
                                key={r.id}
                              >
                                <input
                                  className="form-check-input mb-1 cursor-pointer me-2"
                                  type="checkbox"
                                  style={{ width: "40px", height: "20px" }}
                                  id={r.id}
                                  {...register(`roles.${r.id}`)}
                                  defaultChecked={r.hasAccess}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={r.id}
                                >
                                  {r.descricao}
                                </label>
                              </div>
                            ))}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>

            <FormButtons
              disabled={loading}
              submitText="Salvar"
              cancelAction={() => navigate(`/gestao-de-acesso`)}
            />
          </form>
        )}
      </WhiteContainer>
    </MainLayout>
  );
}
