export default function PedidosIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9895 1.36157C11.6877 1.36157 11.3911 1.4396 11.1284 1.58809L7.41869 3.68488C7.40944 3.68992 7.40027 3.6951 7.39118 3.70043L3.2534 6.03917C2.98361 6.19167 2.75915 6.41307 2.60298 6.68074C2.4468 6.94841 2.3645 7.25275 2.3645 7.56266V16.4375C2.3645 16.7474 2.4468 17.0517 2.60297 17.3194C2.75915 17.5871 2.98361 17.8085 3.2534 17.9609L11.1284 22.412C11.3911 22.5605 11.6877 22.6385 11.9895 22.6385C12.2913 22.6385 12.5879 22.5605 12.8506 22.412L20.7256 17.9609C20.9954 17.8085 21.2199 17.5871 21.376 17.3194C21.5322 17.0517 21.6145 16.7474 21.6145 16.4375V7.56266C21.6145 7.25418 21.533 6.9512 21.3782 6.68443L21.3712 6.67259C21.2153 6.40857 20.9927 6.19012 20.7256 6.03917L12.8506 1.58809C12.5879 1.4396 12.2913 1.36157 11.9895 1.36157ZM7.90061 5.70985L5.40565 7.12005L12.0833 10.8514L14.5638 9.43302L7.90061 5.70985ZM15.5959 11.1467V13.923C15.5959 14.4752 16.0436 14.923 16.5959 14.923C17.1481 14.923 17.5959 14.4752 17.5959 13.923V10.0031L19.6145 8.84885V16.2916L13.0062 20.0267L13.0831 12.5836L15.5959 11.1467ZM16.5902 8.27428L9.93894 4.55776L11.9895 3.39874L18.5912 7.13012L16.5902 8.27428ZM4.3645 16.2916V8.82934L11.083 12.5834L11.0059 20.0454L4.3645 16.2916Z"
        fill={
          window.location.pathname.includes("/pedidos") ? "#fff" : "#9d9d9d"
        }
      />
    </svg>
  );
}
