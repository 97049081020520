import { AxiosError, isAxiosError } from "axios";
import ServiceResult from "../Interfaces/ServiceResult";

export default function AxiosErrorHandler(error: Error | AxiosError): string {
  if (
    isAxiosError(error) &&
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500
  ) {
    const result: ServiceResult = error.response.data;

    if (!result.messages) {
      return "Ocorreu um erro inesperado. Tente novamente mais tarde.";
    }

    return result.messages.map((m: any) => "➡️ " + m.message).join("\n\n");
  }

  return "Ocorreu um erro inesperado. Tente novamente mais tarde.";
}
