export default function SkeletonListagem() {
  return (
    <div className="col-lg-12">
      <div className="card-cinza-claro p-3 mb-3">
        <div className="placeholder-glow w-100">
          <span className="placeholder bg-secondary placeholder-lg col-12"></span>
        </div>
      </div>
      <div className="card-cinza-claro p-3 mb-3">
        <div className="placeholder-glow w-100">
          <span className="placeholder bg-secondary placeholder-lg col-12"></span>
        </div>
      </div>
      <div className="card-cinza-claro p-3 mb-3">
        <div className="placeholder-glow w-100">
          <span className="placeholder bg-secondary placeholder-lg col-12"></span>
        </div>
      </div>
      <div className="card-cinza-claro p-3 mb-3">
        <div className="placeholder-glow w-100">
          <span className="placeholder bg-secondary placeholder-lg col-12"></span>
        </div>
      </div>
      <div className="card-cinza-claro p-3 mb-3">
        <div className="placeholder-glow w-100">
          <span className="placeholder bg-secondary placeholder-lg col-12"></span>
        </div>
      </div>
      <div className="card-cinza-claro p-3 mb-3">
        <div className="placeholder-glow w-100">
          <span className="placeholder bg-secondary placeholder-lg col-12"></span>
        </div>
      </div>
      <div className="card-cinza-claro p-3 mb-3">
        <div className="placeholder-glow w-100">
          <span className="placeholder bg-secondary placeholder-lg col-12"></span>
        </div>
      </div>
      <div className="card-cinza-claro p-3 mb-3">
        <div className="placeholder-glow w-100">
          <span className="placeholder bg-secondary placeholder-lg col-12"></span>
        </div>
      </div>
      <div className="card-cinza-claro p-3 mb-3">
        <div className="placeholder-glow w-100">
          <span className="placeholder bg-secondary placeholder-lg col-12"></span>
        </div>
      </div>
      <div className="card-cinza-claro p-3 mb-3">
        <div className="placeholder-glow w-100">
          <span className="placeholder bg-secondary placeholder-lg col-12"></span>
        </div>
      </div>
    </div>
  );
}
