import add from "../../../Components/assets/images/add.png";
import { IoIosArrowForward } from "react-icons/io";
import ArcticonsTransfer from "../../../Components/assets/images/arcticons_transfer.png";
import WhiteContainer from "../../../Components/WhiteContainer";
import {
  SaquePostArgs,
  useSolicitarSaqueStore,
} from "../../../stores/solicitacao-saque.store";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ListServiceResult } from "../../../Interfaces";
import EventoFinanceiroFavorecidoModel from "../../../Interfaces/Models/EventoFinanceiroFavorecidoModel";
import AxiosClient from "../../../Services/AxiosClient";
import AxiosErrorHandler from "../../../Services/AxiosErrorHandler";
import { useParams, useSearchParams } from "react-router-dom";
import DarkGrayCard from "../../../Components/DarkGrayCard";
import GerarAcronimo from "../../../Utils/GerarAcronimo";
import { TablePagination } from "../../../Components/table-pagination";

export default function NovoSaque() {
  const { setStep, setModel } = useSolicitarSaqueStore();
  const { eventoId } = useParams();
  const [favorecidos, setFavorecidos] = useState<
    EventoFinanceiroFavorecidoModel[] | null
  >(null);
  const [loadingFavorecidos, setLoadingFavorecidos] = useState<boolean>(false);
  const [acessoNegadoFavorecido, setAcessoNegadoFavorecido] =
    useState<boolean>(false);

  const [numeroPaginas, setNumeroPaginas] = useState<number>(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const pagina = Number(searchParams.get("page")) || 1;
  const paginaTamanho = Number(searchParams.get("size")) || 10;

  const obterFavorecidos = async (): Promise<void> => {
    setLoadingFavorecidos(true);

    AxiosClient.get<ListServiceResult<EventoFinanceiroFavorecidoModel>>(
      `/eventos/${eventoId}/financeiro/favorecidos?pagina=${pagina}&paginaTamanho=${paginaTamanho}`
    )
      .then(({ data }) => {
        setNumeroPaginas(data.pages || 0);
        setFavorecidos(data.data);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          setAcessoNegadoFavorecido(true);
          return;
        }
        toast.error(AxiosErrorHandler(error));
      })
      .finally(() => setLoadingFavorecidos(false));
  };

  const handleTransactionClick = (
    transaction: EventoFinanceiroFavorecidoModel
  ) => {
    setModel({
      favorecidoId: transaction.id,
      agencia: transaction.agencia,
      banco: transaction.banco,
      cpfCnpj: transaction.cpfCnpj,
      nomeTitular: transaction.nomeCompleto,
      numeroConta: transaction.conta,
      tipoConta: transaction.contaTipo,
      formaTransferencia: transaction.tipo,
    } as SaquePostArgs);
    setStep("valor");
  };

  useEffect(() => {
    obterFavorecidos();
  }, [pagina, paginaTamanho]);

  return (
    <>
      <WhiteContainer>
        <div
          className="px-3 rounded border border-gray-300"
          onClick={() => setStep("novo-saque")}
          style={{ cursor: "pointer" }}
        >
          <div className="d-flex justify-content-start align-items-center py-3">
            <img src={add} alt="Add" />
            <p className="text-500-black-14 m-0 ms-3">Novo saque</p>
            <IoIosArrowForward
              className="text-black ms-auto"
              style={{ width: "24px", height: "24px" }}
            />
          </div>
        </div>

        <div className="px-lg-3 rounded border border-gray-300 mt-3">
          <div>
            <p
              className="text-500-black-20 pt-4"
              style={{ marginLeft: "20px" }}
            >
              Transferir novamente
            </p>
            <hr className="my-3" />
          </div>

          {loadingFavorecidos ? (
            <p className="text-500-darkest-18 mx-3">
              Carregando histórico de saque...
            </p>
          ) : acessoNegadoFavorecido ? (
            <div className="mb-3">
              <DarkGrayCard message="Você não tem permissão para listar o histórico de saque." />
            </div>
          ) : favorecidos && favorecidos.length > 0 ? (
            <div className="row mt-4 mx-2">
              {favorecidos.map((user, index) => (
                <>
                  <div
                    key={index}
                    className="col-lg-6 d-flex align-items-center mb-4 border-0 flex-wrap"
                    style={{
                      opacity: 1,
                      cursor: "pointer",
                    }}
                    onClick={() => handleTransactionClick(user)}
                  >
                    <div
                      className="rounded-circle bg-light d-flex justify-content-center align-items-center"
                      style={{ width: "64px", height: "64px" }}
                    >
                      <span
                        className="text-400-gray-16"
                        style={{ fontSize: "32px" }}
                      >
                        {GerarAcronimo(user.nomeCompleto)}
                      </span>
                    </div>
                    <div className="d-flex justify-content-center flex-column p-2">
                      <p className="mb-0 text-400-gray-16 fs-6 fs-sm-5">
                        {user.nomeCompleto}
                      </p>
                      <small className="text-muted fs-7 fs-sm-6">
                        CPF: {user.cpfCnpj}
                      </small>
                    </div>
                  </div>

                  <div className="d-lg-none">
                    <div className="border-2 border-bottom mb-3"></div>
                  </div>
                </>
              ))}

              <div className="col-lg-12 mb-1">
                <TablePagination {...{ numeroPaginas }} />
              </div>
            </div>
          ) : (
            <div
              className="d-flex flex-column justify-content-center align-items-center"
              style={{ paddingTop: "60px" }}
            >
              <img
                src={ArcticonsTransfer}
                alt="#"
                style={{ maxWidth: "100%" }}
              />
              <p
                className="text-center text-500-gray-18 mt-3 px-3"
                style={{ width: "100%", height: "auto" }}
              >
                Aqui você vai encontrar suas contas frequentes ou Pix mais
                usados para transferências
              </p>
            </div>
          )}
        </div>
      </WhiteContainer>
    </>
  );
}
