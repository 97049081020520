import { useState } from "react";
import ViaCepClient from "../Services/ViaCepClient";
import ViaCepModel from "../Interfaces/Models/ViaCepModel";

export default function useViaCep() {
  const [fetchingCep, setFetchingCep] = useState<boolean>(false);

  const FetchCep = async (cep: string): Promise<ViaCepModel> => {
    setFetchingCep(true);

    return ViaCepClient.get(`/ws/${cep}/json`)
      .then(({ data }) => {
        return data as ViaCepModel;
      })
      .catch((error: any) => {
        return error.response.data as ViaCepModel;
      })
      .finally(() => setFetchingCep(false));
  };

  return { FetchCep, fetchingCep };
}
