export default function DownloadIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke="#F82853"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M2.667 11.333v1.333A1.333 1.333 0 004 14h8a1.333 1.333 0 001.333-1.334v-1.333m-8.666-4L8 10.666m0 0l3.333-3.333M8 10.666v-8"
      ></path>
    </svg>
  );
}
