export default function SkeletonVinculoIngressos() {
  return (
    <div className="col-xl-12">
      <div className="form-check form-switch d-flex justify-content-end align-items-center ps-5 mb-2">
        <input
          className="form-check-input mb-1"
          style={{ width: "3.3em", height: "1.7em" }}
          type="checkbox"
          role="switch"
          id="selecionar_todos"
          disabled
        />
        <label
          className="form-check-label ps-2 cursor-pointer"
          htmlFor="selecionar_todos"
        >
          Selecionar todos
        </label>
      </div>

      <div
        className="card-cinza-claro p-2 mb-2 d-flex align-items-center"
        style={{ height: 60 }}
      >
        <div className="placeholder-glow w-100">
          <span className="placeholder placeholder-lg col-3 me-5"></span>
          <span className="placeholder placeholder-lg col-7"></span>
        </div>
      </div>
      <div
        className="card-cinza-claro p-2 mb-2 d-flex align-items-center"
        style={{ height: 60 }}
      >
        <div className="placeholder-glow w-100">
          <span className="placeholder placeholder-lg col-3 me-5"></span>
          <span className="placeholder placeholder-lg col-7"></span>
        </div>
      </div>
      <div
        className="card-cinza-claro p-2 mb-2 d-flex align-items-center"
        style={{ height: 60 }}
      >
        <div className="placeholder-glow w-100">
          <span className="placeholder placeholder-lg col-3 me-5"></span>
          <span className="placeholder placeholder-lg col-7"></span>
        </div>
      </div>
      <div
        className="card-cinza-claro p-2 mb-2 d-flex align-items-center"
        style={{ height: 60 }}
      >
        <div className="placeholder-glow w-100">
          <span className="placeholder placeholder-lg col-3 me-5"></span>
          <span className="placeholder placeholder-lg col-7"></span>
        </div>
      </div>
    </div>
  );
}
