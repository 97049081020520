import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import BreadCrumb, { Page } from "../../../Components/Breadcrumb";
import MainLayout from "../../../Components/Layouts/Main";
import WhiteContainer from "../../../Components/WhiteContainer";
import { useEffect, useState } from "react";
import { CodigoAcessoModel } from "../../../Interfaces/Models";
import AxiosErrorHandler from "../../../Services/AxiosErrorHandler";
import toast from "react-hot-toast";
import { ListServiceResult } from "../../../Interfaces";
import AxiosClient from "../../../Services/AxiosClient";
import DarkGrayCard from "../../../Components/DarkGrayCard";
import Loading from "../../../Components/Loading";
import FiltroCodigos from "./Components/FiltroCodigos";
import CodigosPromocionais from "./Components/CodigosPromocionais";
import PromptModal from "../../../Components/Modals/PromptModal";
import { DangerModalIcon } from "../../../Components/Icons";
import useObterNomeEventoHeader from "../../../Hooks/useObterNomeEventoHeader";
import { TablePagination } from "../../../Components/table-pagination";

export default function CodigoPromocional() {
  const { eventoId } = useParams();
  const navigate = useNavigate();

  const { obterNomeEvento } = useObterNomeEventoHeader();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [numeroPaginas, setNumeroPaginas] = useState<number>(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const pesquisa = searchParams.get("q") || "";
  const status = searchParams.get("status") || "";
  const pagina = Number(searchParams.get("page")) || 1;
  const paginaTamanho = Number(searchParams.get("size")) || 10;

  const breadCrumbHistory: Page[] = [
    {
      link: "/",
      name: "dashboard",
    },
    {
      link: "/eventos",
      name: "Eventos",
    },
    {
      link: `/eventos/${eventoId}/codigo-promocional`,
      name: "Código promocional",
    },
  ];

  const [loading, setLoading] = useState<boolean>(false);
  const [codigos, setCodigos] = useState<CodigoAcessoModel[]>([]);

  const [acessoNegadoCodigos, setAcessoNegadoCodigos] =
    useState<boolean>(false);

  const possuiCodigosOuFiltrosAplicados =
    !loading &&
    (codigos.length > 0 || (codigos.length === 0 && (pesquisa || status)));

  const removerFiltro = (filtro: string): void => {
    setSearchParams((state) => {
      state.delete(filtro);

      return state;
    });

    setCodigos([]);
  };

  const getRequestPathWIthQueryParams = () => {
    let url: string = "/codigos";
    url += `?searchString=${pesquisa}`;
    url += `&pagina=${pagina}`;
    url += `&paginaTamanho=${paginaTamanho}`;
    url += "&apenasComIngressosDisponiveis=false";
    url += "&apenasVisiveis=false";
    url += `&evento=${eventoId}`;

    // verifica se tem filtro por status
    if (status) {
      if (status === "ativo") {
        url += `&status=Ativo`;
      }

      if (status === "inativo") {
        url += `&status=Inativo`;
      }
    }

    return url;
  };

  const getCodigos = async () => {
    setLoading(true);

    AxiosClient.get<ListServiceResult<CodigoAcessoModel>>(
      getRequestPathWIthQueryParams()
    )
      .then(({ data: lsr }) => {
        setNumeroPaginas(lsr?.pages || 0);
        // sortby data criacao
        lsr.data!.sort((a, b) => {
          return (
            new Date(b.criadoEm).getTime() - new Date(a.criadoEm).getTime()
          );
        });

        setCodigos(lsr.data ?? []);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          setAcessoNegadoCodigos(true);
          return;
        }
        toast.error(AxiosErrorHandler(error));
      })
      .finally(() => setLoading(false));
  };

  const [auxCodigoModel, setAuxCodigoModel] = useState<CodigoAcessoModel>(
    {} as CodigoAcessoModel
  );
  const [modalExcluirCodigo, setModalExcluirCodigo] = useState<string | null>(
    null
  );

  const abrirModalExcluirCodigo = (codigo: CodigoAcessoModel) => {
    setModalExcluirCodigo(codigo.id);
    setAuxCodigoModel(codigo);
  };

  const excluirCodigo = async (): Promise<void> => {
    setModalExcluirCodigo(null);
    setLoading(true);

    toast.promise(
      AxiosClient.delete(`/codigos/${auxCodigoModel.id}`)
        .then(() => {
          setCodigos(codigos.filter((c) => c.id !== auxCodigoModel.id));
        })
        .finally(() => {
          setAuxCodigoModel({} as CodigoAcessoModel);
          setLoading(false);
        }),
      {
        loading: "Excluindo código...",
        success: "Código excluído com sucesso!",
        error: (error) => AxiosErrorHandler(error),
      }
    );
  };

  useEffect(() => {
    obterNomeEvento();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCodigos();
  }, [pesquisa, status, pagina, paginaTamanho]);

  return (
    <MainLayout>
      <div className="mb-3">
        <BreadCrumb history={breadCrumbHistory} />
      </div>

      <WhiteContainer
        rowMarginBottom={3}
        containerPadding={4}
        containerWidth={12}
      >
        <div className="d-flex flex-column flex-xl-row justify-content-xl-between align-items-center">
          <p className="title-h1 text-black text-center mb-2">
            Código promocional
          </p>

          <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
            <FiltroCodigos />

            <button
              type="button"
              onClick={() =>
                navigate(
                  `/eventos/${eventoId}/codigo-promocional/cadastrar-codigo`
                )
              }
              className="bc-btn bc-btn-primary px-3 py-2 w-100 h-100"
            >
              Adicionar código
            </button>
          </div>
        </div>
      </WhiteContainer>

      <WhiteContainer
        rowMarginBottom={4}
        containerPadding={4}
        containerWidth={12}
      >
        {(pesquisa || status) && (
          <div className="d-flex flex-wrap align-items-center justify-content-center mb-4">
            {pesquisa && (
              <div className="me-3 mb-2">
                <div
                  className="d-flex flex-wrap justify-content-center align-items-center px-4"
                  style={{
                    borderRadius: 50,
                    background: "#F9F8F8",
                  }}
                >
                  <p className="text-400-black-16 m-0 me-2 my-2">Filtro:</p>
                  <p className="text-400-black-16 m-0 me-2 my-2 text-break">
                    {pesquisa}
                  </p>
                  <button
                    className="btn-close my-2"
                    onClick={() => removerFiltro("q")}
                  ></button>
                </div>
              </div>
            )}
            {status && (
              <div className="me-3 mb-2">
                <div
                  className="d-flex flex-wrap justify-content-center align-items-center px-4"
                  style={{
                    borderRadius: 50,
                    background: "#F9F8F8",
                  }}
                >
                  <p className="text-400-black-16 m-0 me-2 my-2">Status:</p>
                  <p className="text-400-black-16 m-0 me-2 my-2 text-break">
                    {status}
                  </p>
                  <button
                    className="btn-close my-2"
                    onClick={() => removerFiltro("status")}
                  ></button>
                </div>
              </div>
            )}
          </div>
        )}

        {loading ? (
          <Loading container="50vh" />
        ) : acessoNegadoCodigos ? (
          <div className="mb-3">
            <DarkGrayCard message="Você não tem permissão para listar códigos promocionais." />
          </div>
        ) : possuiCodigosOuFiltrosAplicados ? (
          <CodigosPromocionais
            codigos={codigos}
            eventoId={eventoId!}
            updateStatusCodigo={() => getCodigos()}
            abrirModalExcluirCodigo={abrirModalExcluirCodigo}
          />
        ) : (
          <DarkGrayCard message="Você ainda não tem códigos promocionais por aqui!" />
        )}

        {/*
          --------------------------------------------------------------------------
                               Modal: Excluir código promocional                    
          --------------------------------------------------------------------------
        */}
        {modalExcluirCodigo && (
          <PromptModal
            isOpen={modalExcluirCodigo !== null}
            close={() => setModalExcluirCodigo(null)}
            icon={<DangerModalIcon />}
            title="Excluir código"
            subtitle={`Tem certeza que deseja excluir o código "${auxCodigoModel.codigo}” ?`}
            cancelText="Cancelar"
            cancelAction={() => setModalExcluirCodigo(null)}
            confirmText="Excluir"
            confirmAction={excluirCodigo}
            loading={loading}
          />
        )}
        <TablePagination numeroPaginas={numeroPaginas} />
      </WhiteContainer>
    </MainLayout>
  );
}
