import { useEffect, useState } from "react";
import MainLayout from "../../Components/Layouts/Main";
import BreadCrumb, { Page } from "../../Components/Breadcrumb";
import WhiteContainer from "../../Components/WhiteContainer";
import NovoSaque from "./Components/NovoSaque";
import Transferir from "./Components/Transferir";
import ValorTransferir from "./Components/ValorTransferir";
import QuandoReceber from "./Components/QuandoReceber";
import ConfirmarDados from "./Components/ConfirmarDados";
import ConfirmarCodigo from "./Components/ConfirmarCodigo";
import { useSolicitarSaqueStore } from "../../stores/solicitacao-saque.store";
import toast from "react-hot-toast";
import { ServiceResult } from "../../Interfaces";
import EventoFinanceiroSaldoModel from "../../Interfaces/Models/EventoFinanceiroSaldoModel";
import AxiosClient from "../../Services/AxiosClient";
import AxiosErrorHandler from "../../Services/AxiosErrorHandler";
import { useParams } from "react-router-dom";
import { FormatarCentavosParaReais } from "../../Utils";
import { OlhoIcon, OlhoSlash } from "../../Components/Icons";
import { useSaldoStore } from "../../stores/saldo.store";

export default function Saque() {
  const { step, setStep, resetModel } = useSolicitarSaqueStore();

  const {
    saldoRefreshing,
    isRefreshing: loadingSaldos,
    setRefreshing,
  } = useSaldoStore();

  const { eventoId } = useParams();

  const breadCrumbHistory: Page[] = [
    {
      link: "/",
      name: "dashboard",
    },
    {
      link: "/eventos",
      name: "eventos",
    },
    {
      link: `/eventos/${eventoId}/saque`,
      name: "Solicitar saque",
    },
  ];

  const [acessoNegadoSaldo, setAcessoNegadoSaldo] = useState<boolean>(false);
  const [saldos, setSaldos] = useState<EventoFinanceiroSaldoModel | null>(null);
  const [isValueVisible, setIsValueVisible] = useState(true);

  const toggleValueVisibility = () => {
    setIsValueVisible(!isValueVisible);
  };

  const obterSaldos = async (): Promise<void> => {
    setRefreshing(true);

    AxiosClient.get<ServiceResult<EventoFinanceiroSaldoModel>>(
      `/eventos/${eventoId}/financeiro/saldo`
    )
      .then(({ data: { data } }) => {
        setSaldos(data as EventoFinanceiroSaldoModel);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          setAcessoNegadoSaldo(true);
          return;
        }

        toast.error(AxiosErrorHandler(error));
      })
      .finally(() => setRefreshing(false));
  };

  useEffect(() => {
    setStep("inicial");
    resetModel();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    obterSaldos();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saldoRefreshing]);

  return (
    <MainLayout>
      <div className="mb-3">
        <BreadCrumb history={breadCrumbHistory} />
      </div>

      <WhiteContainer containerPadding={4} containerWidth={8}>
        <div className="d-flex justify-content-between align-items-center flex-wrap">
          <p className="title-h1 text-black mb-0 mb-md-3">Solicitar saque</p>
          <div className="d-flex flex-column justify-content-center mt-3 mt-lg-0">
            {acessoNegadoSaldo ? (
              <p className="m-0 text-400-gray-12">
                Você não tem permissão para acessar o saldo.
              </p>
            ) : (
              <>
                <span className="m-0 text-400-gray-12">
                  Disponível para saque
                </span>
                <div className="d-flex align-items-center flex-wrap">
                  {loadingSaldos ? (
                    <span
                      className="placeholder bg-secondary"
                      style={{
                        width: "100px",
                        height: "20px",
                        background: "#858796",
                      }}
                    ></span>
                  ) : (
                    <p className="m-0 text-500-black-24 text-break mt-2">
                      {isValueVisible
                        ? FormatarCentavosParaReais(
                            saldos?.saldoDisponivelParaSaqueCentavos || 0
                          )
                        : "R$ ******"}{" "}
                    </p>
                  )}
                  <span
                    style={{
                      marginLeft: "20px",
                      cursor: "pointer",
                    }}
                    onClick={toggleValueVisibility}
                    className="mt-1"
                  >
                    {isValueVisible ? <OlhoSlash /> : <OlhoIcon />}
                  </span>
                </div>

                {loadingSaldos ? (
                  <span
                    className="placeholder mt-2"
                    style={{
                      width: "90px",
                      height: "10px",
                      background: "#858796",
                    }}
                  ></span>
                ) : (
                  <p className="m-0 text-400-gray-12 text-break mt-2">
                    {FormatarCentavosParaReais(
                      saldos?.saldoLiberarSaqueCentavos || 0
                    )}{" "}
                    à liberar
                  </p>
                )}
              </>
            )}
          </div>
        </div>
      </WhiteContainer>

      {step === "inicial" && <NovoSaque />}
      {step === "novo-saque" && <Transferir />}
      {step === "valor" && <ValorTransferir />}
      {step === "data" && <QuandoReceber />}
      {step === "revisao" && <ConfirmarDados />}
      {step === "confirmar-codigo" && <ConfirmarCodigo />}
    </MainLayout>
  );
}
