import DistribuicaoTaxa from "../Enums/DistribuicaoTaxa";

export default function FormatarDistribuicaoTaxa(
  distribuicaoTaxa: DistribuicaoTaxa,
): string {
  switch (distribuicaoTaxa) {
    case DistribuicaoTaxa.Cliente:
      return "Cliente";
    case DistribuicaoTaxa.Produtor:
      return "Produtor";
    case DistribuicaoTaxa.ProdutorCliente:
      return "Cliente e Produtor";
    default:
      return "Não foi possível identificar o tipo de distribuição de taxa";
  }
}
