import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AutenticacaoModel } from "../Interfaces/Models";

export function useValidateToken() {
  const navigate = useNavigate();

  useEffect(() => {
    let auth: AutenticacaoModel = {} as AutenticacaoModel;

    const authLocalStr: string | null = localStorage.getItem("auth");
    const authSessionStr: string | null = sessionStorage.getItem("auth");

    if (authLocalStr) {
      auth = JSON.parse(authLocalStr);
    } else if (authSessionStr) {
      auth = JSON.parse(authSessionStr);
    }

    let dateNow = new Date();
    let tokenExpiresDate = new Date(auth.expiresIn);

    // ainda está logado, volta para a home
    if (dateNow < tokenExpiresDate) {
      navigate("/");
      return;
    }

    localStorage.clear();
    sessionStorage.removeItem("auth");
  }, [navigate]);
}
