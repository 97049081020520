export default function OlhoSlash() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#818187"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M10.73 5.073C11.152 5.024 11.576 5 12 5c4.664 0 8.4 2.903 10 7-.387.997-.91 1.935-1.555 2.788M6.52 6.519C4.48 7.764 2.9 9.693 2 12c1.6 4.097 5.336 7 10 7a10.44 10.44 0 005.48-1.52m-7.6-7.6a3 3 0 104.243 4.243"
      ></path>
      <path
        stroke="#818187"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M4 4l16 16"
      ></path>
    </svg>
  );
}
