import { ChangeEvent, useEffect, useRef, useState } from "react";
import BreadCrumb, { Page } from "../../../Components/Breadcrumb";
import MainLayout from "../../../Components/Layouts/Main";
import WhiteContainer from "../../../Components/WhiteContainer";
import { ImgPreviewIcon, UploadIcon } from "../../../Components/Icons";
import { EstadosBrasileiros, SomenteNumeros } from "../../../Utils";
import { useNavigate } from "react-router-dom";
import AxiosClient from "../../../Services/AxiosClient";
import toast from "react-hot-toast";
import AxiosErrorHandler from "../../../Services/AxiosErrorHandler";
import GeoJSONPoint from "../../../Interfaces/GeoJSONPoint";
import EventoSituacao from "../../../Enums/EventoSituacao";
import EnderecoModel from "../../../Interfaces/Models/EnderecoModel";
import { useForm } from "react-hook-form";
import Input from "../../../Components/Inputs/Input";
import ValidationError from "../../../Components/ValidationError";
import ViaCepModel from "../../../Interfaces/Models/ViaCepModel";
import useViaCep from "../../../Hooks/useViaCep";
import Loading from "../../../Components/Loading";
import FormButtons from "../../../Components/Form/FormButtons";
import { CategoriaModel } from "../../../Interfaces/Models/CategoriaModel";
import { ListServiceResult, ServiceResult } from "../../../Interfaces";
import TaxaModel from "../../../Interfaces/Models/TaxaModel";
import clsx from "clsx";

const breadCrumbHistory: Page[] = [
  {
    link: "/",
    name: "dashboard",
  },
  {
    link: "/eventos",
    name: "eventos",
  },
  {
    link: "/cadastrar-evento",
    name: "criar evento",
  },
];

interface EventosPostBody {
  titulo: string;
  descricao: string;
  exibirDescricao: boolean;
  enderecoPrivado: boolean;
  endereco: EnderecoModel | null;
  idadeMinima: number | null;
  imagemBannerEventoBase64: string;
  imagemEventoBase64: string;
  imagemLocalEventoBase64: string | null;
  imagemThumbEventoBase64: string | null;
  imagemQuadradaBase64: string | null;
  bannerQuadradoBase64: string | null;
  linkSrcLocalizacaoMap: string | null;
  situacao: EventoSituacao;
  taxaAdministracao: number;
  taxaAdministracaoPOS: number;
  localizacao: GeoJSONPoint | null;
  exibirLocalizacao: boolean;
  parcelamentoMaximo: number;
  categoriaId: string | null;
}

export default function CadastrarEvento() {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    register,
    formState: { errors },
  } = useForm({
    values: {
      titulo: "",
      categoriaId: "",
      descricao: "",
      exibirDescricao: true,
      situacao: EventoSituacao.Ativo,
      idadeMinima: 18,
      taxaAdministracao: 0,
      taxaAdministracaoPOS: 0,
      linkSrcLocalizacaoMap: "",
      imagemBannerEventoBase64: "",
      imagemEventoBase64: "",
      exibirLocalizacao: true,
      cep: "",
      logradouro: "",
      numero: "",
      complemento: "",
      bairro: "",
      pais: "brasil",
      unidadeFederacao: "SP",
      cidade: "",
      exibirEndereco: false,
    },
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [numeroParcelas, setNumeroParcelas] = useState<number>(1);
  const [exibirDescricao, setExibirDescricao] = useState<boolean>(true);
  const [descricao, setDescricao] = useState<string>("");
  const [situacao, setSituacao] = useState<EventoSituacao>(
    EventoSituacao.Ativo
  );
  const [exibirLocalizacao, setExibirLocalizacao] = useState<boolean>(true);
  const [bannerEvento, setBannerEvento] = useState<string | undefined>("");
  // const [imagemEvento, setImagemEvento] = useState<string | undefined>("");
  const [imagemQuadrada, setImagemQuadrada] = useState<string | undefined>("");
  // const [bannerQuadrado, setBannerQuadrado] = useState<string | undefined>("");
  const [estado, setEstado] = useState<string>("");

  const [categorias, setCategorias] = useState<CategoriaModel[]>([]);

  const [enderecoPrivado, setEnderecoPrivado] = useState<boolean>(false);

  const bannerEventoRef = useRef<HTMLInputElement | null>(null);
  // const imagemEventoRef = useRef<HTMLInputElement | null>(null);
  const imagemQuadradaRef = useRef<HTMLInputElement | null>(null);
  // const bannerQuadradoRef = useRef<HTMLInputElement | null>(null);

  const getTaxa = async (): Promise<void> => {
    setLoading(true);

    AxiosClient.get<ServiceResult<TaxaModel>>("/Tarifas/default")
      .then(({ data }) => {
        setValue("taxaAdministracao", data.data?.taxaAdministracao ?? 0);
        setValue("taxaAdministracaoPOS", data.data?.taxaAdministracaoPOS ?? 0);
      })
      .catch((error) => toast.error(AxiosErrorHandler(error)))
      .finally(() => setLoading(false));
  };

  const obterTotalParcelas = (): number[] => {
    const n = 12;
    let parcelas: number[] = [];

    for (let i = 1; i <= n; i++) {
      parcelas.push(i);
    }

    return parcelas;
  };

  const AlterarImagem = (
    e: ChangeEvent<HTMLInputElement>,
    img: "banner" | "evento" | "imagemQuadrada" | "bannerQuadrado"
  ): void => {
    const files = e.target.files;

    if (files && files.length > 0) {
      const file = files[0];

      const formData = new FormData();
      formData.append("files", file, file.name);

      // set base 64 img
      const reader = new FileReader();
      reader.onload = (e) => {
        switch (img) {
          case "banner":
            setBannerEvento(e.target?.result as string);
            // setBannerQuadrado(e.target?.result as string);
            break;

          case "imagemQuadrada":
            setImagemQuadrada(e.target?.result as string);
            // setImagemEvento(e.target?.result as string);
            break;
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUploadClick = (inputFile: "banner" | "imagemQuadrada"): void => {
    if (loading) return;

    switch (inputFile) {
      case "banner":
        bannerEventoRef.current?.click();
        break;
      case "imagemQuadrada":
        imagemQuadradaRef.current?.click();
        break;
    }
  };

  const cadastrarEvento = async (data: any): Promise<void> => {
    if (!bannerEvento) {
      toast.error("Insira o banner do evento.");
      return;
    }

    if (!imagemQuadrada) {
      toast.error("Insira a imagem quadrada do evento.");
      return;
    }

    setLoading(true);

    const categoriaSelecionada = categorias.find(
      (c) => c.id === data.categoriaId
    );
    const categoriaId = categoriaSelecionada ? categoriaSelecionada.id : null;

    let args: EventosPostBody = {
      titulo: data.titulo,
      categoriaId: categoriaId,
      descricao: descricao,
      exibirDescricao: exibirDescricao,
      situacao: situacao,
      idadeMinima: data.idadeMinima ? Number(data.idadeMinima) : null,
      linkSrcLocalizacaoMap: data.linkSrcLocalizacaoMap,
      exibirLocalizacao: exibirLocalizacao,
      endereco: enderecoPrivado
        ? null
        : {
            bairro: data.bairro,
            cep: SomenteNumeros(data.cep),
            cidade: data.cidade,
            complemento: data.complemento,
            logradouro: data.logradouro,
            numero: data.numero,
            pais: "brasil",
            unidadeFederacao: estado,
          },
      enderecoPrivado: enderecoPrivado,
      imagemBannerEventoBase64: bannerEvento.split(",")[1] || "",
      bannerQuadradoBase64: bannerEvento.split(",")[1] || "",
      imagemEventoBase64: imagemQuadrada.split(",")[1] || "",
      imagemQuadradaBase64: imagemQuadrada.split(",")[1] || "",
      parcelamentoMaximo: numeroParcelas,
    } as EventosPostBody;

    if (data.taxaAdministracao) {
      args.taxaAdministracao = Number(
        data.taxaAdministracao.toString().replace(",", ".")
      );
    }

    if (data.taxaAdministracaoPOS) {
      args.taxaAdministracaoPOS = Number(
        data.taxaAdministracaoPOS.toString().replace(",", ".")
      );
    }

    toast.promise(
      AxiosClient.post("/eventos", args)
        .then(() => navigate("/eventos"))
        .finally(() => setLoading(false)),
      {
        loading: "Cadastrando evento...",
        success: "Evento cadastrado com sucesso!",
        error: (error: any) => AxiosErrorHandler(error),
      }
    );
  };

  //#region CEP

  const { FetchCep, fetchingCep } = useViaCep();

  const cep: string = watch("cep");

  const [buscouCep, setBuscouCep] = useState<boolean>(false);

  const BuscarCep = async (): Promise<void> => {
    if (cep?.length > 8) {
      const viaCepResponse: ViaCepModel = await FetchCep(cep);

      setBuscouCep(true);

      if (viaCepResponse.erro) {
        toast.error("CEP inválido! Não foi possível obter o endereço.");

        setValue("logradouro", "");
        setValue("complemento", "");
        setValue("bairro", "");
        setValue("cidade", "");
        setEstado("SP");
      } else {
        setValue("cep", viaCepResponse.cep);
        setValue("logradouro", viaCepResponse.logradouro);
        setValue("complemento", viaCepResponse.complemento);
        setValue("bairro", viaCepResponse.bairro);
        setValue("cidade", viaCepResponse.localidade);
        setEstado(viaCepResponse.uf?.toUpperCase());
      }
    }
  };

  const getCategorias = async (): Promise<void> => {
    setLoading(true);

    AxiosClient.get<ListServiceResult<CategoriaModel>>("/categorias")
      .then(({ data }) => {
        setCategorias((prev) => [...prev, ...(data?.data ?? [])]);
      })
      .catch((error) => toast.error(AxiosErrorHandler(error)))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getCategorias();
    getTaxa();
  }, []);

  //#endregion

  return (
    <MainLayout>
      <div className="mb-3">
        <BreadCrumb history={breadCrumbHistory} />
      </div>

      <WhiteContainer>
        <p className="title-h1 text-black mb-2">Criar evento</p>
      </WhiteContainer>

      <form onSubmit={handleSubmit(cadastrarEvento)}>
        <WhiteContainer>
          <p className="title-h1 text-black mb-3">Informações do evento</p>

          <div className="mb-4">
            <p className="text-500-dark-18 mb-2">Título do evento</p>
            <Input
              autoFocus
              name="titulo"
              control={control}
              errors={errors}
              validation={{
                required: "Informe o título do evento",
              }}
              placeholder="Título do evento"
            />
            {errors.titulo && ValidationError(errors.titulo)}
          </div>

          <div className="mb-4">
            <p className="text-500-dark-18 mb-2">Categoria do evento</p>
            <select
              {...register("categoriaId", { required: "Campo obrigatório" })}
              className="form-select bc-input input-outlined p-2"
            >
              <option disabled value="">
                Nenhuma categoria
              </option>
              {categorias.map((categoria) => (
                <option key={categoria.id} value={categoria.id}>
                  {categoria.nome}
                </option>
              ))}
            </select>
            {errors.categoriaId && ValidationError(errors.categoriaId)}
          </div>

          <div className="mb-3">
            <div className="d-flex flex-wrap-reverse justify-content-between align-items-center">
              <p className="text-500-dark-18 mb-2">Descrição do evento</p>

              <div className="form-check form-switch d-flex align-items-center ps-5 mb-2">
                <input
                  className="form-check-input mb-1"
                  style={{ width: "3.3em", height: "1.7em" }}
                  type="checkbox"
                  role="switch"
                  id="exibirDescricao"
                  name="exibirDescricao"
                  checked={exibirDescricao}
                  onChange={({ target: { checked } }) =>
                    setExibirDescricao(checked)
                  }
                />
                <label
                  className="form-check-label ps-2"
                  htmlFor="exibirDescricao"
                >
                  Habilitar descrição
                </label>
              </div>
            </div>

            <textarea
              name="descricao"
              placeholder="Descrição do evento"
              className={`form-control backstage-input p-2 ${
                !exibirDescricao && "disabled"
              }`}
              style={{ height: 120, resize: "none" }}
              disabled={!exibirDescricao}
              value={descricao}
              onChange={({ target: { value } }) => setDescricao(value)}
            />
          </div>
        </WhiteContainer>

        <WhiteContainer>
          <p className="title-h1 text-black mb-3">Informações adicionais</p>

          {/* Situação + Idade mínima */}
          <div className="row g-0 mb-4">
            {/* 
              --------------------------------------------------------------------------
                                          Situação do evento                            
              --------------------------------------------------------------------------
            */}
            <div className="col-lg-6 mb-3 mb-xl-0">
              <p className="text-500-dark-18 mb-3">Situação do evento</p>

              {Object.values(EventoSituacao).map((s) => (
                <div className="mb-2" key={s}>
                  <input
                    type="radio"
                    name="situacao"
                    id={`situacao_${s}`}
                    value={s}
                    checked={situacao === s}
                    onChange={() => setSituacao(s)}
                  />
                  <label htmlFor={`situacao_${s}`} className="ps-2">
                    {s}
                  </label>
                </div>
              ))}
            </div>

            {/* 
              --------------------------------------------------------------------------
                                        Idade mínima permitida                          
              --------------------------------------------------------------------------
            */}
            <div className="col-lg-6">
              <p className="text-500-dark-18 mb-2">Idade mínima permitida</p>
              <Input
                name="idadeMinima"
                control={control}
                errors={errors}
                validation={{
                  pattern: {
                    value: /^[0-9]*$/,
                    message: "Informe apenas números",
                  },
                }}
                placeholder="Idade mínima"
              />
              {errors.idadeMinima && ValidationError(errors.idadeMinima)}
            </div>
          </div>

          {/* Imagens */}
          <div className="row g-0 mb-4 justify-content-center">
            {/* Imagem quadrada */}
            <div className="col-lg-6 mb-4">
              <p className="text-500-dark-18 mb-2">
                Imagem do evento (quadrada)
              </p>
              <p className="text-400-black-16 mb-2 me-lg-4">
                Proporção quadrada (180x180px). Tamanho máximo 2MB.
              </p>

              <div className="my-4 me-lg-4">
                {/* Input file hidden */}
                <input
                  ref={imagemQuadradaRef}
                  style={{ display: "none" }}
                  type="file"
                  accept="image/*"
                  multiple={false}
                  onChange={(e) => AlterarImagem(e, "imagemQuadrada")}
                />
                <div
                  className="cursor-pointer text-center"
                  onClick={() => handleUploadClick("imagemQuadrada")}
                >
                  {imagemQuadrada ? (
                    <img
                      src={imagemQuadrada}
                      alt="imagem quadrada do evento"
                      width="100%"
                      height="auto"
                    />
                  ) : (
                    <ImgPreviewIcon />
                  )}
                </div>
              </div>

              <button
                type="button"
                className="
                                bc-btn 
                                bc-btn-primary 
                                py-2 px-3 
                                mx-auto
                                d-flex 
                                justify-content-center 
                                align-items-center
                                "
                onClick={() => handleUploadClick("imagemQuadrada")}
              >
                <p className="text-500-white-16 pe-2 m-0">
                  {imagemQuadrada ? "Trocar" : "Inserir"} imagem quadrada
                </p>
                <UploadIcon />
              </button>
            </div>
          </div>

          {/* Banner */}
          <div className="row g-0 mb-4 justify-content-center">
            <div className="col-lg-6 mb-4">
              <p className="text-500-dark-18 mb-2">Banner do evento</p>
              <p className="text-400-black-16 mb-2 me-lg-4">
                Recomendamos imagens em 650x350 px. Tamanho máximo 2MB.
              </p>

              <div className="my-4 me-lg-4">
                {/* Input file hidden */}
                <input
                  ref={bannerEventoRef}
                  style={{ display: "none" }}
                  type="file"
                  accept="image/*"
                  multiple={false}
                  onChange={(e) => AlterarImagem(e, "banner")}
                />
                <div
                  className="cursor-pointer text-center"
                  onClick={() => handleUploadClick("banner")}
                >
                  {bannerEvento ? (
                    <img
                      src={bannerEvento}
                      alt="banner"
                      width="100%"
                      height="auto"
                    />
                  ) : (
                    <ImgPreviewIcon />
                  )}
                </div>
              </div>

              <button
                type="button"
                className="
                                bc-btn 
                                bc-btn-primary 
                                py-2 px-3 
                                mx-auto
                                d-flex 
                                justify-content-center 
                                align-items-center
                                "
                onClick={() => handleUploadClick("banner")}
              >
                <p className="text-500-white-16 pe-2 m-0">
                  {bannerEvento ? "Trocar" : "Inserir"} banner
                </p>
                <UploadIcon />
              </button>
            </div>
          </div>

          <div className="row g-3 mb-2">
            {/* Número de parcelas */}
            <div className="col-lg-4">
              <p className="text-500-dark-18 mb-2">Número de parcelas</p>
              <select
                className="form-select bc-input input-outlined p-2"
                value={numeroParcelas.toString()}
                onChange={({ target: { value } }) => setNumeroParcelas(+value)}
              >
                {obterTotalParcelas().map((parcela) => (
                  <option key={parcela} value={parcela}>
                    {parcela}x
                  </option>
                ))}
              </select>
            </div>

            {/* Taxa de administração (POS) */}
            <div className="col-lg-4">
              <p className="text-500-dark-18 mb-2">
                Taxa de administração (POS)
              </p>
              <Input
                name="taxaAdministracaoPOS"
                control={control}
                errors={errors}
                validation={{
                  pattern: {
                    // 0.00 or 0,00
                    value: /^[0-9]+([.,][0-9]{1,2})?$/,
                    message: "Informe um valor válido",
                  },
                }}
                placeholder="Taxa de administração (POS)"
              />
              {errors.taxaAdministracaoPOS &&
                ValidationError(errors.taxaAdministracaoPOS)}
            </div>

            {/* Taxa de administração */}
            <div className="col-lg-4">
              <p className="text-500-dark-18 mb-2">Taxa de administração</p>
              <Input
                name="taxaAdministracao"
                control={control}
                errors={errors}
                validation={{
                  pattern: {
                    // 0.00 or 0,00
                    value: /^[0-9]+([.,][0-9]{1,2})?$/,
                    message: "Informe apenas números",
                  },
                }}
                placeholder="Taxa de administração"
              />
              {errors.taxaAdministracao &&
                ValidationError(errors.taxaAdministracao)}
            </div>
          </div>
        </WhiteContainer>

        <WhiteContainer rowMarginBottom={4}>
          <p className="title-h1 text-black mb-3">Endereço do evento</p>

          {/*
            --------------------------------------------------------------------------
                                                CEP                                   
            --------------------------------------------------------------------------
          */}
          <div className="row g-3 mb-3">
            <div className="form-check form-switch d-flex align-items-center">
              <input
                className="form-check-input mb-1"
                style={{ width: "3.3em", height: "1.7em" }}
                type="checkbox"
                role="switch"
                id="enderecoPrivado"
                name="enderecoPrivado"
                checked={enderecoPrivado}
                onChange={({ target: { checked } }) =>
                  setEnderecoPrivado(checked)
                }
              />
              <label
                className="form-check-label ps-2 cursor-pointer"
                htmlFor="enderecoPrivado"
              >
                Local Privado
              </label>
            </div>

            <div className={clsx("col-lg-4", enderecoPrivado && "d-none")}>
              <p className="text-500-dark-18 mb-2">CEP</p>
              <Input
                name="cep"
                control={control}
                errors={errors}
                mask="99999-999"
                validation={{}}
                placeholder="CEP"
                onBlur={BuscarCep}
                disabled={enderecoPrivado}
                className={enderecoPrivado ? "disabled" : ""}
              />
              {errors.cep && ValidationError(errors.cep)}
            </div>
            <div className="col-lg-2 d-flex align-items-end justify-content-start my-0"></div>
          </div>

          <div className={clsx("row g-3 mb-3", enderecoPrivado && "d-none")}>
            {/*
  --------------------------------------------------------------------------
                            Link do google maps                           
  --------------------------------------------------------------------------
*/}
            <div className="row g-3 mb-3">
              <a
                href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                rel="noopener noreferrer"
                target="_blank"
              >
                Não sei meu CEP
              </a>
              <div className="col-lg-4">
                <p className="text-500-dark-18 mb-2">Link do google maps</p>
                <Input
                  name="linkSrcLocalizacaoMap"
                  control={control}
                  errors={errors}
                  validation={{}}
                  placeholder="Link do google maps"
                  className={!exibirLocalizacao ? "disabled" : ""}
                  disabled={!exibirLocalizacao}
                />
                {errors.linkSrcLocalizacaoMap &&
                  ValidationError(errors.linkSrcLocalizacaoMap)}
              </div>

              <div className="col-lg-8 d-flex align-items-end">
                <div className="form-check form-switch d-flex align-items-center ps-5">
                  <input
                    className="form-check-input mb-1"
                    style={{ width: "3.3em", height: "1.7em" }}
                    type="checkbox"
                    role="switch"
                    id="exibirLocalizacao"
                    name="exibirLocalizacao"
                    checked={exibirLocalizacao}
                    onChange={({ target: { checked } }) =>
                      setExibirLocalizacao(checked)
                    }
                  />
                  <label
                    className="form-check-label ps-2"
                    htmlFor="exibirLocalizacao"
                  >
                    Habilitar Link
                  </label>
                </div>
              </div>
            </div>

            {fetchingCep && (
              <div className="row g-0 mb-3">
                <div className="col-lg-12">
                  <p className="text-500-dark-18 text-center mt-5 mb-2">
                    Buscando CEP...
                  </p>
                  <Loading container="30vh" />
                </div>
              </div>
            )}

            {/* Mostrando os campos de endereço independentemente de o CEP ter sido inserido */}
            <div className="row g-0 mb-3">
              <div className="col-lg-12">
                <p className="text-500-dark-18 mb-2">Endereço (Logradouro)</p>
                <Input
                  name="logradouro"
                  control={control}
                  errors={errors}
                  validation={
                    {
                      //required: "Informe a rua",
                    }
                  }
                  placeholder="Rua"
                  disabled={enderecoPrivado}
                  className={enderecoPrivado ? "disabled" : ""}
                />
                {errors.logradouro && ValidationError(errors.logradouro)}
              </div>
            </div>

            <div className="row g-3 mb-3">
              <div className="col-lg-3">
                <p className="text-500-dark-18 mb-2">Número</p>
                <Input
                  name="numero"
                  control={control}
                  errors={errors}
                  validation={{}}
                  placeholder="Número"
                  disabled={enderecoPrivado}
                  className={enderecoPrivado ? "disabled" : ""}
                />
                {errors.numero && ValidationError(errors.numero)}
              </div>

              <div className="col-lg-3">
                <p className="text-500-dark-18 mb-2">Complemento</p>
                <Input
                  name="complemento"
                  control={control}
                  errors={errors}
                  validation={{}}
                  placeholder="Complemento"
                  disabled={enderecoPrivado}
                  className={enderecoPrivado ? "disabled" : ""}
                />
                {errors.complemento && ValidationError(errors.complemento)}
              </div>

              <div className="col-lg-3">
                <p className="text-500-dark-18 mb-2">Bairro</p>
                <Input
                  name="bairro"
                  control={control}
                  errors={errors}
                  validation={
                    {
                      //required: "Informe o bairro",
                    }
                  }
                  placeholder="Bairro"
                  disabled={enderecoPrivado}
                  className={enderecoPrivado ? "disabled" : ""}
                />
                {errors.bairro && ValidationError(errors.bairro)}
              </div>
            </div>

            {/*
  --------------------------------------------------------------------------
                        País / UF / Cidade                            
  --------------------------------------------------------------------------
*/}
            <div className="row g-3 mb-3">
              <div className="col-lg-3">
                <p className="text-500-dark-18 mb-2">País</p>
                <select
                  name="pais"
                  className="form-select backstage-input"
                  style={{ height: 40 }}
                >
                  <option value="brasil">Brasil</option>
                </select>
              </div>

              <div className="col-lg-3">
                <p className="text-500-dark-18 mb-2">Estado</p>
                <select
                  className="form-select backstage-input"
                  style={{ height: 40 }}
                  value={estado}
                  onChange={({ target: { value } }) => setEstado(value)}
                >
                  <option value="">Selecione um estado</option>{" "}
                  {EstadosBrasileiros.map((estado) => (
                    <option key={estado.sigla} value={estado.sigla}>
                      {estado.nome}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-lg-3">
                <p className="text-500-dark-18 mb-2">Cidade</p>

                <Input
                  name="cidade"
                  control={control}
                  errors={errors}
                  validation={
                    {
                      //required: "Informe a cidade",
                    }
                  }
                  placeholder="Cidade"
                  disabled={enderecoPrivado}
                  className={enderecoPrivado ? "disabled" : ""}
                />
                {errors.cidade && ValidationError(errors.cidade)}
              </div>
            </div>
          </div>
        </WhiteContainer>

        <FormButtons
          cancelAction={() => navigate(-1)}
          disabled={loading || fetchingCep}
          submitText="Cadastrar evento"
        />
      </form>
    </MainLayout>
  );
}
