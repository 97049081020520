import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import BreadCrumb, { Page } from "../../../Components/Breadcrumb";
import MainLayout from "../../../Components/Layouts/Main";
import WhiteContainer from "../../../Components/WhiteContainer";
import AxiosClient from "../../../Services/AxiosClient";
import toast from "react-hot-toast";
import AxiosErrorHandler from "../../../Services/AxiosErrorHandler";
import FormButtons from "../../../Components/Form/FormButtons";
import { useNavigate } from "react-router-dom";
import { ServiceResult } from "../../../Interfaces";
import RoleBSModel from "../../../Interfaces/Models/RoleBSModel";

const breadCrumbHistory: Page[] = [
  {
    link: "/",
    name: "dashboard",
  },
  {
    link: "/gestao-de-acesso",
    name: "Gestão de acesso",
  },
  {
    link: `/gestao-de-acesso/perfil/cadastrar`,
    name: "Cadastrar Perfil",
  },
];

interface PerfilPostArgs {
  nome: string;
  descricao: string;
  roles: string[];
}

export default function CadastrarPerfil() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [roles, setRoles] = useState<RoleBSModel[]>([]);

  const { handleSubmit, register } = useForm<PerfilPostArgs>({
    defaultValues: {
      nome: "",
      descricao: "",
      roles: [],
    },
  });

  const onSubmit = (data: PerfilPostArgs): void => {
    setLoading(true);

    const args = {
      nome: data.nome,
      descricao: data.descricao,
      Roles: data.roles,
    };

    AxiosClient.post("/Perfis", args)
      .then(() => {
        toast.success("Perfil cadastrado com sucesso!");
        navigate("/gestao-de-acesso");
      })
      .catch((error: any) => toast.error(AxiosErrorHandler(error)))
      .finally(() => setLoading(false));
  };

  const getRoles = async (): Promise<void> => {
    setLoading(true);

    AxiosClient.get<ServiceResult<RoleBSModel[]>>("/Role")
      .then(({ data: result }) => {
        setRoles(result.data || []);
      })
      .catch((error: any) => toast.error(AxiosErrorHandler(error)))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getRoles();
  }, []);

  return (
    <MainLayout>
      <div className="mb-3">
        <BreadCrumb history={breadCrumbHistory} />
      </div>

      <WhiteContainer containerPadding={4} containerWidth={12}>
        <div className="d-flex flex-column flex-xl-row justify-content-xl-between align-items-center">
          <p className="title-h1 text-black text-center mb-2">
            Cadastrar perfil
          </p>
        </div>
      </WhiteContainer>

      <WhiteContainer containerPadding={4} containerWidth={12}>
        {loading ? (
          <div className="text-center">Carregando...</div>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="nome" className="form-label">
                    Nome:
                  </label>
                  <input
                    type="text"
                    className="form-control backstage-input p-2"
                    id="nome"
                    {...register("nome")}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="descricao" className="form-label">
                    Descrição:
                  </label>
                  <input
                    type="text"
                    className="form-control backstage-input p-2"
                    id="descricao"
                    {...register("descricao")}
                  />
                </div>
              </div>

              <div className="col-12 mb-3">
                <label htmlFor="roles" className="form-label">
                  Permissões:
                </label>

                <div className="row">
                  {roles
                    // distincty by categoria
                    .filter(
                      (role, index, self) =>
                        index ===
                        self.findIndex((t) => t.categoria === role.categoria)
                    )
                    // sort by categoria with more roles first
                    .sort(
                      (a, b) =>
                        roles.filter((r) => r.categoria === b.categoria)
                          .length -
                        roles.filter((r) => r.categoria === a.categoria).length
                    )
                    .map((role) => (
                      <div
                        className="col-md-6 col-lg-4 col-xxl-3"
                        key={role.id}
                      >
                        <p className="text-500-dark-18 fw-bold mb-1">
                          {role.categoria}:
                        </p>

                        <div className="mb-4 border border-2 rounded pt-2">
                          {roles
                            .sort((a, b) =>
                              a.descricao.localeCompare(b.descricao)
                            )
                            .filter((r) => r.categoria === role.categoria)
                            .map((r) => (
                              <div
                                className="form-check form-switch d-flex align-items-center ps-5 mb-2"
                                key={r.id}
                              >
                                <input
                                  className="form-check-input mb-1 cursor-pointer me-2"
                                  type="checkbox"
                                  style={{ width: "40px", height: "20px" }}
                                  value={r.id}
                                  id={r.id}
                                  {...register("roles")}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={r.id}
                                >
                                  {r.descricao}
                                </label>
                              </div>
                            ))}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>

            <FormButtons
              disabled={loading}
              submitText="Cadastrar"
              cancelAction={() => navigate(`/gestao-de-acesso`)}
            />
          </form>
        )}
      </WhiteContainer>
    </MainLayout>
  );
}
