import { useEffect, useState } from "react";
import AxiosClient from "../../../../Services/AxiosClient";
import { ListServiceResult } from "../../../../Interfaces";
import AxiosErrorHandler from "../../../../Services/AxiosErrorHandler";
import toast from "react-hot-toast";
import WhiteContainer from "../../../../Components/WhiteContainer";
import DarkGrayCard from "../../../../Components/DarkGrayCard";
import Loading from "../../../../Components/Loading";
import { useParams, useSearchParams } from "react-router-dom";
import { TablePagination } from "../../../../Components/table-pagination";
import Button from "../../../../Components/Button";
import { FaPencil } from "react-icons/fa6";
import { usePermissoesStore } from "../../../../Contexts/PermissoesStore";
import UsuarioPermissaoModel from "../../../../Interfaces/Models/UsuarioPermissaoModel";
import SkeletonListagem from "../../../../Components/SkeletonListagem";

export default function ListagemUsuarios() {
  const { eventoId } = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [usuarios, setUsuarios] = useState<UsuarioPermissaoModel[]>([]);
  const [numeroPaginas, setNumeroPaginas] = useState<number>(0);

  const { status, usuarioId, setEditando } = usePermissoesStore();

  const [acessoNegadoPermissoes, setAcessoNegadoPermissoes] =
    useState<boolean>(false);

  const [searchParams] = useSearchParams();
  const pagina = Number(searchParams.get("page")) || 1;
  const paginaTamanho = Number(searchParams.get("size")) || 10;

  const handleEditClick = (usuarioId: string) => {
    setEditando(usuarioId);

    // workaround to scroll to top of the page
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  };

  const getUsuarios = async (): Promise<void> => {
    setLoading(true);

    AxiosClient.get<ListServiceResult<UsuarioPermissaoModel>>(
      `/eventos/${eventoId}/membros-producao?pagina=${pagina}&paginaTamanho=${paginaTamanho}`
    )
      .then(({ data: lsr }) => {
        setNumeroPaginas(lsr.pages || 0);
        setUsuarios(lsr.data || []);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          setAcessoNegadoPermissoes(true);
          return;
        }
        toast.error(AxiosErrorHandler(error));
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getUsuarios();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagina, paginaTamanho]);

  return (
    <WhiteContainer
      containerWidth={12}
      containerPadding={4}
      rowMarginBottom={4}
    >
      {loading ? (
        <SkeletonListagem />
      ) : acessoNegadoPermissoes ? (
        <div className="mb-3">
          <DarkGrayCard message="Acesso negado para listagem de usários." />
        </div>
      ) : usuarios.length === 0 ? (
        <DarkGrayCard message="Você ainda não tem usuários por aqui!" />
      ) : (
        <div className="row">
          <div className="d-none d-lg-block col-lg-12 mb-3">
            <div className="row">
              <div className="col-lg-4">
                <p className="text-500-black-16 m-0">Nome</p>
              </div>
              <div className="col-lg-4">
                <p className="text-500-black-16 m-0">E-mail</p>
              </div>
            </div>
          </div>

          {usuarios.map((u) => (
            <div className="col-lg-12" key={u.id + u.email}>
              <div className="card-cinza-claro p-3 mb-3">
                <div className="row">
                  <div className="align-self-center col-lg-4">
                    <p className="d-block d-lg-none text-500-black-16 m-0 mt-3">
                      Nome
                    </p>
                    <p className="text-500-darkest-14 text-break m-0">
                      {u.nomeCompleto}
                    </p>
                  </div>

                  <div className="align-self-center col-lg-4">
                    <p className="d-block d-lg-none text-500-black-16 m-0 mt-3">
                      Email
                    </p>
                    <p className="text-500-darkest-14 text-break m-0">
                      {u.email}
                    </p>
                  </div>

                  <div className="align-self-center col-lg-4">
                    <div className="d-flex flex-wrap justify-content-center justify-content-md-evenly align-items-center">
                      <Button
                        className="m-2 px-3 rounded-pill w-100 h-100"
                        variant="light"
                        text={
                          status === "editando" && usuarioId === u.id
                            ? "Editando"
                            : "Editar"
                        }
                        height={24}
                        width={150}
                        icon={<FaPencil />}
                        onClick={() => handleEditClick(u.id)}
                        disabled={status === "editando" && usuarioId === u.id}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      <TablePagination numeroPaginas={numeroPaginas} />
    </WhiteContainer>
  );
}
