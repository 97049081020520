import Button from "../../../../Components/Button";
import { LetterIcon } from "../../../../Components/Icons";
import { ParticipanteModel } from "../../../../Interfaces/Models";

interface Props {
  participante: ParticipanteModel;
  abrirModalEnviarMensagem: (g: ParticipanteModel) => void;
}

export default function BotoesListagemParticipantes({
  participante,
  abrirModalEnviarMensagem,
}: Props) {
  return (
    <div className="d-flex flex-wrap justify-content-center justify-content-md-evenly align-items-center">
      <Button
        className="m-2 px-3 rounded-pill"
        variant="light"
        text="Enviar mensagem"
        height={24}
        icon={<LetterIcon />}
        onClick={() => abrirModalEnviarMensagem(participante)}
      />
    </div>
  );
}
