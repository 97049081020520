import { FiltroIcon, SearchIcon } from "../../../Components/Icons";

interface Props {
  handleFiltro: () => void;
  filtroBusca: string;
  setFiltroBusca: React.Dispatch<React.SetStateAction<string>>;
}

export default function FiltroCategorias({
  handleFiltro,
  filtroBusca,
  setFiltroBusca,
}: Props) {
  return (
    <ul className="navbar-nav flex-nowrap">
      <li className="nav-item dropdown no-arrow">
        <div className="nav-item dropdown no-arrow">
          <a
            className="dropdown-toggle nav-link cursor-pointer"
            aria-expanded="false"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
          >
            <button
              type="button"
              className="btn-outline py-2 px-3  d-flex justify-content-center align-items-center"
            >
              <p className="text-500-black-16 pe-2 m-0">Filtrar</p>
              <FiltroIcon />
            </button>
          </a>
          <div className="dropdown-menu shadow dropdown-menu-end p-3 dropdown-filtro">
            <p className="text-400-black-24 mb-2">Filtrar</p>
            <p className="text-400-black-14 mb-3">
              Faça um busca por <strong>título</strong> ou{" "}
              <strong>descrição</strong> da categoria
            </p>

            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleFiltro();
              }}
            >
              {/* Filtro por string */}
              <div className="input-group mb-3 rounded-pill">
                <button
                  className="btn btn-light py-0 pe-0"
                  style={{ borderRadius: "50px 0 0 50px" }}
                  type="submit"
                >
                  <SearchIcon />
                </button>
                <input
                  className="bg-light form-control border-0 small"
                  style={{ borderRadius: "0 50px 50px 0" }}
                  type="text"
                  placeholder="Faça uma busca"
                  value={filtroBusca}
                  onChange={({ target: { value } }) => setFiltroBusca(value)}
                />
              </div>

              <button
                type="submit"
                className="bc-btn bc-btn-primary px-3 py-2 w-100"
              >
                Filtrar
              </button>
            </form>
          </div>
        </div>
      </li>
    </ul>
  );
}
