import { AutenticacaoModel } from "../Interfaces/Models";

export const useIsAuth = (): boolean => {
  let auth: AutenticacaoModel = {} as AutenticacaoModel;

  const authLocalStr: string | null = localStorage.getItem("auth");
  const authSessionStr: string | null = sessionStorage.getItem("auth");

  if (authLocalStr) {
    auth = JSON.parse(authLocalStr);
  } else if (authSessionStr) {
    auth = JSON.parse(authSessionStr);
  } else {
    return false;
  }

  let dateNow = new Date();
  let tokenExpiresDate = new Date(auth.expiresIn);

  if (dateNow > tokenExpiresDate && !auth.refreshToken) {
    return false;
  }

  return true;
};
