import { useParams } from "react-router-dom";
import MainLayout from "../../../Components/Layouts/Main";
import WhiteContainer from "../../../Components/WhiteContainer";
import { AgendamentoSaldo } from "./Components/AgendamentoSaldo";
import { HistoricoPagamentos } from "./Components/HistoricoPagamentos";
import { SolicitacaoSaque } from "./Components/SolicitacaoSaque";
import BreadCrumb, { Page } from "../../../Components/Breadcrumb";

export const EstatisticasFinanceiro = () => {
  const { eventoId } = useParams();

  const breadCrumbHistory: Page[] = [
    {
      link: "/",
      name: "dashboard",
    },
    {
      link: "/eventos",
      name: "eventos",
    },
    {
      link: `/eventos/${eventoId}/Financeiro`,
      name: "Financeiro",
    },
  ];
  return (
    <>
      <MainLayout>
        <div className="mb-3">
          <BreadCrumb history={breadCrumbHistory} />
        </div>

        <WhiteContainer containerPadding={4} containerWidth={12}>
          <div className="d-flex flex-column flex-xl-row justify-content-xl-between align-items-center">
            <p className="title-h1 text-black text-center mb-2">Financeiro</p>
          </div>
        </WhiteContainer>

        <div className="row g-3 mb-3">
          <SolicitacaoSaque />
          <AgendamentoSaldo />
          <HistoricoPagamentos />
        </div>
      </MainLayout>
    </>
  );
};
