import AppRouter from "./Routes";
import { Toaster } from "react-hot-toast";
import { lazy, Suspense, useEffect } from "react";

const BrasilCashStyle = lazy(() => import("./Components/Styles/BrasilCashCSS"));
const TicketsStyle = lazy(() => import("./Components/Styles/TicketsCSS"));

const tenant = process.env.REACT_APP_TENANT;

export default function App() {
  useEffect(() => {
    const link = document.createElement("link");
    const oldLinks = document.querySelectorAll('link[rel="shortcut icon"]');

    oldLinks.forEach((l) => l.parentNode?.removeChild(l));

    link.id = "dynamic-favicon";
    link.rel = "shortcut icon";
    link.href = process.env.REACT_APP_FAVICON || "favicon.ico";

    document.head.appendChild(link);
  }, []);

  return (
    <>
      <Suspense fallback={<></>}>
        {tenant === "BRASILCASH" && <BrasilCashStyle />}
        {tenant === "TICKETSAPP" && <TicketsStyle />}
      </Suspense>
      <Toaster position="top-right" reverseOrder={false} />
      <AppRouter />
    </>
  );
}
