import { useNavigate } from "react-router-dom";
import { DeleteIcon, EditIcon } from "../../../Components/Icons";
import { CategoriaModel } from "../../../Interfaces/Models/CategoriaModel";
import { useState } from "react";
import Button from "../../../Components/Button";
import toast from "react-hot-toast";
import AxiosClient from "../../../Services/AxiosClient";
import CategoriaSituacao from "../../../Enums/CategoriaSituacao";
import AxiosErrorHandler from "../../../Services/AxiosErrorHandler";
import clsx from "clsx";

interface Props {
  categoriaId: string;
  categoria: CategoriaModel;
  updateSituacaoCategoria: (categoria: CategoriaModel) => void;
  abrirModalExcluirCategoria: (c: CategoriaModel) => void;
}

export default function BotoesListagemCategorias({
  categoriaId,
  categoria,
  updateSituacaoCategoria,
  abrirModalExcluirCategoria,
}: Props) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const toggleSituacaoCategoria = async () => {
    setLoading(true);

    toast.promise(
      AxiosClient.put(`/categorias/${categoria.id}/situacao`, {
        situacao:
          categoria.situacao === CategoriaSituacao.Ativo
            ? CategoriaSituacao.Inativo
            : CategoriaSituacao.Ativo,
      })
        .then(() => {
          updateSituacaoCategoria(categoria);
        })
        .finally(() => setLoading(false)),
      {
        loading: "Alterando situação...",
        success: "Situação alterada com sucesso!",
        error: (error) => AxiosErrorHandler(error),
      }
    );
  };

  return (
    <div>
      <Button
        className="m-lg-2 ms-0 mt-3 px-3 rounded-pill w-100 h-100"
        variant="light"
        text="Editar"
        height={24}
        width={100}
        icon={<EditIcon />}
        onClick={() => navigate(`/categorias/${categoria.id}`)}
      />

      <div className="w-100 mt-3" style={{ maxWidth: 150 }}>
        <div className="form-check form-switch d-flex align-items-center ps-lg-5">
          <input
            className="form-check-input mb-1 cursor-pointer"
            style={{ width: "3.3em", height: "1.7em" }}
            type="checkbox"
            role="switch"
            id={`cod_status_${categoria.id}`}
            checked={categoria.situacao === CategoriaSituacao.Ativo}
            onChange={toggleSituacaoCategoria}
            disabled={loading}
          />
          <label
            className={clsx(
              "form-check-label px-2 cursor-pointer",
              categoria.situacao === CategoriaSituacao.Ativo &&
                "text-500-black-16",
              (categoria.situacao === CategoriaSituacao.Inativo || loading) &&
                "text-400-darkest-16"
            )}
            htmlFor={`cod_status_${categoria.id}`}
          >
            {loading && "Alterando..."}

            {!loading &&
              categoria.situacao === CategoriaSituacao.Ativo &&
              "Ativo"}

            {!loading &&
              categoria.situacao === CategoriaSituacao.Inativo &&
              "Desativado"}
          </label>
        </div>
      </div>
    </div>
  );
}
