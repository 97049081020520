import clsx from "clsx";
import { IngressoModel } from "../../../../Interfaces/Models";
import SkeletonVinculoIngressos from "./SkeletonVinculoIngressos";

interface Props {
  loading: boolean;
  vincularIngresso: boolean;
  ingressos: IngressoModel[];
  ingressosSelecionados: string[];
  setIngressosSelecionados: React.Dispatch<React.SetStateAction<string[]>>;
}

export default function VinculoIngressos({
  loading,
  vincularIngresso,
  ingressos,
  ingressosSelecionados,
  setIngressosSelecionados,
}: Props) {
  const toggleSelecionarTodos = (): void => {
    if (ingressosSelecionados.length === ingressos.length) {
      setIngressosSelecionados([]);
    } else {
      setIngressosSelecionados(ingressos.map((i) => i.id));
    }
  };

  const selecionarIngresso = (ingresso: string): void => {
    const ingressos = [...ingressosSelecionados];
    const index = ingressos.indexOf(ingresso);

    if (index > -1) {
      ingressos.splice(index, 1);
    } else {
      ingressos.push(ingresso);
    }

    setIngressosSelecionados(ingressos);
  };

  return (
    <>
      {loading && <SkeletonVinculoIngressos />}

      {!loading && vincularIngresso && ingressos.length === 0 && (
        <div className="col-xl-12">
          <p className="text-500-darkest-16 m-0">Nenhum ingresso encontrado.</p>
        </div>
      )}

      {!loading && vincularIngresso && ingressos.length > 0 && (
        <div className="col-xl-12">
          <div className="form-check form-switch d-flex justify-content-end align-items-center ps-5 mb-2">
            <input
              className="form-check-input mb-1"
              style={{ width: "3.3em", height: "1.7em" }}
              type="checkbox"
              role="switch"
              id="selecionar_todos"
              checked={ingressosSelecionados.length === ingressos.length}
              onChange={toggleSelecionarTodos}
            />
            <label
              className="form-check-label ps-2 cursor-pointer"
              htmlFor="selecionar_todos"
            >
              Selecionar todos
            </label>
          </div>

          <div className="p-2" style={{ maxHeight: 270, overflowY: "auto" }}>
            {ingressos.map((ingresso) => (
              <div
                key={ingresso.id}
                className={clsx(
                  "card-cinza-claro p-2 mb-2 cursor-pointer",
                  ingressosSelecionados.includes(ingresso.id) &&
                    "border border-2"
                )}
                onClick={() => selecionarIngresso(ingresso.id)}
              >
                <div className="row">
                  <div className="col-lg-4">
                    <div className="d-flex align-items-center p-2">
                      <input
                        type="checkbox"
                        className="cursor-pointer form-check-input"
                        style={{ width: 25, height: 25, marginRight: 10 }}
                        checked={ingressosSelecionados.includes(ingresso.id)}
                        onChange={() => selecionarIngresso(ingresso.id)}
                      />
                      <p className="text-400-black-16 m-0">Vincular ingresso</p>
                    </div>
                  </div>

                  <div className="col-lg-8 d-flex align-items-center">
                    <p className="text-500-darkest-16 p-2 m-0">
                      {ingresso.nome}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
