export default function ConfiguracoesIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1593 12.98C19.1993 12.66 19.2293 12.34 19.2293 12C19.2293 11.66 19.1993 11.34 19.1593 11.02L21.2693 9.37005C21.4593 9.22005 21.5093 8.95005 21.3893 8.73005L19.3893 5.27005C19.2693 5.05005 18.9993 4.97005 18.7793 5.05005L16.2893 6.05005C15.7693 5.65005 15.2093 5.32005 14.5993 5.07005L14.2193 2.42005C14.1851 2.17661 13.9751 1.99664 13.7293 2.00005H9.72933C9.47933 2.00005 9.26933 2.18005 9.23933 2.42005L8.85933 5.07005C8.24933 5.32005 7.68933 5.66005 7.16933 6.05005L4.67933 5.05005C4.44933 4.96005 4.18933 5.05005 4.06933 5.27005L2.06933 8.73005C1.93933 8.95005 1.99933 9.22005 2.18933 9.37005L4.29933 11.02C4.25933 11.34 4.22933 11.67 4.22933 12C4.22933 12.33 4.25933 12.66 4.29933 12.98L2.18933 14.63C1.99933 14.78 1.94933 15.05 2.06933 15.27L4.06933 18.73C4.18933 18.95 4.45933 19.03 4.67933 18.95L7.16933 17.95C7.68933 18.35 8.24933 18.68 8.85933 18.93L9.23933 21.58C9.26933 21.82 9.47933 22 9.72933 22H13.7293C13.9793 22 14.1893 21.82 14.2193 21.58L14.5993 18.93C15.2093 18.68 15.7693 18.34 16.2893 17.95L18.7793 18.95C19.0093 19.04 19.2693 18.95 19.3893 18.73L21.3893 15.27C21.5093 15.05 21.4593 14.78 21.2693 14.63L19.1593 12.98ZM11.7293 15.5C9.79933 15.5 8.22933 13.93 8.22933 12C8.22933 10.07 9.79933 8.50005 11.7293 8.50005C13.6593 8.50005 15.2293 10.07 15.2293 12C15.2293 13.93 13.6593 15.5 11.7293 15.5Z"
        fill={
          window.location.pathname.includes("/editar-evento")
            ? "#fff"
            : "#9d9d9d"
        }
      />
    </svg>
  );
}
