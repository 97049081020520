import { useState } from "react";
import { useForm } from "react-hook-form";
import AxiosClient from "../../../Services/AxiosClient";
import toast from "react-hot-toast";
import Input from "../../../Components/Inputs/Input";
import Loading from "../../../Components/Loading";
import Button from "../../../Components/Button";

interface EsqueciSenhaArgs {
  email: string;
}

export default function FormEsqueciSenha() {
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm<EsqueciSenhaArgs>({
    defaultValues: {
      email: "",
    },
  });

  const [loading, setLoading] = useState(false);

  const EnviarEmailRedefinicaoSenha = async (data: EsqueciSenhaArgs) => {
    setLoading(true);

    toast.promise(
      AxiosClient.post("/conta/esqueci-minha-senha", data).finally(() =>
        setLoading(false)
      ),
      {
        loading: "Aguarde...",
        success: `Enviamos um e-mail para ${data.email} com as instruções para redefinir sua senha.`,
        error:
          "Não foi possível identificar a conta de origem pelo e-mail informado. Por favor, confira o e-mail e insira-o novamente.",
      }
    );
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(EnviarEmailRedefinicaoSenha)}
        className="mb-5"
      >
        <div className="form-group mb-5">
          <Input
            control={control}
            name="email"
            validation={{
              required: "Por favor, informe o e-mail.",
              validate: {
                email: (value: string) =>
                  (value.includes("@") && value.includes(".")) ||
                  `O e-mail '${getValues(
                    "email"
                  )}' é inválido. Insira um e-mail corretamente.`,
              },
            }}
            type="text"
            placeholder="exemplo@email.com"
            errors={errors}
          />
          {errors.email && (
            <p className="text-danger text-break m-0 mt-1">
              {errors.email.message?.toString()}
            </p>
          )}
        </div>

        <div className="g-5">
          <div className="col-lg-9 text-center">
            {loading ? (
              <Loading />
            ) : (
              <Button
                width="100%"
                text="Redefinir senha"
                type="submit"
                className="bc-btn bc-btn-primary mb-5"
                disabled={!!errors.email}
              />
            )}
          </div>
        </div>
      </form>
    </>
  );
}
