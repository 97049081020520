export default function ArrowLeftIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5824 10.5924H7.41244L12.2924 5.71244C12.6824 5.32244 12.6824 4.68244 12.2924 4.29244C12.1056 4.10522 11.852 4 11.5874 4C11.3229 4 11.0693 4.10522 10.8824 4.29244L4.29244 10.8824C4.10522 11.0693 4 11.3229 4 11.5874C4 11.852 4.10522 12.1056 4.29244 12.2924L10.8824 18.8824C11.1343 19.1343 11.5014 19.2327 11.8455 19.1405C12.1896 19.0483 12.4583 18.7796 12.5505 18.4355C12.6427 18.0914 12.5443 17.7243 12.2924 17.4724L7.41244 12.5924H18.5824C19.1324 12.5924 19.5824 12.1424 19.5824 11.5924C19.5824 11.0424 19.1324 10.5924 18.5824 10.5924Z"
        fill="#9D9D9D"
      />
    </svg>
  );
}
