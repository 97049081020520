export default function SuccessModalIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="88"
      height="88"
      fill="none"
      viewBox="0 0 88 88"
    >
      <circle cx="44" cy="44" r="44" fill="#fff"></circle>
      <path
        fill="#52CC6C"
        fillRule="evenodd"
        d="M59.585 31c-.793 0-1.552.317-2.11.88l-.002.002-19.088 19.089-6.289-6.289-.002-.002a2.968 2.968 0 00-4.216-.002 2.968 2.968 0 00.001 4.216l.003.002 8.375 8.376a2.992 2.992 0 004.235 0l21.195-21.176.003-.002a2.968 2.968 0 00.002-4.216A2.968 2.968 0 0059.585 31z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
