export default function AplicarMascaraDocumento(cpf: string) {
  // mascara para cpf ou cnpj
  const cpfCnpj = cpf.replace(/\D/g, "");
  let maskedValue;

  if (cpfCnpj.length <= 11) {
    // CPF
    maskedValue = cpfCnpj.replace(
      /(\d{3})(\d{3})(\d{3})(\d{2})/,
      "$1.$2.$3-$4"
    );
  } else {
    // CNPJ
    maskedValue = cpfCnpj.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  }

  return maskedValue;
}
