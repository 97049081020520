import moment from "moment";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useParams, useSearchParams } from "react-router-dom";
import BreadCrumb, { Page } from "../../../../Components/Breadcrumb";
import DarkGrayCard from "../../../../Components/DarkGrayCard";
import MainLayout from "../../../../Components/Layouts/Main";
import SkeletonListagem from "../../../../Components/SkeletonListagem";
import WhiteContainer from "../../../../Components/WhiteContainer";
import { ListServiceResult } from "../../../../Interfaces";
import { BilheteriaClienteModel } from "../../../../Interfaces/Models";
import AxiosClient from "../../../../Services/AxiosClient";
import AxiosErrorHandler from "../../../../Services/AxiosErrorHandler";
import ListagemClientes from "./Components/ListagemClientes";
import useObterNomeEventoHeader from "../../../../Hooks/useObterNomeEventoHeader";
import { TablePagination } from "../../../../Components/table-pagination";

export default function BilheteriaClientes() {
  const { eventoId } = useParams();

  const breadCrumbHistory: Page[] = [
    {
      link: "/",
      name: "dashboard",
    },
    {
      link: "/eventos",
      name: "Eventos",
    },
    {
      link: `/eventos/${eventoId}/bilheteria/clientes`,
      name: "Bilheteria - Clientes",
    },
  ];

  const { obterNomeEvento } = useObterNomeEventoHeader();

  const [numeroPaginas, setNumeroPaginas] = useState<number>(0);
  const [searchParams] = useSearchParams();

  const pagina = Number(searchParams.get("page")) || 1;
  const paginaTamanho = Number(searchParams.get("size")) || 10;

  const [loading, setLoading] = useState<boolean>(false);
  const [exportingCsv, setExportingCsv] = useState<boolean>(false);
  const [clientes, setClientes] = useState<BilheteriaClienteModel[]>([]);

  const [acessoNegadoClientes, setAcessoNegadoClientes] =
    useState<boolean>(false);

  const getClientes = async (): Promise<void> => {
    setLoading(true);

    AxiosClient.get<ListServiceResult<BilheteriaClienteModel>>(
      `/eventos/${eventoId}/bilheteria/clientes?pagina=${pagina}&paginaTamanho=${paginaTamanho}`
    )
      .then(({ data }) => {
        setNumeroPaginas(data.pages || 0);
        setClientes(data.data || []);
      })
      .catch((err) => {
        if (err.response && err.response.status === 403) {
          setAcessoNegadoClientes(true);
          return;
        }
        toast.error(AxiosErrorHandler(err));
      })
      .finally(() => setLoading(false));
  };

  const exportarCsv = async (): Promise<void> => {
    setExportingCsv(true);

    toast.promise(
      AxiosClient.get(`/eventos/${eventoId}/bilheteria/clientes/csv`, {
        responseType: "blob",
      })
        .then(({ data }) => {
          const blob = new Blob([data], {
            type: "text/csv",
          });

          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `bilheteria-clientes-${moment().format(
            "DD-MM-YYYY"
          )}.csv`;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .finally(() => setExportingCsv(false)),
      {
        loading: "Exportando CSV...",
        success: "CSV exportado com sucesso!",
        error: (error) => AxiosErrorHandler(error),
      }
    );
  };

  useEffect(() => {
    getClientes();
    obterNomeEvento();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagina, paginaTamanho]);

  return (
    <MainLayout>
      <div className="mb-3">
        <BreadCrumb history={breadCrumbHistory} />
      </div>

      <WhiteContainer containerPadding={4} containerWidth={12}>
        <div className="d-flex flex-column flex-xl-row justify-content-xl-between align-items-center">
          <p className="title-h1 text-black text-center mb-2">
            Lista de clientes
          </p>

          <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
            <button
              type="button"
              onClick={exportarCsv}
              className={`bc-btn bc-btn-primary px-3 py-2 w-00 h-00 ${
                clientes.length === 0 || loading ? "disabled" : ""
              }`}
              disabled={clientes.length === 0 || loading || exportingCsv}
            >
              Exportar CSV
            </button>
          </div>
        </div>
      </WhiteContainer>

      <WhiteContainer
        rowMarginBottom={4}
        containerPadding={4}
        containerWidth={12}
      >
        {loading ? (
          <SkeletonListagem />
        ) : acessoNegadoClientes ? (
          <div className="mb-3">
            <DarkGrayCard message="Você não tem permissão para listar clientes." />
          </div>
        ) : clientes.length === 0 ? (
          <DarkGrayCard message="Você ainda não tem clientes por aqui!" />
        ) : (
          <ListagemClientes clientes={clientes} />
        )}
      </WhiteContainer>
      <TablePagination numeroPaginas={numeroPaginas} />
    </MainLayout>
  );
}
