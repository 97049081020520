import { useForm } from "react-hook-form";
import { FiltroIcon, SearchIcon } from "../../../../Components/Icons";
import { useSearchParams } from "react-router-dom";

interface FormValues {
  query: string | null;
  status: string | null;
}

export default function FiltroCupom() {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryStr = searchParams.get("q") || null;
  const statusStr = searchParams.get("status") || null;

  const { register, handleSubmit } = useForm<FormValues>({
    values: {
      query: queryStr,
      status: statusStr,
    },
  });

  const onSubmit = (data: FormValues) => {
    setSearchParams((state) => {
      if (data.query) {
        state.set("q", data.query);
      } else {
        state.delete("q");
      }

      if (data.status) {
        state.set("status", data.status);
      } else {
        state.delete("status");
      }

      return state;
    });
  };

  return (
    <ul className="navbar-nav flex-nowrap">
      <li className="nav-item dropdown no-arrow">
        <div className="nav-item dropdown no-arrow">
          <a
            className="dropdown-toggle nav-link cursor-pointer"
            aria-expanded="false"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
          >
            <button
              type="button"
              className="btn-outline py-2 px-3 me-md-3 mb-2 mb-md-0 d-flex justify-content-center align-items-center"
            >
              <p className="text-500-black-16 pe-2 m-0">Filtrar</p>
              <FiltroIcon />
            </button>
          </a>
          <div className="dropdown-menu shadow dropdown-menu-end p-3 dropdown-filtro">
            <p className="text-400-black-24 mb-2">Filtrar</p>
            <p className="text-400-black-14 mb-3">
              Faça uma busca por nome do código
            </p>

            <form onSubmit={handleSubmit(onSubmit)}>
              {/* Filtro por string */}
              <div className="input-group mb-3 rounded-pill">
                <button
                  className="btn btn-light py-0 pe-0"
                  style={{ borderRadius: "50px 0 0 50px" }}
                  type="submit"
                >
                  <SearchIcon />
                </button>
                <input
                  {...register("query")}
                  className="bg-light form-control border-0 small"
                  style={{ borderRadius: "0 50px 50px 0" }}
                  type="text"
                  placeholder="Faça uma busca"
                />
              </div>

              {/* Filtro por período: Ativo / Inativo */}

              <button
                type="submit"
                className="bc-btn bc-btn-primary px-3 py-2 w-100"
              >
                Filtrar
              </button>
            </form>
          </div>
        </div>
      </li>
    </ul>
  );
}
