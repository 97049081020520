import { WhiteLabel } from "../../Utils";

export default function LinkIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.334 4.6665H9.33398C8.96732 4.6665 8.66732 4.9665 8.66732 5.33317C8.66732 5.69984 8.96732 5.99984 9.33398 5.99984H11.334C12.434 5.99984 13.334 6.89984 13.334 7.99984C13.334 9.09984 12.434 9.99984 11.334 9.99984H9.33398C8.96732 9.99984 8.66732 10.2998 8.66732 10.6665C8.66732 11.0332 8.96732 11.3332 9.33398 11.3332H11.334C13.174 11.3332 14.6673 9.83984 14.6673 7.99984C14.6673 6.15984 13.174 4.6665 11.334 4.6665ZM5.33398 7.99984C5.33398 8.3665 5.63398 8.6665 6.00065 8.6665H10.0007C10.3673 8.6665 10.6673 8.3665 10.6673 7.99984C10.6673 7.63317 10.3673 7.33317 10.0007 7.33317H6.00065C5.63398 7.33317 5.33398 7.63317 5.33398 7.99984ZM6.66732 9.99984H4.66732C3.56732 9.99984 2.66732 9.09984 2.66732 7.99984C2.66732 6.89984 3.56732 5.99984 4.66732 5.99984H6.66732C7.03398 5.99984 7.33398 5.69984 7.33398 5.33317C7.33398 4.9665 7.03398 4.6665 6.66732 4.6665H4.66732C2.82732 4.6665 1.33398 6.15984 1.33398 7.99984C1.33398 9.83984 2.82732 11.3332 4.66732 11.3332H6.66732C7.03398 11.3332 7.33398 11.0332 7.33398 10.6665C7.33398 10.2998 7.03398 9.99984 6.66732 9.99984Z"
        fill={WhiteLabel().colorPrimary}
      />
    </svg>
  );
}
