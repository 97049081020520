export default function TicketIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.375 10.1625C21.7984 10.0761 22.1789 9.8461 22.4522 9.51145C22.7255 9.1768 22.8749 8.75802 22.875 8.32594V6C22.875 5.50272 22.6775 5.02581 22.3258 4.67417C21.9742 4.32254 21.4973 4.125 21 4.125H3C2.50272 4.125 2.02581 4.32254 1.67417 4.67417C1.32254 5.02581 1.125 5.50272 1.125 6V8.32594C1.12513 8.75802 1.27448 9.1768 1.54781 9.51145C1.82113 9.8461 2.20165 10.0761 2.625 10.1625C3.04886 10.2486 3.42993 10.4785 3.70364 10.8134C3.97736 11.1483 4.12688 11.5675 4.12688 12C4.12688 12.4325 3.97736 12.8517 3.70364 13.1866C3.42993 13.5215 3.04886 13.7514 2.625 13.8375C2.20165 13.9239 1.82113 14.1539 1.54781 14.4885C1.27448 14.8232 1.12513 15.242 1.125 15.6741V18C1.125 18.4973 1.32254 18.9742 1.67417 19.3258C2.02581 19.6775 2.50272 19.875 3 19.875H21C21.4973 19.875 21.9742 19.6775 22.3258 19.3258C22.6775 18.9742 22.875 18.4973 22.875 18V15.6741C22.8749 15.242 22.7255 14.8232 22.4522 14.4885C22.1789 14.1539 21.7984 13.9239 21.375 13.8375C20.9511 13.7514 20.5701 13.5215 20.2964 13.1866C20.0226 12.8517 19.8731 12.4325 19.8731 12C19.8731 11.5675 20.0226 11.1483 20.2964 10.8134C20.5701 10.4785 20.9511 10.2486 21.375 10.1625ZM3.375 15.9694C4.23945 15.7249 5.00047 15.205 5.54243 14.4886C6.08438 13.7721 6.37764 12.8983 6.37764 12C6.37764 11.1017 6.08438 10.2279 5.54243 9.51144C5.00047 8.79499 4.23945 8.27505 3.375 8.03063V6.375H7.875V17.625H3.375V15.9694ZM20.625 15.9694V17.625H10.125V6.375H20.625V8.03063C19.7606 8.27505 18.9995 8.79499 18.4576 9.51144C17.9156 10.2279 17.6224 11.1017 17.6224 12C17.6224 12.8983 17.9156 13.7721 18.4576 14.4886C18.9995 15.205 19.7606 15.7249 20.625 15.9694Z"
        fill="#003967"
      />
    </svg>
  );
}
