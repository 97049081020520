import React, { useState } from "react";
import { BsChevronDown, BsExclamationCircleFill } from "react-icons/bs";
import { ContaBancariaTipo } from "../../../Enums/ContaBancariaTipo";
import Input from "../../../Components/Inputs/Input";
import { useForm } from "react-hook-form";
import ConverterStringContaBancariaTipo from "../../../Utils/ConverterStringContaBancariaTipo";
import WhiteContainer from "../../../Components/WhiteContainer";
import Button from "../../../Components/Button";
import {
  SaquePostArgs,
  useSolicitarSaqueStore,
} from "../../../stores/solicitacao-saque.store";
import { SomenteNumeros } from "../../../Utils";

type FormValues = Pick<
  SaquePostArgs,
  "nomeTitular" | "agencia" | "banco" | "cpfCnpj" | "tipoConta" | "numeroConta"
>;

export default function Transferir() {
  const { model, setModel, setStep } = useSolicitarSaqueStore();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    values: model,
  });

  function onSubmit(data: FormValues) {
    console.log(model);

    setModel({
      ...model,
      ...data,
      cpfCnpj: SomenteNumeros(data.cpfCnpj ?? ""),
      agencia: SomenteNumeros(data.agencia ?? ""),
      numeroConta: SomenteNumeros(data.numeroConta ?? ""),
    });

    setStep("valor");
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <WhiteContainer>
        <div className="pt-4 p-lg-4 mb-4">
          <p className="text-500-black-20">Para quem você quer transferir?</p>
          <p className="text-400-black-14">
            Você pode transferir por Pix ou usar a conta cadastrada junto ao
            produtor
          </p>

          <hr className="mt-4" />

          <div className="row mt-5 p-0 p-lg-2">
            <div className="col-md-7">
              <label className="text-500-gray-18  mb-1">
                Nome e sobrenome do titular
              </label>
              <Input
                control={control}
                name="nomeTitular"
                validation={{ required: "Campo obrigatório" }}
                errors={errors}
                placeholder="Nome"
              />
              {errors.nomeTitular && (
                <p className="text-danger text-break m-0 mt-1">
                  {errors.nomeTitular?.message?.toString()}
                </p>
              )}

              <label className="text-500-gray-18  mb-1">
                Banco ou Instituição financeira
              </label>
              <Input
                control={control}
                name="banco"
                validation={{ required: "Campo obrigatório" }}
                errors={errors}
                placeholder=""
              />
              {errors.banco && (
                <p className="text-danger text-break m-0 mt-1">
                  {errors.banco?.message?.toString()}
                </p>
              )}

              <label className="text-500-gray-18  mb-1">Número da conta</label>
              <Input
                control={control}
                name="numeroConta"
                validation={{ required: "Campo obrigatório" }}
                errors={errors}
                placeholder="000000000000"
                maxLength={12}
              />
              {errors.numeroConta && (
                <p className="text-danger text-break m-0 mt-1">
                  {errors.numeroConta?.message?.toString()}
                </p>
              )}
            </div>

            <div className="col-md-5">
              <label className="text-500-gray-18  mb-1">CPF / CNPJ</label>
              <Input
                placeholder="999.999.999-99"
                control={control}
                name="cpfCnpj"
                validation={{ required: "Campo obrigatório" }}
                errors={errors}
              />
              {errors.cpfCnpj && (
                <p className="text-danger text-break m-0 mt-1">
                  {errors.cpfCnpj?.message?.toString()}
                </p>
              )}

              <label className="text-500-gray-18  mb-1">Agência</label>
              <Input
                control={control}
                name="agencia"
                validation={{ required: "Campo obrigatório" }}
                errors={errors}
                placeholder="00000"
                minLength={4}
                maxLength={5}
              />
              {errors.agencia && (
                <p className="text-danger text-break m-0 mt-1">
                  {errors.agencia?.message?.toString()}
                </p>
              )}

              <div>
                <p className="text-500-gray-18 mb-1">Tipo de conta</p>
                <select
                  {...register("tipoConta", {
                    required: "Campo obrigatório",
                  })}
                  className="form-select bc-input input-outlined p-2"
                >
                  {Object.values(ContaBancariaTipo).map((tipo) => (
                    <option key={tipo} value={tipo}>
                      {ConverterStringContaBancariaTipo(tipo)}
                    </option>
                  ))}
                </select>
                {errors.tipoConta && (
                  <p className="text-danger text-break m-0 mt-1">
                    {errors.tipoConta?.message?.toString()}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </WhiteContainer>

      <div className="row">
        <div className="mb-3 d-flex justify-content-lg-start align-items-center flex-column flex-sm-row">
          <BsExclamationCircleFill />
          <p className="ms-2 text-500-black-14 m-0 ms-lg-3 text-center text-sm-left">
            Revise os dados para garantir que a transferência seja realizada com
            sucesso
          </p>
        </div>

        <div className="col-xxl-8">
          <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between justify-content-xl-end align-items-center mb-3 mb-md-0">
            <Button
              type="button"
              variant="light"
              width={216}
              height={40}
              text="Voltar"
              className=" mb-3 mb-md-0 me-xl-3"
              onClick={() => setStep("inicial")}
            />

            <Button text="Continuar" width={216} height={40} type="submit" />
          </div>
        </div>
      </div>
    </form>
  );
}
