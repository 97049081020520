import { useNavigate } from "react-router-dom";
import Button from "../../../../Components/Button";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  DeleteIcon,
  EditIcon,
} from "../../../../Components/Icons";
import GrupoModel from "../../../../Interfaces/Models/GrupoModel";

interface Props {
  eventoId: string;
  grupo: GrupoModel;
  exibindoGrupo: string | null;
  toggleExibicao: (grupoId: string) => void;
  abrirModalExcluirGrupo: (g: GrupoModel) => void;
}

export default function BotoesListagemGrupo({
  eventoId,
  grupo,
  exibindoGrupo,
  toggleExibicao,
  abrirModalExcluirGrupo,
}: Props) {
  const navigate = useNavigate();

  return (
    <div className="d-flex flex-wrap justify-content-center justify-content-md-evenly align-items-center">
      {/* BTN: Editar */}
      <Button
        className="m-2 px-3 rounded-pill w-100 h-100"
        variant="light"
        text="Editar"
        height={24}
        width={100}
        icon={<EditIcon />}
        onClick={() =>
          navigate(`/eventos/${eventoId}/ingressos/editar-grupo/${grupo.id}`)
        }
      />

      {/* BTN: Excluir */}
      <Button
        className="m-2 px-3 rounded-pill w-100 h-100"
        variant="light"
        text="Excluir"
        height={24}
        width={100}
        icon={<DeleteIcon />}
        onClick={() => abrirModalExcluirGrupo(grupo)}
      />

      {/* BTN: Exibir */}
      <Button
        className="m-2 px-3 rounded-pill w-100 h-100"
        variant={grupo.id === exibindoGrupo ? "primary" : "light"}
        text="Exibir"
        height={24}
        width={100}
        icon={
          grupo.id === exibindoGrupo ? <ChevronUpIcon /> : <ChevronDownIcon />
        }
        onClick={() => toggleExibicao(grupo.id)}
      />
      {/* BTN: Adicionar ingressos */}
      <Button
        className="m-2 py-1 px-3 rounded-pill w-100 h-100"
        variant="primary"
        text="Adicionar ingressos"
        height={24}
        width={200}
        onClick={() =>
          navigate(
            `/eventos/${eventoId}/ingressos/grupo/${grupo.id}/cadastrar-ingresso`
          )
        }
      />
    </div>
  );
}
