import { ContaBancariaTipo } from "../Enums/ContaBancariaTipo";

export default function ConverterStringContaBancariaTipo(
  contaBancariaTipo: ContaBancariaTipo
): string {
  switch (contaBancariaTipo) {
    case ContaBancariaTipo.Corrente:
      return "Conta Corrente";
    case ContaBancariaTipo.Poupanca:
      return "Conta Poupança";
    case ContaBancariaTipo.ContaPagamento:
      return "Conta de Pagamento";

    default:
      return "Método de pagamento não identificado";
  }
}
