export default function LineChartIcon() {
  return (
    <svg
      className="d-flex align-self-end"
      style={{
        width: "100%",
        maxWidth: 198,
      }}
      height="116"
      viewBox="0 0 198 116"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M195 3C136.111 6.32109 141.335 90.7718 67.2486 78.4363C29.3937 72.1334 10.9636 75.5591 3 81"
        stroke="#303F9F"
        strokeWidth="5"
        strokeLinecap="round"
      />
      <path
        d="M197 4C137.191 7.32109 142.496 91.7718 67.2525 79.4363C28.8061 73.1334 9.08806 76.5591 1 82V116H197V4Z"
        fill="url(#paint0_linear_314_4467)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_314_4467"
          x1="99"
          y1="27"
          x2="99"
          y2="116"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E5EBFF" />
          <stop offset="1" stopColor="#E5EBFF" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
