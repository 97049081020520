import { create } from "zustand";
import { ContaBancariaTipo } from "../Enums/ContaBancariaTipo";
import Transferencia from "../Enums/Transferencia";

export interface SaquePostArgs {
  agencia: string | null;
  banco: string | null;
  cpfCnpj: string | null;
  dataAgendamento: string;
  descricao: string;
  favorecidoId: string | null;
  formaTransferencia: Transferencia | null;
  nomeTitular: string | null;
  numeroConta: string | null;
  tipoConta: ContaBancariaTipo | null;
  valorCentavos: number;
}

type pageSteps =
  | "inicial"
  | "novo-saque"
  | "valor"
  | "data"
  | "revisao"
  | "confirmar-codigo";

type SolicitacaoSaqueStore = {
  step: pageSteps;
  setStep: (step: pageSteps) => void;
  model: SaquePostArgs;
  setModel: (model: SaquePostArgs) => void;
  saqueId: string | null;
  setSaqueId: (saqueId: string) => void;
  resetModel: () => void;
};

export const useSolicitarSaqueStore = create<SolicitacaoSaqueStore>((set) => ({
  step: "inicial",
  setStep: (step) => set({ step }),
  model: {
    formaTransferencia: Transferencia.TED,
  } as SaquePostArgs,
  setModel: (model) => set({ model }),
  saqueId: null,
  setSaqueId: (saqueId) => set({ saqueId }),
  resetModel: () =>
    set({ model: { formaTransferencia: Transferencia.TED } as SaquePostArgs }),
}));
