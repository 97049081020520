import { useParams } from "react-router-dom";
import BreadCrumb, { Page } from "../../../Components/Breadcrumb";
import MainLayout from "../../../Components/Layouts/Main";
import WhiteContainer from "../../../Components/WhiteContainer";
import ListagemUsuarios from "./Components/ListagemUsuarios";
import { useEffect } from "react";
import { AddIcon, ChevronUpIcon } from "../../../Components/Icons";
import useObterNomeEventoHeader from "../../../Hooks/useObterNomeEventoHeader";
import { usePermissoesStore } from "../../../Contexts/PermissoesStore";
import { EditarMembroProducao } from "./Components/EditarMembroProducao";
import { CadastrarMembroProducao } from "./Components/CadastrarMembroProducao";

export default function Permissoes() {
  const { eventoId } = useParams();

  const { obterNomeEvento } = useObterNomeEventoHeader();

  const breadCrumbHistory: Page[] = [
    {
      link: "/",
      name: "dashboard",
    },
    {
      link: "/eventos",
      name: "Eventos",
    },
    {
      link: `/eventos/${eventoId}/permissoes`,
      name: "Permissões",
    },
  ];

  const { status, setCadastrando, setInicial } = usePermissoesStore();

  const handleClick = () => {
    if (status === "inicial") {
      setCadastrando();
    } else if (status === "editando" || status === "cadastrando") {
      setInicial();
    }
  };

  useEffect(() => {
    obterNomeEvento();
    setInicial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout>
      <div className="mb-3">
        <BreadCrumb history={breadCrumbHistory} />
      </div>

      <WhiteContainer containerWidth={12} containerPadding={4}>
        <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-sm-between align-items-center">
          <p className="title-h1 text-black mb-2">Permissões</p>
          <button
            className="bc-btn bc-btn-primary py-2 px-3 d-flex justify-content-center align-items-center"
            onClick={handleClick}
          >
            <p className="text-500-white-16 pe-2 m-0">
              {status === "inicial" && "Cadastrar"}
              {(status === "editando" || status === "cadastrando") &&
                "Cancelar"}
            </p>

            {(status === "editando" || status === "cadastrando") && (
              <ChevronUpIcon />
            )}
            {status === "inicial" && <AddIcon />}
          </button>
        </div>
      </WhiteContainer>

      {status === "cadastrando" && <CadastrarMembroProducao />}

      {status === "editando" && <EditarMembroProducao />}

      <ListagemUsuarios />
    </MainLayout>
  );
}
