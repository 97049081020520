export default function BannerIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 16.5L12 21.75L21 16.5"
        stroke={window.location.pathname.includes("/banners") ? "#fff" : "#9d9d9d"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 12L12 17.25L21 12"
        stroke={window.location.pathname.includes("/banners") ? "#fff" : "#9d9d9d"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 7.5L12 12.75L21 7.5L12 2.25L3 7.5Z"
        stroke={window.location.pathname.includes("/banners") ? "#fff" : "#9d9d9d"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
