import { WhiteLabel } from "../../Utils";

export default function LetterIcon() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.834 2.6665H3.16732C2.43398 2.6665 1.83398 3.2665 1.83398 3.99984V11.9998C1.83398 12.7332 2.43398 13.3332 3.16732 13.3332H9.16732V11.9998H3.16732V5.33317L7.79398 8.2265C8.22732 8.49984 8.77398 8.49984 9.20732 8.2265L13.834 5.33317V8.6665H15.1673V3.99984C15.1673 3.2665 14.5673 2.6665 13.834 2.6665ZM8.50065 7.33317L3.16732 3.99984H13.834L8.50065 7.33317ZM13.1673 10.8065C13.1673 10.5065 13.5273 10.3598 13.734 10.5732L15.594 12.4332C15.7273 12.5665 15.7273 12.7732 15.594 12.9065L13.734 14.7665C13.5273 14.9732 13.1673 14.8265 13.1673 14.5265V13.3332H11.1673C10.8007 13.3332 10.5007 13.0332 10.5007 12.6665C10.5007 12.2998 10.8007 11.9998 11.1673 11.9998H13.1673V10.8065Z"
        fill={WhiteLabel().colorPrimary}
      />
    </svg>
  );
}
