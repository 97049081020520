import MetodoPagamento from "../Enums/MetodoPagamento";

export default function ConverterStringMetodoPagamento(
  m: MetodoPagamento,
): string {
  switch (m) {
    case MetodoPagamento.Credito:
      return "Cartão de crédito";
    case MetodoPagamento.PIX:
      return "PIX";
    case MetodoPagamento.Dinheiro:
      return "Dinheiro";

    default:
      return "Método de pagamento não identificado";
  }
}
