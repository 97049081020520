export default function VendasIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3 10.9C10.03 10.31 9.3 9.7 9.3 8.75C9.3 7.66 10.31 6.9 12 6.9C13.42 6.9 14.13 7.44 14.39 8.3C14.51 8.7 14.84 9 15.26 9H15.56C16.22 9 16.69 8.35 16.46 7.73C16.04 6.55 15.06 5.57 13.5 5.19V4.5C13.5 3.67 12.83 3 12 3C11.17 3 10.5 3.67 10.5 4.5V5.16C8.56 5.58 7 6.84 7 8.77C7 11.08 8.91 12.23 11.7 12.9C14.2 13.5 14.7 14.38 14.7 15.31C14.7 16 14.21 17.1 12 17.1C10.35 17.1 9.5 16.51 9.17 15.67C9.02 15.28 8.68 15 8.27 15H7.99C7.32 15 6.85 15.68 7.1 16.3C7.67 17.69 9 18.51 10.5 18.83V19.5C10.5 20.33 11.17 21 12 21C12.83 21 13.5 20.33 13.5 19.5V18.85C15.45 18.48 17 17.35 17 15.3C17 12.46 14.57 11.49 12.3 10.9Z"
        fill={window.location.pathname.includes("/vendas") ? "#fff" : "#9d9d9d"}
      />
    </svg>
  );
}
