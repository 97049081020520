import clsx from "clsx";
import { useState } from "react";
import toast from "react-hot-toast";
import Button from "../../../../Components/Button";
import {
  CortesiaIcon,
  DeleteIcon,
  EditIcon,
} from "../../../../Components/Icons";
import IngressoSituacao from "../../../../Enums/IngressoSituacao";
import IngressoModel from "../../../../Interfaces/Models/IngressoModel";
import AxiosClient from "../../../../Services/AxiosClient";
import AxiosErrorHandler from "../../../../Services/AxiosErrorHandler";

interface Props {
  eventoId: string;
  grupoId: string;
  ingresso: IngressoModel;
  abrirModalExcluirIngresso: (ingresso: IngressoModel) => void;
  abrirModalCortesia: (ingresso: IngressoModel) => void;
  editarIngresso: () => void;
  updateSituacaoIngresso: (ingresso: IngressoModel) => void;
}

export default function BotoesListagemIngresso({
  eventoId,
  grupoId,
  ingresso,
  abrirModalExcluirIngresso,
  abrirModalCortesia,
  editarIngresso,
  updateSituacaoIngresso,
}: Props) {
  const [loading, setLoading] = useState<boolean>(false);

  const toggleSituacaoIngresso = async () => {
    setLoading(true);

    toast.promise(
      AxiosClient.post(
        `/eventos/${eventoId}/grupos/${grupoId}/ingressos/${ingresso.id}/alternar-situacao`,
      )
        .then(() => {
          updateSituacaoIngresso(ingresso);
        })
        .finally(() => setLoading(false)),
      {
        loading: "Alterando status...",
        success: "Status alterado com sucesso!",
        error: (error) => AxiosErrorHandler(error),
      },
    );
  };

  return (
    <div className="d-flex flex-wrap justify-content-center align-items-center">
      <Button
        className="m-2 rounded-pill w-100 h-100"
        variant="light"
        text="Enviar cortesia"
        height={24}
        width={200}
        icon={<CortesiaIcon />}
        onClick={() => abrirModalCortesia(ingresso)}
      />

      <Button
        className="m-2 px-3 rounded-pill w-100 h-100"
        variant="light"
        text="Editar ingresso"
        height={24}
        width={200}
        icon={<EditIcon />}
        onClick={editarIngresso}
      />

      <Button
        className="m-2 px-3 rounded-pill w-100 h-100"
        variant="light"
        text="Excluir ingresso"
        height={24}
        width={200}
        icon={<DeleteIcon />}
        onClick={() => abrirModalExcluirIngresso(ingresso)}
      />

      <div className="w-100" style={{ maxWidth: 150 }}>
        <div className="form-check form-switch d-flex align-items-center ps-5">
          <input
            className="form-check-input mb-1 cursor-pointer"
            style={{ width: "3.3em", height: "1.7em" }}
            type="checkbox"
            role="switch"
            id={`ing_situacao_${ingresso.id}`}
            checked={ingresso.situacao === IngressoSituacao.Ativo}
            onChange={toggleSituacaoIngresso}
            disabled={loading}
          />
          <label
            className={clsx(
              "form-check-label px-2 cursor-pointer",
              ingresso.situacao === IngressoSituacao.Ativo &&
                "text-500-black-16",
              (ingresso.situacao === IngressoSituacao.Inativo || loading) &&
                "text-400-darkest-16",
            )}
            htmlFor={`ing_situacao_${ingresso.id}`}
          >
            {loading && "Alterando..."}

            {!loading &&
              ingresso.situacao === IngressoSituacao.Ativo &&
              "Ativo"}

            {!loading &&
              ingresso.situacao === IngressoSituacao.Inativo &&
              "Desativado"}
          </label>
        </div>
      </div>
    </div>
  );
}
