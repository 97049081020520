import toast from "react-hot-toast";
import Button from "../../../../Components/Button";
import { DeleteIcon, EditIcon, LinkIcon } from "../../../../Components/Icons";
import CodigoAcessoStatus from "../../../../Enums/CodigoAcessoStatus";
import { useState } from "react";
import AxiosClient from "../../../../Services/AxiosClient";
import AxiosErrorHandler from "../../../../Services/AxiosErrorHandler";
import { WhiteLabel } from "../../../../Utils";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import CupomDescontoModel from "../../../../Interfaces/Models/CupomDescontoModel";

interface Props {
  cupom: CupomDescontoModel;
  eventoId: string;
  abrirModalExcluirCupom: (codigo: CupomDescontoModel) => void;
}

export default function BotoesListagemCupom({
  cupom,
  eventoId,
  abrirModalExcluirCupom,
}: Props) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div className="d-flex flex-wrap justify-content-center justify-content-md-evenly align-items-center">
      <p></p>
      <Button
        className="m-2 px-3 rounded-pill w-100 h-100"
        variant="light"
        text="Editar"
        height={24}
        width={150}
        icon={<EditIcon />}
        onClick={() =>
          navigate(
            `/eventos/${eventoId}/cupom-desconto/editar-cupom/${cupom.id}`
          )
        }
      />

      <Button
        className="m-2 px-3 rounded-pill w-100 h-100"
        variant="light"
        text="Excluir"
        height={24}
        width={150}
        icon={<DeleteIcon />}
        onClick={() => abrirModalExcluirCupom(cupom)}
      />
    </div>
  );
}
