export default function ImgPreviewIcon() {
  return (
    <svg
      width="100%"
      height="160"
      viewBox="0 0 260 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M143.429 32H116.571C115.157 32 114 33.1571 114 34.5714V56.9107C114 58.325 115.157 59.4821 116.571 59.4821H143.429C144.843 59.4821 146 58.325 146 56.9107V34.5714C146 33.1571 144.843 32 143.429 32ZM143.429 56.9036L143.425 56.9071H116.579L116.575 56.9036V49.3036L123.204 42.525L130.964 52.1643C131.393 52.65 132.114 52.7429 132.65 52.3786L138.382 48.5036L143.429 50.8786V56.9036ZM139.211 45.9036L143.429 47.6429L143.425 34.575L143.421 34.5714H116.575L116.571 34.575V45.625L122.329 39.7357C122.579 39.4821 122.925 39.3393 123.282 39.35C123.639 39.3607 123.979 39.5179 124.214 39.7857L132.146 49.6143L137.711 45.8607C138.168 45.55 138.771 45.5679 139.211 45.9036ZM136.643 37.2071C134.654 37.2071 133.036 38.825 133.036 40.8143C133.036 42.8035 134.654 44.4214 136.643 44.4214C138.632 44.4214 140.25 42.8035 140.25 40.8143C140.25 38.825 138.632 37.2071 136.643 37.2071ZM135.607 40.8143C135.607 41.3857 136.071 41.85 136.643 41.85C137.214 41.85 137.679 41.3857 137.679 40.8143C137.679 40.2428 137.214 39.7785 136.643 39.7785C136.071 39.7785 135.607 40.2428 135.607 40.8143Z"
        fill="#50B7FF"
      />
      <path d="M2 34V2H34" stroke="#50B7FF" strokeWidth="3" />
      <path d="M226 2L258 2L258 34" stroke="#50B7FF" strokeWidth="3" />
      <path d="M258 58L258 90L226 90" stroke="#50B7FF" strokeWidth="3" />
      <path d="M34 90L2 90L2 58" stroke="#50B7FF" strokeWidth="3" />
    </svg>
  );
}
