import "moment/locale/pt-br";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ptBR } from "date-fns/locale";
import Button from "../../../../Components/Button";
import { DownloadIcon } from "../../../../Components/Icons";
import { useParams, useSearchParams } from "react-router-dom";
import moment from "moment";
import { useEffect, useState } from "react";
import AxiosClient from "../../../../Services/AxiosClient";
import { ListServiceResult } from "../../../../Interfaces";
import toast from "react-hot-toast";
import AxiosErrorHandler from "../../../../Services/AxiosErrorHandler";
import { FormatarCentavosParaReais } from "../../../../Utils";
import DarkGrayCard from "../../../../Components/DarkGrayCard";
import Loading from "../../../../Components/Loading";
import { EventoFinanceiroSaqueAgendamentoModel } from "../../../../Interfaces/Models/EventoFinanceiroSaqueAgendamentoModel";
registerLocale("pt-BR", ptBR);
moment.locale("pt-br");

export const AgendamentoSaldo = () => {
  const { eventoId } = useParams();

  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 992);
  const [searchParams, setSearchParams] = useSearchParams();
  const agendamento = searchParams.get("data");

  const [acessoNegadoAgendamentos, setAcessoNegadoAgendamentos] =
    useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [exportingCsv, setExportingCsv] = useState<boolean>(false);

  const [agendamentoData, setAgendamentoData] =
    useState<EventoFinanceiroSaqueAgendamentoModel | null>(null);

  const getRequestPathWIthQueryParams = (): string => {
    let url: string = `/eventos/${eventoId}/financeiro/saques/agendamentos`;

    if (agendamento) {
      url += `?data=${agendamento}`;
    } else {
      url += `?data=${moment().format("YYYY-MM-DD")}`;
    }

    return url;
  };

  const obterAgendamentos = async (): Promise<void> => {
    setLoading(true);

    AxiosClient.get<ListServiceResult<EventoFinanceiroSaqueAgendamentoModel>>(
      getRequestPathWIthQueryParams()
    )
      .then(({ data: { data } }) => {
        setAgendamentoData(
          data as EventoFinanceiroSaqueAgendamentoModel | null
        );
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          setAcessoNegadoAgendamentos(true);
          return;
        }
        toast.error(AxiosErrorHandler(error));
      })
      .finally(() => setLoading(false));
  };

  const getRequestPathWIthQueryParamsCsv = (): string => {
    let url = `/eventos/${eventoId}/financeiro/saques/agendamentos/csv`;

    if (agendamento) {
      url += `?data=${agendamento}`;
    }

    return url;
  };

  const exportarCsv = async (): Promise<void> => {
    setExportingCsv(true);

    toast.promise(
      AxiosClient.get(getRequestPathWIthQueryParamsCsv(), {
        responseType: "blob",
      })
        .then(({ data }) => {
          const blob = new Blob([data], {
            type: "text/csv",
          });

          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);

          const dataSelected = agendamento ? moment(agendamento) : moment();

          link.download = `total-mes-de-${moment(dataSelected).format(
            "MMMM-[de]-YYYY"
          )}.csv`;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .finally(() => setExportingCsv(false)),
      {
        loading: "Exportando CSV...",
        success: "CSV exportado com sucesso!",
        error: (error) => AxiosErrorHandler(error),
      }
    );
  };

  const handleDateChange = (date: Date | null) => {
    setSearchParams((state) => {
      if (date) {
        const data = moment(date);
        data.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        state.set("data", data.format("YYYY-MM-DD"));
      } else {
        state.delete("data");
      }

      return state;
    });
  };

  // Controle do inline do DatePicker
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 992);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    obterAgendamentos();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agendamento]);

  return (
    <div className="col-xl-8">
      <div className="shadow-sm bg-white p-3 h-100">
        <div className="row">
          <div className="col-xl-6">
            <div className="d-flex flex-column align-items-center justify-content-center">
              <span className="text-400-black-24 fw-bold">
                Agendamento de saldo
              </span>
              <p
                className="text-400-darkest-16 mt-1"
                style={{ color: "#818187" }}
              >
                Acompanhe aqui seus agendamentos de saques
              </p>
            </div>
            {loading ? (
              <div className="d-flex align-items-center justify-content-center">
                <Loading />
              </div>
            ) : acessoNegadoAgendamentos ? (
              <div className="col-xl-12 mb-3">
                <DarkGrayCard message="Você não tem permissão para acessar o histórico de pagamentos." />
              </div>
            ) : agendamentoData?.agendamentos.length === 0 ? (
              <div className="col-xl-12 mb-3">
                <DarkGrayCard message="Você ainda não tem agendamentos por aqui!" />
              </div>
            ) : (
              <>
                {agendamentoData?.agendamentos.map((agendamento) => (
                  <div className="mb-2">
                    <div
                      className="d-flex align-items-center justify-content-center p-2 p-lg-0"
                      style={{ background: "#2B1479" }}
                    >
                      <span className="text-400-white-18 fw-bold">
                        {moment(agendamento.data).format(
                          "D [de] MMMM [de] YYYY"
                        )}
                      </span>
                    </div>
                    <div className="d-flex align-items-center text-400-darkest-16">
                      <span className="fs-2" style={{ color: "#52CC6C" }}>
                        •
                      </span>
                      <span
                        className="mx-2 mt-1 text-break"
                        style={{ color: "#505055" }}
                      >
                        {FormatarCentavosParaReais(agendamento.valor)}
                      </span>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>

          <div className="col-xl-6">
            {/* Calendário */}
            <div className="form-group d-flex align-items-center justify-content-center mt-2 mt-lg-0">
              <DatePicker
                locale="pt-BR"
                dateFormat="dd/MM/yyyy"
                inline={isLargeScreen}
                isClearable
                withPortal
                showIcon
                selected={
                  agendamento ? moment(agendamento).toDate() : new Date()
                }
                onChange={handleDateChange}
                className="form-control cursor-pointer custom-datepicker"
              />
            </div>

            <div className="d-flex align-items-center justify-content-center mt-4">
              <span className="text-400-black-24 fw-semibold text-black text-break">
                Total do mês:{" "}
                {loading ? (
                  <span
                    className="placeholder"
                    style={{
                      width: "90px",
                      height: "10px",
                      background: "#858796",
                    }}
                  ></span>
                ) : (
                  <span>
                    {FormatarCentavosParaReais(
                      agendamentoData?.saldoAgendamentosMes || 0
                    )}
                  </span>
                )}
              </span>
            </div>

            <div className="d-flex align-items-center justify-content-center mt-2 mb-2">
              <Button
                className="mt-3 px-3 rounded-pill w-100"
                variant="light"
                text="Exportar excel"
                height={24}
                width={300}
                icon={<DownloadIcon />}
                onClick={exportarCsv}
                disabled={exportingCsv}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
