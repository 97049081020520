import { useParams, useSearchParams } from "react-router-dom";
import BreadCrumb, { Page } from "../../../../Components/Breadcrumb";
import MainLayout from "../../../../Components/Layouts/Main";
import WhiteContainer from "../../../../Components/WhiteContainer";
import EmptyModal from "../../../../Components/Modals/EmptyModal";
import AxiosClient from "../../../../Services/AxiosClient";
import { ListServiceResult } from "../../../../Interfaces";
import AxiosErrorHandler from "../../../../Services/AxiosErrorHandler";
import EventoVinculoModel from "../../../../Interfaces/Models/EventoVinculoModel";
import PromptModal from "../../../../Components/Modals/PromptModal";
import { DangerModalIcon, SearchIcon } from "../../../../Components/Icons";
import SkeletonListagem from "../../../../Components/SkeletonListagem";
import DarkGrayCard from "../../../../Components/DarkGrayCard";
import useObterNomeEventoHeader from "../../../../Hooks/useObterNomeEventoHeader";
import Loading from "../../../../Components/Loading";
import { useEffect, useState } from "react";
import clsx from "clsx";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import Button from "../../../../Components/Button";

export default function PortariaEventosVinculados() {
  const { eventoId } = useParams();

  const { obterNomeEvento } = useObterNomeEventoHeader();

  const [loadingDesvincular, setLoadingDesvincular] = useState<boolean>(false);
  const [loadingLista, setLoadingLista] = useState<boolean>(false);
  const [loadingModal, setLoadingModal] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [eventosParaVincular, setEventosParaVincular] = useState<
    EventoVinculoModel[]
  >([]);

  const [acessoNegadoEventos, setAcessoNegadoEventos] =
    useState<boolean>(false);

  const [acessoNegadoVinculoEvento, setAcessoNegadoVinculoEvento] =
    useState<boolean>(false);

  const [eventosTmp, setEventosTmp] = useState<EventoVinculoModel[]>([]);

  const [eventosJaVinculados, setEventosJaVinculados] = useState<
    EventoVinculoModel[]
  >([]);

  const [eventoVinculadoExcluir, setEventoVinculadoExcluir] =
    useState<EventoVinculoModel>({} as EventoVinculoModel);

  const [modalExluirEventoVinculado, setModalExcluirEventoVinculado] = useState<
    string | null
  >(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const pesquisa = searchParams.get("q") || "";

  function handleFiltro(data: { pesquisa: string }): void {
    setSearchParams((state) => {
      state.set("q", data.pesquisa);

      return state;
    });
  }

  const abrirModalExcluirIngresso = (evento: EventoVinculoModel): void => {
    setEventoVinculadoExcluir(evento);
    setModalExcluirEventoVinculado(evento.id);
  };

  // GET eventos que podem ser vinculados
  const getEventosParaVincular = async (): Promise<void> => {
    setLoadingModal(true);

    AxiosClient.get<ListServiceResult<EventoVinculoModel>>(
      `/eventos/${eventoId}/vinculos/possiveis?searchString=${pesquisa}`
    )
      .then(({ data: result }) => {
        setEventosParaVincular(result.data || []);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          setAcessoNegadoVinculoEvento(true);
          return;
        }
        toast.error(AxiosErrorHandler(error));
      })
      .finally(() => setLoadingModal(false));
  };

  // GET do eventos já vinculados
  const getEventosJaVinculados = async (): Promise<void> => {
    setLoadingLista(true);
    setEventosJaVinculados([]);

    AxiosClient.get<ListServiceResult<EventoVinculoModel>>(
      `/eventos/${eventoId}/vinculos`
    )
      .then(({ data }) => {
        setEventosJaVinculados(data.data || []);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          setAcessoNegadoEventos(true);
          return;
        }
        toast.error(AxiosErrorHandler(error));
      })
      .finally(() => setLoadingLista(false));
  };

  const cadastrarVinculo = async (eventosTmp: EventoVinculoModel[]) => {
    const eventosIds = eventosTmp.map((evento) => evento.id);

    toast.promise(
      AxiosClient.post(`/eventos/${eventoId}/vinculos`, {
        eventos: eventosIds,
      }).then(() => {
        getEventosJaVinculados();
        cancelarSelecaoEvento();
      }),
      {
        loading: "Cadastrando vínculo...",
        success: () => {
          return "Vínculo cadastrado com sucesso!";
        },
        error: (error) => AxiosErrorHandler(error),
      }
    );
  };

  const desvincularEvento = async (): Promise<void> => {
    setLoadingDesvincular(true);

    toast.promise(
      AxiosClient.delete(
        `/eventos/${eventoId}/vinculos/${eventoVinculadoExcluir.id}`
      ).finally(() => {
        setLoadingDesvincular(false);
      }),
      {
        loading: "Desvinculando...",
        success: () => {
          setModalExcluirEventoVinculado(null);
          cancelarSelecaoEvento();
          getEventosJaVinculados();

          return "Evento desvinculado com sucesso!";
        },
        error: (error) => AxiosErrorHandler(error),
      }
    );
  };

  const selecionarEvento = (evento: EventoVinculoModel): void => {
    const eventos = [...eventosTmp];
    const index = eventos.indexOf(evento);

    if (index > -1) {
      eventos.splice(index, 1);
    } else {
      eventos.push(evento);
    }

    setEventosTmp(eventos);
  };

  const cancelarSelecaoEvento = (): void => {
    setEventosTmp([]);
    setModalOpen(false);

    if (pesquisa) {
      setSearchParams((state) => {
        state.delete("q");
        return state;
      });

      reset();
    }

    getEventosParaVincular();
  };

  const confirmarSelecaoEvento = (): void => {
    setModalOpen(false);

    const eventosSelecionadosIds = eventosTmp.map((evento) => evento.id);

    if (eventosSelecionadosIds.length === 0) {
      toast.error("Nenhum evento selecionado.");
      return;
    }

    const eventosSelecionadosParaVincular = eventosParaVincular.filter(
      (evento) => eventosSelecionadosIds.includes(evento.id)
    );

    cadastrarVinculo(eventosSelecionadosParaVincular);
  };

  useEffect(() => {
    getEventosParaVincular();
    getEventosJaVinculados();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pesquisa]);

  useEffect(() => {
    obterNomeEvento();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { handleSubmit, register, reset } = useForm<{ pesquisa: string }>();

  const breadCrumbHistory: Page[] = [
    { link: "/", name: "Dashboard" },
    { link: "/eventos", name: "Eventos" },
    {
      link: `/eventos/${eventoId}/portaria`,
      name: "Portaria - Vincular eventos",
    },
  ];

  return (
    <MainLayout>
      <div className="mb-3">
        <BreadCrumb history={breadCrumbHistory} />
      </div>

      <WhiteContainer containerPadding={4} containerWidth={12}>
        <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-sm-between align-items-center">
          <p className="title-h1 text-black mb-2">Eventos Vinculados</p>
          <div>
            <Button
              text="Vincular Evento"
              className="bc-btn bc-btn-primary py-2 px-3 d-flex justify-content-center align-items-center"
              onClick={() => {
                if (acessoNegadoVinculoEvento) {
                  toast.error(
                    "Você não possui permissão para vincular eventos."
                  );
                  return;
                }
                setModalOpen(true);
              }}
              disabled={acessoNegadoVinculoEvento || loadingLista}
            />

            <EmptyModal
              isOpen={modalOpen}
              close={cancelarSelecaoEvento}
              title="Selecione os eventos"
            >
              <form onSubmit={handleSubmit(handleFiltro)}>
                {/* Filtro por string */}
                <div className="input-group mb-3 rounded-pill">
                  <button
                    className="btn btn-light py-0 pe-0"
                    style={{ borderRadius: "50px 0 0 50px" }}
                    type="submit"
                  >
                    <SearchIcon />
                  </button>
                  <input
                    className="bg-light form-control border-0 small"
                    style={{ borderRadius: "0 50px 50px 0" }}
                    type="text"
                    placeholder="Faça uma busca"
                    {...register("pesquisa")}
                  />
                </div>

                {pesquisa && (
                  <button
                    type="button"
                    onClick={() => {
                      if (pesquisa) {
                        setSearchParams((state) => {
                          state.delete("q");
                          return state;
                        });

                        reset();
                      }
                    }}
                    className="bc-btn bc-btn-primary px-3 py-2 w-100 mt-2"
                  >
                    Limpar filtro
                  </button>
                )}
              </form>

              <div
                className="pe-2 mb-4 mt-3"
                style={{ maxHeight: 340, overflowY: "auto" }}
              >
                {loadingModal && <Loading container="0" />}

                {!loadingModal &&
                  eventosParaVincular.map((e) => (
                    <div
                      key={e.id}
                      className={clsx(
                        "card-cinza-claro cursor-pointer p-1 mb-1",
                        eventosTmp.includes(e) && "border border-2"
                      )}
                      onClick={() => selecionarEvento(e)}
                    >
                      <div className="d-flex align-items-center p-2">
                        <input
                          type="checkbox"
                          className="cursor-pointer form-check-input mt-0"
                          style={{ width: 20, height: 20, marginRight: 10 }}
                          checked={eventosTmp.includes(e)}
                          onChange={() => selecionarEvento(e)}
                        />
                        <p className="text-400-dark-16 text-break m-0">
                          {e.titulo}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>

              <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-sm-between align-items-center mb-3">
                <button
                  className="bc-btn bc-btn-light px-3 py-2 mb-3 mb-sm-0"
                  style={{ width: 175 }}
                  onClick={cancelarSelecaoEvento}
                >
                  Cancelar
                </button>
                <button
                  className="bc-btn bc-btn-primary px-3 py-2 h-100"
                  style={{ width: 175 }}
                  onClick={confirmarSelecaoEvento}
                >
                  Confirmar
                </button>
              </div>
            </EmptyModal>
          </div>
        </div>
      </WhiteContainer>

      <WhiteContainer
        rowMarginBottom={4}
        containerPadding={4}
        containerWidth={12}
      >
        {loadingLista ? (
          <SkeletonListagem />
        ) : acessoNegadoEventos ? (
          <div className="mb-3">
            <DarkGrayCard message="Acesso negado para listagem de eventos vinculados." />
          </div>
        ) : eventosJaVinculados.length === 0 ? (
          <DarkGrayCard message="Não há eventos por aqui!" />
        ) : (
          <div className="row">
            <div className="d-none d-lg-block col-lg-12 mb-3">
              <div className="row">
                <div className="col-lg-6">
                  <p className="text-500-black-16 m-0">Nome do evento</p>
                </div>
                <div className="col-lg-3">
                  <p className="text-500-black-16 m-0">Data</p>
                </div>
                <div className="col-lg-3">
                  <p className="text-500-black-16 m-0">Ações</p>
                </div>
              </div>
            </div>

            {eventosJaVinculados.map((evento) => (
              <div className="col-lg-12" key={evento.id}>
                <div className="card-cinza-claro p-3 mb-3">
                  <div className="row">
                    <div className="col-lg-6 align-self-center">
                      <p className="d-block d-lg-none text-500-black-16 m-0">
                        Nome do evento
                      </p>
                      <p className="text-500-darkest-14 m-0">{evento.titulo}</p>
                    </div>
                    <div className="col-lg-3 align-self-center">
                      <p className="d-block d-lg-none text-500-black-16 m-0">
                        Data
                      </p>
                      <p className="text-500-darkest-14 m-0">
                        {evento.criadoEm}
                      </p>
                    </div>
                    <div className="col-lg-3 align-self-center">
                      <button
                        className="bc-btn bc-btn-light px-3 me-3 h-100 mt-2 mt-lg-0"
                        style={{ minWidth: 200 }}
                        onClick={() => abrirModalExcluirIngresso(evento)}
                      >
                        Desvincular
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </WhiteContainer>

      <PromptModal
        isOpen={modalExluirEventoVinculado !== null}
        close={() => setModalExcluirEventoVinculado(null)}
        icon={<DangerModalIcon />}
        title="Excluir vinculo"
        subtitle={`Tem certeza que deseja excluir o vinculo? "${eventoVinculadoExcluir.titulo}" ? Após o cancelamento seu vinculo ficará inativo`}
        cancelText="Cancelar"
        cancelAction={() => setModalExcluirEventoVinculado(null)}
        confirmText="Excluir"
        confirmAction={desvincularEvento}
        loading={loadingDesvincular}
      />
    </MainLayout>
  );
}
