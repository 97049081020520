import React, { useEffect, useState } from "react";
import WhiteContainer from "../../../Components/WhiteContainer";
import Button from "../../../Components/Button";
import { useSolicitarSaqueStore } from "../../../stores/solicitacao-saque.store";
import axios from "axios";
import AxiosClient from "../../../Services/AxiosClient";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import AxiosErrorHandler from "../../../Services/AxiosErrorHandler";
import { useSaldoStore } from "../../../stores/saldo.store";

export default function ConfirmarCodigo() {
  const { model, setStep, saqueId } = useSolicitarSaqueStore();

  const { updateSaldo } = useSaldoStore();

  const [loading, setLoading] = useState<boolean>(false);

  const { eventoId } = useParams();

  const [code, setCode] = useState<string[]>(["", "", "", "", "", ""]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = e.target.value;

    // Permitir apenas um caractere por input
    if (value.length > 1 || isNaN(Number(value))) return;

    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    // Muda o foco para o próximo input se houver valor
    if (value && index < code.length - 1) {
      const nextInput = document.getElementById(`codeInput${index + 1}`);
      if (nextInput) {
        (nextInput as HTMLInputElement).focus();
      }
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace" && index > 0 && !code[index]) {
      // Muda o foco para o input anterior ao apagar
      const prevInput = document.getElementById(`codeInput${index - 1}`);
      if (prevInput) {
        (prevInput as HTMLInputElement).focus();
      }
    }
  };

  const handleSubmit = () => {
    setLoading(true);

    const fullCode = code.join("");

    AxiosClient.put(
      `/eventos/${eventoId}/financeiro/saques/${saqueId}/confirmar`,
      { codigo: fullCode }
    )
      .then(() => {
        toast.success("Código confirmado com sucesso");
        setStep("inicial");

        updateSaldo();
      })
      .catch((err) => {
        AxiosErrorHandler(err);
        toast.error("Falha ao enviar código");
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!saqueId) {
      setStep("revisao");
    }
  }, []);

  return (
    <>
      <WhiteContainer>
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "466px" }}
        >
          <div
            style={{
              marginTop: "-200px",
              width: "406px",
              height: "95px",
              textAlign: "center",
            }}
          >
            <p className="title-h1" style={{ color: "#000000" }}>
              Enviamos um código de verificação para você
            </p>
            <p className="text-500-black-14">
              Digite abaixo o código que foi enviado para seu
              <br /> telefone e E-mail
            </p>
            <div className="d-flex justify-content-center md-p-2">
              {code.map((digit, index) => (
                <input
                  key={index}
                  id={`codeInput${index}`}
                  type="text"
                  value={digit}
                  onChange={(e) => handleChange(e, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  maxLength={1}
                  style={{
                    width: "33px",
                    height: "43px",
                    margin: "5px",
                    textAlign: "center",
                    fontSize: "24px",
                    border: "1px solid #003967",
                    borderRadius: "3px",
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </WhiteContainer>

      <div className="row">
        <div className="col-xxl-8 mb-2">
          <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between justify-content-xl-end align-items-center mb-3 mb-md-0">
            <Button
              type="button"
              variant="light"
              width={216}
              height={40}
              text="Voltar"
              className=" mb-3 mb-md-0 me-xl-3"
              onClick={() => setStep("revisao")}
            />

            <Button
              text={loading ? "Enviando..." : "Continuar"}
              width={216}
              height={40}
              type="button"
              disabled={loading}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </>
  );
}
