import { FormEvent, useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import SearchIcon from "./Icons/SearchIcon";
import AxiosClient from "../Services/AxiosClient";
import { ServiceResult } from "../Interfaces";
import { DadosPessoaisModel } from "../Interfaces/Models";
import AxiosErrorHandler from "../Services/AxiosErrorHandler";
import toast from "react-hot-toast";
import { useUserStore } from "../Contexts/UserStore";
import { useEventoStore } from "../Contexts/EventoStore";
import { FaBars, FaXmark } from "react-icons/fa6";

interface Props {
  toggleNavMenu: () => void;
  responsiveCss: "d-flex" | "d-none";
}

export default function Header({ toggleNavMenu, responsiveCss }: Props) {
  const navigate = useNavigate();

  const { tituloEvento } = useEventoStore();
  const { imagemPerfil, setImagemPerfil, nome, setNome } = useUserStore();

  const [searchString, setSearchString] = useState<string>("");

  // const handleSearch = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
  //   e.preventDefault();
  //
  //   navigate({
  //     pathname: "/eventos",
  //     search: createSearchParams({
  //       searchString,
  //     }).toString(),
  //   });
  //
  //   return window.location.reload();
  // };

  const getUserData = async (): Promise<void> => {
    let profileImg: string = "/assets/images/anon_image.png";

    AxiosClient.get<ServiceResult<DadosPessoaisModel>>("/conta/dados-pessoais")
      .then(({ data: { data } }) => {
        if (data?.imagemPerfil) {
          profileImg = data.imagemPerfil;
          setImagemPerfil(profileImg);
        }

        setNome(data?.nomeCompleto.split(" ")[0] ?? "");
      })
      .catch((err) => toast.error(AxiosErrorHandler(err)));
  };

  const Logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    setNome("");
    navigate("/login");
  };

  useEffect(() => {
    if (!nome) {
      getUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <nav className="navbar navbar-light navbar-expand bg-white mb-3 topbar static-top">
        <div className="container-fluid">
          {responsiveCss === "d-flex" && (
            <>
              <button
                onClick={toggleNavMenu}
                className="btn d-none d-md-block me-3 position-fixed outline-0 border-0"
                style={{ marginLeft: -70 }}
              >
                <FaXmark className="fs-4" />
              </button>

              <button
                onClick={toggleNavMenu}
                className="btn d-block d-md-none me-3 position-fixed outline-0 border-0"
              >
                <FaBars className="fs-4" />
              </button>
            </>
          )}

          {responsiveCss === "d-none" && (
            <button onClick={toggleNavMenu} className="btn d-block me-3">
              <FaBars className="fs-4" />
            </button>
          )}

          {/* verificar se está na pa'gina de algum evento: ~/eventos/:id */}
          {window.location.pathname.includes("/eventos/") && (
            <>
              <p className="d-flex d-md-none text-black text-break m-0 fs-6">
                {tituloEvento?.length > 25
                  ? tituloEvento.substring(0, 25) + "..."
                  : tituloEvento}
              </p>

              <p className="d-none d-md-flex text-black text-break m-0 fs-5">
                EVENTO: {tituloEvento}
              </p>
            </>
          )}

          {/* Search Web */}
          {/*
          <form
            className="d-none d-sm-inline-block ms-md-3 my-2 my-md-0 mw-100 navbar-search"
            onSubmit={(e) => handleSearch(e)}
          >
            <div className="input-group">
              <button className="btn btn-light py-0 pe-0" type="submit">
                <SearchIcon />
              </button>
              <input
                className="bg-light form-control border-0 small"
                type="text"
                placeholder="Faça uma busca"
                value={searchString}
                onChange={({ target: { value } }) => setSearchString(value)}
              />
            </div>
          </form>
          */}

          <ul className="navbar-nav flex-nowrap ms-auto">
            {/* Search Mobile */}
            {/*
            <li className="nav-item dropdown d-sm-none no-arrow">
              <button
                className="btn border-0 dropdown-toggle nav-link"
                aria-expanded="false"
                data-bs-toggle="dropdown"
              >
                <SearchIcon />
              </button>
              <div
                className="dropdown-menu dropdown-menu-end p-3 animated--grow-in"
                aria-labelledby="searchDropdown"
              >
                <form
                  className="me-auto navbar-search w-100"
                  onSubmit={(e) => handleSearch(e)}
                >
                  <div className="input-group">
                    <button className="btn btn-light py-0 pe-0" type="submit">
                      <SearchIcon />
                    </button>
                    <input
                      className="bg-light form-control border-0 small"
                      type="text"
                      placeholder="Faça uma busca"
                      value={searchString}
                      onChange={({ target: { value } }) =>
                        setSearchString(value)
                      }
                    />
                  </div>
                </form>
              </div>
            </li>
            */}

            <li className="nav-item dropdown no-arrow">
              <div className="nav-item dropdown no-arrow">
                <a
                  className="dropdown-toggle nav-link cursor-pointer"
                  aria-expanded="false"
                  data-bs-toggle="dropdown"
                >
                  {/* <!-- Foto perfil --> */}
                  <p className="d-none d-sm-flex text-400-darkest-16 m-0 me-2">
                    {nome}
                  </p>
                  <img
                    src={imagemPerfil!}
                    alt="perfil"
                    className="border rounded-circle img-profile"
                  />
                </a>
                <div className="dropdown-menu shadow dropdown-menu-end animated--grow-in">
                  <a className="dropdown-item" href="/">
                    Página inicial
                  </a>
                  <a className="dropdown-item" href="/eventos">
                    Eventos
                  </a>
                  <a className="dropdown-item" href="/banners">
                    Banners
                  </a>
                  <a className="dropdown-item" href="/usuarios">
                    Usuários
                  </a>
                  <div className="dropdown-divider"></div>
                  <button
                    onClick={Logout}
                    className="dropdown-item cursor-pointer"
                  >
                    Sair
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}
