import ReactInputMask from "react-input-mask";
import Button from "../../../../Components/Button";
import EmptyModal from "../../../../Components/Modals/EmptyModal";
import { ClockIcon, EditModalIcon } from "../../../../Components/Icons";
import toast from "react-hot-toast";
import AxiosClient from "../../../../Services/AxiosClient";
import moment from "moment";
import AxiosErrorHandler from "../../../../Services/AxiosErrorHandler";
import { useState } from "react";

interface Props {
  eventoId: string;
  modalEditarSessao: boolean;
  setModalEditarSessao: React.Dispatch<React.SetStateAction<boolean>>;
  obterSessoes: () => Promise<void>;
}

export default function AdicionarSessaoGrupo({
  eventoId,
  modalEditarSessao,
  setModalEditarSessao,
  obterSessoes,
}: Props) {
  const [data, setData] = useState<string>(
    new Date().toISOString().split("T")[0]
  );
  const [hora, setHora] = useState<string>("00:00");
  const [loading, setLoading] = useState<boolean>(false);

  const postSessao = async (): Promise<void> => {
    if (!data || !hora) {
      toast.error("Informe a data e hora da sessão!");
      return;
    }

    if (Number(hora.split(":")[0]) > 23 || Number(hora.split(":")[1]) > 59) {
      toast.error("Informe uma hora válida!");
      return;
    }

    setLoading(true);

    const dataHoraISO8601 = moment(
      `${data} ${hora}`,
      "YYYY/MM/DD HH:mm"
    ).toISOString();

    let args = {
      data: dataHoraISO8601,
    };

    toast.promise(
      AxiosClient.post(`/eventos/${eventoId}/sessoes`, args)
        .then(() => {
          resetSessao();

          obterSessoes();
        })
        .finally(() => setLoading(false)),
      {
        loading: "Cadastrando sessão...",
        success: "Sessão cadastrada com sucesso!",
        error: (error) => AxiosErrorHandler(error),
      }
    );
  };

  const resetSessao = (): void => {
    setModalEditarSessao(false);
    setData(new Date().toISOString().split("T")[0]);
    setHora("00:00");
  };

  return (
    <div className="col-xl-8">
      <div className="card-cinza-claro p-3">
        <p className="text-400-darkest-16 mb-3">
          Indique a sessão que este grupo de ingressos permitirá acesso.
        </p>

        <button
          type="button"
          onClick={() => setModalEditarSessao(true)}
          className="bc-btn bc-btn-primary px-3 py-2 w-100 h-100"
          style={{ maxWidth: 200 }}
        >
          Adicionar sessão
        </button>

        <EmptyModal close={resetSessao} isOpen={modalEditarSessao}>
          <div className="row d-flex justify-content-center align-itens-center text-center">
            <EditModalIcon />

            <p className="title-h1 text-black m-0">
              Informe a data e hora da sessão do grupo
            </p>
            <div className="row g-3 mt-3 mb-4">
              <div className="col-md-6">
                <div className="input-group">
                  <input
                    autoFocus
                    type="date"
                    placeholder="Data da sessão"
                    className="form-control backstage-input"
                    style={{ height: 40 }}
                    disabled={loading}
                    value={data}
                    onChange={({ target: { value } }) => setData(value)}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="input-group">
                  <ReactInputMask
                    type="text"
                    mask="99:99"
                    maskChar="_"
                    placeholder="Hora da sessão"
                    className="form-control backstage-input"
                    style={{ height: 40 }}
                    disabled={loading}
                    value={hora}
                    onChange={({ target: { value } }) => setHora(value)}
                  />
                  <div className="input-group-text border-0 bg-cinza">
                    <ClockIcon />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-xxl-12">
                <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center">
                  <Button
                    variant="light"
                    text="Cancelar"
                    onClick={resetSessao}
                    className="px-3 py-2 mb-3 mb-md-0 me-md-3"
                    width="100%"
                    disabled={loading}
                  />

                  <Button
                    text="Adicionar sessão"
                    className="px-3 py-2"
                    width="100%"
                    disabled={loading}
                    onClick={postSessao}
                  />
                </div>
              </div>
            </div>
          </div>
        </EmptyModal>
      </div>
    </div>
  );
}
