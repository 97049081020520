export default function PendenteIcon() {
  return (
    <svg
      className="my-2"
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="28" cy="28" r="28" fill="#DD453D" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.99 19C22.47 19 18 23.48 18 29C18 34.52 22.47 39 27.99 39C33.52 39 38 34.52 38 29C38 23.48 33.52 19 27.99 19ZM28.0002 36.9999C23.5802 36.9999 20.0002 33.4199 20.0002 28.9999C20.0002 24.5799 23.5802 20.9999 28.0002 20.9999C32.4202 20.9999 36.0002 24.5799 36.0002 28.9999C36.0002 33.4199 32.4202 36.9999 28.0002 36.9999ZM27.78 24H27.72C27.32 24 27 24.32 27 24.72V29.44C27 29.79 27.18 30.12 27.49 30.3L31.64 32.79C31.98 32.99 32.42 32.89 32.62 32.55C32.7215 32.3864 32.7522 32.1886 32.705 32.0019C32.6579 31.8153 32.537 31.6558 32.37 31.56L28.5 29.26V24.72C28.5 24.32 28.18 24 27.78 24Z"
        fill="white"
      />
    </svg>
  );
}
