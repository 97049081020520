import { useNavigate, useParams } from "react-router-dom";
import BreadCrumb, { Page } from "../../../Components/Breadcrumb";
import MainLayout from "../../../Components/Layouts/Main";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import AxiosClient from "../../../Services/AxiosClient";
import AxiosErrorHandler from "../../../Services/AxiosErrorHandler";
import { Axios } from "axios";
import { ServiceResult } from "../../../Interfaces";
import { CategoriaModel } from "../../../Interfaces/Models/CategoriaModel";
import WhiteContainer from "../../../Components/WhiteContainer";
import ValidationError from "../../../Components/ValidationError";
import Input from "../../../Components/Inputs/Input";

interface CategoriasPutArgs {
  nome: string;
  descricao: string | null;
}

export default function EditarCategoria() {
  const { categoriaId } = useParams();

  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const breadCrumbHistory: Page[] = [
    {
      link: "/",
      name: "dashboard",
    },
    {
      link: "/categorias",
      name: "Categorias",
    },
    {
      link: `/categorias/${categoriaId}`,
      name: "editar categoria",
    },
  ];

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<CategoriasPutArgs>({
    defaultValues: {
      nome: "",
      descricao: "",
    },
  });

  const editarCategoria = async (data: CategoriasPutArgs): Promise<void> => {
    setLoading(true);

    let args: CategoriasPutArgs = {
      nome: data.nome,
      descricao: data.descricao,
    };

    toast.promise(
      AxiosClient.put(`/categorias/${categoriaId}`, args)
        .then(() => navigate(`/categorias`))
        .finally(() => setLoading(false)),
      {
        loading: "Editando categoria...",
        success: "Categoria editada com sucesso!",
        error: (error) => AxiosErrorHandler(error),
      }
    );
  };

  const obterCategoriaPorId = async (): Promise<void> => {
    setLoading(true);

    AxiosClient.get<ServiceResult<CategoriaModel>>(`/categorias/${categoriaId}`)
      .then(({ data: { data } }) => {
        setValue("nome", data!.nome);
        setValue("descricao", data!.descricao);
      })
      .catch((error) => {
        //navigate("/categorias");
        toast.error(AxiosErrorHandler(error));
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    obterCategoriaPorId();
  }, []);

  return (
    <MainLayout>
      <div className="mb-3">
        <BreadCrumb history={breadCrumbHistory} />
      </div>

      <form onSubmit={handleSubmit(editarCategoria)}>
        <WhiteContainer containerPadding={4} rowMarginBottom={4}>
          <div className="row g-3">
            <div className="col-xl-4">
              <Input
                autoFocus
                name="nome"
                control={control}
                errors={errors}
                validation={{
                  required: "Informe o título da categoria",
                }}
                placeholder="Informe o título da categoria"
                disabled={loading}
              />
              {errors.nome && ValidationError(errors.nome)}
            </div>

            <div className="col-xl-4">
              <Input
                name="descricao"
                control={control}
                errors={errors}
                validation={{}}
                placeholder="Informe a descrição da categoria"
                disabled={loading}
              />
              {errors.descricao && ValidationError(errors.descricao)}
            </div>

            <div className="col-xl-4 d-flex justify-content-xl-end align-items-xl-end">
              <button className="bc-btn bc-btn-primary py-2 px-3 d-flex justify-content-center align-items-center">
                <p className="text-500-white-16 m-0">Adicionar</p>
              </button>
            </div>
          </div>
        </WhiteContainer>
      </form>
    </MainLayout>
  );
}
