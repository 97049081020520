export default function ChevronUpIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.7998 10.591C3.94328 10.7348 4.13806 10.8156 4.34118 10.8156C4.5443 10.8156 4.73907 10.7348 4.88255 10.591L7.86204 7.6115L10.8415 10.591C11.0349 10.7844 11.3168 10.8599 11.5811 10.7891C11.8453 10.7183 12.0516 10.512 12.1224 10.2478C12.1932 9.98355 12.1177 9.70164 11.9243 9.50823L8.39957 5.98353C8.25609 5.83976 8.06132 5.75896 7.8582 5.75896C7.65508 5.75896 7.4603 5.83976 7.31682 5.98353L3.79212 9.50823C3.50032 9.80004 3.50032 10.2915 3.7998 10.591Z"
        fill="white"
      />
    </svg>
  );
}
