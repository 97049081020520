import toast from "react-hot-toast";
import Button from "../../../Components/Button";
import { EditIcon, LetterIcon } from "../../../Components/Icons";
import { UsuarioModel } from "../../../Interfaces/Models";
import AxiosClient from "../../../Services/AxiosClient";
import AxiosErrorHandler from "../../../Services/AxiosErrorHandler";
import { useNavigate } from "react-router-dom";

interface Props {
  usuario: UsuarioModel;
}

export default function BotoesListagemUsuarios({ usuario }: Props) {
  const navigate = useNavigate();

  const reenviarEmail = async (usuarioId: string): Promise<void> => {
    toast.promise(
      AxiosClient.post(`/usuarios/${usuarioId}/reenviar-email-confirmacao`),
      {
        loading: "Enviando e-mail de confirmação",
        success: "E-mail de confirmação reenviado com sucesso",
        error: (error) => AxiosErrorHandler(error),
      }
    );
  };

  return (
    <div className="d-flex flex-wrap justify-content-center justify-content-md-evenly align-items-center">
      {!usuario.emailConfirmado && (
        <Button
          className="m-2 px-3 rounded-pill"
          variant="light"
          text="Reenviar"
          width={110}
          height={24}
          icon={<LetterIcon />}
          onClick={() => reenviarEmail(usuario.id)}
        />
      )}

      <Button
        className="m-2 px-3 rounded-pill"
        variant="light"
        text="Editar"
        width={110}
        height={24}
        icon={<EditIcon />}
        onClick={() => navigate(`/usuarios/${usuario.id}/editar`)}
      />
    </div>
  );
}
