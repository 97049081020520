export default function CalendarMoneyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#clip0_323_2349)">
        <path
          fill="#505055"
          d="M19.5 3h-3V1.5H15V3H9V1.5H7.5V3h-3C3.675 3 3 3.675 3 4.5v15c0 .825.675 1.5 1.5 1.5h15c.825 0 1.5-.675 1.5-1.5v-15c0-.825-.675-1.5-1.5-1.5zm0 16.5h-15V9h15v10.5zm0-12h-15v-3h3V6H9V4.5h6V6h1.5V4.5h3v3z"
        ></path>
        <path
          fill="#fff"
          d="M0 0H12.659V12.191H0z"
          transform="translate(11.429 11.429)"
        ></path>
        <path
          fill="#505055"
          d="M17.758 22.604c2.098 0 4.22-.698 4.22-2.032v-6.095c0-1.334-2.123-2.032-4.22-2.032-2.097 0-4.22.698-4.22 2.032v6.095c0 1.334 2.123 2.032 4.22 2.032zm0-1.016c-1.963 0-3.165-.658-3.165-1.016v-.644c.813.425 1.993.644 3.165.644 1.173 0 2.352-.219 3.165-.644v.644c0 .358-1.201 1.016-3.165 1.016zm0-8.127c1.963 0 3.165.657 3.165 1.016 0 .358-1.201 1.015-3.165 1.015-1.963 0-3.165-.657-3.165-1.015 0-.359 1.202-1.016 3.165-1.016zm-3.165 2.403c.813.426 1.993.644 3.165.644 1.173 0 2.352-.218 3.165-.644v.644c0 .358-1.201 1.016-3.165 1.016-1.963 0-3.165-.658-3.165-1.016v-.644zm0 2.032c.813.426 1.993.644 3.165.644 1.173 0 2.352-.218 3.165-.644v.644c0 .358-1.201 1.016-3.165 1.016-1.963 0-3.165-.658-3.165-1.016v-.644z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_323_2349">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}
