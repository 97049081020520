import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import NotFound from "../Components/NotFound";
import Home from "../Pages/Home";
import Eventos from "../Pages/Eventos";
import Banners from "../Pages/Banners";
import Estatisticas from "../Pages/Eventos/Estatisticas";
import Ingressos from "../Pages/Eventos/Ingressos";
import Login from "../Pages/Login";
import PrivateRoute from "../Components/PrivateRoute";
import CadastrarBanner from "../Pages/Banners/Cadastrar";
import CadastrarEvento from "../Pages/Eventos/Cadastrar";
import EditarEvento from "../Pages/Eventos/Editar";
import CadastrarGrupo from "../Pages/Eventos/Ingressos/CadastrarGrupo";
import EditarGrupo from "../Pages/Eventos/Ingressos/EditarGrupo";
import CadastrarIngresso from "../Pages/Eventos/Ingressos/CadastrarIngresso";
import EditarIngresso from "../Pages/Eventos/Ingressos/EditarIngresso";
import CodigoPromocional from "../Pages/Eventos/CodigoPromocional";
import CadastrarCodigo from "../Pages/Eventos/CodigoPromocional/Cadastrar";
import EditarCodigo from "../Pages/Eventos/CodigoPromocional/Editar";
import Pedidos from "../Pages/Eventos/Pedidos";
import Participantes from "../Pages/Eventos/Participantes";
import EsqueciSenha from "../Pages/EsqueciSenha";
import RedefinirSenha from "../Pages/RedefinirSenha";
import Usuarios from "../Pages/Usuarios";
import EditarUsuario from "../Pages/Usuarios/Editar";
import Categorias from "../Pages/Categorias";
import EditarCategoria from "../Pages/Categorias/EditarCategoria";
import EstatisticasPortaria from "../Pages/Eventos/Portaria/Estatisticas";
import BilheteriaVendas from "../Pages/Eventos/Bilheteria/Vendas";
import BilheteriaClientes from "../Pages/Eventos/Bilheteria/Clientes";
import BilheteriaClientesIngressos from "../Pages/Eventos/Bilheteria/Clientes/BilheteriaClientesIngressos";
import PortariaEventosVinculados from "../Pages/Eventos/Portaria/EventosVinculados";
import CupomDesconto from "../Pages/Eventos/CupomDesconto";
import CupomCadastrar from "../Pages/Eventos/CupomDesconto/Cadastrar";
import CupomEditar from "../Pages/Eventos/CupomDesconto/Editar";
import IngressosParticipantesPortaria from "../Pages/Eventos/Portaria/IngressosParticipantes";
import GestaoAcesso from "../Pages/GestaoAcesso";
import CadastrarPerfil from "../Pages/GestaoAcesso/CadastrarPerfil";
import EditarPerfil from "../Pages/GestaoAcesso/EditarPerfil";
import VisualizarPerfil from "../Pages/GestaoAcesso/VisualizarPerfil";
import Permissoes from "../Pages/Eventos/Permissoes";
import Saque from "../Pages/Saque";
import { EstatisticasFinanceiro } from "../Pages/Eventos/Financeiro";

export default function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/esqueci-senha" element={<EsqueciSenha />} />
        <Route path="/redefinir-senha/:token" element={<RedefinirSenha />} />

        <Route
          path="/"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />

        <Route
          path="/eventos"
          element={
            <PrivateRoute>
              <Eventos />
            </PrivateRoute>
          }
        />
        <Route
          path="/cadastrar-evento"
          element={
            <PrivateRoute>
              <CadastrarEvento />
            </PrivateRoute>
          }
        />
        <Route
          path="/eventos/:eventoId/editar"
          element={
            <PrivateRoute>
              <EditarEvento />
            </PrivateRoute>
          }
        />

        <Route
          path="/eventos/:eventoId/estatisticas"
          element={
            <PrivateRoute>
              <Estatisticas />
            </PrivateRoute>
          }
        />

        <Route
          path="/eventos/:eventoId/financeiro"
          element={
            <PrivateRoute>
              <EstatisticasFinanceiro />
            </PrivateRoute>
          }
        />

        <Route
          path="/eventos/:eventoId/saque"
          element={
            <PrivateRoute>
              <Saque />
            </PrivateRoute>
          }
        />

        <Route
          path="/eventos/:eventoId/portaria/vinculados"
          element={
            <PrivateRoute>
              <PortariaEventosVinculados />
            </PrivateRoute>
          }
        />

        <Route
          path="/eventos/:eventoId/portaria/estatisticas"
          element={
            <PrivateRoute>
              <EstatisticasPortaria />
            </PrivateRoute>
          }
        />

        <Route
          path="/eventos/:eventoId/portaria/participantes"
          element={
            <PrivateRoute>
              <IngressosParticipantesPortaria />
            </PrivateRoute>
          }
        />

        <Route
          path="/eventos/:eventoId/ingressos/cadastrar-grupo"
          element={
            <PrivateRoute>
              <CadastrarGrupo />
            </PrivateRoute>
          }
        />
        <Route
          path="/eventos/:eventoId/ingressos/editar-grupo/:grupoId"
          element={
            <PrivateRoute>
              <EditarGrupo />
            </PrivateRoute>
          }
        />

        <Route
          path="/eventos/:eventoId/ingressos"
          element={
            <PrivateRoute>
              <Ingressos />
            </PrivateRoute>
          }
        />
        <Route
          path="/eventos/:eventoId/ingressos/grupo/:grupoId/cadastrar-ingresso"
          element={
            <PrivateRoute>
              <CadastrarIngresso />
            </PrivateRoute>
          }
        />
        <Route
          path="/eventos/:eventoId/ingressos/grupo/:grupoId/editar-ingresso/:ingressoId"
          element={
            <PrivateRoute>
              <EditarIngresso />
            </PrivateRoute>
          }
        />

        <Route
          path="/eventos/:eventoId/codigo-promocional"
          element={
            <PrivateRoute>
              <CodigoPromocional />
            </PrivateRoute>
          }
        />
        <Route
          path="/eventos/:eventoId/codigo-promocional/cadastrar-codigo"
          element={
            <PrivateRoute>
              <CadastrarCodigo />
            </PrivateRoute>
          }
        />
        <Route
          path="/eventos/:eventoId/codigo-promocional/editar-codigo/:codigoId"
          element={
            <PrivateRoute>
              <EditarCodigo />
            </PrivateRoute>
          }
        />

        <Route
          path="/eventos/:eventoId/cupom-desconto"
          element={
            <PrivateRoute>
              <CupomDesconto />
            </PrivateRoute>
          }
        />
        <Route
          path="/eventos/:eventoId/cupom-desconto/cadastrar-cupom"
          element={
            <PrivateRoute>
              <CupomCadastrar />
            </PrivateRoute>
          }
        />
        <Route
          path="/eventos/:eventoId/cupom-desconto/editar-cupom/:cupomId"
          element={
            <PrivateRoute>
              <CupomEditar />
            </PrivateRoute>
          }
        />

        <Route
          path="/eventos/:eventoId/pedidos"
          element={
            <PrivateRoute>
              <Pedidos />
            </PrivateRoute>
          }
        />

        <Route
          path="/eventos/:eventoId/participantes"
          element={
            <PrivateRoute>
              <Participantes />
            </PrivateRoute>
          }
        />
        {/* <Route path="/eventos/:eventoId/participantes/editar/:participanteId"
               element={<PrivateRoute><Participantes /></PrivateRoute>} /> */}

        <Route
          path="/eventos/:eventoId/permissoes"
          element={
            <PrivateRoute>
              <Permissoes />
            </PrivateRoute>
          }
        />

        <Route
          path="/eventos/:eventoId/bilheteria/vendas"
          element={
            <PrivateRoute>
              <BilheteriaVendas />
            </PrivateRoute>
          }
        />

        <Route
          path="/eventos/:eventoId/bilheteria/clientes"
          element={
            <PrivateRoute>
              <BilheteriaClientes />
            </PrivateRoute>
          }
        />

        <Route
          path="/eventos/:eventoId/bilheteria/clientes/:pedidoId"
          element={
            <PrivateRoute>
              <BilheteriaClientesIngressos />
            </PrivateRoute>
          }
        />

        <Route
          path="/banners"
          element={
            <PrivateRoute>
              <Banners />
            </PrivateRoute>
          }
        />
        <Route
          path="/banners/cadastrar"
          element={
            <PrivateRoute>
              <CadastrarBanner />
            </PrivateRoute>
          }
        />

        <Route
          path="/usuarios"
          element={
            <PrivateRoute>
              <Usuarios />
            </PrivateRoute>
          }
        />

        <Route
          path="/gestao-de-acesso"
          element={
            <PrivateRoute>
              <GestaoAcesso />
            </PrivateRoute>
          }
        />
        <Route
          path="/gestao-de-acesso/perfil/cadastrar"
          element={
            <PrivateRoute>
              <CadastrarPerfil />
            </PrivateRoute>
          }
        />
        <Route
          path="/gestao-de-acesso/perfil/:perfilId/editar"
          element={
            <PrivateRoute>
              <EditarPerfil />
            </PrivateRoute>
          }
        />
        <Route
          path="/gestao-de-acesso/perfil/:perfilId/visualizar"
          element={
            <PrivateRoute>
              <VisualizarPerfil />
            </PrivateRoute>
          }
        />

        <Route
          path="/usuarios/:usuarioId/editar"
          element={
            <PrivateRoute>
              <EditarUsuario />
            </PrivateRoute>
          }
        />

        <Route
          path="/categorias"
          element={
            <PrivateRoute>
              <Categorias />
            </PrivateRoute>
          }
        />

        <Route
          path="/categorias/:categoriaId"
          element={
            <PrivateRoute>
              <EditarCategoria />
            </PrivateRoute>
          }
        />

        {/* 404 pages */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}
