// ignore eslint
// @ts-nocheck

// this is the way

/** wrapper para workaround da falta de suporte a variáveis globais no react */
function SetIsRefreshing(value: boolean): void {
  window.$isRefreshing = value;
}

/** wrapper para workaround da falta de suporte a variáveis globais no react */
function GetIsRefreshing(): boolean {
  return window.$isRefreshing ?? false;
}

export { GetIsRefreshing, SetIsRefreshing };
