import clsx from "clsx";
import SessaoModel from "../../../../Interfaces/Models/SessaoModel";
import Button from "../../../../Components/Button";
import {
  ClockIcon,
  EditIcon,
  EditModalIcon,
} from "../../../../Components/Icons";
import toast from "react-hot-toast";
import { format } from "date-fns";
import { useState } from "react";
import AxiosClient from "../../../../Services/AxiosClient";
import AxiosErrorHandler from "../../../../Services/AxiosErrorHandler";
import EmptyModal from "../../../../Components/Modals/EmptyModal";
import ReactInputMask from "react-input-mask";
import moment from "moment";
import { FormatarDataSessao } from "../../../../Utils";

interface Props {
  sessoes: SessaoModel[];
  setSessoes: React.Dispatch<React.SetStateAction<SessaoModel[] | null>>;
  sessoesSelecionadas: string[];
  selecionarSessao: (sessao: string) => void;
}

export default function SessoesGrupo({
  sessoes,
  setSessoes,
  sessoesSelecionadas,
  selecionarSessao,
}: Props) {
  const [modalEditarSessao, setModalEditarSessao] = useState<boolean>(false);
  const [sessao, setSessao] = useState<SessaoModel>({} as SessaoModel);
  const [data, setData] = useState<string>("");
  const [hora, setHora] = useState<string>("");
  const [loadingEdit, setLoadingEdit] = useState<boolean>(false);

  const editarSessao = (sessao: SessaoModel): void => {
    const data = format(new Date(sessao.data), "yyyy-MM-dd");
    const hora = format(new Date(sessao.data), "HH:mm");

    setData(data);
    setHora(hora);

    setModalEditarSessao(true);
    setSessao(sessao);
  };

  const putSessao = async (): Promise<void> => {
    if (!data || !hora) {
      toast.error("Informe a data e hora da sessão!");
      return;
    }

    if (Number(hora.split(":")[0]) > 23 || Number(hora.split(":")[1]) > 59) {
      toast.error("Informe uma hora válida!");
      return;
    }

    setLoadingEdit(true);

    const dataHoraISO8601 = moment(
      `${data} ${hora}`,
      "YYYY/MM/DD HH:mm"
    ).toISOString();

    let args = {
      data: dataHoraISO8601,
    };

    toast.promise(
      AxiosClient.put(`/eventos/${sessao.eventoId}/sessoes/${sessao.id}`, args)
        .then(() => {
          resetSessao();

          const index = sessoes.findIndex((s) => s.id === sessao.id);
          const sessoesCopy = [...sessoes];
          sessoesCopy[index].data = dataHoraISO8601;
          setSessoes(sessoesCopy);
        })
        .finally(() => setLoadingEdit(false)),
      {
        loading: "Editando sessão...",
        success: "Sessão editada com sucesso!",
        error: (error) => AxiosErrorHandler(error),
      }
    );
  };

  const resetSessao = (): void => {
    setModalEditarSessao(false);
    setSessao({} as SessaoModel);
    setData("");
    setHora("");
  };

  return (
    <>
      <p className="text-500-dark-18 mt-2 m-0">Sessões</p>
      <div
        className="row g-1 mx-1 mb-2"
        style={{ maxHeight: 295, overflowY: "auto" }}
      >
        {sessoes.map((sessao, index) => (
          <div className="col-xl-6" key={index}>
            <div
              className={clsx(
                "card-cinza-claro p-2",
                sessoesSelecionadas.includes(sessao.id) && "border border-2"
              )}
            >
              <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                <div className="d-flex align-items-center p-2">
                  <input
                    type="checkbox"
                    className="cursor-pointer form-check-input"
                    style={{ width: 25, height: 25, marginRight: 10 }}
                    checked={sessoesSelecionadas.includes(sessao.id)}
                    onChange={() => selecionarSessao(sessao.id)}
                  />
                  <p
                    onClick={() => selecionarSessao(sessao.id)}
                    className="text-400-black-16 m-0 cursor-pointer"
                  >
                    {FormatarDataSessao(sessao.data)}
                  </p>
                </div>

                <Button
                  text="Alterar"
                  className="mb-2 mb-sm-0"
                  width={110}
                  height="24px"
                  icon={<EditIcon />}
                  onClick={() => editarSessao(sessao)}
                  variant="light"
                />
              </div>
            </div>
          </div>
        ))}

        {/*
          --------------------------------------------------------------------------
                                     Modal: Editar sessão                           
          --------------------------------------------------------------------------
        */}
        <EmptyModal close={resetSessao} isOpen={modalEditarSessao}>
          <div className="row d-flex justify-content-center align-itens-center text-center">
            <EditModalIcon />

            <p className="title-h1 text-black m-0">
              Informe a data e hora da sessão do grupo
            </p>
            <div className="row g-3 mt-3 mb-4">
              <div className="col-md-6">
                <div className="input-group">
                  <input
                    autoFocus
                    type="date"
                    placeholder="Data da sessão"
                    className="form-control backstage-input"
                    style={{ height: 40 }}
                    disabled={loadingEdit}
                    value={data}
                    onChange={({ target: { value } }) => setData(value)}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="input-group">
                  <ReactInputMask
                    type="text"
                    mask="99:99"
                    maskChar="_"
                    placeholder="Hora da sessão"
                    className="form-control backstage-input"
                    style={{ height: 40 }}
                    disabled={loadingEdit}
                    value={hora}
                    onChange={({ target: { value } }) => setHora(value)}
                  />
                  <div className="input-group-text border-0 bg-cinza">
                    <ClockIcon />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-xxl-12">
                <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center">
                  <Button
                    variant="light"
                    text="Cancelar"
                    onClick={resetSessao}
                    className="px-3 py-2 mb-3 mb-md-0 me-md-3"
                    width="100%"
                    disabled={loadingEdit}
                  />

                  <Button
                    text="Editar sessão"
                    className="px-3 py-2"
                    width="100%"
                    disabled={loadingEdit}
                    onClick={putSessao}
                  />
                </div>
              </div>
            </div>
          </div>
        </EmptyModal>
      </div>
    </>
  );
}
