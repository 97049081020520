import { useForm } from "react-hook-form";
import BreadCrumb, { Page } from "../../../../Components/Breadcrumb";
import MainLayout from "../../../../Components/Layouts/Main";
import WhiteContainer from "../../../../Components/WhiteContainer";
import { useNavigate, useParams } from "react-router-dom";
import CodigoAcessoStatus from "../../../../Enums/CodigoAcessoStatus";
import AxiosClient from "../../../../Services/AxiosClient";
import toast from "react-hot-toast";
import AxiosErrorHandler from "../../../../Services/AxiosErrorHandler";
import { useEffect, useState } from "react";
import FormButtons from "../../../../Components/Form/FormButtons";
import { ListServiceResult } from "../../../../Interfaces";
import { GrupoModel, IngressoModel } from "../../../../Interfaces/Models";
import VinculoIngressos from "../Components/VinculoIngressos";
import useObterNomeEventoHeader from "../../../../Hooks/useObterNomeEventoHeader";
import DarkGrayCard from "../../../../Components/DarkGrayCard";

interface CodigoAcessoPostBody {
  codigos: string[];
  eventoId: string;
  ingressos: string[];
  limiteDeUso: number;
  status: CodigoAcessoStatus;
}

export default function CadastrarCodigo() {
  const navigate = useNavigate();

  const { eventoId } = useParams();

  const { obterNomeEvento } = useObterNomeEventoHeader();

  const breadCrumbHistory: Page[] = [
    {
      link: "/",
      name: "dashboard",
    },
    {
      link: "/eventos",
      name: "Eventos",
    },
    {
      link: `/eventos/${eventoId}/codigo-promocional`,
      name: "Código promocional",
    },
    {
      link: `/eventos/${eventoId}/codigo-promocional/cadastrar-codigo`,
      name: "adicionar código",
    },
  ];

  const { handleSubmit } = useForm<CodigoAcessoPostBody>({
    defaultValues: {
      codigos: [],
      ingressos: [],
      limiteDeUso: 0,
      status: CodigoAcessoStatus.Ativo,
    },
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [ingressos, setIngressos] = useState<IngressoModel[]>([]);
  const [ingressosSelecionados, setIngressosSelecionados] = useState<string[]>(
    []
  );
  const [vincularIngresso, setVincularIngresso] = useState<boolean>(false);
  const [codigos, setCodigos] = useState<string>("");

  const [acessoNegadoIngressos, setNegadoIngressos] = useState<boolean>(false);

  const cadastrarCodigos = async (
    data: CodigoAcessoPostBody
  ): Promise<void> => {
    if (!codigos) {
      toast.error("O campo código é obrigatório.");
      return;
    }

    const args: CodigoAcessoPostBody = {
      ...data,
      codigos: codigos
        .split(/[;,]/)
        .map((codigo) => codigo.replace(/\s/g, "").trim())
        .filter((codigo) => codigo !== ""),

      eventoId: eventoId!,
      ingressos: vincularIngresso ? ingressosSelecionados : [],
    };

    toast.promise(
      AxiosClient.post(`/codigos`, args).then(() =>
        navigate(`/eventos/${eventoId}/codigo-promocional`)
      ),
      {
        loading: "Cadastrando código...",
        success: "Código cadastrado com sucesso!",
        error: (error) => AxiosErrorHandler(error),
      }
    );
  };

  const getIngressos = async (): Promise<void> => {
    setLoading(true);

    toast.promise(
      AxiosClient.get<ListServiceResult<GrupoModel>>(
        `/eventos/${eventoId}/grupos?pagina=1&paginaTamanho=100`
      )
        .then(({ data: { data } }) => {
          const ingressos: IngressoModel[] =
            data?.flatMap((grupo) => grupo.ingressos) ?? [];

          setIngressos(ingressos);
        })
        .finally(() => setLoading(false)),
      {
        loading: "Carregando ingressos...",
        success: "Ingressos carregados.",
        error: (error) => {
          if (error.response && error.response.status === 403) {
            setNegadoIngressos(true);
          }
          return toast.error(AxiosErrorHandler(error));
        },
      }
    );
  };

  const toggleVincularIngresso = (): void => {
    setVincularIngresso(!vincularIngresso);

    if (!vincularIngresso && ingressos.length === 0) {
      getIngressos();
    }
  };

  useEffect(() => {
    obterNomeEvento();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout>
      <div className="mb-3">
        <BreadCrumb history={breadCrumbHistory} />
      </div>

      <WhiteContainer containerPadding={4}>
        <div className="d-flex flex-column flex-xl-row justify-content-xl-between align-items-center">
          <p className="title-h1 text-black text-center mb-2">
            Adicionar código
          </p>
        </div>
      </WhiteContainer>

      <form onSubmit={handleSubmit(cadastrarCodigos)}>
        <WhiteContainer containerPadding={4} rowMarginBottom={4}>
          <div className="row g-3">
            <div className="col-xl-6">
              <p className="text-500-dark-18 mb-2">
                Cadastre múltiplos códigos utilizando o separador
                <span
                  className="mx-3"
                  style={{ fontSize: "2rem", color: "#595961" }}
                >
                  <strong>;</strong>
                </span>
                ou
                <span
                  className="mx-3"
                  style={{ fontSize: "2rem", color: "#595961" }}
                >
                  <strong>,</strong>
                </span>
                e não utilize espaços.
              </p>
              <textarea
                name="codigo"
                placeholder="Codigo1; Codigo2; Codigo3; ..."
                className="form-control backstage-input p-2"
                style={{ height: 120, resize: "none" }}
                value={codigos}
                onChange={({ target: { value } }) => setCodigos(value)}
              />
            </div>

            {acessoNegadoIngressos ? (
              <div className="mb-3">
                <DarkGrayCard message="Você não tem acesso a lista de ingressos." />
              </div>
            ) : (
              <>
                <div className="col-xl-8">
                  <div className="card-cinza-claro p-3">
                    <div className="form-check form-switch d-flex align-items-center ps-5 mb-3">
                      <input
                        className="form-check-input mb-1 cursor-pointer"
                        style={{ width: "3.3em", height: "1.7em" }}
                        type="checkbox"
                        role="switch"
                        id="vincular_ingresso"
                        checked={vincularIngresso}
                        onChange={toggleVincularIngresso}
                      />
                      <label
                        className="form-check-label px-2 text-500-black-16 cursor-pointer"
                        htmlFor="vincular_ingresso"
                      >
                        Vincular ingresso
                      </label>
                    </div>
                    <p className="text-400-darkest-14 m-0">
                      Vincule os ingressos a este código assim que ele for
                      criado. Eles não estarão mais visíveis ao público sem o
                      uso do código.
                    </p>
                  </div>
                </div>

                <VinculoIngressos
                  loading={loading}
                  vincularIngresso={vincularIngresso}
                  ingressos={ingressos}
                  ingressosSelecionados={ingressosSelecionados}
                  setIngressosSelecionados={setIngressosSelecionados}
                />
              </>
            )}
          </div>
        </WhiteContainer>

        <FormButtons
          submitText="Cadastrar"
          cancelAction={() =>
            navigate(`/eventos/${eventoId}/codigo-promocional`)
          }
        />
      </form>
    </MainLayout>
  );
}
