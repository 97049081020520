import { useForm } from "react-hook-form";
import BreadCrumb, { Page } from "../../../../Components/Breadcrumb";
import MainLayout from "../../../../Components/Layouts/Main";
import WhiteContainer from "../../../../Components/WhiteContainer";
import { useNavigate, useParams } from "react-router-dom";
import AxiosClient from "../../../../Services/AxiosClient";
import toast from "react-hot-toast";
import AxiosErrorHandler from "../../../../Services/AxiosErrorHandler";
import { useState } from "react";
import FormButtons from "../../../../Components/Form/FormButtons";
import CupomSituacao from "../../../../Enums/CupomSituacao";
import moment from "moment";
import InputCurrency from "../../../../Components/Inputs/InputCurrency";
import { SomenteNumeros } from "../../../../Utils";

interface CupomDescontoPostArgs {
  cupons: string[];
  descricao: string | null;
  dataVigenciaInicio: string;
  dataVigenciaFim: string;
  situacao: CupomSituacao;
  possuiQuantidadeMinima: boolean;
  quantidadeMinima: number | null;
  possuiValorMinimo: boolean;
  valorMinimoCentavos: number | null;
  desconto: number;
}

interface FormValues {
  cupons: string[];
  descricao: string | null;
  dataVigenciaInicio: string;
  dataVigenciaFim: string;
  situacao: CupomSituacao;
  possuiQuantidadeMinima: boolean;
  quantidadeMinima: number | null;
  possuiValorMinimo: boolean;
  valorMinimoCentavos: string;
  desconto: number;
}

export default function CadastrarCupom() {
  const navigate = useNavigate();
  const { eventoId } = useParams();

  const breadCrumbHistory: Page[] = [
    { link: "/", name: "dashboard" },
    { link: "/eventos", name: "Eventos" },
    {
      link: `/eventos/${eventoId}/cupom-desconto/`,
      name: "Cupom desconto",
    },
    {
      link: `/eventos/${eventoId}/cupom-desconto/cadastrar-cupom`,
      name: "adicionar cupom",
    },
  ];

  const [possuiValorMinimo, setPossuiValorMinimo] = useState<boolean>(false);
  const [possuiQuantidadeMinima, setPossuiQuantidadeMinima] =
    useState<boolean>(false);

  const { register, handleSubmit, control } = useForm<FormValues>({
    defaultValues: {
      cupons: [],
      descricao: "",
      dataVigenciaInicio: moment().local().format("YYYY-MM-DD"),
      dataVigenciaFim: moment().local().add(1, "month").format("YYYY-MM-DD"),
      situacao: CupomSituacao.Ativo,
      possuiQuantidadeMinima: false,
      quantidadeMinima: null,
      possuiValorMinimo: false,
      valorMinimoCentavos: "",
      desconto: 0,
    },
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [cupons, setCupons] = useState<string>("");

  const cadastrarCupons = async (data: FormValues): Promise<void> => {
    if (!cupons) {
      toast.error("O campo cupom é obrigatório.");
      return;
    }

    if (possuiValorMinimo && !data.valorMinimoCentavos) {
      toast.error(
        "É necessario informar uma quantia para habilitar o valor mínimo"
      );
      return;
    }

    if (possuiQuantidadeMinima && !data.quantidadeMinima) {
      toast.error(
        "É necessario informar um valor para habilidar a quantidade mínima"
      );
      return;
    }

    setLoading(true);

    const args: CupomDescontoPostArgs = {
      ...data,
      dataVigenciaInicio: moment(data.dataVigenciaInicio).utc().format(),
      dataVigenciaFim: moment(data.dataVigenciaFim).utc().format(),
      valorMinimoCentavos: data.valorMinimoCentavos
        ? Number(SomenteNumeros(data.valorMinimoCentavos))
        : null,
      possuiValorMinimo: possuiValorMinimo,
      possuiQuantidadeMinima: possuiQuantidadeMinima,
      cupons: cupons
        .split(/[;,]/)
        .map((codigo) => codigo.replace(/\s/g, "").trim())
        .filter((codigo) => codigo !== ""),
    };

    toast.promise(
      AxiosClient.post(`/eventos/${eventoId}/cupom-desconto`, args)
        .then(() => navigate(`/eventos/${eventoId}/cupom-desconto`))
        .finally(() => setLoading(false)),
      {
        loading: "Cadastrando cupom...",
        success: "Cupom cadastrado com sucesso!",
        error: (error) => AxiosErrorHandler(error),
      }
    );
  };

  return (
    <MainLayout>
      <div className="mb-3">
        <BreadCrumb history={breadCrumbHistory} />
      </div>

      <WhiteContainer containerPadding={4}>
        <div className="d-flex flex-column flex-xl-row justify-content-xl-between align-items-center">
          <p className="title-h1 text-black text-center mb-2">
            Adicionar cupom
          </p>
        </div>
      </WhiteContainer>

      <form onSubmit={handleSubmit(cadastrarCupons)}>
        <WhiteContainer containerPadding={4} rowMarginBottom={4}>
          {/*INPUT CADASTRO CUPOM*/}
          <div className="row g-3">
            <div className="col-xl-8">
              <p className="text-500-dark-18 mb-2">
                Cadastre múltiplos cupons utilizando o separador
                <span
                  className="mx-3"
                  style={{ fontSize: "2rem", color: "#595961" }}
                >
                  <strong>;</strong>
                </span>
                ou
                <span
                  className="mx-3"
                  style={{ fontSize: "2rem", color: "#595961" }}
                >
                  <strong>,</strong>
                </span>
                e não utilize espaços.
              </p>
              <textarea
                name="codigo"
                placeholder="Cupom1; Cupom2; Cupom3; ..."
                className="form-control backstage-input p-2"
                style={{ height: 120, resize: "none" }}
                value={cupons}
                onChange={({ target: { value } }) => setCupons(value)}
              />
            </div>
          </div>
          {/*Descrição*/}
          <div className="row g-3">
            <div className="col-xl-8">
              <p className="text-500-dark-18 mb-2">Descrição</p>
              <textarea
                placeholder="Descrição"
                className="form-control backstage-input p-2"
                style={{ height: 120, resize: "none" }}
                {...register("descricao")}
              />
            </div>
          </div>
          {/*QUANTIDADE MINIMA*/}
          <div className="row g-3">
            <div className="col-xl-4">
              <div className="d-flex flex-wrap-reverse justify-content-between align-items-center">
                <p className="text-500-dark-18 mb-2">Qtde mínima</p>

                <div className="form-check form-switch d-flex align-items-center ps-5 mb-2">
                  <input
                    type="checkbox"
                    className="form-check-input mb-1 cursor-pointer"
                    id="possuiQuantidadeMinima"
                    checked={possuiQuantidadeMinima}
                    onChange={({ target: { checked } }) => {
                      setPossuiQuantidadeMinima(checked);
                    }}
                  />
                  <label
                    className="form-check-label ps-2"
                    htmlFor="possuiQuantidadeMinima"
                  >
                    <p className="text-500-dark-18 mb-2"> Habilitar</p>
                  </label>
                </div>
              </div>
              <input
                type="number"
                className={`form-control backstage-input p-2 ${
                  !possuiQuantidadeMinima && "disabled"
                }`}
                disabled={!possuiQuantidadeMinima}
                placeholder="Quantidade mínima"
                {...register("quantidadeMinima", { valueAsNumber: true })}
              />
            </div>
            {/* VALOR MINIMO */}
            <div className="col-xl-4">
              <div className="d-flex flex-wrap-reverse justify-content-between align-items-center">
                <p className="text-500-dark-18 mb-2">Valor mínimo</p>

                <div className="form-check form-switch d-flex align-items-center ps-5 mb-2">
                  <input
                    type="checkbox"
                    className="form-check-input mb-1 cursor-pointer"
                    id="possuiValorMinimo"
                    checked={possuiValorMinimo}
                    onChange={({ target: { checked } }) => {
                      setPossuiValorMinimo(checked);
                    }}
                  />
                  <label
                    className="form-check-label ps-2"
                    htmlFor="possuiValorMinimo"
                  >
                    <p className="text-500-dark-18 mb-2"> Habilitar</p>
                  </label>
                </div>
              </div>

              <InputCurrency
                control={control}
                name="valorMinimoCentavos"
                validation={{}}
                className={`form-control backstage-input p-2 ${
                  !possuiValorMinimo && "disabled"
                }`}
                placeholder="Valor mínimo"
                disabled={!possuiValorMinimo}
              />
            </div>
          </div>
          {/* DATA INICIO */}
          <div className="row g-3">
            <div className="col-xl-4">
              <label htmlFor="dataVigenciaInicio" className="form-label">
                <p className="text-500-dark-18 mb-2"> Data de Início </p>
              </label>
              <input
                style={{
                  height: 40,
                }}
                type="date"
                className="form-control backstage-input"
                id="dataVigenciaInicio"
                {...register("dataVigenciaInicio")}
              />
            </div>
            {/* DATA FIM */}
            <div className="col-xl-4">
              <label htmlFor="dataVigenciaFim" className="form-label">
                <p className="text-500-dark-18 mb-2"> Data de Fim </p>
              </label>
              <input
                style={{
                  height: 40,
                }}
                type="date"
                className="form-control backstage-input"
                id="dataVigenciaFim"
                {...register("dataVigenciaFim")}
              />
            </div>
          </div>

          {/* DESCONTO */}
          <div className="row g-3">
            <div className="col-xl-4">
              <label htmlFor="desconto" className="form-label">
                <p className="text-500-dark-18 mb-2"> Desconto (%) </p>
              </label>
              <input
                style={{
                  height: 40,
                }}
                type="number"
                className="form-control backstage-input"
                id="desconto"
                {...register("desconto", { valueAsNumber: true })}
              />
            </div>
          </div>
        </WhiteContainer>

        <FormButtons
          disabled={loading}
          submitText="Cadastrar"
          cancelAction={() => navigate(`/eventos/${eventoId}/cupom-desconto`)}
        />
      </form>
    </MainLayout>
  );
}
