import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import CleanLayout from "../../Components/Layouts/Clean";
import { useValidateToken } from "../../Hooks/useValidateToken";
import { useState } from "react";
import toast from "react-hot-toast";
import AxiosClient from "../../Services/AxiosClient";
import Button from "../../Components/Button";
import Input from "../../Components/Inputs/Input";
import AxiosErrorHandler from "../../Services/AxiosErrorHandler";
import { ChevronRightIcon } from "../../Components/Icons";

interface LoginArgs {
  grant_type: "password";
  user: string;
  password: string;
  lembrarSenha: boolean;
}

export default function Login() {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [lembrarSenha, setLembrarSenha] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useValidateToken();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginArgs>({
    defaultValues: {
      user: "",
      password: "",
    },
  });

  const handleLogin = async (data: LoginArgs) => {
    setLoading(true);

    const args: LoginArgs = {
      grant_type: "password",
      user: data.user,
      password: data.password,
      lembrarSenha: lembrarSenha,
    };

    toast.promise(
      AxiosClient.postForm("/auth/token", args)
        .then(({ data: { data } }) => {
          if (lembrarSenha) {
            localStorage.setItem("auth", JSON.stringify(data));
          } else {
            sessionStorage.setItem("auth", JSON.stringify(data));
          }

          navigate("/");
        })
        .finally(() => setLoading(false)),
      {
        loading: "Entrando...",
        success: "Bem vindo!",
        error: (error: any) => AxiosErrorHandler(error),
      }
    );
  };

  return (
    <CleanLayout>
      {loading && (
        <div
          className="d-flex flex-column justify-content-start justify-content-md-center align-items-center"
          style={{ height: "30vh" }}
        >
          <img
            src="/assets/images/loading.gif"
            width={200}
            height={200}
            alt="loading ticket"
          />
        </div>
      )}

      {!loading && (
        <>
          <p className="title-h1 text-black mt-3 mb-2">Acessar sua conta</p>

          <form onSubmit={handleSubmit(handleLogin)}>
            <p className="text-400-black-18 m-0 mb-5">
              Digite seu e-mail e senha abaixo:
            </p>

            <div className="form-group mb-5">
              <Input
                control={control}
                errors={errors}
                name="user"
                placeholder="e-mail"
                type="text"
                height="56px"
                validation={{
                  required: "Por favor, informe um e-mail válido",
                  validate: {
                    user: (value: string) =>
                      (value.includes("@") && value.includes(".")) ||
                      `O e-mail '${value}' é inválido. Insira um e-mail corretamente.`,
                  },
                }}
              />
              {errors.user && (
                <p className="text-danger text-break m-0 mt-1">
                  {errors.user.message?.toString()}
                </p>
              )}
            </div>
            <div className="form-group mb-5">
              <div className="d-flex align-items-center">
                <Input
                  control={control}
                  errors={errors}
                  name="password"
                  placeholder="senha"
                  type={showPassword ? "text" : "password"}
                  height="56px"
                  validation={{
                    required: "Por favor, informe a senha.",
                    minLength: {
                      message: "A senha deve conter no mínimo 6 caracteres",
                      value: 6,
                    },
                  }}
                />
                <button
                  type="button"
                  className="bc-btn p-3"
                  style={{
                    background: errors.password ? "transparent" : "#f9f8f8",
                    border: "none",
                    borderRadius: "4px",
                    height: "56px",
                  }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <i className="fa-solid fa-eye-slash"></i>
                  ) : (
                    <i className="fa-solid fa-eye"></i>
                  )}
                </button>
              </div>
              {errors.password && (
                <p className="text-danger text-break m-0 mt-1">
                  {errors.password.message?.toString()}
                </p>
              )}
            </div>

            {/* Lembrar acesso / Esqueci a senha */}
            <div className="d-flex flex-wrap flex-column flex-lg-row justify-content-center justify-content-xxl-between align-items-center mb-5">
              <div className="form-check form-switch d-flex align-items-center ps-5">
                <input
                  className="form-check-input mb-1"
                  style={{ width: "3.3em", height: "1.7em" }}
                  type="checkbox"
                  role="switch"
                  id="lembrarAcesso"
                  checked={lembrarSenha}
                  onChange={() => setLembrarSenha(!lembrarSenha)}
                />
                <label
                  className="form-check-label ps-2"
                  htmlFor="lembrarAcesso"
                >
                  Lembrar do meu acesso
                </label>
              </div>

              <Link to="/esqueci-senha" className="bc-link text-center ps-lg-4">
                Esqueci a senha
              </Link>
            </div>

            <div className="g-5">
              <div className="col-lg-9">
                <Button
                  className="bc-btn bc-btn-primary"
                  disabled={!!errors.user || !!errors.password}
                  text="Acessar conta"
                  icon={<ChevronRightIcon />}
                  type="submit"
                />
              </div>
            </div>
          </form>
        </>
      )}
    </CleanLayout>
  );
}
