export default function DownloadTableIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#595961"
        d="M8 12L3 7l1.4-1.45L7 8.15V0h2v8.15l2.6-2.6L13 7l-5 5zm-6 4c-.55 0-1.02-.196-1.412-.587A1.93 1.93 0 010 14v-3h2v3h12v-3h2v3c0 .55-.196 1.021-.587 1.413A1.92 1.92 0 0114 16H2z"
      ></path>
    </svg>
  );
}
