import { useParams, useSearchParams } from "react-router-dom";
import MainLayout from "../../../../Components/Layouts/Main";
import useObterNomeEventoHeader from "../../../../Hooks/useObterNomeEventoHeader";
import BreadCrumb, { Page } from "../../../../Components/Breadcrumb";
import { useEffect, useState } from "react";
import WhiteContainer from "../../../../Components/WhiteContainer";
import { ParticipanteModel } from "../../../../Interfaces/Models";
import AxiosClient from "../../../../Services/AxiosClient";
import { ListServiceResult } from "../../../../Interfaces";
import toast from "react-hot-toast";
import AxiosErrorHandler from "../../../../Services/AxiosErrorHandler";
import SkeletonListagem from "../../../../Components/SkeletonListagem";
import DarkGrayCard from "../../../../Components/DarkGrayCard";
import { TablePagination } from "../../../../Components/table-pagination";
import PromptModal from "../../../../Components/Modals/PromptModal";
import {
  SuccessModalIcon,
  WarningModalIcon,
} from "../../../../Components/Icons";
import { IngressoCompradoSituacao } from "../../../../Enums/IngressoCompradoSituacao";

export default function IngressosParticipantesPortaria() {
  const { eventoId } = useParams();

  const { obterNomeEvento } = useObterNomeEventoHeader();

  const [loading, setLoading] = useState<boolean>(false);
  const [participantes, setParticipantes] = useState<ParticipanteModel[]>([]);

  const [acessoNegadoParticipantes, setAcessoNegadoParticipantes] =
    useState<boolean>(false);

  const [numeroPaginas, setNumeroPaginas] = useState<number>(0);
  const [searchParams] = useSearchParams();

  const pagina = Number(searchParams.get("page")) || 1;
  const paginaTamanho = Number(searchParams.get("size")) || 10;

  const [
    modalValidarIngressoParticipante,
    setModalValidarIngressoParticipante,
  ] = useState<string | null>(null);

  const [modalConfirmacao, setModalConfirmacao] = useState<string | null>(null);

  const [ingressoSelecionado, setIngressoSelecionado] =
    useState<ParticipanteModel>({} as ParticipanteModel);

  const abrirModalValidarIngressoParticipante = (
    ingressoParticipante: ParticipanteModel
  ): void => {
    setIngressoSelecionado(ingressoParticipante);
    setModalValidarIngressoParticipante(ingressoParticipante.id);
  };

  const validarParticipante = async (): Promise<void> => {
    setModalValidarIngressoParticipante(null);

    toast.promise(
      AxiosClient.put(
        `/eventos/${eventoId}/portaria/participantes/${ingressoSelecionado.id}`
      ),
      {
        loading: "Validando ingresso...",
        success: () => {
          setModalConfirmacao(ingressoSelecionado.id);
          getParticipantes();
          return "Ingresso validado com sucesso!";
        },
        error: (error) => AxiosErrorHandler(error),
      }
    );
  };

  const getRequestPathWIthQueryParams = (): string => {
    let url: string = `/eventos/${eventoId}/portaria/participantes`;
    url += `?pagina=${pagina}`;
    url += `&paginaTamanho=${paginaTamanho}`;

    return url;
  };

  const getParticipantes = async (): Promise<void> => {
    setLoading(true);

    AxiosClient.get<ListServiceResult<ParticipanteModel>>(
      getRequestPathWIthQueryParams()
    )
      .then(({ data }) => {
        setNumeroPaginas(data?.pages || 0);
        setParticipantes(data?.data ?? []);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          setAcessoNegadoParticipantes(true);
          return;
        }
        toast.error(AxiosErrorHandler(error));
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    obterNomeEvento();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getParticipantes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagina, paginaTamanho]);

  const breadCrumbHistory: Page[] = [
    { link: "/", name: "Dashboard" },
    { link: "/eventos", name: "Eventos" },
    {
      link: `/eventos/${eventoId}/portaria/participantes`,
      name: "Portaria - Participantes",
    },
  ];

  return (
    <MainLayout>
      <div className="mb-3">
        <BreadCrumb history={breadCrumbHistory} />
      </div>

      <WhiteContainer containerPadding={4} containerWidth={12}>
        <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-sm-between align-items-center">
          <p className="title-h1 text-black mb-2">Participantes</p>

          <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center">
            <p className="text-black mb-2 fw-semibold">
              Ativos:{" "}
              {
                participantes.filter(
                  (participante) =>
                    participante.situacao === IngressoCompradoSituacao.Ativo
                ).length
              }
            </p>
            <p className="text-black mb-2 fw-semibold mx-3">
              Utilizados:{" "}
              {
                participantes.filter(
                  (participante) =>
                    participante.situacao === IngressoCompradoSituacao.Utilizado
                ).length
              }
            </p>
            <p className="text-black mb-2 fw-semibold">
              Totais:{" "}
              {
                participantes.filter(
                  (participante) =>
                    participante.situacao === IngressoCompradoSituacao.Ativo ||
                    participante.situacao === IngressoCompradoSituacao.Utilizado
                ).length
              }
            </p>
          </div>
        </div>
      </WhiteContainer>

      <WhiteContainer
        rowMarginBottom={4}
        containerPadding={4}
        containerWidth={12}
      >
        {loading ? (
          <SkeletonListagem />
        ) : acessoNegadoParticipantes ? (
          <div className="mb-3">
            <DarkGrayCard message="Acesso negado para listagem de participantes da portaria." />
          </div>
        ) : participantes.length === 0 ? (
          <DarkGrayCard message="Não há participantes por aqui!" />
        ) : (
          <div className="row">
            <div className="d-none d-lg-block col-lg-12 mb-3">
              <div className="row">
                <div className="col-lg-3">
                  <p className="text-500-black-16 m-0">Nome</p>
                </div>
                <div className="col-lg-3">
                  <p className="text-500-black-16 m-0">E-mail</p>
                </div>
                <div className="col-lg-3">
                  <p className="text-500-black-16 m-0">Ingresso</p>
                </div>
                <div className="col-lg-3">
                  <p className="text-500-black-16 m-0">Situação</p>
                </div>
              </div>
            </div>

            {participantes.map((participante) => (
              <div className="col-lg-12" key={participante.id}>
                <div
                  className={`card-cinza-claro p-3 mb-3 cursor-pointer ${
                    participante.situacao === IngressoCompradoSituacao.Ativo
                      ? "border border-success"
                      : "" ||
                        participante.situacao ===
                          IngressoCompradoSituacao.Utilizado
                      ? "border border-warning"
                      : ""
                  }`}
                  onClick={() =>
                    abrirModalValidarIngressoParticipante(participante)
                  }
                >
                  <div className="row">
                    <div className="align-self-center col-lg-3">
                      <p className="d-block d-lg-none text-500-black-16 m-0 mt-3">
                        Nome
                      </p>
                      <p className="text-500-darkest-14 text-break m-0">
                        {participante.nomeCompleto}
                      </p>
                    </div>

                    <div className="align-self-center col-lg-3">
                      <p className="d-block d-lg-none text-500-black-16 m-0 mt-3">
                        Email
                      </p>
                      <p className="text-500-darkest-14 text-break m-0">
                        {participante.email}
                      </p>
                    </div>
                    <div className="align-self-center col-lg-3">
                      <p className="d-block d-lg-none text-500-black-16 m-0 mt-3">
                        Ingresso
                      </p>
                      <p className="text-500-darkest-14 text-break m-0">
                        {participante.ingressoNome}
                      </p>
                    </div>
                    <div className="align-self-center col-lg-3">
                      <p className="d-block d-lg-none text-500-black-16 m-0 mt-3">
                        Situação
                      </p>
                      <p className="text-500-darkest-14 text-break m-0 text-left mx-0 mx-lg-2">
                        {participante.situacao}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {loading && <SkeletonListagem />}
      </WhiteContainer>

      <PromptModal
        isOpen={modalValidarIngressoParticipante !== null}
        close={() => setModalValidarIngressoParticipante(null)}
        icon={
          ingressoSelecionado.situacao ===
          IngressoCompradoSituacao.Utilizado ? (
            <WarningModalIcon />
          ) : (
            <SuccessModalIcon />
          )
        }
        title={
          ingressoSelecionado.situacao === IngressoCompradoSituacao.Ativo
            ? `Ingreesso válido`
            : ingressoSelecionado.situacao ===
              IngressoCompradoSituacao.Utilizado
            ? "Ingresso já utilizado"
            : "Validar ingresso"
        }
        subtitle={
          ingressoSelecionado.situacao === IngressoCompradoSituacao.Ativo
            ? `Participante: \n ${ingressoSelecionado.nomeCompleto}`
            : ingressoSelecionado.situacao ===
              IngressoCompradoSituacao.Utilizado
            ? `Participante: \n ${ingressoSelecionado.nomeCompleto}`
            : ""
        }
        cancelText="Cancelar"
        cancelAction={() => setModalValidarIngressoParticipante(null)}
        confirmText={
          ingressoSelecionado.situacao === IngressoCompradoSituacao.Ativo
            ? "Validar entrada"
            : ingressoSelecionado.situacao ===
              IngressoCompradoSituacao.Utilizado
            ? "Reativar ingresso"
            : "Validar"
        }
        confirmAction={validarParticipante}
        backgroundModal={
          ingressoSelecionado.situacao === IngressoCompradoSituacao.Ativo
            ? "#52CC6C"
            : ingressoSelecionado.situacao ===
              IngressoCompradoSituacao.Utilizado
            ? "#F9A000"
            : "#fff"
        }
        colorTextModal="text-white"
        removeCancelButton={true}
        loading={loading}
      />

      {/* Modal de confirmação  */}
      <PromptModal
        isOpen={modalConfirmacao !== null}
        close={() => setModalConfirmacao(null)}
        icon={<SuccessModalIcon />}
        title={
          ingressoSelecionado.situacao === IngressoCompradoSituacao.Ativo
            ? "Ingresso atualizado com sucesso!"
            : ingressoSelecionado.situacao ===
              IngressoCompradoSituacao.Utilizado
            ? `Ingresso reativado com sucesso`
            : "Atualizado com sucesso"
        }
        subtitle={`A situação do ingresso do participante ${ingressoSelecionado.nomeCompleto} foi alterada com sucesso`}
        cancelAction={() => setModalConfirmacao(null)}
        confirmText="Voltar"
        confirmAction={() => setModalConfirmacao(null)}
        backgroundModal="#52CC6C"
        colorTextModal="text-white"
        removeCancelButton={true}
        loading={loading}
      />

      <TablePagination numeroPaginas={numeroPaginas} />
    </MainLayout>
  );
}
