import { useNavigate, useParams } from "react-router-dom";
import BreadCrumb, { Page } from "../../../../Components/Breadcrumb";
import MainLayout from "../../../../Components/Layouts/Main";
import WhiteContainer from "../../../../Components/WhiteContainer";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import AxiosClient from "../../../../Services/AxiosClient";
import toast from "react-hot-toast";
import Input from "../../../../Components/Inputs/Input";
import ValidationError from "../../../../Components/ValidationError";
import FormButtons from "../../../../Components/Form/FormButtons";
import AxiosErrorHandler from "../../../../Services/AxiosErrorHandler";
import SessaoModel from "../../../../Interfaces/Models/SessaoModel";
import Loading from "../../../../Components/Loading";
import useObterSessoesGrupo from "../../../../Hooks/useObterSessoesGrupo";
import SessoesGrupo from "../Components/SessoesGrupo";
import AdicionarSessaoGrupo from "../Components/AdicionarSessaoGrupo";
import useObterNomeEventoHeader from "../../../../Hooks/useObterNomeEventoHeader";
import DarkGrayCard from "../../../../Components/DarkGrayCard";

interface GruposPostBody {
  nome: string;
  //quantidade: number;
  sessoes: string[] | null;
}

export default function CadastrarGrupo() {
  const { eventoId } = useParams();

  const { obterNomeEvento } = useObterNomeEventoHeader();

  const breadCrumbHistory: Page[] = [
    {
      link: "/",
      name: "dashboard",
    },
    {
      link: "/eventos",
      name: "Eventos",
    },
    {
      link: `/eventos/${eventoId}/ingressos`,
      name: "Ingressos",
    },
    {
      link: `/eventos/${eventoId}/ingressos/cadastrar-grupo`,
      name: "adicionar grupo",
    },
  ];

  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [modalEditarSessao, setModalEditarSessao] = useState<boolean>(false);

  const [sessoes, setSessoes] = useState<SessaoModel[] | null>(null);
  const [sessoesSelecionadas, setSessoesSelecionadas] = useState<string[]>([]);

  const { loadingSessoes, getSessoes, acesseNegadoSessoes } =
    useObterSessoesGrupo();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<GruposPostBody>({
    defaultValues: {
      nome: "",
      //quantidade: 0,
      sessoes: null,
    },
  });

  const selecionarSessao = (sessao: string): void => {
    const sessoes = [...sessoesSelecionadas];
    const index = sessoes.indexOf(sessao);

    if (index > -1) {
      sessoes.splice(index, 1);
    } else {
      sessoes.push(sessao);
    }

    setSessoesSelecionadas(sessoes);
  };

  const cadastrarGrupo = async (data: GruposPostBody): Promise<void> => {
    setLoading(true);

    let args: GruposPostBody = {
      nome: data.nome,
      //quantidade: Number(data.quantidade),
      sessoes: sessoesSelecionadas.length > 0 ? sessoesSelecionadas : null,
    };

    toast.promise(
      AxiosClient.post(`/eventos/${eventoId}/grupos`, args)
        .then(() => navigate(`/eventos/${eventoId}/ingressos`))
        .finally(() => setLoading(false)),
      {
        loading: "Cadastrando grupo...",
        success: "Grupo adicionado com sucesso!",
        error: (error) => AxiosErrorHandler(error),
      }
    );
  };

  const obterSessoes = async (): Promise<void> => {
    const sessoes: SessaoModel[] | null = await getSessoes(eventoId!);
    setSessoes(sessoes);
  };

  useEffect(() => {
    obterSessoes();
    obterNomeEvento();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout>
      <div className="mb-3">
        <BreadCrumb history={breadCrumbHistory} />
      </div>

      <WhiteContainer containerPadding={4}>
        <div className="d-flex flex-column flex-xl-row justify-content-xl-between align-items-center">
          <p className="title-h1 text-black text-center mb-2">
            Adicionar grupo
          </p>
        </div>
      </WhiteContainer>

      <form onSubmit={handleSubmit(cadastrarGrupo)}>
        <WhiteContainer containerPadding={4} rowMarginBottom={4}>
          <div className="row g-3">
            <div className="col-xl-4">
              <p className="text-500-dark-18 mb-2">Nome do grupo</p>
              <Input
                autoFocus
                name="nome"
                control={control}
                errors={errors}
                validation={{
                  required: "Informe o nome do grupo",
                }}
                placeholder="Informe o nome do grupo"
              />
              {errors.nome && ValidationError(errors.nome)}
            </div>

            {/*<div className="col-xl-8">
              <p className="text-500-dark-18 mb-2">Quantidade</p>
              <Input
                type="number"
                min={0}
                name="quantidade"
                control={control}
                errors={errors}
                validation={{
                  required: "Informe um valor acima de zero para a quantidade",
                  min: {
                    value: 1,
                    message: "Informe um valor acima de zero para a quantidade",
                  },
                  max: {
                    value: 100000000,
                    message:
                      "Informe um valor abaixo de 100.000.000 para a quantidade",
                  },
                }}
                placeholder="Informe a quantidade"
              />
              {errors.quantidade && ValidationError(errors.quantidade)}
              </div>*/}

            {loadingSessoes ? (
              <div className="my-5">
                <Loading container="0" />
              </div>
            ) : acesseNegadoSessoes ? (
              <div className="mb-1">
                <DarkGrayCard message="Você não tem acesso a listagem de sessões." />
              </div>
            ) : sessoes && sessoes.length > 0 ? (
              <SessoesGrupo
                sessoes={sessoes}
                setSessoes={setSessoes}
                sessoesSelecionadas={sessoesSelecionadas}
                selecionarSessao={selecionarSessao}
              />
            ) : (
              <div className="row g-0" style={{ minHeight: "50vh" }}>
                <div className="col-lg-12">
                  <DarkGrayCard message="Você ainda não tem sessões por aqui!" />
                </div>
              </div>
            )}

            <AdicionarSessaoGrupo
              eventoId={eventoId!}
              modalEditarSessao={modalEditarSessao}
              setModalEditarSessao={setModalEditarSessao}
              obterSessoes={obterSessoes}
            />
          </div>
        </WhiteContainer>

        <FormButtons
          cancelAction={() => navigate(-1)}
          submitText="Adicionar grupo"
          disabled={loading}
        />
      </form>
    </MainLayout>
  );
}
