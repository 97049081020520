import { Link } from "react-router-dom";
import LogoSvgSemiWhite from "./Logo/LogoSvgSemiWhite";
import LogoutIcon from "./Icons/LogoutIcon";
import CustomNavLink from "./CustomNavLink";
import { HomeIcon, EventoIcon, BannerIcon, ParticipantesIcon } from "./Icons";
import { useEffect } from "react";
import AxiosClient from "../Services/AxiosClient";
import { ServiceResult } from "../Interfaces";
import { FuncionalidadesModel } from "../Interfaces/Models";
import { toast } from "react-hot-toast";
import AxiosErrorHandler from "../Services/AxiosErrorHandler";
import getAppPortariaDownloadUrl from "../Services/getAppPortariaDownloadUrl";
import { BiSolidCategory } from "react-icons/bi";
import { MdManageAccounts } from "react-icons/md";
interface Props {
  logout: () => void;
  responsiveCss: "d-flex" | "d-none";
}

interface NavItem {
  url: string;
  name: string;
  icon: JSX.Element;
}

export default function NavMenuInicial({ responsiveCss, logout }: Props) {
  const items: NavItem[] = [
    { url: "/", name: "Página inicial", icon: <HomeIcon /> },
    { url: "/eventos", name: "Eventos", icon: <EventoIcon /> },
    { url: "/banners", name: "Banners", icon: <BannerIcon /> },
    {
      url: "/categorias",
      name: "Categorias",
      icon: <BiSolidCategory className="fs-4" />,
    },
    { url: "/usuarios", name: "Usuários", icon: <ParticipantesIcon /> },
    {
      url: "/gestao-de-acesso",
      name: "Gestão de acesso",
      icon: <MdManageAccounts className="fs-4" />,
    },
  ];

  const getUserRole = async (): Promise<void> => {
    AxiosClient.get<ServiceResult<FuncionalidadesModel>>(
      "/conta/funcionalidades",
    )
      .then(({ data: { data } }) => {
        if (!data) return;

        if (data.criarEvento) {
          localStorage.setItem("role", "produtor");
        } else {
          localStorage.setItem("role", "coprodutor");
        }
      })
      .catch((error) => toast.error(AxiosErrorHandler(error)));
  };

  useEffect(() => {
    if (!localStorage.getItem("role")) {
      getUserRole();
    }
  }, []);

  return (
    <nav
      className={`${responsiveCss} navbar align-items-start sidebar accordion bg-navmenu-whitelabel p-0`}
    >
      <div className="container-fluid d-flex flex-column p-0 min-vh-100 h-100 px-3">
        <div className="d-flex flex-column justify-content-center align-items-center w-100">
          <Link
            to="/"
            reloadDocument
            className="navbar-brand d-flex justify-content-center align-items-center sidebar-brand m-0 p-0"
          >
            <div className="sidebar-brand-icon">
              <div style={{ width: "100%", maxWidth: 120, maxHeight: 48 }}>
                <LogoSvgSemiWhite />
              </div>
            </div>
          </Link>
          <hr className="sidebar-divider my-0" />
          <ul className="navbar-nav w-100" id="accordionSidebar">
            {/* Menu para tela inicial */}
            {items.map((p, index) => (
              <CustomNavLink
                key={index}
                href={p.url}
                title={p.name}
                icon={p.icon}
              />
            ))}
          </ul>
        </div>
        <div className="mt-5 d-flex flex-column align-items-center">
          <button
            onClick={getAppPortariaDownloadUrl}
            className="bc-btn bc-btn-white mb-3 px-3 py-2 d-flex flex-wrap justify-content-center align-items-center"
            style={{ minHeight: 56, maxWidth: 216 }}
            title="Baixar app Portaria"
          >
            <div className="d-none d-md-flex">Baixar app Portaria</div>
            <i className="fa-solid fa-download p-2"></i>
          </button>

          <button
            onClick={logout}
            className="bc-btn bc-btn-white w-100 mb-4 px-3 px-md-4 py-2 d-flex flex-wrap justify-content-center align-items-center"
            style={{ maxWidth: 216, minHeight: 56 }}
            title="Sair da conta"
          >
            <div className="d-none d-md-flex">Sair da conta &nbsp;</div>
            <LogoutIcon />
          </button>
          <p className="text-500-white-14 text-center mb-4">V.2. Tickets App</p>
        </div>
      </div>
    </nav>
  );
}
